import React, { useEffect, useState } from "react";
import axios from 'axios';
import { KRAKEN_URL } from '../../constants'

import "./css/Pagos.css"
import { CheckAll } from 'react-bootstrap-icons';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next';

//Elementos bootstrap
import { Form, Modal, Col } from "react-bootstrap";
//componentes
import ModalAgregarUsuario from '../Configuracion/Empresa/Usuarios/UsuarioContactos/ModalAgregarUsuarioContacto';
import { data } from "jquery";

function ModalNotificarPago(props) {
     console.log(props)
    const idContactos = props.children[3]?props.children[3]:[]
    const compartir = props.children[2]?true:false
    const token = JSON.parse(localStorage.getItem('token'));
    const userId = token.userId;
    const id = props.children[0];
    const empresaAlias = props.children[1];
    const [request] = useState(false);
    const [listContactos, setListContactos] = useState([]);
    const [updateListado, setUpdateListado] = useState(false);
    const [error, setError] = useState(false);
    const [statusError, setStatusError] = useState('');
    const [comentario, setComentario] = useState('');
    const [seleccionados, setSeleccionados] = useState([]);//Aquí se guardan sólo los id's de los contactos que se seleccionen
    const [ctcSeleccionados, setCtcSeleccionados] = useState([]);//Aquí se guardan sólo los emails que se seleccionen
    const usuario = {
        userId: userId,
        username: token.username,
        empresaAlias: empresaAlias
    }
    const [pagador] = useState(props.children[4]?props.children[4] :"")

    const [modalAgregarUsuarioShow, setModalAgregarUsuarioShow] = useState(false);
    let modalAgregarUsuarioClose = () => { setModalAgregarUsuarioShow(false); }
    let updateGetContactos = () => { setUpdateListado(!!!updateListado); setListContactos([]); }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getContactos, [updateListado, empresaAlias, userId]);
  
    function getContactos() {
        let url = compartir ?"empresa/usuarios/list": 'empresa/usuario/contactos/list';
       
        let solicitud = compartir? {empresaId: id }:{userId:userId,empresa:empresaAlias}
       
        axios.post(KRAKEN_URL + url, solicitud).then((res) => {
            if (res.data.result.code === 200 && res.data.list.length) {
               
                let auxContactos = []
                res.data.list.map((e)=>{
                    console.log(e)
                    if(e.username !==props.children[5]){
                    if(pagador ==="pendiente"&&e.userType!=="ADMINISTRADOR" ){
                        auxContactos.push({ usuarioId:e.userId, usuarioEmail: e.email, usuarioNombre:e.username })
                    }
                    else if(e.email!==pagador ){
                        
                        if(pagador !=="pendiente")
                    auxContactos.push({ usuarioId:e.userId, usuarioEmail: e.email, usuarioNombre:e.username })
                }}
                })
               
                setListContactos(compartir ?eliminarPorId(auxContactos):res.data.list);
              
                if(idContactos.length>0){
                    let auxIdArray = []
                    let auxEmailArray =[]
                
                    idContactos.map((e)=>{ 
                        auxIdArray.push(e.usuarioId)
                        auxEmailArray.push( { usuarioId:e.usuarioId, usuarioEmail: e.usuarioEmail, usuarioNombre:e.usuarioNombre })
                    })
                    setCtcSeleccionados(auxEmailArray) 
                    setSeleccionados(auxIdArray) 
                   }
                   else   if(!compartir) 
                  {
                    setSeleccionados([...seleccionados, res.data.list[0].contactoId]);
                    setCtcSeleccionados([...ctcSeleccionados, res.data.list[0].emailContacto]);
                   }
                
                  
                
              
               
            } else {
                setListContactos('');
               
            }
        }).catch((error) => {
            setError(true);
            setStatusError("Ocurrió un error, no se pudieron recuperar los contactos de este usuario");
        })
    }
    function eliminarPorId(arreglo) {
        arreglo = arreglo.filter(function(objeto) {
            return objeto.usuarioNombre !== usuario.username
        });
       return arreglo
    }
    function confirmarPago() {
     
        props.onContactosSelected(ctcSeleccionados, comentario);
        props.onHide();
    }

    function handleComentarioChange(e) {
        setComentario(e.target.value);
    }

    let handleSizePerPage = ({ page, onSizePerPageChange }, newSizePerPage) => {
        onSizePerPageChange(newSizePerPage, page);
    }

    let mostrarListaContactos = () => {
        if (error) {
            return (
                <div>{statusError}</div>
            )
        } else {
            const columns = [
                {
                    dataField: compartir ? 'usuarioNombre':'contacto',
                    text:compartir?'Nombre del usuario' :'Nombre de contacto',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                },
                {
                    dataField: compartir? 'usuarioEmail': 'emailContacto',
                    text:compartir?'Email del usuario' : 'Email del contacto',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                }];

            const selectRow = {
                mode: 'checkbox',
                clickToSelect: true,
                style: { backgroundColor: 'rgb(155, 227, 230)' },
                selected: seleccionados,//renglones seleccionados de acuerdo al contactoId (keyField del BootstrapTable)
                onSelect: (row, isSelect, rowIndex, e) => {
                
                    if (isSelect) {
                        compartir ?setSeleccionados([...seleccionados, row.usuarioId]): setSeleccionados([...seleccionados, row.contactoId]);
                        
                        compartir ? setCtcSeleccionados([...ctcSeleccionados, { usuarioId: row.usuarioId, usuarioEmail: row.usuarioEmail, usuarioNombre:row.usuarioNombre}]) : setCtcSeleccionados([...ctcSeleccionados, row.emailContacto]);
                    } else {
                        let eliminarSeleccionado = seleccionados.filter(x => x !== row.contactoId);
                        let eliminarSeleccionadoCompartir = seleccionados.filter(x => x !== row.usuarioId);
                        setSeleccionados(compartir?eliminarSeleccionadoCompartir :eliminarSeleccionado);
                        let eliminarContacto = ctcSeleccionados.filter(x => x !== row.emailContacto);
                        let eliminarContactoCompartir = ctcSeleccionados.filter(x => x.usuarioEmail !== row.usuarioEmail);

                     compartir?  setCtcSeleccionados(eliminarContactoCompartir): setCtcSeleccionados(eliminarContacto);
                    }
                },
                onSelectAll: (isSelect, rows, e) => {
                    const ids = compartir ? rows.map(r => r.usuarioId): rows.map(r => r.contactoId);
                    const emails = rows.map(r => r.emailContacto);
                    if (isSelect) {
                        setSeleccionados(ids);
                        compartir ? setCtcSeleccionados(rows.map(r => ({ usuarioId: r.usuarioId, usuarioEmail: r.usuarioEmail,usuarioNombre:r.usuarioNombre }))) : setCtcSeleccionados(emails);
                    } else {
                        setSeleccionados([]);
                        setCtcSeleccionados([]);
                    }
                }
            }

            function indication() {
                return listContactos.length ? "La búsqueda no coincide con ningún criterio" : "No hay registros que mostrar, da clic en el botón 'Agregar contacto' para crear uno";
            }

            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size}
                </span>
            );

            const options = {
                custom: true,
                sizePerPage: 5,
                totalSize: listContactos.length,
                paginationTotalRenderer: customTotal
            }

            return <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div>
                        <Form.Row className="d-flex justify-content-center">
                          {compartir?"":
                          <button
                                className="m-1 button-default-req"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Registrar contacto"
                                onClick={() => { setModalAgregarUsuarioShow(true) }}
                            ><span>Agregar contacto</span>
                            </button>}  
                            <ModalAgregarUsuario show={modalAgregarUsuarioShow} onHide={modalAgregarUsuarioClose} onUpdate={updateGetContactos}>{usuario}</ModalAgregarUsuario>
                        </Form.Row>
                        <Form.Row className="p-2">
                            <div className="d-flex flex-row">
                                <Form.Label column>Mostrar</Form.Label>
                                <Col>
                                    <Form.Control size="sm" as="select" onChange={(e) => handleSizePerPage(paginationProps, e.target.value)}>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value={listContactos}>Todos</option>
                                    </Form.Control>
                                </Col>
                            </div>
                            <div className="d-flex flex-row">
                                <Col>
                                    <PaginationTotalStandalone {...paginationProps} />
                                </Col>
                            </div>
                            <div className="d-flex ml-auto flex-row-reverse">
                                <Col>
                                    <PaginationListStandalone {...paginationProps} />
                                </Col>
                                <Form.Label column>Página</Form.Label>
                            </div>
                        </Form.Row>
                        <div className="table-responsive table-sm">
                            <BootstrapTable
                                id="listaContactos"
                                keyField={compartir? "usuarioId":"contactoId"}
                                data={listContactos}
                                columns={columns}
                                selectRow={selectRow}
                                filter={filterFactory()}
                                bordered={false}
                                striped
                                hover
                                condensed
                                noDataIndication={indication}
                                {...paginationTableProps}
                            />
                        </div>
                    </div>
                )}
            </PaginationProvider>
        }
    }

    return (
        <section className="text-center">
            <Modal
                show={props.show}
                //onHide={props.onHide}
                size="lg"
                id="modalNotificarPago"
                centered
            >
                <Modal.Header className="modal-header-notificar-pago" closeButton onHide={props.onHide}>
                    <p><CheckAll size={22} color="#5B4B51" />  {compartir?"Compartir requisición con otros usuarios":`Notificar pago de la requisición #${id}`} </p>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row as={Col}>
                        <p>
                            <CheckAll size={22} color="#5B4B51" />
                            &nbsp;Seleccione al menos un contacto para enviar notificación
                        </p>
                        {request ? "Cargando..." : mostrarListaContactos()}
                       {compartir?"": <Form.Control id="comentario" as="textarea" rows={1} placeholder="¿Desea agregar algún comentario?" value={comentario} onChange={handleComentarioChange}></Form.Control>}
                    </Form.Row>
                    <div className="d-flex justify-content-center">
                        <button
                            className="m-1 button-default-confirmar"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Confirmar"
                            onClick={confirmarPago}
                        ><span>Confirmar</span>
                        </button>
                        <button
                            className="m-1 button-default-cancelar"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Cancelar"
                            onClick={props.onHide}
                        ><span>Cancelar</span>
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    );

}

export default ModalNotificarPago;