//const API_PROVEEDORES_URL = 'https://api-prove.elothe.dev/proveedores/resources/';

const API_PROVEEDORES_URL = "https://req.elothe.dev/api-proveedores/resources/";

const KRAKEN_URL = "https://dev.hixsa.net/Kraken/api/admin/";

const KRAKEN_AUTH = "https://dev.hixsa.net/Kraken/api/authProveedores";

//const API_PROVEEDORES_URL = 'http://localhost:8080/api-proveedores/resources/';

//const KRAKEN_URL = 'http://localhost:8080/Kraken/api/admin/';

//const KRAKEN_AUTH = 'http://localhost:8080/Kraken/api/authProveedores';

const SERVICIO_ID = "S-5r1HJbkxi821dJcD6l";

export { API_PROVEEDORES_URL, KRAKEN_URL, KRAKEN_AUTH, SERVICIO_ID };
