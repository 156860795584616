import React, { useState } from "react";
import axios from 'axios';
import { KRAKEN_URL } from '../../../../constants'

//Estilos
import '../../css/Configuracion.css'

//Componentes bootstrap
import { Modal, Form, Col, Container } from "react-bootstrap";
import { toast } from "react-toastify";

function ModalAgregarServicioUsuario(props) {
    const [deactivate, setDeactivate] = useState(false);
    const empresa = props.children;
    const [status, setStatus] = useState(false);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [userType, setUserType] = useState('ADMINISTRADOR');
    const [telefono, setTelefono] = useState('');
    const [contacto, setContacto] = useState('');
    const [emailContacto, setEmailContacto] = useState('');

    function handleUsernameChange(e) {
        setUsername(e.target.value);
    }

    function handleEmailChange(e) {
        setEmail(e.target.value);
    }

    function handlePasswordChange(e) {
        setPassword(e.target.value);
    }

    function handleUserTypeChange(e) {
        setUserType(e.target.value);
    }

    function handleTelefonoChange(e) {
        setTelefono(e.target.value);
    }

    function handleContactoChange(e) {
        setContacto(e.target.value);
    }

    function handleEmailContactoChange(e) {
        setEmailContacto(e.target.value);
    }

    function agregarUsuario() {
        setStatus(true);
        axios.post(KRAKEN_URL + 'empresa/usuarios/addUser',
            {
                "empresaId": empresa.empresaId,
                "empresaAlias": empresa.alias,
                "username": username,
                "email": email,
                "password": password,
                "userType": userType,
                "telefono": telefono,
                "contacto": contacto,
                "emailContacto": emailContacto
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                setStatus(false);

                toast.success(res.data.result.message,{
                    position:"top-center",
                    autoClose: 4500, 
                });

                setDeactivate(true);

                setTimeout(() => {
                    setDeactivate(false);
                }, 4500);

                toast.success("Seleccione un usuario de este listado",{
                    position:"top-center",
                    autoClose: 4500, 
                });
                
                props.onUpdate();//Actualiza el listado, llamando a la función padre para actualizarlo  
                props.onHide();//Cierra el modal, llamando a la función padre para cerrarlo 
            } else {
                setStatus(false);

                setDeactivate(true);

                setTimeout(() => {
                    setDeactivate(false);
                }, 4500);

                toast.warning(res.data.result.message,{
                    position:"top-center",
                    autoClose: 4500, 
                });
                toast.warning(res.data.result.suggestion,{
                    position:"top-center",
                    autoClose: 4500, 
                });
            }
        }).catch((error) => {
            setStatus(false);
            setDeactivate(true);

            setTimeout(() => {
                setDeactivate(false);
            }, 4500);

            toast.error("Hubo un error, no se pudo agregar el usuario a la empresa.",{
                position:"top-center",
                autoClose: 4500, 
            });
        })
    }

    return (
        <section className="text-center">
            <Modal
                show={props.show}
                //onHide={props.onHide}
                size="lg"
                centered
                id="modalAgregarEmpresaUsuario"
                scrollable={true}
            >
                <Modal.Header className="modal-header-empresa-usuario" closeButton onHide={props.onHide}>
                    <p>Agregar usuario a {empresa.alias}</p>
                </Modal.Header>
                <Modal.Body >
                    <Container className="container-modals">
                        <Form.Row>
                            <Form.Group as={Col} md={12} lg={12}>
                                <fieldset>
                                    <legend className="legend-empresa-usuario">Datos del usuario</legend>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Nombre de usuario</Form.Label>
                                            <Form.Control size="sm" id="username" type="text" required placeholder="Ingrese nombre de usuario" onChange={handleUsernameChange}>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control size="sm" id="email" type="text" required placeholder="Ingrese email" onChange={handleEmailChange} ></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Contraseña</Form.Label>
                                            <Form.Control size="sm" id="password" type="password" required placeholder="Ingrese contraseña" onChange={handlePasswordChange} ></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Tipo de usuario</Form.Label>
                                            <Form.Control as="select" size="sm" value={userType} type="text" required placeholder="Ingrese tipo de usuario" onChange={handleUserTypeChange}>
                                                <option value="ADMINISTRADOR">ADMINISTRADOR</option>
                                                <option value="SCHEMA_SOLICITANTE">SCHEMA_SOLICITANTE</option>
                                                <option value="SCHEMA_PAGADOR">SCHEMA_PAGADOR</option>
                                                <option value="SCHEMA_ADMIN">SCHEMA_ADMIN</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Teléfono</Form.Label>
                                            <Form.Control size="sm" id="telefono" type="text" required placeholder="Ingrese teléfono" onChange={handleTelefonoChange} ></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                </fieldset>
                                <fieldset>
                                    <legend className="legend-empresa-usuario">Agregar contacto para enviarle notificaciones (movimientos dentro del portal) de este usuario</legend>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Nombre de contacto</Form.Label>
                                            <Form.Control size="sm" id="contacto" type="text" required placeholder="Ingrese nombre de contacto" onChange={handleContactoChange}>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Email de contacto</Form.Label>
                                            <Form.Control size="sm" id="emailContacto" type="text" required placeholder="Ingrese email de contacto" onChange={handleEmailContactoChange}></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                </fieldset>
                            </Form.Group>
                        </Form.Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="justify-content-end">
                    <button
                        className="m-1 button-default-confirmar"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Agregar"
                        type="submit"
                        onClick={agregarUsuario}
                    >{status ? <span>Agregando...</span> : <span>Agregar</span>}
                    </button>
                    <button
                        className="m-1 button-default-cancelar"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Cancelar"
                        disabled = { deactivate }
                        onClick={props.onHide}
                    ><span>Cancelar</span>
                    </button>
                </Modal.Footer>
            </Modal>
        </section>
    )
}

export default ModalAgregarServicioUsuario;