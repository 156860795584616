import React, { useState, useEffect } from "react";
import axios from 'axios';
import { API_PROVEEDORES_URL, KRAKEN_URL } from '../../constants';
import { monedasSAT } from '../../constantes/moneda-sat';
import { formaPagoSAT } from '../../constantes/forma-pago-sat';
import { bigDecimalFormat } from '../../constantes/general-require';

//Estilos
import '../Requisiciones/css/Requisiciones.css'
import { PencilSquare } from 'react-bootstrap-icons';
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import { ExpandMore, ExpandLess } from "@material-ui/icons";

//Componentes bootstrap
import { Container, InputGroup, Col, Form, Modal, Accordion,Card,Table } from "react-bootstrap";
import ModalNotificarPago from "../Pagos/ModalNotificarPago";
//Componentes
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { createMuiTheme } from "@material-ui/core";
import MomentUtils from '@date-io/moment';
import Moment from 'moment';
import NumberFormat from 'react-number-format';
import ListadoCotizaciones from './CotizacionesArchivos/ListadoCotizaciones';
import SeleccionarBanco from './SeleccionarBanco'
import SeleccionarEmpresa from './SeleccionarEmpresa'
import { set } from "date-fns";
import Spinner from 'react-bootstrap/Spinner';
const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            light: '#f5f5f580',
            main: '#5B4B51',
            dark: '#5B4B51',
            contrastText: '#fff',
        }
    },
});

const useStyles = makeStyles((theme) => ({
    picker: {
        margin: theme.spacing(0.6),
    }
}));

function ModalEditarRequisicion(props) {
   
    const classes = useStyles();
    const[isLoading,setIsLoading] =useState(false)
    const[id,setId] = useState(props.children.id)
    const [status, setStatus] = useState(false);
    const listMonedas = monedasSAT;
    const listFormaPago = formaPagoSAT;
    const [inputValue, setInputValue] = useState(Moment(props.children.fechaRequeridaPago).format('DD/MM/yyyy'));
    /**Inicio formulario */
    const idRequisicion = props.children.idRequisicion;
    const solicitante = props.children.solicitante;
    const [empresa, setEmpresa] = useState(props.children.empresa);
    const [idEmpresa, setIdEmpresa] = useState(props.children.idEmpresa);
    const fechaCreacion = Moment(props.children.fechaCreacion).format('DD/MM/YYYY');
    const [fechaRequeridaPago, setFechaRequerida] = useState(props.children.fechaRequeridaPago);
    const [concepto, setConcepto] = useState(props.children.concepto);
    const [moneda, setMoneda] = useState();
    const [monedaClaveSat, setMonedaClaveSat] = useState(props.children.monedaClaveSat);//MXN
    const [monedaSat, setMonedaSat] = useState(props.children.monedaSat);//Peso Mexicano
    const [monedaDecimalesSat, setMonedaDecimalesSat] = useState(props.children.monedaDecimales);//2
    const [paridad, setParidad] = useState(props.children.paridad);
    const [metodoPago, setMetodoPago] = useState(props.children.metodoPagoClaveSat + ',' + props.children.metodoPagoSat);
    const [metodoPagoClaveSat, setMetodoPagoClaveSat] = useState(props.children.metodoPagoClaveSat);
    const [metodoPagoSat, setMetodoPagoSat] = useState(props.children.metodoPagoSat);
    const [formaPago, setFormaPago] = useState(props.children.formaPagoClaveSat + "," + props.children.formaPagoSat);
    const [formaPagoClaveSat, setFormaPagoClaveSat] = useState(props.children.formaPagoClaveSat);
    const [formaPagoSat, setFormaPagoSat] = useState(props.children.formaPagoSat);
    /*const [precisionCalculos, setPrecisionCalculos] = useState('');*/
    const decimalesRequisicion = props.children.decimalesRequisicion;
    const [importe, setImporte] = useState(props.children.importe);
    const [ivaTrasladadoTasaCuota, setIvaTrasladadoTasaCuota] = useState(props.children.ivaTrasladadoTasaCuota);
    const [ivaTrasladadoImporte, setIvaTrasladadoImporte] = useState(props.children.ivaTrasladadoImporte);
    const [ivaRetenido, setIvaRetenido] = useState(props.children.ivaRetenido);
    const [ivaRetenidoTasaCuota, setIvaRetenidoTasaCuota] = useState(props.children.ivaRetenidoTasaCuota);
    const [ivaRetenidoImporte, setIvaRetenidoImporte] = useState(props.children.ivaRetenidoImporte);
    const [isr, setIsr] = useState('');
    const [isrTasaCuota, setIsrTasaCuota] = useState(props.children.isrTasaImporte);
    const [isrImporte, setIsrImporte] = useState(props.children.isrImporte);
    const [otrosImpuestos, setOtrosImpuestos] = useState(props.children.otrosImpuestosImporte);
    const [otrosImpuestosImporte, setOtrosImpuestosImporte] = useState(props.children.otrosImpuestosImporte);
    const [opcionDescuento, setOpcionDescuento] = useState('porcentaje');
    const [descuento, setDescuento] = useState('');
    const [descuentoPorcentaje, setDescuentoPorcentaje] =  useState((props.children.descuentoPorcentaje * 100).toString())
    const [descuentoImporte, setDescuentoImporte] = useState(props.children.descuentoImporte);
    const [porcentaje, setPorcentaje] = useState('0.00');
    const [descuentoImpCantidad, setDescuentoImpCantidad] = useState(props.children.descuentoImporte);
    const [descuentoCantidad, setDescuentoCantidad] = useState(props.children.descuentoPorcentaje);
    const [subtotal, setSubtotal] = useState(props.children.subtotal);//Total sin impuestos
    const [subtotalConDescuento, setSubtotalConDescuento] = useState(props.children.subtotalDescuento);//Total con descuento y sin impuestos    
    const [total, setTotal] = useState(props.children.total);
    const [importePesosMexicanos, setImportePesosMexicanos] = useState(props.children.importePesosMexicanos);
    //const proveedor = props.children.nombreComercial;//
    const proveedor = props.children.proveedor;
    const idProveedor = props.children.idProveedor;//
    const proveedorRfc = props.children.proveedorRfc;//
    const [porcentajeTotal, setPorcentajeTotal] = useState(props.children.porcentajeTotal);//Porcentaje de anticipo requerido del total
    const [totalRequerido, setTotalRequerido] = useState(props.children.totalRequerido);//Anticipo del total requerido
    const [anticipoPesosMexicanos, setAnticipoPesosMexicanos] = useState(props.children.anticipoPesosMexicanos);
    const [banco, setBanco] = useState(props.children.banco);//lista de cuentas    
    const [cuentaClabe, setCuentaClabe] = useState(props.children.cuentaClabe);//lista de cuentas
    const [cuentaBanco, setCuentaBanco] = useState(props.children.cuentaBanco);//lista de cuentas
    const [referencia, setReferencia] = useState(props.children.referencia);
    const [taxesChecked, setTaxesChecked] = useState(props.children.objetoImpuesto);
    const [showParidad, setShowParidad ]= useState(props.children.monedaClaveSat==="MXN" ? false:true)
    const [pagadores,setPagadores] = useState([])
    const [pagadorName,setPagadorName]= useState(props.children.pagadorId)
    const [pagadorEmail,setPagadorEmail] = useState(props.children.pagadorEmail)
    const [notificarPagador,setNotificarPagador] = useState(false)
    const [modalNotificarPagoShow, setModalNotificarPagoShow] = useState(false);
    const [contactos, setContactos] = useState(props.children.compartidos?JSON.parse(props.children.compartidos): []);
    let modalConfirmarClose = () => {
        setModalNotificarPagoShow(false);
      };
    /**Fin formulario */
    useEffect(() => {
        calculoImporteTrasladado();
        calculoImporteRetenido();
        calculoImporteIsr();
        calculoImporteOtrosImpuestos();
        opcionesDescuento();
        calculoPorcentajeDescuento();
        calculoImportePorcentajeTotal();
        calculoTotales();
      
    })
    useEffect(()=>{
        
        setTimeout(() => {
            calculoImporteDescuentoInit();
          }, "1000");
          
        
        getPagadores()
    },[idEmpresa])

    useEffect(()=>{
        calculoImporteDescuento();
    },[descuentoPorcentaje])

    function getPagadores(){
        
        axios.post(KRAKEN_URL + "empresa/usuarios/payers", {
            "empresaId": idEmpresa
        })
            .then((res) => {
                let aux = []
                if(res.data.list)
                res.data.list.map((pagador)=>{
                aux.push(pagador)
                })
                setPagadores(aux)
             
                
            })
            .catch((error) => {
                ;
                ;
            });
    }
    function onContactosSelected(contactosEntrantes, comentario) {
 
        let aux = [];
          aux.push(contactosEntrantes);
          setContactos(contactosEntrantes);
          
    }
    function bancoSelected(row) {
        setBanco(row.banco);
        setCuentaClabe(row.cuentaClabe);
        setCuentaBanco(row.cuenta);
    }
    const handleCheckboxChange = () => {
    
        setTaxesChecked(!taxesChecked);
        
        setIvaTrasladadoTasaCuota(0)
        setIvaTrasladadoImporte("0.00")
        setIsrTasaCuota("0.00")
        setIvaRetenido("0.00")
        setIvaRetenidoImporte("0.00")
        setIvaRetenidoTasaCuota("0.00")
        setIsrTasaCuota("0.00")
        setIsr(0)
        setIsrImporte("0.00")
        setDescuentoImporte("0.00")
        setDescuentoPorcentaje("0.00")
        setDescuentoImpCantidad("0.00")
        setOtrosImpuestos("0.00")
        setOtrosImpuestosImporte("0.00")
        setMetodoPago("")
        setFormaPago("")
        setMetodoPago("");
        setMetodoPagoClaveSat("");
        setMetodoPagoSat("");
            };

    function empresaSelected(empresa) {
        var str = empresa;
        var array = str.split(",");
        setEmpresa(array[0]);
        setIdEmpresa(array[1]);
    }
    const handleFechaRequeridaChange = (date, value) => {
        setFechaRequerida(date);
        setInputValue(value);
    }
    function handleConceptoChange(e) {
        setConcepto(e.target.value);
    }
    function handleMonedaChange(e) {
        var str = e.target.value;
        var array = str.split(",");
        setMoneda(e.target.value);
        setMonedaClaveSat(array[0]);
        setMonedaSat(array[1]);
        setMonedaDecimalesSat(array[2]);
        if (array[0] === 'MXN') {
            setShowParidad(false)
            //document.getElementById("paridad").value = '1.00';
            setParidad('1.00');
            document.getElementById("paridad").disabled = true;
        } else {
            setShowParidad(true)
            document.getElementById("paridad").disabled = false;
        }
    }
    function handleParidadChange(e) {
        setParidad(e.target.value);
    }
    function handleMetodoPagoChange(e) {
        var str = e.target.value;
        var array = str.split(",");
        setMetodoPago(e.target.value);
        setMetodoPagoClaveSat(array[0]);
        setMetodoPagoSat(array[1]);

        if (array[0] === 'PPD') {
            setFormaPago('99,Por definir');
            setFormaPagoClaveSat('99');
            setFormaPagoSat('Por definir')
            document.getElementById("formaPago").disabled = true;
        } else {
            document.getElementById("formaPago").disabled = false;
        }
    }
    function handleFormaPagoChange(e) {
        var str = e.target.value;
        var array = str.split(",");

        setFormaPago(e.target.value);
        setFormaPagoClaveSat(array[0]);
        setFormaPagoSat(array[1]);
    }
    /*function handlePrecisionCalculos(e) {
        setPrecisionCalculos(e.target.value);
        if (e.target.value === '2') {
            setDecimalesRequisicion(monedaDecimalesSat);
        } else {
            setDecimalesRequisicion(e.target.value);
        }
    }*/

    function handleImporteChange(e) {
        setImporte(e.target.value);//Se guarda tal cual el valor que aparece en el input
    }
    function handleIvaTrasladadoChange(e) {
        setIvaTrasladadoTasaCuota(e.target.value);//Se guarda tal cual el valor que aparece en el input       
    }

    function calculoImporteTrasladado() {
        let importeDecimal = bigDecimalFormat(importe);//Se convierte a bigDecimal
        let ivaTrasladadoTasaCuotaDecimal = bigDecimalFormat(ivaTrasladadoTasaCuota);
        let iDecimal = importeDecimal.times(ivaTrasladadoTasaCuotaDecimal);//Método times que sirve para multiplicar a*b
        let iFixed = iDecimal.toFixed(parseFloat(monedaDecimalesSat));//toFixed: cuántos dígitos se van a visualizar después del punto decimal; parseFloat: convierte un string a número
        setIvaTrasladadoImporte(iFixed);//Se guarda el valor sin comas y se agrega el diseño hasta que se utiliza en el formulario
    }
    function handleIvaRetenidoChange(e) {
        setIvaRetenido(e.target.value);//Se guarda tal cual el valor que aparece en el input
        let ivaRetenidoDecimal = bigDecimalFormat(e.target.value);//Se convierte a bigDecimal
        let tasaCuotaDecimal = ivaRetenidoDecimal.dividedBy(100);//dividedBy: a/b
        setIvaRetenidoTasaCuota(tasaCuotaDecimal);//Se guarda el valor sin comas
    }

    function calculoImporteRetenido() {
        let importeDecimal = bigDecimalFormat(importe);//Se convierte a bigDecimal        
        let importeIvaRetenidoDecimal = importeDecimal.times(ivaRetenidoTasaCuota).toFixed(parseFloat(monedaDecimalesSat));//times: a*b; toFixed: cuántos dígitos después del punto decimal
        setIvaRetenidoImporte(importeIvaRetenidoDecimal);//Se guarda el valor sin comas y se agrega el diseño hasta que se utiliza en el formulario        
    }
    function handleIsrChange(e) {
        setIsr(e.target.value);//Se guarda tal cual el valor que aparece en el input
        let isrDecimal = bigDecimalFormat(e.target.value);//Se convierte a bigDecimal
        let tasaCuotaDecimal = isrDecimal.dividedBy(100); //dividedBy: a/b        
        setIsrTasaCuota(tasaCuotaDecimal);//Se guarda el valor sin comas
    }
    function calculoImporteIsr() {
        let importeDecimal = bigDecimalFormat(importe);//Se convierte a bigDecimal        
        let importeIsrDecimal = importeDecimal.times(isrTasaCuota).toFixed(parseFloat(monedaDecimalesSat));//times: a*b; toFixed: cuántos dígitos después del punto decimal
        setIsrImporte(importeIsrDecimal);//Se guarda el valor sin comas        
    }
    function handleOtrosImpuestosChange(e) {
        setOtrosImpuestos(e.target.value);//Se guarda tal cual el valor que aparece en el input
    }
    function calculoImporteOtrosImpuestos() {
        let otrosImpuestosDecimal = bigDecimalFormat(otrosImpuestos);
        let importeOtrosImpuestosDecimal = otrosImpuestosDecimal.toFixed(parseFloat(monedaDecimalesSat));//toFixed: cuántos dígitos después del punto decimal
        setOtrosImpuestosImporte(importeOtrosImpuestosDecimal);//Se guarda el valor sin comas
    }
    function handleDescuentoOpcionPorcentajeChange() {
        let porcentaje = document.getElementById('porcentaje').checked

        porcentaje ?
            document.getElementById('cantidad').checked = false
            :
            document.getElementById('porcentaje').checked = true
        setOpcionDescuento("porcentaje");
        opcionesDescuento();
    }
    function handleDescuentoOpcionCantidadChange() {
        let cantidad = document.getElementById('cantidad').checked;
        cantidad ?
            document.getElementById('porcentaje').checked = false
            :
            document.getElementById('cantidad').checked = true

        setOpcionDescuento("cantidad");
        opcionesDescuento();
    }
    function opcionesDescuento() {
        return (
            opcionDescuento === "porcentaje" ?
                <Form.Row hidden={!taxesChecked}>
                    <Col>
                        <InputGroup size="sm">
                        <NumberFormat
    id="descuento"
    className="input-signs-align form-control form-control-sm"
    thousandSeparator={true}
    value={descuento}
    onValueChange={(values) => handleDescuentoChange(values.value)}>
</NumberFormat>
                            <InputGroup.Append >
                                <InputGroup.Text className="input-signs-right">%</InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup size="sm">
                            <InputGroup.Prepend >
                                <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <NumberFormat
                                id="descuentoImporte"
                                className="input-signs-align form-control form-control-sm"
                                thousandSeparator={true}
                                disabled
                                value={descuentoImporte}>
                            </NumberFormat>
                        </InputGroup>
                    </Col>
                </Form.Row>
                : opcionDescuento === "cantidad" ?
                
                    <Form.Row>
                        <Col>
                            <InputGroup size="sm">|
                                <NumberFormat id="porcentaje"
                                    className="input-signs-align form-control form-control-sm"
                                    thousandSeparator={true}
                                    disabled value={descuentoImpCantidad}>
                                </NumberFormat>
                                <InputGroup.Prepend >
                                    <InputGroup.Text className="input-signs-right">%</InputGroup.Text>
                                </InputGroup.Prepend>
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup size="sm">
                                <InputGroup.Append >
                                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                </InputGroup.Append>
                                <NumberFormat id="descuentoPorcentaje"
                                    className="input-signs-align form-control form-control-sm"
                                    thousandSeparator={true}
                                    value={porcentaje}
                                    onChange={handlePorcentajeDescuentoChange}>
                                </NumberFormat>
                            </InputGroup>
                        </Col>
                    </Form.Row>
                    :
                    ''
        )
    }
    function handleNotificarPagador(e){
        setNotificarPagador(!notificarPagador);
    }
    function handlePagador(e){
        let value = e.target.value
          let aux = value.split("-")
          setPagadorName(aux[0])
          setPagadorEmail(aux[1])
        
      }
      function handleDescuentoChange(value) {
        // Guarda el valor formateado del input
        setDescuento(value.toString());
    
        // Convierte el valor formateado a un valor decimal
        let descuentoDecimal = bigDecimalFormat(value);
        let porcentajeDecimal = descuentoDecimal.dividedBy(100); //dividedBy: a/b  
    
        // Guarda el valor decimal (sin formato) para el porcentaje
        setDescuentoPorcentaje(porcentajeDecimal);
    }
    
    function calculoImporteDescuento() {
        
        let importeDecimal = bigDecimalFormat(importe);//Se convierte a bigDecimal
        let importeDescuentoDecimal = importeDecimal.times(descuentoPorcentaje).toFixed(parseFloat(monedaDecimalesSat));//times: a*b; toFixed: cuántos dígitos después del punto decimal        
        setDescuentoImporte(importeDescuentoDecimal);//Se guarda el valor sin comas y se agrega el diseño hasta que se utiliza en el formulario        
    }
    function openCompartirReq(e) {
        e.preventDefault();
        setModalNotificarPagoShow(true);
      }
    function calculoImporteDescuentoInit() {
        
        let importeDecimal = bigDecimalFormat(importe); // Convertir a BigDecimal
        let importeDescuentoDecimal = importeDecimal.times(descuentoPorcentaje / 100).toFixed(parseFloat(monedaDecimalesSat)); // Calcular el descuento
        let importeDescuentoString = importeDescuentoDecimal.toString(); // Convertir a cadena
        setDescuentoImporte(importeDescuentoString);
        setDescuento(descuentoPorcentaje) // Guardar el valor como cadena
    }
    function handlePorcentajeDescuentoChange(e) {
        setPorcentaje(e.target.value);//Se guarda tal cual el valor que aparece en el input
        let descuentoDecimal = bigDecimalFormat(e.target.value);
        let importeDecimal = bigDecimalFormat(importe);//Se convierte a bigDecimal
        let porcentajeDecimal = descuentoDecimal.dividedBy(importeDecimal); //dividedBy: a/b        
        setDescuentoCantidad(porcentajeDecimal);//Se guarda el valor sin comas
    }
    function calculoPorcentajeDescuento() {
       
        let importeDecimal = bigDecimalFormat(100);//Se convierte a bigDecimal
        let importeDescuentoDecimal = importeDecimal.times(descuentoCantidad).toFixed(parseFloat(monedaDecimalesSat));//times: a*b; toFixed: cuántos dígitos después del punto decimal        
        setDescuentoImpCantidad(importeDescuentoDecimal);//Se guarda el valor sin comas y se agrega el diseño hasta que se utiliza en el formulario
    }
    function handlePorcentajeTotalChange(e) {
        setPorcentajeTotal(e.target.value);//Se guarda tal cual el valor que aparece en el input
    }
    function calculoImportePorcentajeTotal() {
        let porcentajeDecimal = bigDecimalFormat(porcentajeTotal).dividedBy(100);//dividedBy: a/b 
        let totalDecimal = bigDecimalFormat(total);
        let totalRequeridoDecimal = totalDecimal.times(porcentajeDecimal).toFixed(parseFloat(monedaDecimalesSat));//times: a*b; toFixed: cuántos dígitos después del punto decimal
        setTotalRequerido(totalRequeridoDecimal);//El diseño se pondrá cuando se use en el formulario
    }
    function handleReferenciaChange(e) {
        setReferencia(e.target.value);
    }

    function calculoTotales() {
        /** NOTA: NO SE PUEDE CONCATENAR BIGDECIMALFORMAT CON MÉTODOS DE DECIMAL EN UNA SÓLA OPERACIÓN
         *  PORQUE DECIMAL SÓLO SOPORTA SUS PROPIOS MÉTODOS, DE LO CONTRARIO LANZARÁ ERROR
         *  DECIMAL API: http://mikemcl.github.io/decimal.js/
         */
        let importeDecimal = bigDecimalFormat(importe);
        setImporte(importeDecimal.toFixed(parseFloat(monedaDecimalesSat)));
        setSubtotal(importeDecimal.toFixed(parseFloat(monedaDecimalesSat)));
        let descuentoImporteDecimal = bigDecimalFormat(descuentoImporte);
        let descuentoPorcentajeDecimal = bigDecimalFormat(porcentaje);//descuentoImpCantidad
        let subtotalConDescuentoDecimal = importeDecimal.minus(descuentoImporteDecimal);
        let subtotalConDescuentoPorcentajeDecimal = importeDecimal.minus(descuentoPorcentajeDecimal);
        setSubtotalConDescuento(opcionDescuento === "porcentaje" ? subtotalConDescuentoDecimal.toFixed(parseFloat(monedaDecimalesSat)) : subtotalConDescuentoPorcentajeDecimal.toFixed(parseFloat(monedaDecimalesSat)));//minus: a-b; plus: a+b
        let ivaTrasladadoImporteDecimal = bigDecimalFormat(ivaTrasladadoImporte);
        let ivaRetenidoImporteDecimal = bigDecimalFormat(ivaRetenidoImporte);
        let isrImporteDecimal = bigDecimalFormat(isrImporte);
        let otrosImpuestosDecimal = bigDecimalFormat(otrosImpuestosImporte);
        let totalFinal = subtotalConDescuentoDecimal.plus(ivaTrasladadoImporteDecimal).minus(ivaRetenidoImporteDecimal).minus(isrImporteDecimal).plus(otrosImpuestosDecimal);
        let totalPorcentajeFinal = subtotalConDescuentoPorcentajeDecimal.plus(ivaTrasladadoImporteDecimal).minus(ivaRetenidoImporteDecimal).minus(isrImporteDecimal).plus(otrosImpuestosDecimal);
        setTotal(opcionDescuento === "porcentaje" ? totalFinal.toFixed(parseFloat(monedaDecimalesSat)) : totalPorcentajeFinal.toFixed(parseFloat(monedaDecimalesSat)));
        let paridadDecimal = bigDecimalFormat(paridad);
        let importeConParidad = totalFinal.times(paridadDecimal);
        let importeConparidadPorcentaje = totalPorcentajeFinal.times(paridadDecimal);
        setImportePesosMexicanos(opcionDescuento === "porcentaje" ? importeConParidad.toFixed(parseFloat(monedaDecimalesSat)) : importeConparidadPorcentaje.toFixed(parseFloat(monedaDecimalesSat)));
        let totalRequeridoDecimal = bigDecimalFormat(totalRequerido);
        let totalRequeridoConParidad = totalRequeridoDecimal.times(paridadDecimal);
        setAnticipoPesosMexicanos(totalRequeridoConParidad.toFixed(parseFloat(monedaDecimalesSat)));
    }

    const createInputTotalPesosMexicanos = () => {
        let inputTPM = (<span><Form.Label > Total pesos mexicanos</Form.Label>
            <InputGroup size="sm">
                <InputGroup.Prepend >
                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                </InputGroup.Prepend>
                <NumberFormat
                    id="totalPesosMexicanos"
                    className="input-signs-align form-control form-control-sm"
                    thousandSeparator={true}
                    disabled value={importePesosMexicanos}></NumberFormat>
            </InputGroup>
        </span>
        );
        return inputTPM;
    }

    const createInputAnticipoPesosMexicanos = () => {
        let inputTPM = (<span><Form.Label > Anticipo/Total pesos mexicanos</Form.Label>
            <InputGroup size="sm">
                <InputGroup.Prepend >
                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                </InputGroup.Prepend>
                <NumberFormat
                    id="anticipoPesosMexicanos"
                    className="input-signs-align form-control form-control-sm"
                    thousandSeparator={true}
                    disabled value={anticipoPesosMexicanos}></NumberFormat>
            </InputGroup>
        </span>
        );
        return inputTPM;
    }

    function cargarSelectMonedas() {
        return listMonedas.length ? listMonedas.map(e => {
            return (<option
                key={e.clave}
                value={e.clave + "," + e.nombre + "," + e.decimales}
                selected={e.clave === props.children.monedaClaveSat}
            >
                {[e.clave, e.nombre, "Presición", e.decimales].join(' - ')}
            </option>)
        }) : 'No existen monedas registradas'
    }  function cargando() {
        return (
            <div className="d-flex justify-content-center align-items-center" >
            <Spinner  animation="border" role="status">
              <span className="visually-hidden"></span>
            </Spinner>
          </div>)
      }

    function cargarSelectFormaPago() {
        return listFormaPago.length ? listFormaPago.map(e => {
            return (<option key={e.clave} value={e.clave + "," + e.nombre}>{[e.clave, e.nombre].join(' - ')}</option>)
        }) : 'No existen formas de pago registradas'
    }

    function updateRequisicion(e) {
        e.preventDefault();
        setStatus(true);
        setIsLoading(true);
        let errors = 0;

        if(!concepto){
            toast.error("Se requiere el Concepto General",{
                position:"top-center",
                autoClose: 4500, 
            });

            setStatus(false)
          errors++;
        }
        if(!pagadorName){
            toast.error("Se requiere seleccionar pagador",{
                position:"top-center",
                autoClose: 4500, 
            });

            setStatus(false)
            errors++;
        }
        if(concepto.length>199){
            toast.error("El concepto no puede tener más de 200 caracteres ",{
                position:"top-center",
                autoClose: 4500, 
            });
            errors++
        }

        if(importe === "0" || importe.length === 0){
            toast.error("Se requiere ingresar un importe mayor a 0",{
                position:"top-center",
                autoClose: 4500, 
            });
            errors++
        }
        if(contieneEmail(contactos,pagadorEmail)){
            toast.error("No se puede compartir la requisición con el pagador", {
              position: "top-center",
              autoClose: 4500,
            });
            errors++;
          }
      if(errors>0){
        setIsLoading(false);
        return false
      }
      let pagadoresAux= ""
      if(pagadorName==="0"){
        console.log("Pagador")
        pagadores.map((p,index)=>{
        if(index!=0){
          pagadoresAux+=","
        }
          pagadoresAux+=p.email
         
        })
    
      }
        axios.post(API_PROVEEDORES_URL + 'updateRequisicion',
            {
                idRequisicion: idRequisicion,
                empresa: empresa,
                idEmpresa: idEmpresa,
                fechaRequeridaPago: fechaRequeridaPago,
                concepto: concepto,
                monedaClaveSat: monedaClaveSat,
                monedaSat: monedaSat,
                monedaDecimalesSat: parseFloat(monedaDecimalesSat),
                paridad: parseFloat(paridad),
                metodoPagoClaveSat: metodoPagoClaveSat,
                metodoPagoSat: metodoPagoSat,
                formaPagoClaveSat: formaPagoClaveSat,
                formaPagoSat: formaPagoSat,
                decimalesRequisicion: parseFloat(decimalesRequisicion),
                importe: parseFloat(importe),
                ivaTrasladadoTasaCuota: parseFloat(ivaTrasladadoTasaCuota),
                ivaTrasladadoImporte: parseFloat(ivaTrasladadoImporte),
                ivaRetenidoImporte: parseFloat(ivaRetenidoImporte),
                ivaRetenidoTasaCuota: parseFloat(ivaRetenidoTasaCuota),
                isrTasaCuota: parseFloat(isrTasaCuota),
                isrImporte: parseFloat(isrImporte),
                otrosImpuestosImporte: parseFloat(otrosImpuestosImporte),
                /*descuentoPorcentaje: parseFloat(descuentoPorcentaje),
                descuentoImporte: parseFloat(descuentoImporte),*/
                descuentoPorcentaje: opcionDescuento === 'porcentaje' ? parseFloat(descuentoPorcentaje) : parseFloat(descuentoCantidad),
                descuentoImporte: opcionDescuento === 'porcentaje' ? parseFloat(descuentoImporte) : parseFloat(porcentaje),
                subtotal: parseFloat(subtotal),
                subtotalConDescuento: parseFloat(subtotalConDescuento),
                total: parseFloat(total),
                importePesosMexicanos: parseFloat(importePesosMexicanos),
                proveedor: proveedor,
                idProveedor: idProveedor,
                proveedorRfc: proveedorRfc,
                porcentajeTotal: parseFloat(porcentajeTotal),
                totalRequerido: parseFloat(totalRequerido),
                anticipoPesosMexicanos: parseFloat(anticipoPesosMexicanos),
                banco: banco,
                cuentaClabe: cuentaClabe,
                cuentaBanco: cuentaBanco,
                referencia: referencia,
                objetoImpuesto:taxesChecked,
                pagadorId:pagadorName,
                pagadorEmail:pagadorName!=="0"? pagadorEmail:pagadoresAux,
                notificarPagador:notificarPagador,
                solicitante: solicitante,
                id:id,
                compartidos: contactos ?JSON.stringify(contactos): []
             

            }
        ).then((res) => {
            setIsLoading(false);
            if (res.data.result.success) {
                setStatus(false);
                toast.success(res.data.result.message, {
                    position: "top-center",
                    autoClose: 4500,
                });
                props.onUpdate();//Actualiza el listado, llamando a la función padre para actualizarlo
                props.onHide();//Cierra el modal, llamando a la función padre para cerrarlo 
            } else {
                setStatus(false);
                toast.error(res.data.result.message, {
                    position: "top-center",
                    autoClose: 4500,
                });
                toast.error(res.data.result.suggestion, {
                    position: "top-center",
                    autoClose: 4500,
                });
            }
        }).catch((error) => {
            setIsLoading(false);
            setStatus(false);
            toast.error("No se ha podido guardar la requisición", {
                position: "top-center",
                autoClose: 4500,
            });
        })
    }
    function contieneEmail(arreglo, email) {
        return arreglo.find(objeto => objeto.usuarioEmail === email) !== undefined;
    }
    return (
        <section className="text-center">
            <Modal
                show={props.show}
                //onHide={props.onHide}
                size="xl"
                centered
                id="modalEditarRequisicion"
                scrollable={true}
            >
                <Modal.Header className="modal-header-editar-req" closeButton onHide={props.onHide}>
                    <p><PencilSquare size={22} color="#5B4B51" />   3.Editar requisición #{props.children.id}</p>
                </Modal.Header>
                {!isLoading?
                <Modal.Body className="modal-portal-body">
                        {modalNotificarPagoShow ? (
              <ModalNotificarPago
                show={modalNotificarPagoShow}
                onHide={modalConfirmarClose}
                onContactosSelected={onContactosSelected}
              >
                {idEmpresa}
                {empresa}
                {true}
                {contactos}
                {pagadorEmail}
                {solicitante}
              </ModalNotificarPago>
            ) : (
              ""
            )}
                    <Container className="container-modals">
                        <Form.Row>
                            <Form.Group as={Col} md={12} lg={6}>
                                <fieldset>
                                    <legend className="legend-editar-req">Proveedor</legend>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col}>
                                            <Form.Label>Nombre Proveedor</Form.Label>
                                            <Form.Control size="sm" id="proveedor" type="text" defaultValue={proveedor} disabled></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col}>
                                            <Form.Label>% Anticipo/Total</Form.Label>
                                            <InputGroup size="sm" >
                                                <NumberFormat id="anticipoPorcentaje"
                                                    className="input-signs-align form-control form-control-sm"
                                                    thousandSeparator={true}
                                                    required placeholder="Ingrese porcentaje de anticipo"
                                                    defaultValue={porcentajeTotal}
                                                    onChange={handlePorcentajeTotalChange}
                                                />
                                                <InputGroup.Append >
                                                    <InputGroup.Text className="input-signs-right">%</InputGroup.Text>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Anticipo/Total</Form.Label>
                                            <InputGroup size="sm">
                                                <InputGroup.Prepend >
                                                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <NumberFormat id="totalRequerido"
                                                    className="input-signs-align form-control form-control-sm"
                                                    thousandSeparator={true}
                                                    disabled value={totalRequerido}
                                                />
                                            </InputGroup>
                                            {monedaClaveSat !== 'MXN' ? createInputAnticipoPesosMexicanos() : ''}
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col}>
                                            <Form.Label>Cuenta CLABE</Form.Label>
                                            <NumberFormat
                                                id="cuentaClabe"
                                                format="#### #### #### #### ##"
                                                mask="_"
                                                className="form-control form-control-sm"
                                                disabled
                                                value={cuentaClabe}
                                            >
                                            </NumberFormat>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Cuenta banco</Form.Label>
                                            <NumberFormat
                                                id="cuentaBanco"
                                                format="#### #### #### #### #### ####"
                                                mask="_"
                                                className="form-control form-control-sm"
                                                disabled
                                                value={cuentaBanco}
                                            >
                                            </NumberFormat >
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col} md={12} lg={6}>
                                            <Form.Label>Referencia</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text" required
                                                placeholder="Ingrese referencia"
                                                defaultValue={referencia}
                                                onChange={handleReferenciaChange}>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <SeleccionarBanco onBancoSelected={bancoSelected}>{idProveedor}{banco}</SeleccionarBanco>
                                </fieldset>
                                <ListadoCotizaciones>{idRequisicion}{idEmpresa}</ListadoCotizaciones>
                                <Form.Row as={Col}>
                                  <Form.Group as={Col}>
                                    <legend className="modal-header-consultar-pagos">Notificaciones</legend>

                                    {/*<<div style={{margin: "0px 0px 10px 0px"}}>
                                      <Form.Check
                                        type="checkbox"
                                        label="Notificar a usuario seleccionado"
                                        checked={notificarPagador}
                                        onChange={handleNotificarPagador}
                                      />
                                    </div>*/}

                                    <Form.Label>Usuario pagador</Form.Label>
                                    <Form.Control size="sm" id="pagador" type="text" as="select" required placeholder="Seleccione pagador" value={[pagadorName,pagadorEmail].join("-")} onChange={handlePagador} >
                                      <option default value="">Seleccione pagador</option>
                                      <option  value={["0","pendiente"].join("-")}>
                                        Todos (provisional)
                                    </option>
                                        {pagadores.map((pagador,index) => (
                                      < option key={index} value={[pagador.userId,pagador.email].join("-")}>
                                        {pagador.username}
                                      </option>))}
               
                                    </Form.Control>
                                  </Form.Group>
                                  
                                </Form.Row>
                                    <Form.Row as={Col}>
                 
                   {contactos.length>0 ?(
                    <Form.Group>
                          <legend className="legend-crear-req">Se compartirá con los siguientes usuarios:</legend>
                   <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th><b>Nombre de usuario</b></th>
                          <th><b>Email</b></th>
                        </tr>
                      </thead>
                      <tbody> {contactos.map(elemento => (
                <tr key={elemento.usuarioId}>
                <td>{elemento.usuarioNombre}</td>
                <td>{elemento.usuarioEmail}</td>
             
            </tr>
          ))}</tbody>
                    </Table></Form.Group>):"" } 
                    <Form.Group>
                      <button
                        className="m-1 button-default-subir-archivo"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Guardar requisición"
                        onClick={openCompartirReq}
                        disabled={!pagadorEmail}
                      >
                        {contactos.length>0?(<span>Editar el compartir</span>):<span> Compartir Requisicion</span>}
                      </button>
                    </Form.Group>
                  </Form.Row>
                            </Form.Group>
                            <Form.Group as={Col} md={12} lg={6}>
                                <fieldset >
                                    <legend className="legend-editar-req">Requisición</legend>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col}>
                                            <Form.Label>Solicitante</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                disabled
                                                defaultValue={solicitante}>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Fecha de creación</Form.Label>
                                            <Form.Control size="sm"
                                                type="text"
                                                disabled
                                                defaultValue={fechaCreacion}></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row as={Col}>
                                        <SeleccionarEmpresa onEmpresaSelected={empresaSelected}>{empresa + "," + idEmpresa}</SeleccionarEmpresa>
                                        <Form.Group as={Col} className={classes.picker}>
                                            <ThemeProvider theme={defaultMaterialTheme}>
                                                <MuiPickersUtilsProvider theme={defaultMaterialTheme} libInstance={Moment} utils={MomentUtils}>
                                                    <KeyboardDatePicker
                                                        label="Fecha requerida de pago"
                                                        autoOk={true}
                                                        variant="inline"
                                                        format="DD/MM/yyyy"
                                                        value={fechaRequeridaPago}
                                                        inputValue={inputValue}
                                                        onChange={handleFechaRequeridaChange}
                                                    >
                                                    </KeyboardDatePicker>
                                                </MuiPickersUtilsProvider>
                                            </ThemeProvider>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col}>
                                            <Form.Label>Concepto general</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                as="textarea"
                                                rows={3}
                                                required
                                                placeholder="Ingrese concepto general"
                                                value={concepto}
                                                onChange={handleConceptoChange}>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Accordion.Toggle style={{ cursor: 'pointer' }}   onClick={handleCheckboxChange}  as={Card.Header}  eventKey="1" className="legend-crear-prov">
                                    Impuestos{!taxesChecked?<ExpandMore />:<ExpandLess />} 
                                    </Accordion.Toggle> 
                                    <Form.Row as={Col}>
                                    <Form.Group as={Col}>
                                           
                                            <Form.Check
                                                type="checkbox"
                                               hidden={true}
                                                checked={taxesChecked}
                                                onChange={handleCheckboxChange} />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col} lg={4}>
                                            <Form.Label>Moneda</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                id="moneda"
                                                type="text"
                                                as="select"
                                                required
                                                placeholder="Seleccione moneda"
                                                value={moneda}
                                                onChange={handleMonedaChange}>
                                                {cargarSelectMonedas()}
                                            </Form.Control>
                                            <Form.Label hidden={!showParidad}>Paridad</Form.Label>
                                            <InputGroup  hidden={!showParidad} size="sm">
                                                <InputGroup.Prepend >
                                                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control size="sm" id="paridad" type="number" className="input-signs-align" required value={paridad} onChange={handleParidadChange}></Form.Control>
                                            </InputGroup>
                                            <Form.Label hidden={!taxesChecked}>Método de pago</Form.Label>
                                            <Form.Control hidden={!taxesChecked} size="sm" id="metodoPago" type="text" as="select" required value={metodoPago} onChange={handleMetodoPagoChange}>
                                                <option default>Seleccione método de pago</option>
                                                <option value="PUE,Pago en una sola exhibición">PUE - Pago en una sola exhibición</option>
                                                <option value="PPD,Pago en parcialidades o diferido">PPD - Pago en parcialidades o diferido</option>
                                            </Form.Control>
                                            <Form.Label hidden={!taxesChecked}>Forma de pago</Form.Label>
                                            <Form.Control hidden={!taxesChecked} size="sm" id="formaPago" type="text" as="select" required placeholder="Seleccione forma de pago" value={formaPago} onChange={handleFormaPagoChange}>
                                                <option default>Seleccione forma de pago</option>
                                                {cargarSelectFormaPago()}
                                            </Form.Control>
                                            {/*<Form.Label>Precisión de los cálculos</Form.Label>
                                            <Form.Control size="sm" id="decimalesRequisicion" as="select" required placeholder="Seleccione precisión" defaultValue={props.children.monedaDecimales} onChange={handlePrecisionCalculos}>
                                                <option value="0">Seleccione precisión</option>
                                                <option value="2">Indicada por la moneda</option>
                                                <option value="4">4 decimales</option>
                                                <option value="6">6 decimales</option>
                                            </Form.Control>*/}
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Importe</Form.Label>
                                            <InputGroup size="sm">
                                                <InputGroup.Prepend >
                                                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <NumberFormat
                                                    id="importe"
                                                    className="input-signs-align form-control form-control-sm"
                                                    required placeholder="Ingrese importe"
                                                    thousandSeparator={true}
                                                    value={importe}
                                                    onChange={handleImporteChange}>
                                                </NumberFormat>
                                            </InputGroup>
                                            <Form.Label hidden={!taxesChecked}>IVA Trasladado</Form.Label>
                                            <Form.Row  hidden={!taxesChecked}>
                                                <Col>
                                                    <Form.Control size="sm"
                                                        id="ivaTrasladadoTasaCuota"
                                                        type="text"
                                                        as="select"
                                                        required placeholder="Seleccione porcentaje"
                                                        value={ivaTrasladadoTasaCuota}
                                                        onChange={handleIvaTrasladadoChange}>
                                                        <option value="0">Seleccione porcentaje</option>
                                                        <option value="0.16">16 %</option>
                                                        <option value="0.0">0 %</option>
                                                        <option value="-1.0">Excento</option>
                                                        <option value="-2.0">No aplica</option>
                                                    </Form.Control>
                                                </Col>
                                                <Col  hidden={!taxesChecked}>
                                                    <InputGroup size="sm">
                                                        <InputGroup.Prepend >
                                                            <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <NumberFormat
                                                            id="ivaTrasladadoImporte"
                                                            className="input-signs-align form-control form-control-sm"
                                                            disabled thousandSeparator={true}
                                                            value={ivaTrasladadoImporte}>
                                                        </NumberFormat>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Row>
                                            <Form.Label  hidden={!taxesChecked}>IVA Retenido</Form.Label>
                                            <Form.Row  hidden={!taxesChecked}>
                                                <Col>
                                                    <InputGroup size="sm">
                                                        <NumberFormat
                                                            id="ivaRetenido"
                                                            className="input-signs-align form-control form-control-sm"
                                                            thousandSeparator={true}
                                                            defaultValue={ivaRetenido}
                                                            onChange={handleIvaRetenidoChange}></NumberFormat>
                                                        <InputGroup.Append >
                                                            <InputGroup.Text className="input-signs-right">%</InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </Col>
                                                <Col>
                                                    <InputGroup size="sm">
                                                        <InputGroup.Prepend >
                                                            <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <NumberFormat
                                                            id="ivaRetenidoImporte"
                                                            className="input-signs-align form-control form-control-sm"
                                                            thousandSeparator={true}
                                                            disabled
                                                            value={ivaRetenidoImporte}>
                                                        </NumberFormat>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Row>
                                            <Form.Text  hidden={!taxesChecked} muted>Debe ser entre 0.0 y 16.0</Form.Text>
                                            <Form.Label  hidden={!taxesChecked}>Porcentaje ISR</Form.Label>
                                            <Form.Row  hidden={!taxesChecked}>
                                                <Col>
                                                    <InputGroup size="sm">
                                                        <NumberFormat
                                                            id="isr"
                                                            className="input-signs-align form-control form-control-sm"
                                                            thousandSeparator={true}
                                                            defaultValue={isrTasaCuota}
                                                            onChange={handleIsrChange}>
                                                        </NumberFormat>
                                                        <InputGroup.Append >
                                                            <InputGroup.Text className="input-signs-right">%</InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </Col>
                                                <Col>
                                                    <InputGroup size="sm">
                                                        <InputGroup.Prepend >
                                                            <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <NumberFormat
                                                            id="isrImporte"
                                                            className="input-signs-align form-control form-control-sm"
                                                            thousandSeparator={true}
                                                            disabled
                                                            value={isrImporte}>
                                                        </NumberFormat>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Row>
                                            <Form.Text muted  hidden={!taxesChecked}>Debe ser entre 0.0 y 35.0</Form.Text>
                                            <Form.Label  hidden={!taxesChecked}>Más otros impuestos</Form.Label>
                                            <Form.Row hidden={!taxesChecked}>
                                                <Col>
                                                    <InputGroup size="sm">
                                                        <InputGroup.Append >
                                                            <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                        </InputGroup.Append>
                                                        <NumberFormat id="otrosImpuestos" className="input-signs-align form-control form-control-sm" thousandSeparator={true} value={otrosImpuestos} onChange={handleOtrosImpuestosChange}></NumberFormat>
                                                    </InputGroup>
                                                </Col>
                                                <Col>
                                                    <InputGroup size="sm">
                                                        <InputGroup.Prepend >
                                                            <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <NumberFormat id="otrosImpuestosImporte" className="input-signs-align form-control form-control-sm" thousandSeparator={true} disabled value={otrosImpuestosImporte}></NumberFormat>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Row>
                                            <Form.Text  hidden={!taxesChecked} muted>Estos impuestos se sumarán al total</Form.Text>
                                            <Form.Label  hidden={!taxesChecked}>Descuento</Form.Label>
                                            <div  hidden={!taxesChecked} className="mb-3">
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label="Porcentaje %"
                                                    name="porcentaje"
                                                    id="porcentaje"
                                                    hidden={!taxesChecked}
                                                    onChange={handleDescuentoOpcionPorcentajeChange}
                                                />
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label="Cantidad $"
                                                    name="cantidad"
                                                    id="cantidad"
                                                    hidden={!taxesChecked}
                                                    onChange={handleDescuentoOpcionCantidadChange}
                                                />
                                            </div>
                                            {opcionesDescuento()}
                                            <Form.Label  hidden={!taxesChecked}>Subtotal</Form.Label>
                                            <InputGroup  hidden={!taxesChecked} size="sm">
                                                <InputGroup.Prepend >
                                                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <NumberFormat
                                                    id="subtotal"
                                                    className="input-signs-align form-control form-control-sm"
                                                    disabled thousandSeparator={true}
                                                    value={subtotal} />
                                            </InputGroup>
                                            <Form.Label  hidden={!taxesChecked}>Subtotal con descuento</Form.Label>
                                            <InputGroup   hidden={!taxesChecked} size="sm">
                                                <InputGroup.Prepend >
                                                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <NumberFormat
                                                    id="subtotalConDescuento"
                                                    className="input-signs-align form-control form-control-sm"
                                                    disabled
                                                    thousandSeparator={true}
                                                    value={subtotalConDescuento}>
                                                </NumberFormat>
                                            </InputGroup>
                                            <Form.Label  hidden={!taxesChecked}>Total con impuestos</Form.Label>
                                            <InputGroup  hidden={!taxesChecked} size="sm">
                                                <InputGroup.Prepend   hidden={!taxesChecked}>
                                                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <NumberFormat  hidden={!taxesChecked}
                                                    id="totalConImpuestos"
                                                    className="input-signs-align form-control form-control-sm"
                                                    thousandSeparator={true}
                                                    disabled
                                                    value={total}>
                                                </NumberFormat>
                                            </InputGroup>
                                            {monedaClaveSat !== 'MXN' ? createInputTotalPesosMexicanos() : ''}
                                        </Form.Group>
                                    </Form.Row>
                                </fieldset>
                            </Form.Group>
                        </Form.Row>
                    </Container>
                    </Modal.Body>:<Form.Row as={Col}>{cargando()}</Form.Row> }
                <Modal.Footer className="justify-content-end">
                    <button
                     hidden={isLoading}
                        className="m-1 button-default-confirmar"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Guardar requisición"
                        onClick={updateRequisicion}
                    >{status ?
                        <span>Guardando</span>
                        : <span>Guardar</span>
                        }
                    </button>
                    <button
                        hidden={isLoading}
                        className="m-1 button-default-cancelar"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Cancelar"
                        onClick={props.onHide}
                    >Cancelar
                    </button>
                </Modal.Footer>
            </Modal>
        </section >
    );
}

export default ModalEditarRequisicion;