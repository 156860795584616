import React, { useState } from "react";
import axios from 'axios';
import { KRAKEN_URL } from '../../../../../constants'

//Estilos
import '../../../css/Configuracion.css'

//Componentes bootstrap
import { Modal, Form, Col, Container } from "react-bootstrap";
import { PersonLinesFill } from 'react-bootstrap-icons';
import { toast } from "react-toastify";

function ModalAgregarUsuarioContacto(props) {
    const usuario = props.children;
    const [status, setStatus] = useState(false);
    const [contacto, setContacto] = useState('');
    const [emailContacto, setEmailContacto] = useState('');

    function handleContactoChange(e) {
        setContacto(e.target.value);
    }

    function handleEmailContactoChange(e) {
        setEmailContacto(e.target.value);
    }

    function agregarContacto() {
        setStatus(true);
        axios.post(KRAKEN_URL + 'empresa/usuario/contactos/add',
            {
                "contacto": contacto,
                "emailContacto": emailContacto,
                "userId": usuario.userId,
                "username": usuario.username,
                "empresaAlias": usuario.empresaAlias
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                setStatus(false);
                toast.success(res.data.result.message,{
                    position:"top-center",
                    autoClose: 4500, 
                });
                props.onUpdate();//Actualiza el listado, llamando a la función padre para actualizarlo  
                props.onHide();//Cierra el modal, llamando a la función padre para cerrarlo 
            } else {
                setStatus(false);
                toast.warning(res.data.result.message,{
                    position:"top-center",
                    autoClose: 4500, 
                });
                toast.warning(res.data.result.suggestion,{
                    position:"top-center",
                    autoClose: 4500, 
                });
            }
        }).catch((error) => {
            setStatus(false);
            toast.error("Hubo un error, no se pudo agregar el contacto a este usuario.",{
                position:"top-center",
                autoClose: 4500, 
            });
        })
    }

    return (
        <section className="text-center">
            <Modal
                show={props.show}
                //onHide={props.onHide}
                size="md"
                centered
                id="modalAgregarUsuarioContacto"
                scrollable={true}
            >
                <Modal.Header className="modal-header-empresa-usuario" closeButton onHide={props.onHide}>
                    <p><PersonLinesFill size={22} color="#5B4B51" />   Agregar contacto a {usuario.username}</p>
                </Modal.Header>
                <Modal.Body>
                    <Container className="container-modals">
                        <Form.Row>
                            <Form.Group as={Col} lg={12}>
                                <fieldset>
                                    <legend className="legend-empresa-usuario">Datos del contacto</legend>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Nombre de contacto</Form.Label>
                                            <Form.Control size="sm" id="username" type="text" required placeholder="Ingrese nombre de contacto" onChange={handleContactoChange}></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Email de contacto</Form.Label>
                                            <Form.Control size="sm" id="email" type="text" required placeholder="Ingrese email de contacto" onChange={handleEmailContactoChange}></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                </fieldset>
                            </Form.Group>
                        </Form.Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="justify-content-end">
                    <button
                        className="m-1 button-default-req"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Agregar"
                        onClick={agregarContacto}
                    >{status ? <span>Agregando...</span> : <span>Agregar</span>}
                    </button>
                    <button
                        className="m-1 button-default-cancelar"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Cancelar"
                        onClick={props.onHide}
                    ><span>Cancelar</span>
                    </button>
                </Modal.Footer>
            </Modal>
        </section>
    )
}

export default ModalAgregarUsuarioContacto;