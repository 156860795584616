import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_PROVEEDORES_URL } from '../../constants'

//Estilos
import '../Requisiciones/css/Requisiciones.css'
import './css/ProveedoresCatalogo.css'
import { PencilSquare, Trash } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';

//Componentes bootstrap
import InputGroup from 'react-bootstrap/InputGroup';
import { Container, Col, Form, Modal, Button, ButtonGroup } from "react-bootstrap";

//Componentes
import ModalEditarCuenta from './ProveedorCuentasBancarias/ModalEditarCuenta';
import ModalBorrarCuenta from './ProveedorCuentasBancarias/ModalBorrarCuenta';
import ModalAgregarCuenta from './ProveedorCuentasBancarias/ModalAgregarCuenta';
import SeleccionarEmpresa from '../Requisiciones/SeleccionarEmpresa';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

function ModalEditarProveedor(props) {
    const [request] = useState(false);
    const [error, setError] = useState(false);
    const [status, setStatus] = useState('');
    const [statusError, setStatusError] = useState('');
    const [arrayCuentas, setArrayCuentas] = useState([]);
    const id = props.children.id;
    const idProveedor = props.children.idProveedor;
    const [empresa, setEmpresa] = useState(props.children.empresa);
    const [idEmpresa, setIdEmpresa] = useState(props.children.idEmpresa);
    const [proveedorRfc, setProveedorRfc] = useState(props.children.proveedorRfc);
    const [razonSocial, setRazonSocial] = useState(props.children.razonSocial);
    const [nombreComercial, setNombreComercial] = useState(props.children.nombreComercial);
    const [correoElectronico, setCorreoElectronico] = useState(props.children.correoElectronico);
    const [telefono, setTelefono] = useState(props.children.telefono);
    const [giro, setGiro] = useState(props.children.giro);
    /* Empresa */
    const idProveedorEmpresa = props.children.idProveedorEmpresa;
    /* Dirección */
    const idProveedorDireccion = props.children.idProveedorDireccion;
    const [tipo, setTipo] = useState(props.children.tipo);
    const [calle, setCalle] = useState(props.children.calle);
    const [numeroExterior, setNumeroExterior] = useState(props.children.numeroExterior);
    const [numeroInterior, setNumeroInterior] = useState(props.children.numeroInterior);
    const [colonia, setColonia] = useState(props.children.colonia);
    const [ciudad, setCiudad] = useState(props.children.ciudad);
    const [codigoPostal, setCodigoPostal] = useState(props.children.codigoPostal);
    const [municipio, setMunicipio] = useState(props.children.municipio);
    const [estado, setEstado] = useState(props.children.estado);
    const [pais, setPais] = useState(props.children.pais);
    /* ---- */
    const [proveedorId, setProveedorId] = useState('');
    const [ctaEditar, setCtaEditar] = useState('');
    const [ctaBorrar, setCtaBorrar] = useState('');

    const [modalEditarCuentaShow, setModalEditarCuentaShow] = useState(false);
    const [modalBorrarCuentaShow, setModalBorrarCuentaShow] = useState(false);
    const [modalAgregarCuentaShow, setModalAgregarCuentaShow] = useState(false);
    const [updateListado, setUpdateListado] = useState(false);

    const [errorCPMessage, setErrorCPMessage] = useState('');
    const [isSearch, setIsSearch] = useState(false);

    let modalEditarCuentaClose = () => { setModalEditarCuentaShow(false); setCtaEditar(''); }
    let modalBorrarCuentaClose = () => { setModalBorrarCuentaShow(false); setCtaBorrar(''); }
    let modalAgregarCuentaClose = () => { setModalAgregarCuentaShow(false); setProveedorId('') };
    let updateGetListado = () => { setUpdateListado(!!!updateListado) }
    const [colonias, setColonias] = useState([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getCuentasBancarias, [updateListado]) //Se actualiza cada que hay un cambio en cuentasBancarias (agregar, editar o eliminar)

    function getCuentasBancarias() {
        axios.post(API_PROVEEDORES_URL + 'listCuentas',
            {
                "idProveedor": idProveedor
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                setArrayCuentas(res.data.listCuenta)
            } else {
                setError(true);
                setStatusError(res.data.result.message);
            }
        }).catch((error) => {
            setError(true);
            setStatusError("Error: No se han podido recuperar las cuentas bancarias de este proveedor");
        })
    }

    let handleSizePerPage = ({ page, onSizePerPageChange }, newSizePerPage) => {
        onSizePerPageChange(newSizePerPage, page);
    }

    function empresaSelected(empresa) {
        var str = empresa;
        var array = str.split(",");
        setEmpresa(array[0]);
        setIdEmpresa(array[1]);
    }
    function handleProveedorRfcChange(e) {
        setProveedorRfc(e.target.value);
    }
    function handleNombreComercialChange(e) {
        setNombreComercial(e.target.value);
    }
    function handleRazonSocialChange(e) {
        setRazonSocial(e.target.value);
    }
    function handleCorreoElectronicoChange(e) {
        setCorreoElectronico(e.target.value);
    }
    function handleTelefonoChange(e) {
        setTelefono(e.target.value);
    }
    function handleGiroChange(e) {
        setGiro(e.target.value);
    }

    function handleTipoChange(e) {
        setTipo(e.target.value);
    }
    function handleCalleChange(e) {
        setCalle(e.target.value);
    }
    function handleNumeroExteriorChange(e) {
        setNumeroExterior(e.target.value);
    }
    function handleNumeroInteriorChange(e) {
        setNumeroInterior(e.target.value);
    }
    function handlerColoniaChange(e) {
        setColonia(e.target.value);
    }
    function handleCiudadChange(e) {
        setCiudad(e.target.value);
    }
    function handleCodigoPostalChange(e) {
        setCodigoPostal(e.target.value);
    }
    function handleMunicipioChange(e) {
        setMunicipio(e.target.value);
    }
    function handleEstadoChange(e) {
        setEstado(e.target.value);
    }
    function handlePaisChange(e) {
        setPais(e.target.value);
    }

    function getCuentas(cuenta) {
        setCtaEditar(cuenta);
        setModalEditarCuentaShow(true);
    }

    function getProveedor() {
        setProveedorId(idProveedor);
        setModalAgregarCuentaShow(true);
    }

    function deleteCuenta(cuenta) {
        setCtaBorrar(cuenta);
        setModalBorrarCuentaShow(true);
    }

    function handleKeyPress(e) {
        if (e.key === 'Enter') {
            searchCP();
        }
    }

    function searchCP() {
        axios.post("https://dev.hixsa.net/API-DIRECCIONES/api/direccion", {
            codigoPostal: codigoPostal,
        }).then(res => {
            if ((res.data.respuesta.estados).length !== 0) {
                console.log(console.log(res.data.respuesta));

                setErrorCPMessage('');
                setIsSearch(true);
                setCalle(calle);
                setNumeroExterior(numeroExterior);
                setNumeroInterior(numeroInterior);
                setCodigoPostal(codigoPostal);
                setCiudad(res.data.respuesta.estados[0].descripcion);
                setMunicipio(res.data.respuesta.municipios[0].descripcion);
                setColonias(res.data.respuesta.colonias);
                setEstado(res.data.respuesta.estados[0].descripcion);
                setPais('México');
            } else {
                setErrorCPMessage('CP sin registros');
            }
            console.log(res);
        }).catch(err => {
            setErrorCPMessage('CP sin registros');
        });
    }


    function editProveedor(e) {
        e.preventDefault();
        setStatus(true);
        axios.post(API_PROVEEDORES_URL + 'updateProveedor',
            {
                empresa: empresa,
                idEmpresa: idEmpresa,
                idProveedor: idProveedor,
                nombreComercial: nombreComercial,
                razonSocial: razonSocial,
                proveedorRfc: proveedorRfc,
                correoElectronico: correoElectronico,
                telefono: telefono,
                giro: giro,
                //Empresa
                idProveedorEmpresa: idProveedorEmpresa,
                //Direccion
                idProveedorDireccion: idProveedorDireccion,
                tipo: tipo,
                calle: calle,
                numeroExterior: numeroExterior,
                numeroInterior: numeroInterior,
                colonia: colonia,
                ciudad: ciudad,
                codigoPostal: codigoPostal,
                municipio: municipio,
                estado: estado,
                pais: pais
            }
        ).then((res) => {
            if (res.data.result.success) {
                setStatus(false);
                toast.success(res.data.result.message, {
                    position: "top-center",
                    autoClose: 4500,
                });
                props.onUpdate();//Actualiza el listado, llamando a la función padre para actualizarlo 
            } else {
                toast.error(res.data.result.message, {
                    position: "top-center",
                    autoClose: 4500,
                });
                toast.error(res.data.result.suggestion, {
                    position: "top-center",
                    autoClose: 4500,
                });
                setStatus(false);
            }
        }).catch((error) => {
            toast.error("No se ha podido actualizar el proveedor", {
                position: "top-center",
                autoClose: 4500,
            });
        });
    }

    let listarCuentasBancarias = () => {
        if (error) {
            return (
                <div>{statusError}</div>
            );
        } else {
            const columns = [
                {
                    dataField: 'id',
                    text: 'ID'
                }, {
                    dataField: 'banco',
                    text: 'Banco'
                }, {
                    dataField: 'comentario',
                    text: 'Comentario'
                }, {
                    dataField: 'cuenta',
                    text: 'Cuenta'
                }, {
                    dataField: 'cuentaClabe',
                    text: 'CuentaClabe'
                }, {
                    dataField: 'acciones',
                    isDummy: true,
                    text: 'Acciones',
                    formatter: (cellContent, row) => {
                        return (
                            <ButtonGroup>
                                <Button variant="light" className="button-editar-accion" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Editar cuenta"
                                    onClick={() => getCuentas(row)}>
                                    <PencilSquare size={24} color="#5B4B51" />
                                </Button>
                                <Button variant="light" className="button-eliminar-accion" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Borrar cuenta"
                                    onClick={() => deleteCuenta(row)}>
                                    <Trash size={24} color="#5B4B51" />
                                </Button>
                            </ButtonGroup>
                        );
                    },
                }];

            function indication() {
                return arrayCuentas.length ? "Cargando..." : "No hay registros que mostrar";
            }
            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size}
                </span>
            );
            const options = {
                custom: true,
                totalSize: arrayCuentas.length,
                paginationTotalRenderer: customTotal
            };

            return <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div>
                        <Form>
                            <fieldset className="fieldsetCuentas">
                                <Form.Row>{/**className="formRow"*/}
                                    <div className="d-flex flex-row">
                                        <Form.Label>Mostrar</Form.Label>
                                        <Col>
                                            <Form.Control size="sm" id="sizePerPage" as="select" onChange={(e) => handleSizePerPage(paginationProps, e.target.value)}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value={arrayCuentas.length}>Todos</option>
                                            </Form.Control>
                                        </Col>
                                    </div>
                                    <Col>
                                        <PaginationTotalStandalone {...paginationProps} />
                                    </Col>
                                    <div className="d-flex  ml-auto flex-row-reverse">
                                        <Col>
                                            <PaginationListStandalone {...paginationProps} />
                                        </Col>
                                        <Form.Label column>Página</Form.Label>
                                    </div>
                                </Form.Row>
                            </fieldset>
                        </Form>
                        <div className="table-responsive table-sm">
                            <BootstrapTable
                                keyField='id'
                                data={arrayCuentas}
                                columns={columns}
                                bordered={false}
                                noDataIndication={indication}
                                {...paginationTableProps}
                            />
                        </div>
                    </div>
                )}
            </PaginationProvider>
        }
    }

    return (
        <section className="text-center">
            <Modal
                show={props.show}
                //onHide={props.onHide}
                size="xl"
                id="modalEditarProveedor"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable={true}
            >
                <Modal.Header className="modal-header-editar-prov" closeButton onHide={props.onHide}>
                    <h5 className="legend-crear-prov"><PencilSquare size={22} color="#5B4B51" />   Editar proveedor</h5>
                </Modal.Header>
                <Modal.Body className="modal-body-proveedor">
                    <Container>
                        <Form.Row as={Col}>
                            <Form.Group as={Col} md={6} lg={6}>
                                <fieldset className="fieldset-consultar-prov-info">
                                    <legend className="legend-editar-prov">Información general</legend>
                                    <Form.Row>
                                        <SeleccionarEmpresa onEmpresaSelected={empresaSelected}>{empresa + "," + idEmpresa}</SeleccionarEmpresa>
                                        <Form.Group as={Col}>
                                            <Form.Label>Identificador</Form.Label>
                                            <Form.Control type="text" disabled
                                                defaultValue={id}
                                                className="form-control form-control-sm">
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Razón social</Form.Label>
                                            <Form.Control id="razonSocial" type="text"
                                                required value={razonSocial}
                                                placeholder="Ingrese razón social"
                                                onChange={handleRazonSocialChange}
                                                className="form-control form-control-sm">
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>RFC</Form.Label>
                                            <Form.Control id="proveedorRfc" type="text"
                                                required value={proveedorRfc}
                                                placeholder="Ingrese rfc"
                                                className="form-control form-control-sm"
                                                onChange={handleProveedorRfcChange}>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Nombre comercial</Form.Label>
                                            <Form.Control id="nombreComercial" type="text"
                                                required value={nombreComercial}
                                                placeholder="Ingrese nombre comercial"
                                                onChange={handleNombreComercialChange}
                                                className="form-control form-control-sm">
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Giro</Form.Label>
                                            <Form.Control id="giro" type="text"
                                                value={giro} placeholder="Ingrese giro"
                                                onChange={handleGiroChange}
                                                className="form-control form-control-sm"
                                            ></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Correo electrónico</Form.Label>
                                            <Form.Control id="correoElectronico" type="text"
                                                required value={correoElectronico}
                                                placeholder="Ingrese correo electrónico"
                                                onChange={handleCorreoElectronicoChange}
                                                className="form-control form-control-sm">
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Teléfono</Form.Label>
                                            <Form.Control id="telefono" type="text"
                                                required value={telefono}
                                                placeholder="Ingrese teléfono"
                                                onChange={handleTelefonoChange}
                                                className="form-control form-control-sm">
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                </fieldset>
                            </Form.Group>
                            <Form.Group as={Col} md={6} lg={6}>
                                <fieldset className="fieldset-consultar-prov-direccion">
                                    <legend className="legend-consultar-prov">Dirección</legend>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Tipo de dirección</Form.Label>
                                            <Form.Control as="select"
                                                placeholder="Elige un tipo de dirección"
                                                className="form-control form-control-sm"
                                                onChange={handleTipoChange} defaultValue={tipo}
                                            >
                                                <option default>Selecciona un tipo</option>
                                                <option value="Fiscal">Dirección fiscal</option>
                                                <option value="Sucursal">Dirección de Sucursal</option>
                                                <option value="Bodega">Dirección de Bodega</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Calle</Form.Label>
                                            <Form.Control type="text" defaultValue={calle}
                                                className="form-control form-control-sm"
                                                onChange={handleCalleChange} placeholder="Ingrese calle"
                                            ></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>No. Exterior</Form.Label>
                                            <Form.Control type="text" defaultValue={numeroExterior}
                                                className="form-control form-control-sm"
                                                onChange={handleNumeroExteriorChange} placeholder="Ingrese no. exterior"
                                            ></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>No. Interior</Form.Label>
                                            <Form.Control type="text" defaultValue={numeroInterior}
                                                className="form-control form-control-sm"
                                                onChange={handleNumeroInteriorChange} placeholder="Ingrese no. interior"
                                            ></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Código postal</Form.Label>

                                            <InputGroup size="sm">
                                                <Form.Control type="text" required
                                                    placeholder="Ingrese cp"
                                                    className="form-control form-control-sm"
                                                    value={codigoPostal.length != 0 ? codigoPostal : ''}
                                                    onKeyPress={handleKeyPress}
                                                    onChange={handleCodigoPostalChange}>
                                                </Form.Control>

                                                <button
                                                    className="m-1 button-default-subir-archivo"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title="Buscar"
                                                    onClick={searchCP}
                                                >
                                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                                </button>
                                            </InputGroup>
                                            {
                                                errorCPMessage.length > 0 ?
                                                    <p>{errorCPMessage}</p> : ''
                                            }



                                            {/* <Form.Control type="text" defaultValue={codigoPostal}
                                                className="form-control form-control-sm"
                                                onChange={handleCodigoPostalChange} placeholder="Ingrese código postal"
                                            ></Form.Control> */}
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Colonia</Form.Label>
                                            {
                                                !isSearch ? <Form.Control type="text" required
                                                    placeholder="Ingrese colonia"
                                                    className="form-control form-control-sm"
                                                    value={colonia.length != 0 ? colonia : ''}
                                                    onChange={handlerColoniaChange}>
                                                </Form.Control>
                                                    :
                                                    <Form.Control as="select"
                                                        placeholder="Elige la colonia"
                                                        className="form-control form-control-sm"
                                                        onChange={handlerColoniaChange}>
                                                        <option default value={-1}>Elige una opción</option>
                                                        {colonias.map(el => (
                                                            <option key={el.clave} value={el.descripcion}>{el.descripcion}</option>
                                                        ))}
                                                    </Form.Control>
                                            }

                                            {/* <Form.Control type="text" defaultValue={colonia}
                                                className="form-control form-control-sm"
                                                onChange={handlerColoniaChange} placeholder="Ingrese colonia"
                                            ></Form.Control> */}
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Ciudad</Form.Label>
                                            <Form.Control type="text" required
                                                placeholder="Ingrese ciudad"
                                                className="form-control form-control-sm"
                                                value={ciudad.length != 0 ? ciudad : ''}
                                                onChange={handleCiudadChange}>
                                            </Form.Control>
                                            {/* <Form.Control type="text" defaultValue={ciudad}
                                                className="form-control form-control-sm"
                                                onChange={handleCiudadChange} placeholder="Ingrese ciudad"
                                            ></Form.Control> */}
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Municipio</Form.Label>
                                            <Form.Control type="text" required
                                                placeholder="Ingrese municipio"
                                                className="form-control form-control-sm"
                                                value={municipio.length != 0 ? municipio : ''}
                                                onChange={handleMunicipioChange}>
                                            </Form.Control>
                                            {/* <Form.Control type="text" defaultValue={municipio}
                                                className="form-control form-control-sm"
                                                onChange={handleMunicipioChange} placeholder="Ingrese municipio"
                                            ></Form.Control> */}
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Estado</Form.Label>
                                            <Form.Control type="text" required
                                                placeholder="Ingrese estado"
                                                className="form-control form-control-sm"
                                                value={estado.length != 0 ? estado : ''}
                                                onChange={handleEstadoChange}>
                                            </Form.Control>
                                            {/* <Form.Control type="text" defaultValue={estado}
                                                className="form-control form-control-sm"
                                                onChange={handleEstadoChange} placeholder="Ingrese estado"
                                            ></Form.Control> */}
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>País</Form.Label>
                                            <Form.Control type="text" required
                                                placeholder="Ingrese país"
                                                className="form-control form-control-sm"
                                                value={pais.length != 0 ? pais : ''}
                                                onChange={handlePaisChange}>
                                            </Form.Control>
                                            {/* <Form.Control type="text" defaultValue={pais}
                                                className="form-control form-control-sm"
                                                onChange={handlePaisChange} placeholder="Ingrese pais"
                                            ></Form.Control> */}
                                        </Form.Group>
                                    </Form.Row>
                                </fieldset>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row as={Col}>
                            <Form.Group as={Col} md={6} lg={12}>
                                <h5 className="legend-editar-prov">Datos bancarios</h5>
                                <fieldset className="fieldset-editar-prov">
                                    <legend></legend>
                                    <div>
                                        {request ? "Cargando..." : listarCuentasBancarias()}
                                        {ctaEditar !== '' ? <ModalEditarCuenta show={modalEditarCuentaShow} onHide={modalEditarCuentaClose} onUpdate={updateGetListado}>{ctaEditar}</ModalEditarCuenta> : ''}
                                        {ctaBorrar !== '' ? <ModalBorrarCuenta show={modalBorrarCuentaShow} onHide={modalBorrarCuentaClose} onUpdate={updateGetListado}>{ctaBorrar}</ModalBorrarCuenta> : ''}
                                        {proveedorId !== '' ? <ModalAgregarCuenta show={modalAgregarCuentaShow} onHide={modalAgregarCuentaClose} onUpdate={updateGetListado}>{proveedorId}</ModalAgregarCuenta> : ''}
                                    </div>
                                </fieldset>
                                <div className="d-flex justify-content-center">
                                    <button
                                        className="m-1 button-default-subir-archivo"
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title="Agregar nueva cuenta"
                                        onClick={getProveedor}
                                    ><span>Agregar cuenta </span>
                                        {/*<CreditCard2Front size={24} color="white" />*/}
                                    </button>
                                </div>
                            </Form.Group>
                        </Form.Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="justify-content-end">
                    <button
                        className="m-1 button-default-confirmar"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Guardar proveedor"
                        onClick={editProveedor}
                    >{status ?
                        <span>Guardando...</span>
                        : <span>Guardar</span>}
                    </button>
                    <button
                        className="m-1 button-default-cancelar"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Cerrar"
                        onClick={props.onHide}
                    ><span>Cerrar</span>
                    </button>
                </Modal.Footer>
            </Modal>
        </section>
    );
}

export default ModalEditarProveedor;