import React, { useState } from "react";
import axios from "axios";
import { API_PROVEEDORES_URL } from '../../constants'
import { base64ToArrayBuffer, saveByteArray } from "../../constantes/download-file-handler";

//Estilos
import "../Pagos/css/Pagos.css"
import "../Pagos/css/PagosPasados.css"
import { Eye, FileArrowDown, ListTask, Grid3x2GapFill, CaretDownFill, CaretUpFill, PeopleFill } from 'react-bootstrap-icons';

//Elementos bootstrap
import { Card, Form, Col, Button, ButtonGroup, Badge } from "react-bootstrap";

//Componentes
import Moment from 'moment';
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ModalConsultar from './ModalConsultar';
import Loader from "../Loader";

function PagosPasados(props) {
    console.log(props.children[0])
    const user = JSON.parse(localStorage.getItem('token'));
    const token = JSON.parse(localStorage.getItem('token'));
    const [gridCards, setGridCards] = useState(false);
    const [cargando, setCargando] = useState(false)
    const [listaPasados] = useState(eliminarObjetosCompartidos(props.children[0]));
    const [empresa] = useState([props.children[1]]);
    const [error] = useState(false);
    const [errorStatus, setErrorStatus] = useState('');
    const [modalConsultarShow, setModalConsultarShow] = useState(false);
    const [pago, setPago] = useState('');
    const [proveedor, setProveedor] = useState('');
    const [rfc, setRfc] = useState('');
    const [solicitante, setSolicitante] = useState('');
    const [filterList, setFilterList] = useState([]);
    const [filterByDate, setFilterByDate] = useState([]);

    let modalConsultarClose = () => setModalConsultarShow(false);
    let updateGetListado = () =>  [props.onUpdate()]
    
    const getPagosPasadosOneEmpresa  = () => {
      
        setCargando(true)
        axios.get(API_PROVEEDORES_URL + 'getPagosPasadosOneEmpresa/' + empresa)
        .then(res => {
            user.userType === 'SCHEMA_ADMIN'? filterDataToMaster(res.data?.pagos[0]?.lista) : 
            filterDataToUser(res.data?.pagos[0]?.lista);
            setFilterByDate(eliminarObjetosCompartidos(res.data?.pagos[0]?.lista))
        })
        .finally(() => {
            setCargando(false)
        })
    }
    function eliminarObjetosCompartidos(arreglo) {
        arreglo = arreglo.filter(function(objeto) {
        
            return !(objeto.esCompartida === 1 && (objeto.usuarioCompartido !== token.username && objeto.usuarioCompartido !== ""));
        });
        return arreglo;
    }
    function filterDataToUser(empresas){ 
        empresas.map((empresa)=>{
         empresa.lista = empresa.lista.filter(function(objeto) {
            return ((objeto.solicitante ===token.username)||objeto.usuarioCompartido ===token.username||objeto.pagadorId ===token.userId );
         });
         })
         return empresas;
     }
 
     function filterDataToMaster(empresas){
         empresas.map((empresa)=>{
         if(empresa.lista)
             empresa.lista = empresa.lista.filter(function(objeto) {
                 
                 return (objeto.usuarioCompartido !==token.username );
             });
             })
             return empresas;
 
     }
    function handleProveedorChange(e) { 
        setProveedor(e.target.value);
        filterPagosPasados(e.target.value, '', '')
    }

    function handleRFCChange(e) {
        setRfc(e.target.value);
        filterPagosPasados('', e.target.value, '')
    }

    function handleSolicitanteChange(e) {
        setSolicitante(e.target.value);
        filterPagosPasados('', '', e.target.value)
    }

    function getPago(pago) {
        setPago(pago);
        setModalConsultarShow(true);
    }

    function downloadReport(requisicion) {
        let fileName = requisicion.empresa + "_" + requisicion.id;
        setCargando(true)
        axios.post(API_PROVEEDORES_URL + 'files/getRequisicionPDF',
            {
                id: requisicion.id,
                fechaCreacion: Moment(requisicion.fechaCreacion).format('DD/MM/YYYY'),
                fechaRequeridaPago: Moment(requisicion.fechaRequeridaPago).format('DD/MM/YYYY'),
                solicitante: requisicion.solicitante,
                empresa: requisicion.empresa,
                concepto: requisicion.concepto,
                total: requisicion.total,
                proveedor: requisicion.proveedor,
                banco: requisicion.banco,
                referencia: requisicion.referencia,
                idProveedor: requisicion.idProveedor,
                idRequisicion: requisicion.idRequisicion,
            }
        ).then(res => {
            let arrayBuffer = base64ToArrayBuffer(res.data.bytes);
            saveByteArray(fileName, "application/pdf", arrayBuffer);
        }).catch((error) => {
            toast.error("No fue posible generar el reporte",{
                position:"top-center",
                autoClose: 4500, 
            });
        }).finally(() => {
            setCargando(false)
        })
    }
    
    function filterPagosbyDate(e, opcion){
        e.preventDefault();
        if(opcion === "5") {
            getPagosPasadosOneEmpresa()
        } else {
            setCargando(true)
            axios.post(API_PROVEEDORES_URL + 'filterDatePagosPasados',
            {
                "empresas": empresa,
                "opcion": opcion
                }
            ).then(res => {
                if(!res.data.pagos){
                    toast.warning("No se encontraron pagos pasados asociados",{
                        position:"top-center",
                        autoClose: 4500, 
                    });
                }else {
                    if (res.data.result.code === 200) {
                      
                        user.userType === 'SCHEMA_ADMIN'? filterDataToMaster(res.data?.pagos[0]?.lista) : 
                            filterDataToUser(res.data?.pagos[0]?.lista);
                            setFilterByDate(eliminarObjetosCompartidos(res.data?.pagos[0]?.lista))
                           
                    } else {
                        setFilterByDate('');
                        setErrorStatus(res.data.result.message);
                    }
                }
            }).catch((error) => {
                setErrorStatus("Ocurrió un error, no se pudieron recuperar los pagos pasados");
            }).finally(() => {
                setCargando(false)
            })
        }
    }
    
    function filterPagosPasados(proveedor, proveedorRfc, solicitante){
        console.log("Entra en la funcion filtro")
        setCargando(true)
        axios.post(API_PROVEEDORES_URL + 'filterListPagosPasados',
            {
                "empresas": empresa,
                "proveedor": proveedor,
                "proveedorRfc": proveedorRfc,
                "solicitante": solicitante
            }
        ).then(res => {
            if (res.data.result.code === 200) {
                user.userType === 'SCHEMA_ADMIN'? filterDataToMaster(res.data?.pagos[0]?.lista) : 
                filterDataToUser(res.data?.pagos[0]?.lista);     
                setFilterList(eliminarObjetosCompartidos(res.data.pagos[0].lista))
                console.log(res.data.pagos[0].lista)
            } else {
                setFilterList('');
                setErrorStatus(res.data.result.message);
            }
        }).catch((error) => {
            setErrorStatus("Ocurrió un error, no se pudieron recuperar los pagos pasados");
        })
        setCargando(false)
    }

    let listarProveedores = ()  => {
        const listProveedores = listaPasados.reduce((acc, item) => {
            if(!acc.includes(item.proveedor)){
                acc.push([item.proveedor, item.proveedorRfc]);
            }
            return acc;
        }, [])

        const objProveedor = {};
        const arrayProveedor = [];
        listProveedores.forEach(rs => {
            if(!(rs in objProveedor)){
                objProveedor[rs] = true
                arrayProveedor.push(rs)
            }
        })
        return arrayProveedor;        
    }

    let listarSolicitantes = ()  => {
        const listSolicitantes = listaPasados.reduce((acc, item) => {
            if(!acc.includes(item.solicitante)){
                acc.push([item.solicitante]);
            }
            return acc;
        }, [])

        const objSolicitante = {};
        const arraySolicitante = [];
        listSolicitantes.forEach(s => {
            if(!(s in objSolicitante)){
                objSolicitante[s] = true
                arraySolicitante.push(s)
            }
        })
        return arraySolicitante;      
    }

    function cargarSelectSolitantes(){
        return listarSolicitantes().length ? listarSolicitantes().map(s => {
            return (<option key={s[0]} value={s[0]}>{s[0]}</option>)
        }) : 'No hay solicitantes disponibles'
    }

    function cargarSelectRazonSocial(){
        return listarProveedores().length ? listarProveedores().map(rs => {
            return (<option key={rs[1]} value={rs[0]}>{rs[0]}</option>)
        }) : 'No hay razones sociales disponibles'
    }

    function cargarSelectRFC () {
        return listarProveedores().length ? listarProveedores().map(rfc => {
            return (<option key={rfc[1]} value={rfc[1]}>{rfc[1]}</option>)
        }) : "No hay RFC's disponibles"
    }

    let mostrarListaPasados = () => {
        if (error) {
            return (
                <div>{errorStatus}</div>
            );
        } else {
            const columnsList = [
                {
                    dataField: 'id',
                    text: 'ID',
                    sort: true,
                    footer: '',
                    sortCaret: (order, column) => {
                        if (!order) return (<span>&nbsp;&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000" /><CaretUpFill size={10} className="button-default-black" color="#000" /></span>);
                        else if (order === "desc") return (<span>&nbsp;&nbsp;<CaretUpFill size={10} className="button-default-black" color="#000" /><CaretDownFill size={10} className="button-default-black" color="#dee2e6" /></span>);
                        else if (order === "asc") return (<span>&nbsp;&nbsp;<CaretUpFill size={10} className="button-default-black" color="#dee2e6" /><CaretDownFill size={10} className="button-default-black" color="#000" /></span>);
                        return null;
                    }
                },
                {
                    dataField: 'concepto',
                    text: 'Concepto',
                    footer: '',
                },
                {
                    dataField: 'solicitante',
                    text: 'Solicitante',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true,
                    footer: '',
                    sortCaret: (order, column) => {
                        if (!order) return (<span>&nbsp;&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000" /><CaretUpFill size={10} className="button-default-black" color="#000" /></span>);
                        else if (order === "desc") return (<span>&nbsp;&nbsp;<CaretUpFill size={10} className="button-default-black" color="#000" /><CaretDownFill size={10} className="button-default-black" color="#dee2e6" /></span>);
                        else if (order === "asc") return (<span>&nbsp;&nbsp;<CaretUpFill size={10} className="button-default-black" color="#dee2e6" /><CaretDownFill size={10} className="button-default-black" color="#000" /></span>);
                        return null;
                    }
                },
                {
                    dataField: 'proveedorRfc',
                    text: 'RFC Proveedor',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true,
                    footer: '',
                    sortCaret: (order, column) => {
                        if (!order) return (<span><CaretDownFill size={10} className="button-default-black" color="#000" /><CaretUpFill size={10} className="button-default-black" color="#000" /></span>);
                        else if (order === "desc") return (<span><CaretUpFill size={10} className="button-default-black" color="#000" /><CaretDownFill size={10} className="button-default-black" color="#dee2e6" /></span>);
                        else if (order === "asc") return (<span><CaretUpFill size={10} className="button-default-black" color="#dee2e6" /><CaretDownFill size={10} className="button-default-black" color="#000" /></span>);
                        return null;
                    }
                },
                {
                    dataField: 'proveedor',
                    text: 'Proveedor',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "custom-datefilter-class"
                    }),
                    sort: true,
                    footer: '',
                    sortCaret: (order, column) => {
                        if (!order) return (<span>&nbsp;&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000" /><CaretUpFill size={10} className="button-default-black" color="#000" /></span>);
                        else if (order === "desc") return (<span>&nbsp;&nbsp;<CaretUpFill size={10} className="button-default-black" color="#000" /><CaretDownFill size={10} className="button-default-black" color="#dee2e6" /></span>);
                        else if (order === "asc") return (<span>&nbsp;&nbsp;<CaretUpFill size={10} className="button-default-black" color="#dee2e6" /><CaretDownFill size={10} className="button-default-black" color="#000" /></span>);
                        return null;
                    }
                },
                {
                    dataField: 'fechaRequeridaPago',
                    text: 'Fecha requerida',
                    formatter: (cellContent, row) => {
                        return (
                            Moment(row.fechaRequeridaPago).format('DD/MM/YYYY')
                        )
                    },
                    sort: true,
                    footer: '',
                    sortCaret: (order, column) => {
                        if (!order) return (<span>&nbsp;&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000" /><CaretUpFill size={10} className="button-default-black" color="#000" /></span>);
                        else if (order === "desc") return (<span>&nbsp;&nbsp;<CaretUpFill size={10} className="button-default-black" color="#000" /><CaretDownFill size={10} className="button-default-black" color="#dee2e6" /></span>);
                        else if (order === "asc") return (<span>&nbsp;&nbsp;<CaretUpFill size={10} className="button-default-black" color="#dee2e6" /><CaretDownFill size={10} className="button-default-black" color="#000" /></span>);
                        return null;
                    }
                },
                {
                    dataFiel:'fechaPago',
                    text: 'Fecha de pago',
                    formatter:(cellContent, row) => {
                        return(
                            Moment(row.fechaPago).format('DD/MM/YYYY')
                        )
                    },
                    sort: true,
                    footer: 'Totales:',
                    sortCaret: (order, column) => {
                        if (!order) return (<span>&nbsp;&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000" /><CaretUpFill size={10} className="button-default-black" color="#000" /></span>);
                        else if (order === "desc") return (<span>&nbsp;&nbsp;<CaretUpFill size={10} className="button-default-black" color="#000" /><CaretDownFill size={10} className="button-default-black" color="#dee2e6" /></span>);
                        else if (order === "asc") return (<span>&nbsp;&nbsp;<CaretUpFill size={10} className="button-default-black" color="#dee2e6" /><CaretDownFill size={10} className="button-default-black" color="#000" /></span>);
                        return null;
                    }
                },
                {
                    dataField: 'total',
                    text: 'Total',
                    formatter: (cellContent, row) => {
                        return (
                            <NumberFormat
                                displayType={'text'}
                                thousandSeparator={true}
                                value={row.total.toFixed(2/*row.monedaDecimales*/)}
                            ></NumberFormat>
                        )
                    },
                    sort: true,
                    footer: columnData => {
                        let sumatoria = columnData.reduce((acc, item) => acc + item, 0).toFixed(2)
                        return (<h5><Badge variant="success">
                            <NumberFormat displayType={'text'} thousandSeparator={true} value={sumatoria} />
                        </Badge></h5>)
                    },
                    sortCaret: (order, column) => {
                        if (!order) return (<span>&nbsp;&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000" /><CaretUpFill size={10} className="button-default-black" color="#000" /></span>);
                        else if (order === "desc") return (<span>&nbsp;&nbsp;<CaretUpFill size={10} className="button-default-black" color="#000" /><CaretDownFill size={10} className="button-default-black" color="#dee2e6" /></span>);
                        else if (order === "asc") return (<span>&nbsp;&nbsp;<CaretUpFill size={10} className="button-default-black" color="#dee2e6" /><CaretDownFill size={10} className="button-default-black" color="#000" /></span>);
                        return null;
                    }
                },
                {
                    dataField: 'acciones',
                    isDummyField: true,
                    text: 'Acciones',
                    formatter: (cellContent, row) => {
                        return (
                            <div>
                                <Button className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Consultar"
                                    onClick={() => getPago(row)}>
                                    <Eye size={22} color="#000" className="button-default-black" />
                                </Button>
                                
                               

                                            {row.esCompartida !== 1 ? (
  <Button
    className="button-default-black"
    variant="light"
    size="sm"
    data-toggle="tooltip"
    data-placement="bottom"
    title="Descargar"
    onClick={() => downloadReport(row)}
  >
    <FileArrowDown size={22} color="#000" className="button-default-black" />
  </Button>
) : 
  ""
} {row.compartidos!=="[]" ? (<Button  className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Compartida"                         
                                ><PeopleFill size={22} className="button-default-black" color="#000" />
                                </Button>):""} 
                                
                            </div>
                        );
                    }
                }
            ];
            const columns = [
                {
                    id: 'titlePasado',
                    dataField: 'pasado',
                    text: '',
                    isDummyField: true,
                    formatter: (cellContent, row) => {
                        return (
                            <div className="font-title-pagos-pasados">
                                <div >{'Pagado'}</div>
                            </div>
                        )
                    },
                },
                {
                    dataField: 'id',
                    text: 'ID',
                    isDummyField: true,
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                },
                {
                    dataField: 'concepto',
                    text: 'Concepto',
                    isDummyField: true,
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                },
                {
                    dataField: 'proveedorRfc',
                    text: 'RFC Proveedor',
                    isDummyField: true,
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                },
                {
                    dataField: 'proveedor',
                    text: 'Proveedor',
                    isDummyField: true,
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                },
                {
                    dataField: 'rowId',
                    text: '',
                    isDummyField: true,
                    formatter:(cellContent, row) => {
                        return (
                            <div className="d-flex justify-content-around">
                                <div>
                                    <div className="pago-tbody-tr-td-div">
                                        <div className="font-col-pago">{'ID: '}</div>
                                        <div>{row.id}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                },
                {
                    dataField: 'rowOne',
                    text: '',
                    isDummyField: true,
                    formatter: (cellContent, row) => {
                        return (
                            <div className="d-flex justify-content-around pago-tbody-tr-td-div">
                                <div>
                                    <div className="pago-tbody-tr-td-div">
                                        <div className="font-col-pago">{'Fecha requerida: '}</div>
                                        <div>{Moment(row.fechaRequeridaPago).format('DD/MM/YYYY')}</div>
                                    </div>
                                    <div className="pago-tbody-tr-td-div">
                                        <div className="font-col-pago">{'Empresa: '}</div>
                                        <div>{row.empresa}</div>
                                    </div>
                                </div>
                                <div>
                                    <div className="pago-tbody-tr-td-div">
                                        <div className="font-col-pago">{'Fecha de pago: '}</div>
                                        <div>{Moment(row.fechaPago).format('DD/MM/YYYY')}</div>
                                    </div>
                                    <div className="pago-tbody-tr-td-div">
                                        <div className="font-col-pago">{'Total: '}</div>
                                        <NumberFormat
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            value={row.total.toFixed(2/*row.monedaDecimales*/)}
                                        ></NumberFormat>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                },
                {
                    dataField: 'rowTwo',
                    text: '',
                    isDummyField: true,
                    formatter: (cellContent, row) => {
                        return (
                            <div className="pago-tbody-tr-td-div font-rowTwo-pagos-pasados">
                                <div>
                                    <div className="pago-tbody-tr-td-div">
                                        <div className="font-col-pago">{'Concepto: '}</div>
                                        <div>{row.concepto}</div>
                                    </div>
                                    <div className="pago-tbody-tr-td-div">
                                        <div className="font-col-pago">{'Solicitante: '}</div>
                                        <div>{row.solicitante}</div>
                                    </div>
                                </div>
                                <div >
                                    <div className="pago-tbody-tr-td-div">
                                        <div className="font-col-pago">{'Proveedor: '}</div>
                                        <div>{row.proveedor}</div>
                                    </div>
                                    <div className="pago-tbody-tr-td-div">
                                        <div className="font-col-pago">{'RFC Proveedor: '}</div>
                                        <div>{row.proveedorRfc}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                },
                {
                    dataField: 'acciones',
                    isDummyField: true,
                    text: '',
                    formatter: (cellContent, row) => {
                        return (
                            <div className="font-footer-pagos-pasados">
                                <ButtonGroup>
                                    <button size="sm" className="button-default-black-transparent"
                                        data-toggle="tooltip" data-placement="bottom" title="Consultar"
                                        onClick={() => getPago(row)}>
                                        <Eye size={22} color="#000" className="button-default-black-transparent" />
                                    </button>
                                    <button size="sm" className="button-default-black-transparent"
                                        data-toggle="tooltip" data-placement="bottom" title="Descargar"
                                        onClick={() => downloadReport(row)}
                                    >
                                        <FileArrowDown size={22} color="#000" className="button-default-black-transparent" />
                                    </button>
                                </ButtonGroup>
                            </div>
                        );
                    }
                }
            ];

            function indication() {
                return listaPasados.length ? "La búsqueda no coincide con ningún criterio" : "No hay registros que mostrar";
            }

            function indicationFilter() {
                return filterList.length ? "La búsqueda no coincide con ningún criterio" : "No hay registros que mostrar";
            }

            function indicationFilterByDate() {
                return filterByDate.length ? "La búsqueda no coincide con ningún criterio" : "No hay registros que mostrar";
            }


            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size} registros
                </span>
            );

            const sizePage = [
                {
                    text: '10', value: 10
                },
                {
                    text: '25', value: 25
                },
                {
                    text: '30', value: 30
                },
                {
                    text: '50', value: 50
                }
            ]

            const options = {
                custom: true,
                sizePerPage: gridCards ? 4 : 10,
                totalSize: listaPasados.length,
                paginationTotalRenderer: customTotal,
                sizePerPageList: sizePage,
            }

            const optionsFilter = {
                custom: true,
                sizePerPage: gridCards ? 4 : 10,
                totalSize: filterList.length,
                sizePerPageList: sizePage,
                paginationTotalRenderer: customTotal,
            }

            const optionsFilterByDate = {
                custom: true,
                sizePerPage: gridCards ? 4 : 10,
                totalSize: filterByDate.length,
                sizePerPageList: sizePage,
                paginationTotalRenderer: customTotal,
            }

            return <PaginationProvider pagination={paginationFactory(filterList.length ? optionsFilter :  filterByDate.length ? optionsFilterByDate : options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div className="fieldsetPagosPasados">
                        <div>
                            <Form inline className="d-flex justify-content-center">
                                <Form.Row as={Col} sm={12} md={12} lg={12} className="date-filter m-1">
                                    <Form.Group >
                                        <button className="m-1 button-mes-actual-filter" variant="light" size="sm" 
                                            onClick={e => filterPagosbyDate(e, "1")}>Este mes
                                        </button>
                                    </Form.Group>
                                    <Form.Group>
                                        <button className="m-1 button-ultimos-dias-filter" variant="light" size="sm" 
                                            onClick={e => filterPagosbyDate(e, "2")}>Últimos 10 días
                                        </button>
                                    </Form.Group>
                                    <Form.Group>
                                        <button className="m-1 button-mes-anterior-filter" variant="light" size="sm" 
                                            onClick={e => filterPagosbyDate(e, "3")}>Mes pasado
                                        </button>
                                    </Form.Group>
                                    <Form.Group>
                                        <button className="m-1 button-year-filter" variant="light" size="sm"
                                            onClick={e => filterPagosbyDate(e, "4")}>Este año
                                        </button>
                                    </Form.Group>
                                    <Form.Group>
                                        <button className="m-1 button-year-filter" variant="light" size="sm"
                                            onClick={e => filterPagosbyDate(e, "5")}>Histórico
                                        </button>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Group className="m-1">
                                    <Form.Control size="sm" id="proveedor"
                                        type="text" as="select"
                                        required placeholder="Selecione opción"
                                        defaultValue={proveedor}
                                        onChange={handleProveedorChange}>
                                        <option default value={proveedor}>Razón social</option>
                                        {cargarSelectRazonSocial()}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className="m-1">
                                    <Form.Control size="sm" id="rfc"
                                        type="text" as="select"
                                        required placeholder="Selecione opción"
                                        defaultValue={rfc}
                                        onChange={handleRFCChange}>
                                        <option default value={rfc}>RFC</option>
                                        {cargarSelectRFC()}
                                    </Form.Control>   
                                </Form.Group>
                                <Form.Group className="m-1">
                                    <Form.Control size="sm" id="solicitante"
                                        type="text" as="select"
                                        required placeholder="Selecione opción"
                                        defaultValue={solicitante}
                                        onChange={handleSolicitanteChange}>
                                        <option default value={solicitante}>Solicitante</option>
                                        {cargarSelectSolitantes()}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group>
                                    <button className="button-refresh-filter" variant="light" size="sm" onClick={updateGetListado}>Limpiar</button>
                                </Form.Group>
                            </Form>
                        </div>
                        <div className="d-flex ml-auto mb-2 flex-row-reverse">
                            {gridCards ?
                                <Button className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Listado"
                                    onClick={() => { setGridCards(false) }}
                                >
                                    <ListTask size={24} className="button-default-black" color="#000" />
                                </Button> :
                                <Button className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Cuadrícula"
                                    onClick={() => { setGridCards(true) }}
                                >
                                    <Grid3x2GapFill size={24} className="button-default-black" color="#000" />
                                </Button>
                            }
                        </div>
                        <div className="d-flex">
                            {gridCards ? '' :
                                <div className="d-flex flex-row">
                                    <Form.Label column>Mostrar</Form.Label>
                                    <Col>
                                        {/* <Form.Control size="sm" as="select" onChange={(e) => {
                                            // handleSizePerPage(paginationProps, e?.target?.value)
                                        }}>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value={filterList.length ? filterList.length : filterByDate.length ? filterByDate.length : listaPasados.length}>Todos</option>
                                        </Form.Control> */}
                                        <SizePerPageDropdownStandalone bootstrap4 {...paginationProps} btnContextual/>
                                    </Col>
                                </div>}
                            <div className="d-flex flex-row">
                                <Col>
                                    <PaginationTotalStandalone {...paginationProps} />
                                </Col>
                            </div>
                            <div className="d-flex ml-auto flex-row-reverse">
                                <Col>
                                    <PaginationListStandalone {...paginationProps} />
                                </Col>
                                <Form.Label column>Página</Form.Label>
                            </div>
                        </div>
                        {gridCards ?
                            <div className="table-responsive table-sm">
                                <BootstrapTable
                                    id="pagosPasados"
                                    keyField='id'
                                    data={filterList.length ? filterList : filterByDate.length ? filterByDate : listaPasados }
                                    columns={columns}
                                    filter={filterFactory()}
                                    bordered={false}
                                    noDataIndication={filterList.length ? indicationFilter : filterByDate.length ? indicationFilterByDate : indication}
                                />
                            </div>
                            :     
                            <div className="table-responsive table-sm text-center">
                                <BootstrapTable
                                    bootstrap4
                                    keyField='id'
                                    data={filterList.length ? filterList : filterByDate.length ? filterByDate : listaPasados}
                                    columns={columnsList}
                                    filter={filterFactory()}
                                    headerClasses="header-class-pago"
                                    bordered={false}
                                    striped
                                    hover
                                    condensed
                                    noDataIndication={filterList.length ? indicationFilter : filterByDate.length ? indicationFilterByDate : indication}
                                    {...paginationTableProps}
                                />
                            </div>        
                        }
                    </div>
                )}
            </PaginationProvider >
        }
    }

    return (
        <section id="listaPagosPasados">
            <Card id="cardPagosPasados">
                <div>
                    {modalConsultarShow ? <ModalConsultar show={modalConsultarShow} onHide={modalConsultarClose} onUpdate={updateGetListado}>{pago}</ModalConsultar> : ''}
                </div>
                <Card.Body className="cardBodyPagosPasados" style={{height: '100%'}}>
                    {cargando ? <Loader /> : mostrarListaPasados()}
                </Card.Body>
            </Card>
        </section>
    );
}

export default PagosPasados;