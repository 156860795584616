import React, { useState } from "react";
import axios from "axios";
import { API_PROVEEDORES_URL } from '../../constants'
import { base64ToArrayBuffer, saveByteArray } from "../../constantes/download-file-handler";

//Estilos
import './css/Requisiciones.css'
import './css/RequisicionesPasadas.css'
import { FileArrowDown, Eye, ListTask, Grid3x2GapFill, CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';

//Componentes bootstrap
import { Card, Col, Form, Button, ButtonGroup } from 'react-bootstrap';

//Componentes
import Moment from 'moment';
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ModalConsultarRequisicion from './ModalConsultarRequisicion';

function RequisicionesPasadas(props) {
    const [gridCards, setGridCards] = useState(false);
    const [listaPasadas] = useState(props.children);
    const [error] = useState(false);
    const [request] = useState(false);
    const [requisicion, setRequisicion] = useState('');
    const [modalConsultarRequisicionShow, setModalConsultarRequisicionShow] = useState(false);

    let modalConsultarRequisicionClose = () => setModalConsultarRequisicionShow(false);

    function viewRequisicion(requisicion) {
        setRequisicion(requisicion);
        setModalConsultarRequisicionShow(true);
    }

    let handleSizePerPage = ({ page, onSizePerPageChange }, newSizePerPage) => {
        onSizePerPageChange(newSizePerPage, page);
    }

    function downloadReport(requisicion) {
        let fileName = requisicion.empresa + "_" + requisicion.id;
        axios.post(API_PROVEEDORES_URL + 'files/getRequisicionPDF',
            {
                id: requisicion.id,
                fechaCreacion: Moment(requisicion.fechaCreacion).format('DD/MM/YYYY'),
                fechaRequeridaPago: Moment(requisicion.fechaRequeridaPago).format('DD/MM/YYYY'),
                solicitante: requisicion.solicitante,
                empresa: requisicion.empresa,
                concepto: requisicion.concepto,
                total: requisicion.total,
                proveedor: requisicion.proveedor,
                banco: requisicion.banco,
                referencia: requisicion.referencia,
                idProveedor: requisicion.idProveedor,
                idRequisicion: requisicion.idRequisicion,
            }
        ).then(res => {
            let arrayBuffer = base64ToArrayBuffer(res.data.bytes);
            saveByteArray(fileName, "application/pdf", arrayBuffer);
        }).catch((error) => {
            toast.error("No fue posible generar el reporte",{
                position:"top-center",
                autoClose: 4500, 
            });
        })
    }

    let mostrarListaPasadas = () => {
        if (error) {
            return (
                <div>Error: No se han podido recuperar las requisiciones pasadas</div>
            );
        } else {
            const columnsList = [
                {
                    dataField: 'id',
                    text: 'ID',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                },
                {
                    dataField: 'concepto',
                    text: 'Concepto',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                },
                {
                    dataField: 'solicitante',
                    text: 'Solicitante',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                },
                {
                    dataField: 'proveedorRfc',
                    text: 'RFC proveedor',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                },
                {
                    dataField: 'proveedor',
                    text: 'Proveedor',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                },
                {
                    dataField: 'fechaRequeridaPago',
                    text: 'Fecha requerida',
                    formatter: (cellContent, row) => {
                        return (
                            Moment(row.fechaRequeridaPago).format('DD/MM/YYYY')
                        )
                    },
                    sort: true,
                    sortCaret: (order, column) => {
                        if (!order) return (<span>&nbsp;&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000" /><CaretUpFill size={10} className="button-default-black" color="#000" /></span>);
                        else if (order === "desc") return (<span>&nbsp;&nbsp;<CaretUpFill size={10} className="button-default-black" color="#000" /><CaretDownFill size={10} className="button-default-black" color="#dee2e6" /></span>);
                        else if (order === "asc") return (<span>&nbsp;&nbsp;<CaretUpFill size={10} className="button-default-black" color="#dee2e6" /><CaretDownFill size={10} className="button-default-black" color="#000" /></span>);
                        return null;
                    }
                },
                {
                    dataField: 'importePesosMexicanos', //total
                    text: 'Total',
                    formatter: (cellContent, row) => {
                        return (
                            <NumberFormat
                                displayType={'text'}
                                thousandSeparator={true}
                                value={row.importePesosMexicanos.toFixed(2/*row.monedaDecimales*/)}
                            ></NumberFormat>
                        )
                    },
                    sort: true,
                    sortCaret: (order, column) => {
                        if (!order) return (<span>&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000" /><CaretUpFill size={10} className="button-default-black" color="#000" /></span>);
                        else if (order === "desc") return (<span>&nbsp;<CaretUpFill size={10} className="button-default-black" color="#000" /><CaretDownFill size={10} className="button-default-black" color="#dee2e6" /></span>);
                        else if (order === "asc") return (<span>&nbsp;<CaretUpFill size={10} className="button-default-black" color="#dee2e6" /><CaretDownFill size={10} className="button-default-black" color="#000" /></span>);
                        return null;
                    }
                },
                {
                    dataField: 'acciones',
                    isDummyField: true,
                    text: 'Acciones',
                    formatter: (cellContent, row) => {
                        return (
                            <ButtonGroup>
                                <Button id="consultarRequisicion" className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Consultar"
                                    onClick={() => viewRequisicion(row)}>
                                    <Eye size={24} className="button-default-black" color="#000" />
                                </Button>
                                <Button id="descargarRequisicion" className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Descargar"
                                    onClick={() => downloadReport(row)}
                                ><FileArrowDown size={24} className="button-default-black" color="#000" />
                                </Button>
                            </ButtonGroup>
                        )
                    }
                },
            ];
            const columns = [
                {
                    id: 'titlePasada',
                    dataField: 'pasada',
                    text: '',
                    isDummyField: true,
                    formatter: (cellContent, row) => {
                        return (
                            <div className="font-title-req-pasadas">
                                <div >{'Pagada'}</div>
                            </div>
                        )
                    },
                },
                {
                    dataField: 'empresa',
                    text: '',
                    formatter: (cellContent, row) => {
                        return (
                            <div className="d-flex justify-content-center req-tbody-tr-td-div ">
                                <div className="font-col-req">{'Empresa: '}</div>
                                <div>{row.empresa}</div>
                            </div>
                        )
                    },
                    sort: true
                },
                {
                    dataField: 'id',
                    text: 'ID',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    formatter: (cellContent, row) => {
                        return (
                            <div className="d-flex justify-content-center req-tbody-tr-td-div ">
                                <div className="id-req">{'ID: '}</div>
                                <div>{row.id}</div>
                            </div>
                        )
                    },
                    sort: true
                },
                {
                    dataField: 'concepto',
                    text: 'Concepto',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    formatter: (cellContent, row) => {
                        return (
                            <div className="d-flex justify-content-center req-tbody-tr-td-div ">
                                <div className="id-req">{'Concepto: '}</div>
                                <div>{row.concepto}</div>
                            </div>
                        )
                    },
                    sort: true
                },
                {
                    dataField: 'acciones',
                    isDummyField: true,
                    text: '',
                    formatter: (cellContent, row) => {
                        return (
                            <div className="d-flex justify-content-center req-tbody-tr-td-div">
                                <ButtonGroup>
                                    <Button id="consultarRequisicion" className="button-default-black" variant="light" size="sm"
                                        data-toggle="tooltip" data-placement="bottom" title="Consultar"
                                        onClick={() => viewRequisicion(row)}>
                                        <Eye size={24} className="button-default-black" color="#000" />
                                    </Button>
                                    <Button id="descargarRequisicion" className="button-default-black" variant="light" size="sm"
                                        data-toggle="tooltip" data-placement="bottom" title="Descargar"
                                        onClick={() => downloadReport(row)}
                                    ><FileArrowDown size={24} className="button-default-black" color="#000" />
                                    </Button>
                                </ButtonGroup>
                            </div>
                        )
                    }
                },
                {
                    dataField: 'solicitante',
                    text: 'Solicitante',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    formatter: (cellContent, row) => {
                        return (
                            <div className="req-tbody-tr-td-div">
                                <div className="font-col-req">{'Solicitante: '}</div>
                                <div>{row.solicitante}</div>
                            </div>
                        )
                    },
                    sort: true
                },
                {
                    dataField: 'proveedorRfc',
                    text: 'RFC proveedor',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    formatter: (cellContent, row) => {
                        return (
                            <div className="req-tbody-tr-td-div">
                                <div className="font-col-req">{'RFC Proveedor: '}</div>
                                <div>{row.proveedorRfc}</div>
                            </div>
                        )
                    },
                    sort: true
                },
                {
                    dataField: 'proveedor',
                    text: 'Proveedor',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    formatter: (cellContent, row) => {
                        return (
                            <div className="req-tbody-tr-td-div">
                                <div className="font-col-req">{'Proveedor: '}</div>
                                <div>{row.proveedor}</div>
                            </div>
                        )
                    },
                    sort: true
                },
                {
                    dataField: 'fechaRequeridaPago',
                    text: '',
                    formatter: (cellContent, row) => {
                        return (
                            <div className="req-tbody-tr-td-div">
                                <div className="font-col-req">{'Fecha requerida: '}</div>
                                <div>{Moment(row.fechaRequeridaPago).format('DD/MM/YYYY')}</div>
                            </div>
                        )
                    },
                    sort: true
                },
                {
                    dataField: 'importePesosMexicanos',//total
                    text: '',
                    formatter: (cellContent, row) => {
                        return (
                            <div className="req-tbody-tr-td-div">
                                <div className="font-col-req">{'Total: '}</div>
                                <NumberFormat
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    value={row.importePesosMexicanos.toFixed(2/*row.monedaDecimales*/)}
                                ></NumberFormat>
                            </div>
                        )
                    },
                    sort: true
                }
            ];

            function indication() {
                return listaPasadas.length ? "La búsqueda no coincide con ningún criterio" : "No hay registros que mostrar";
            }

            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size}
                </span>
            );

            const options = {
                custom: true,
                sizePerPage: gridCards ? 4 : 10,
                totalSize: listaPasadas.length,
                paginationTotalRenderer: customTotal
            }

            return <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div className="fieldsetReqPasadas">
                        <div className="d-flex ml-auto mb-2 flex-row-reverse">
                            {gridCards ?
                                <Button className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Listado"
                                    onClick={() => { setGridCards(false) }}
                                >
                                    <ListTask size={24} className="button-default-black" color="#000" />
                                </Button> :
                                <Button className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Cuadrícula"
                                    onClick={() => { setGridCards(true) }}
                                >
                                    <Grid3x2GapFill size={24} className="button-default-black" color="#000" />
                                </Button>
                            }
                        </div>
                        <div className="d-flex">
                            {gridCards ? '' :
                                <div className="d-flex flex-row">
                                    <Form.Label column>Mostrar</Form.Label>
                                    <Col>
                                        <Form.Control size="sm" as="select" onChange={(e) => handleSizePerPage(paginationProps, e.target.value)}>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value={listaPasadas.length}>Todos</option>
                                        </Form.Control>
                                    </Col>
                                </div>}
                            <div className="d-flex flex-row">
                                <Col>
                                    <PaginationTotalStandalone {...paginationProps} />
                                </Col>
                            </div>
                            <div className="d-flex ml-auto flex-row-reverse">
                                <Col>
                                    <PaginationListStandalone {...paginationProps} />
                                </Col>
                                <Form.Label column>Página</Form.Label>
                            </div>
                        </div>
                        {
                            gridCards ?
                                <div className="table-responsive table-sm">
                                    <BootstrapTable
                                        id="reqPasadas"
                                        keyField='id'
                                        data={listaPasadas}
                                        columns={columns}
                                        filter={filterFactory()}
                                        bordered={false}
                                        noDataIndication={indication}
                                        {...paginationTableProps}
                                    />
                                </div>
                                :
                                <div className="table-responsive table-sm text-center">
                                    <BootstrapTable
                                        keyField='id'
                                        data={listaPasadas}
                                        columns={columnsList}
                                        filter={filterFactory()}
                                        headerClasses="header-class-pasadas"
                                        bordered={false}
                                        striped
                                        hover
                                        condensed
                                        noDataIndication={indication}
                                        {...paginationTableProps}
                                    />
                                </div>
                        }
                    </div>
                )}
            </PaginationProvider >
        }
    }

    return (
        <section id="listaReqPasadas">
            <Card id="cardReqPasadas">
                <div>
                    {modalConsultarRequisicionShow ? <ModalConsultarRequisicion show={modalConsultarRequisicionShow} onHide={modalConsultarRequisicionClose} >{requisicion}</ModalConsultarRequisicion> : ''}
                </div>
                <Card.Body className="cardBodyReqPasadas">
                    {request ? "Cargando..." : mostrarListaPasadas()}
                </Card.Body>
            </Card>
        </section>
    )
}

export default RequisicionesPasadas;