import React, { useState, useEffect } from "react";
import axios from 'axios'
import { KRAKEN_URL, SERVICIO_ID } from '../../../constants'

import '../css/Configuracion.css'
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next';
import { Col, Form, Card, Button } from 'react-bootstrap';
import { Check, X, PencilSquare, Trash, PersonLinesFill, Toggle2On, Toggle2Off } from 'react-bootstrap-icons';
import ModalUsuariosDisponibles from './Usuarios/ModalUsuariosDisponibles';
import ModalEditarEmpresaUsuario from './Usuarios/ModalEditarEmpresaUsuario';
import ModalDeshabilitarEmpresaUsuario from './Usuarios/ModalDeshabilitarEmpresaUsuario';
import ModalEliminarServicioUsuario from './Usuarios/ModalEliminarServicioUsuario';
import ModalConsultarEmpresaUsuario from './Usuarios/ModalConsultarEmpresaUsuario';

function EmpresaUsuarios(props) {
    const empresa = props.location.empresa;
    const [request] = useState(false);
    const [error, setError] = useState(false);
    const [status, setStatus] = useState('');
    const [updateListado, setUpdateListado] = useState(false);
    const [listUsuarios, setListUsuarios] = useState([]);
    const [usrView, setUsrView] = useState('');
    const [usrEdit, setUsrEdit] = useState('');
    const [usrEstatus, setUsrEstatus] = useState('');
    const [usrDelete, setUsrDelete] = useState('');

    const [modalUsuariosDisponiblesShow, setModalUsuariosDisponiblesShow] = useState(false);
    const [modalUsuarioEditarShow, setModalUsuarioEditarShow] = useState(false);
    const [modalDeshabilitarUsuarioShow, setModalDeshabilitarUsuarioShow] = useState(false);
    const [modalUsuarioEliminarShow, setModalUsuarioEliminarShow] = useState(false);
    const [modalUsuarioConsultarShow, setModalUsuarioConsultarShow] = useState(false);

    let modalUsuariosDisponiblesClose = () => setModalUsuariosDisponiblesShow(false);
    let modalUsuarioEditarClose = () => setModalUsuarioEditarShow(false);
    let modalDeshabilitarUsuarioClose = () => setModalDeshabilitarUsuarioShow(false);
    let modalUsuarioEliminarClose = () => setModalUsuarioEliminarShow(false);
    let modalUsuarioConsultarClose = () => setModalUsuarioConsultarShow(false);
    let updateGetListado = () => { setUpdateListado(!!!updateListado) }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getUsuarios, [updateListado]);

    function getUsuarios() {
        axios.post(KRAKEN_URL + 'empresa/servicio/usuarios/list',
            {
                "empresaId": empresa.empresaId,
                "servicioId": SERVICIO_ID
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                setListUsuarios(res.data.list);
            } else {
                setError(true);
                setStatus(res.data.result.message);
            }
        }).catch((error) => {
            setError(true);
            setStatus("Error: No se han podido recuperar los usuarios de esta empresa.");
        })
    }

    let handleSizePerPage = ({ page, onSizePerPageChange }, newSizePerPage) => {
        onSizePerPageChange(newSizePerPage, page);
    }

    function viewUsuario(usuario) {
        setUsrView(usuario);
        setModalUsuarioConsultarShow(true);
    }

    function getUsuario(usuario) {
        setUsrEdit(usuario);
        setModalUsuarioEditarShow(true);
    }

    function updateEstatusUsr(usuario) {
        setUsrEstatus(usuario);
        setModalDeshabilitarUsuarioShow(true);
    }

    function confirmDelete(usuario) {
        setUsrDelete(usuario);
        setModalUsuarioEliminarShow(true);
    }

    let mostrarListaUsuarios = () => {
        if (error) {
            return (
                <div>{status}</div>
            );
        } else {
            const columns = [{
                dataField: 'username',
                text: 'Nombre usuario',
                filter: textFilter({
                    placeholder: 'Buscar...',
                    className: "form-control-sm"
                }),
                sort: true
            },
            {
                dataField: 'email',
                text: 'Email',
                filter: textFilter({
                    placeholder: 'Buscar...',
                    className: "form-control-sm"
                }),
                sort: true
            },
            {
                dataField: 'userType',
                text: 'Tipo usuario',
                filter: textFilter({
                    placeholder: 'Buscar...',
                    className: "form-control-sm"
                }),
                sort: true
            },
            {
                dataField: 'telefono',
                text: 'Telefono',
                filter: textFilter({
                    placeholder: 'Buscar...',
                    className: "form-control-sm"
                }),
                sort: true
            },
            {
                dataField: 'estatus',
                isDummyField: true,
                text: 'Activo',
                formatter: (cellContent, row) => {
                    return row.estatus === "1"
                        ? <Check size={24} color="#7edf77" />
                        : <X size={24} color="#e62f40" />
                }
            },
            {
                dataField: 'acciones',
                text: 'Acciones',
                isDummyField: true,
                formatter: (cellContent, row) => {
                    return (
                        <div>
                            <Button id="consultar" className="button-default-black" variant="light" size="sm"
                                data-toggle="tooltip" data-placement="bottom" title="Consultar contactos"
                                onClick={() => viewUsuario(row)}>
                                <PersonLinesFill size={22} className="button-default-black" color="#000" />
                            </Button>
                            <Button id="editar" className="button-default-black" variant="light" size="sm"
                                data-toggle="tooltip" data-placement="bottom" title="Editar"
                                onClick={() => getUsuario(row)}
                            ><PencilSquare size={22} className="button-default-black" color="#000" />
                            </Button>
                            {
                                row.estatus === "0" ?
                                    <Button id="habilitar" className="button-default-black" variant="light" size="sm"
                                        data-toggle="tooltip" data-placement="bottom" title="Habilitar"
                                        onClick={() => updateEstatusUsr(row)}>
                                        <Toggle2Off size={22} className="button-default-black" color="#000"></Toggle2Off>
                                    </Button>
                                    : <Button id="deshabilitar" className="button-default-black" variant="light" size="sm"
                                        data-toggle="tooltip" data-placement="bottom" title="Deshabilitar"
                                        onClick={() => updateEstatusUsr(row)}>
                                        <Toggle2On size={22} className="button-default-black" color="#000"></Toggle2On>
                                    </Button>
                            }
                            <Button id="eliminar" className="button-default-black" variant="light" size="sm"
                                data-toggle="tooltip" data-placement="bottom" title="Eliminar"
                                onClick={() => confirmDelete(row)}
                            ><Trash size={22} className="button-default-black" color="#000" />
                            </Button>
                        </div>
                    )
                }
            }];

            function indication() {
                return listUsuarios.length ? "La búsqueda no coincide con ningún criterio" : "No hay registros que mostrar";
            }

            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size}
                </span>
            );

            const options = {
                custom: true,
                totalSize: listUsuarios.length,
                paginationTotalRenderer: customTotal
            }

            return <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div>
                        <Form.Row>
                            <div className="d-flex ml-auto mb-2 flex-row-reverse">
                                <button
                                    className="ml-1 button-default-req"
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="Agregar usuario al portal"
                                    onClick={() => { setModalUsuariosDisponiblesShow(true) }}
                                ><span>Usuarios disponibles </span>
                                </button>
                            </div>
                        </Form.Row>
                        <Form.Row className="p-2">
                            <div className="d-flex flex-row">
                                <Form.Label column>Mostrar</Form.Label>
                                <Col>
                                    <Form.Control size="sm" as="select" onChange={(e) => handleSizePerPage(paginationProps, e.target.value)}>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value={listUsuarios.length}>Todos</option>
                                    </Form.Control>
                                </Col>
                            </div>
                            <div className="d-flex flex-row">
                                <Col>
                                    <PaginationTotalStandalone {...paginationProps} />
                                </Col>
                            </div>
                            <div className="d-flex ml-auto flex-row-reverse">
                                <Col>
                                    <PaginationListStandalone {...paginationProps} />
                                </Col>
                                <Form.Label column>Página</Form.Label>
                            </div>
                        </Form.Row>
                        <div className="table-responsive table-sm">
                            <BootstrapTable
                                id="listaUsuarios"
                                keyField='userId'
                                data={listUsuarios}
                                columns={columns}
                                filter={filterFactory()}
                                bordered={false}
                                striped
                                hover
                                condensed
                                noDataIndication={indication}
                                {...paginationTableProps}
                            />
                        </div>
                    </div>
                )}
            </PaginationProvider>
        }
    }

    return (
        <section id="empresaUsuarios" className="text-center">
            <Card id="cardEmpresa">
                <div>
                    {modalUsuariosDisponiblesShow ? <ModalUsuariosDisponibles show={modalUsuariosDisponiblesShow} onHide={modalUsuariosDisponiblesClose} onUpdate={updateGetListado}>{empresa}</ModalUsuariosDisponibles> : ''}
                    {modalUsuarioConsultarShow ? <ModalConsultarEmpresaUsuario show={modalUsuarioConsultarShow} onHide={modalUsuarioConsultarClose} onUpdate={updateGetListado}>{usrView}</ModalConsultarEmpresaUsuario> : ''}
                    {modalUsuarioEditarShow ? <ModalEditarEmpresaUsuario show={modalUsuarioEditarShow} onHide={modalUsuarioEditarClose} onUpdate={updateGetListado}>{usrEdit}</ModalEditarEmpresaUsuario> : ''}
                    {modalDeshabilitarUsuarioShow ? <ModalDeshabilitarEmpresaUsuario show={modalDeshabilitarUsuarioShow} onHide={modalDeshabilitarUsuarioClose} onUpdate={updateGetListado}>{usrEstatus}</ModalDeshabilitarEmpresaUsuario> : ''}
                    {modalUsuarioEliminarShow ? <ModalEliminarServicioUsuario show={modalUsuarioEliminarShow} onHide={modalUsuarioEliminarClose} onUpdate={updateGetListado}>{usrDelete}</ModalEliminarServicioUsuario> : ''}
                </div>
                <Card.Body className="cardBodyEmpresa">
                    <legend>Usuarios permitidos de {empresa.alias}</legend>
                    {request ? "Cargando..." : mostrarListaUsuarios()}
                </Card.Body>
            </Card>
        </section>
    );
}
export default EmpresaUsuarios;