import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_PROVEEDORES_URL } from '../../constants'
import { useSelector } from 'react-redux';

//Estilos
import "../Pagos/css/Pagos.css"
import "../Pagos/css/PagosPendientes.css"
import { Eye, Wallet2, ListTask, Grid3x2GapFill, CaretDownFill, CaretUpFill, XCircle, PeopleFill } from 'react-bootstrap-icons';
//Elementos bootstrap
import { Card, Form, Col, ButtonGroup, Button, Tabs, Tab, Badge } from "react-bootstrap";

//Componentes
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import Moment from 'moment';
import NumberFormat from 'react-number-format';
import ModalConsultar from './ModalConsultar';
import ModalSubirPago from './ModalSubirPago';
import ModalCancelarAprobacion from "./ModalCancelarAprobacion";
import Loader from '../Loader';

function PagosPendientes() {
    const token = JSON.parse(localStorage.getItem('token'));
    const empresasDisponibles = useSelector(state => state.empresas.empresasDisponibles);
    let empresas = empresasDisponibles.map(e => { return e.empresa; });
    const empresaDefault = empresas[0];
    const [key, setKey] = useState(empresaDefault);
    const [listaPendientes, setListaPendientes] = useState([]);
    const [cargando, setCargando] = useState(true)
    const [error, setError] = useState(false);
    const [errorStatus, setErrorStatus] = useState('');
    const [updateListado, setUpdateListado] = useState(false);
    const [checkboxStates, setCheckboxStates] = useState([]);
    let updateGetListado = () => { setUpdateListado(!!!updateListado); setListaPendientes([]); }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getListadoPagosPendientes, [updateListado]);

    function getListadoPagosPendientes() {
        let user = JSON.parse(localStorage.getItem('token'));

        setCargando(true);
        axios.post(API_PROVEEDORES_URL + 'getPagosPendientes',
            {
                empresas: empresas
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                user.userType === 'SCHEMA_ADMIN'? filterDataToMaster(res.data.pagos) : 
                filterDataToUser(res.data.pagos);

                setListaPendientes(res.data.pagos);
            } else {
                setError(true);
                setErrorStatus(res.data.result.message);
            }
        }).catch((error) => {
            setError(true);
            setErrorStatus("Error: No se han podido recuperar los pagos pendientes.")
        }).finally(() => {
            setCargando(false);
        });
    }

    if (cargando) {
        return <Loader />
    }

    function filterDataToUser(empresas){ 
        empresas.map((empresa)=>{
         empresa.lista = empresa.lista.filter(function(objeto) {
            return ((objeto.solicitante ===token.username)||objeto.usuarioCompartido ===token.username||objeto.pagadorId ===token.userId );
         });
         })
         return empresas;
     }
 
     function filterDataToMaster(empresas){
         empresas.map((empresa)=>{
        
             empresa.lista = empresa.lista.filter(function(objeto) {
                 
                 return (objeto.usuarioCompartido !==token.username );
             });
             })
             return empresas;
 
     }

    function createTabs() {
        let empresasTabs;
        console.log(listaPendientes)
        listaPendientes.length ?
            empresasTabs = listaPendientes.map(empresa => (
                <Tab key={empresa.id} eventKey={empresa.id} title={empresa.nombreEmpresa}>
                    <ListadoPagosPendientes onUpdate={updateGetListado}>{empresa.lista}</ListadoPagosPendientes>
                </Tab>
            )) : empresasTabs = empresas.map(empresa => (
                <Tab key={empresa} eventKey={empresa} title={empresa}>
                    <section id="listaPagosPendientes">
                        <Card id="cardPagosPendientes">
                            <Card.Body className="cardBodyPagosPendientes">
                                {error ? errorStatus : 'Error: No se ha podido cargar la información'}
                            </Card.Body>
                        </Card>
                    </section>
                </Tab>
            ))
        return empresasTabs;
    }

    return (
        <div >
            <Tabs id="controlled-tab-pagos" activeKey={key} onSelect={(k) => setKey(k)}>
                {createTabs()}
            </Tabs>
        </div >
    );
}

function ListadoPagosPendientes(props) {
    const token = JSON.parse(localStorage.getItem('token'));
    console.log(props.children)
    const userType = token.userType;
    const [gridCards, setGridCards] = useState(false);
    const [listaPendientes] = useState(eliminarObjetosCompartidos(props.children));
    const [request] = useState(false);
    const [error] = useState(false);
    const [modalConsultarShow, setModalConsultarShow] = useState(false);
    const [modalPagarShow, setModalPagarShow] = useState(false);
    const [modalCancelarShow, setModalCancelarShow] = useState(false);
    const [pagoConsulta, setPagoConsulta] = useState('');
    const [pagoSubir, setPagoSubir] = useState('');
    const [pagoCancelar, setPagoCancelar] = useState('');
    const [checkboxStates, setCheckboxStates] = useState([]);
    const [pagoSeleccioados, setPagoSeleccioados] = useState([]);
    const[cancelacionMasivaButton, setCancelacionMasivaButton] = useState(true)
    let modalConsultarClose = () => { setModalConsultarShow(false); setPagoConsulta('') }
    let modalPagarClose = () => { setModalPagarShow(false); setPagoSubir('') }
    let modalCancelarClose = () => { setModalCancelarShow(false); setPagoCancelar('') }
    let updateGetListado = () => { props.onUpdate(); }

    let handleSizePerPage = ({ page, onSizePerPageChange }, newSizePerPage) => {
        onSizePerPageChange(newSizePerPage, page);
    }

    function eliminarObjetosCompartidos(arreglo) {
        arreglo = arreglo.filter(function(objeto) {
        
            return !(objeto.esCompartida === 1 && (objeto.usuarioCompartido !== token.username && objeto.usuarioCompartido !== ""));
        });
        return arreglo;
    }

    useEffect(() => {
        if(checkboxStates.length>0){
            setCancelacionMasivaButton(false)
        }
        else{
            setCancelacionMasivaButton(true)
        }
      }, [checkboxStates]);
    function getPago(pago) {
        setPagoConsulta(pago);
        setModalConsultarShow(true);
    }

    function getSubirPago(pago) {
        setPagoSubir(pago);
        setModalPagarShow(true);
    }
    function cancelarMasivamente(){
        
        setPagoCancelar(pagoSeleccioados)
        setModalCancelarShow(true)
    }
    function getCancelarAprobacion(pago){
        setPagoCancelar(pago);
        setModalCancelarShow(true);
    }

    let mostrarListaPendientes = () => {
        if (error) {
            return (
                <div>Error: No se han podido recuperar las requisiciones pendientes</div>
            );
        } else {
            const columnsList = [
                {
                    dataField: 'id',
                    text: 'ID',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true,
                    footer: ''
                },
                {
                    dataField: 'concepto',
                    text: 'Concepto',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true,
                    footer: ''
                },
                {
                    dataField: 'solicitante',
                    text: 'Solicitante',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true,
                    footer: ''
                },
                {
                    dataField: 'proveedorRfc',
                    text: 'RFC Proveedor',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true,
                    footer: ''
                },
                {
                    dataField: 'proveedor',
                    text: 'Proveedor',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true,
                    footer: ''
                },
                {
                    dataField: 'fechaRequeridaPago',
                    text: 'Fecha requerida',
                    formatter: (cellContent, row) => {
                        return (
                            Moment(row.fechaRequeridaPago).format('DD/MM/YYYY')
                        )
                    },
                    sort: true,
                    footer: 'Totales:',
                    sortCaret: (order, column) => {
                        if (!order) return (<span>&nbsp;&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000" /><CaretUpFill size={10} className="button-default-black" color="#000" /></span>);
                        else if (order === "desc") return (<span>&nbsp;&nbsp;<CaretUpFill size={10} className="button-default-black" color="#000" /><CaretDownFill size={10} className="button-default-black" color="#dee2e6" /></span>);
                        else if (order === "asc") return (<span>&nbsp;&nbsp;<CaretUpFill size={10} className="button-default-black" color="#dee2e6" /><CaretDownFill size={10} className="button-default-black" color="#000" /></span>);
                        return null;
                    },
                },
                {
                    dataField: 'saldoPendiente',
                    text: 'Saldo pendiente',
                    formatter: (cellContent, row) => {
                        return (
                            <NumberFormat
                                displayType={'text'}
                                thousandSeparator={true}
                                value={row.saldoPendiente.toFixed(2/*row.monedaDecimalesSat*/)}
                            ></NumberFormat>
                        )
                    },
                    sort: true,
                    footer: columnData => {
                        let sumatoria = columnData.reduce((acc, item) => acc + item, 0).toFixed(2)
                        return (<h5><Badge variant="success">
                            <NumberFormat displayType={'text'} thousandSeparator={true} value={sumatoria} />
                        </Badge></h5>)
                    },
                    sortCaret: (order, column) => {
                        if (!order) return (<span>&nbsp;&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000" /><CaretUpFill size={10} className="button-default-black" color="#000" /></span>);
                        else if (order === "desc") return (<span>&nbsp;&nbsp;<CaretUpFill size={10} className="button-default-black" color="#000" /><CaretDownFill size={10} className="button-default-black" color="#dee2e6" /></span>);
                        else if (order === "asc") return (<span>&nbsp;&nbsp;<CaretUpFill size={10} className="button-default-black" color="#dee2e6" /><CaretDownFill size={10} className="button-default-black" color="#000" /></span>);
                        return null;
                    },
                },
                {
                    dataField: 'diasVencimiento',
                    text: 'Días de vencimiento',
                    sort: true,
                    footer: '',
                    sortCaret: (order, column) => {
                        if (!order) return (<span>&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000" /><CaretUpFill size={10} className="button-default-black" color="#000" /></span>);
                        else if (order === "desc") return (<span>&nbsp;<CaretUpFill size={10} className="button-default-black" color="#000" /><CaretDownFill size={10} className="button-default-black" color="#dee2e6" /></span>);
                        else if (order === "asc") return (<span>&nbsp;<CaretUpFill size={10} className="button-default-black" color="#dee2e6" /><CaretDownFill size={10} className="button-default-black" color="#000" /></span>);
                        return null;
                    },
                    formatter: (cellContent, row) => {
                        return (
                            row.diasVencimiento >= 10 ?
                                <h6><Badge variant="success">{row.diasVencimiento}</Badge></h6>
                                :
                                row.diasVencimiento <= 9 && row.diasVencimiento >= 4 ?
                                    <h6><Badge variant="warning">{row.diasVencimiento}</Badge></h6>
                                    :
                                    row.diasVencimiento <= 3 && row.diasVencimiento >= 1 ?
                                        <h6><Badge className="vencimientoNaranja">{row.diasVencimiento}</Badge></h6>
                                        :
                                        row.diasVencimiento <= 0 && row.diasVencimiento >= -5 ?
                                            <h6><Badge variant="danger">{row.diasVencimiento}</Badge></h6>
                                            :
                                            row.diasVencimiento <= -6 ?
                                                <h6><Badge className="vencimientoMorado">{row.diasVencimiento}</Badge></h6>
                                                :
                                                row.diasVencimiento
                        )
                    }
                },
                {
                    dataField: 'importePesosMexicanos',//total
                    text: 'Total',
                    formatter: (cellContent, row) => {
                        return (
                            <NumberFormat
                                displayType={'text'}
                                thousandSeparator={true}
                                value={row.importePesosMexicanos.toFixed(2/*row.monedaDecimales*/)}
                            ></NumberFormat>
                        )
                    },
                    sort: true,
                    footer: columnData => {
                        let sumatoria = columnData.reduce((acc, item) => acc + item, 0).toFixed(2)
                        return (<h5><Badge variant="success">
                            <NumberFormat displayType={'text'} thousandSeparator={true} value={sumatoria} />
                        </Badge></h5>)
                    },
                    sortCaret: (order, column) => {
                        if (!order) return (<span>&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000" /><CaretUpFill size={10} className="button-default-black" color="#000" /></span>);
                        else if (order === "desc") return (<span>&nbsp;<CaretUpFill size={10} className="button-default-black" color="#000" /><CaretDownFill size={10} className="button-default-black" color="#dee2e6" /></span>);
                        else if (order === "asc") return (<span>&nbsp;<CaretUpFill size={10} className="button-default-black" color="#dee2e6" /><CaretDownFill size={10} className="button-default-black" color="#000" /></span>);
                        return null;
                    }
                },
                {
                    dataField: 'porcentajeTotal',
                    text: '% Requerido',
                    formatter: (cellContent, row) => {
                        return (
                            <NumberFormat
                                displayType={'text'} prefix={'%'}
                                thousandSeparator={true}
                                value={row.porcentajeTotal.toFixed(row.monedaDecimales)}
                            ></NumberFormat>
                        )
                    },
                    sort: true,
                    footer: '',
                    sortCaret: (order, column) => {
                        if (!order) return (<span>&nbsp;&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000" /><CaretUpFill size={10} className="button-default-black" color="#000" /></span>);
                        else if (order === "desc") return (<span>&nbsp;&nbsp;<CaretUpFill size={10} className="button-default-black" color="#000" /><CaretDownFill size={10} className="button-default-black" color="#dee2e6" /></span>);
                        else if (order === "asc") return (<span>&nbsp;&nbsp;<CaretUpFill size={10} className="button-default-black" color="#dee2e6" /><CaretDownFill size={10} className="button-default-black" color="#000" /></span>);
                        return null;
                    }
                },
                {
                    dataField: 'totalRequerido',
                    text: 'Total requerido',
                    formatter: (cellContent, row) => {
                        return (
                            <NumberFormat
                                displayType={'text'}
                                thousandSeparator={true}
                                value={row.totalRequerido.toFixed(2/*row.monedaDecimales*/)}
                            ></NumberFormat>
                        )
                    },
                    sort: true,
                    footer: columnData => {
                        let sumatoria = columnData.reduce((acc, item) => acc + item, 0).toFixed(2)
                        return (<h5><Badge variant="success">
                            <NumberFormat displayType={'text'} thousandSeparator={true} value={sumatoria} />
                        </Badge></h5>)
                    },
                    sortCaret: (order, column) => {
                        if (!order) return (<span>&nbsp;&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000" /><CaretUpFill size={10} className="button-default-black" color="#000" /></span>);
                        else if (order === "desc") return (<span>&nbsp;&nbsp;<CaretUpFill size={10} className="button-default-black" color="#000" /><CaretDownFill size={10} className="button-default-black" color="#dee2e6" /></span>);
                        else if (order === "asc") return (<span>&nbsp;&nbsp;<CaretUpFill size={10} className="button-default-black" color="#dee2e6" /><CaretDownFill size={10} className="button-default-black" color="#000" /></span>);
                        return null;
                    }
                },
                {
                    dataField: 'acciones',
                    isDummyField: true,
                    text: 'Acciones',
                    formatter: (cellContent, row) => {
                        return (userType === 'SCHEMA_PAGADOR' || userType === 'SCHEMA_ADMIN' || userType === 'ADMINISTRADOR' ?
                            <div>
                                <Button className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Consultar"
                                    onClick={() => getPago(row)}>
                                    <Eye size={22} color="#000" className="button-default-black" />
                                </Button>
                                {row.esCompartida!==1?(
                                    <Button className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Subir Pago"
                                    onClick={() => getSubirPago(row)}>
                                    <Wallet2 size={22} color="#000" className="button-default-black-transparent"></Wallet2>
                                </Button>
                                ):""}
                                
                                {row.esCompartida!==1?(
                                    <Button className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Cancelar Aprobación"
                                    onClick={() => getCancelarAprobacion(row)}>
                                    <XCircle size={22} color="#000" className="button-default-black-transparent" />
                                </Button>
                                ):""}
                                  {row.compartidos!=="[]" ? (<Button  className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Compartida"
                                   
                                ><PeopleFill size={22} className="button-default-black" color="#000" />
                                </Button>):""} 
                            </div>
                            :
                            <div >
                                <Button className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Consultar"
                                    onClick={() => getPago(row)}>
                                    <Eye size={22} color="#000" className="button-default-black" />
                                </Button>
                                {row.compartidos!=="[]" ? (<Button  className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Compartida"
                                   
                                ><PeopleFill size={22} className="button-default-black" color="#000" />
                                </Button>):""} 
                            </div>
                        )
                    }
                }
            ];
            const selectRow = {
                mode: 'checkbox',
                clickToSelect: true,
                style: { backgroundColor: '#6ee3af' },
                selected: checkboxStates,
                onSelect: (row, isSelect, rowIndex, e) => {
                    if (isSelect) {
                        
                      
                        setCheckboxStates([...checkboxStates, row.id]);
                        setPagoSeleccioados([...pagoSeleccioados, row]);
                      
                    } else {
                        
                       
                        let eliminarIdReqSeleccionada = checkboxStates.filter(x => x !== row.id);
                        setCheckboxStates(eliminarIdReqSeleccionada);
                        let eliminarReqSeleccionada = pagoSeleccioados.filter(x => x !== row);
                        setPagoSeleccioados(eliminarReqSeleccionada);
                        
                   
                    }
                },
                onSelectAll: (isSelect, rows, e) => {
                    const ids = listaPendientes.map(row => row.id);
                    const reqs = listaPendientes.map(row => row);
                    if (isSelect) {
                       
                            setCancelacionMasivaButton(false)
                        
                       
                        setCheckboxStates(ids);
                        setPagoSeleccioados(reqs);
                    } else {
                       
                        
                            setCancelacionMasivaButton(true)
                        
                        setCheckboxStates([]);
                        setPagoSeleccioados([]);
                    }
                },
            };

            const columns = [
                {
                    id: 'titlePendiente',
                    dataField: 'pendiente',
                    text: '',
                    isDummyField: true,
                    formatter: (cellContent, row) => {
                        return (
                            <div className="font-title-pagos-pendientes">
                                <div >{'Pendiente'}</div>
                            </div>
                        )
                    },
                },
                {
                    dataField: 'id',
                    text: 'ID',
                    isDummyField: true,
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                },
                {
                    dataField: 'empresa',
                    text: 'Empresa',
                    isDummyField: true,
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                }, {
                    dataField: 'concepto',
                    text: 'Concepto',
                    isDummyField: true,
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                }, {
                    dataField: 'proveedorRfc',
                    text: 'RFC Proveedor',
                    isDummyField: true,
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                }, {
                    dataField: 'proveedor',
                    text: 'Proveedor',
                    isDummyField: true,
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                },
                {
                    dataField: 'rowOne',
                    text: '',
                    isDummyField: true,
                    formatter: (cellContent, row) => {
                        return (
                            <div className="d-flex justify-content-around pago-tbody-tr-td-div">
                                <div>
                                    <div className="pago-tbody-tr-td-div">
                                        <div className="font-col-pago">{'ID: '}</div>
                                        <div>{row.id}</div>
                                    </div>
                                    <div className="pago-tbody-tr-td-div">
                                        <div className="font-col-pago">{'Empresa: '}</div>
                                        <div>{row.empresa}</div>
                                    </div>
                                </div>
                                <div>
                                    <div className="pago-tbody-tr-td-div">
                                        <div className="font-col-pago">{'Fecha requerida: '}</div>
                                        <div>{Moment(row.fechaRequeridaPago).format('DD/MM/YYYY')}</div>
                                    </div>
                                    <div className="pago-tbody-tr-td-div">
                                        <div className="font-col-pago">{'Saldo pendiente: '}</div>
                                        <NumberFormat
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            value={row.saldoPendiente.toFixed(2/*row.monedaDecimalesSat*/)}
                                        ></NumberFormat>
                                    </div>
                                </div>
                               
                            </div>
                        )
                    }
                },
                {
                    dataField: 'rowTwo',
                    text: '',
                    isDummyField: true,
                    formatter: (cellContent, row) => {
                        return (
                            <div className="pago-tbody-tr-td-div font-rowTwo-pagos-pendientes">
                                <div>
                                    <div className="pago-tbody-tr-td-div">
                                        <div className="font-col-pago">{'Concepto: '}</div>
                                        <div>{row.concepto}</div>
                                    </div>
                                    <div className="pago-tbody-tr-td-div">
                                        <div className="font-col-pago">{'Solicitante: '}</div>
                                        <div>{row.solicitante}</div>
                                    </div>
                                    <div className="pago-tbody-tr-td-div">
                                        <div className="font-col-pago">{'Proveedor: '}</div>
                                        <div>{row.proveedor}</div>
                                    </div>
                                    <div className="pago-tbody-tr-td-div">
                                        <div className="font-col-pago">{'RFC Proveedor: '}</div>
                                        <div>{row.proveedorRfc}</div>
                                    </div>
                                </div>
                                <div >
                                    <div className="pago-tbody-tr-td-div">
                                        <div className="font-col-pago">{'Días de vencimiento: '}</div>
                                        <div>
                                            {row.diasVencimiento >= 10 ?
                                                <h6><Badge variant="success">{row.diasVencimiento}</Badge></h6>
                                                :
                                                row.diasVencimiento <= 9 && row.diasVencimiento >= 4 ?
                                                    <h6><Badge variant="warning">{row.diasVencimiento}</Badge></h6>
                                                    :
                                                    row.diasVencimiento <= 3 && row.diasVencimiento >= 1 ?
                                                        <h6><Badge className="vencimientoNaranja">{row.diasVencimiento}</Badge></h6>
                                                        :
                                                        row.diasVencimiento <= 0 && row.diasVencimiento >= -5 ?
                                                            <h6><Badge variant="danger">{row.diasVencimiento}</Badge></h6>
                                                            :
                                                            row.diasVencimiento <= -6 ?
                                                                <h6><Badge className="vencimientoMorado">{row.diasVencimiento}</Badge></h6>
                                                                :
                                                                row.diasVencimiento}
                                        </div>
                                    </div>
                                    <div className="pago-tbody-tr-td-div">
                                        <div className="font-col-pago">{'Total: '}</div>
                                        <NumberFormat
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            value={row.importePesosMexicanos.toFixed(2/*row.monedaDecimalesSat*/)}
                                        ></NumberFormat>
                                    </div>
                                    <div className="pago-tbody-tr-td-div">
                                        <div className="font-col-pago">{'% Requerido: '}</div>
                                        <NumberFormat
                                            displayType={'text'} prefix={'%'}
                                            thousandSeparator={true}
                                            value={row.porcentajeTotal.toFixed(row.monedaDecimalesSat)}
                                        ></NumberFormat>
                                    </div>
                                    <div className="pago-tbody-tr-td-div">
                                        <div className="font-col-pago">{'Total requerido: '}</div>
                                        <NumberFormat
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            value={row.totalRequerido.toFixed(2/*row.monedaDecimalesSat*/)}
                                        ></NumberFormat>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                },
                {
                    dataField: 'acciones',
                    isDummyField: true,
                    text: '',
                    formatter: (cellContent, row) => {
                        return (
                            <div className="font-footer-pagos-pendientes">
                                {
                                    userType === 'SCHEMA_PAGADOR' || userType === 'SCHEMA_ADMIN' || userType === 'ADMINISTRADOR' ?
                                        <ButtonGroup>
                                            <button className="button-default-black-transparent" title="Consultar" onClick={() => getPago(row)}>
                                                <Eye size={22} color="#000" className="button-default-black-transparent" />
                                            </button>
                                            {row.esCompartida!==1 ? (<button className="button-default-black-transparent" title="Subir Pago" onClick={() => getSubirPago(row)}>
                                                <Wallet2 size={22} color="#000" className="button-default-black-transparent"></Wallet2>
                                            </button>):""}
                                            {row.esCompartida!==1 ? (<button className="button-default-black" title="Cancelar Aprobación" onClick={() => getCancelarAprobacion(row)}>
                                                <XCircle size={22} color="#000" className="button-default-black-transparent" />
                                            </button>):""}
                                            
                                        </ButtonGroup>
                                        :
                                        <ButtonGroup >
                                            <button className="button-default-black-transparent" title="Consultar" onClick={() => getPago(row)}>
                                                <Eye size={22} color="#000" className="button-default-black-transparent" />
                                            </button>
                                        </ButtonGroup>
                                }
                                {row.compartidos!=="[]" ? (<Button  className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Compartida"
                                   
                                ><PeopleFill size={22} className="button-default-black" color="#000" />
                                </Button>):""} 
                            </div>
                        )
                    }
                }
            ];

            function indication() {
                return listaPendientes.length ? "La búsqueda no coincide con ningún criterio" : "No hay registros que mostrar";
            }

            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size}
                </span>
            );

            const options = {
                custom: true,
                sizePerPage: gridCards ? 4 : 10,
                totalSize: listaPendientes.length,
                paginationTotalRenderer: customTotal
            }

            return <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div>
                        <div className="d-flex ml-auto mb-2 flex-row-reverse">
                            {gridCards ?
                                <Button className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Listado"
                                    onClick={() => { setGridCards(false) }}
                                >
                                    <ListTask size={24} className="button-default-black" color="#000" />
                                </Button> :
                                <Button className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Cuadrícula"
                                    onClick={() => { setGridCards(true) }}
                                >
                                    <Grid3x2GapFill size={24} className="button-default-black" color="#000" />
                                </Button>
                            }
                        </div>
            
                        <div className="d-flex">
                            {/*token.userType==="SCHEMA_ADMIN"||token.userType==="ADMINISTRADOR"?( <button
                            className={`m-1 ${cancelacionMasivaButton ? 'button-default-subir-archivo-disabled' : 'button-default-subir-archivo'}`}
                            mata-toggle="tooltip"
                            data-placement="bottom"
                            title="Confirmar"
                            disabled={cancelacionMasivaButton} 
                            onClick={cancelarMasivamente}
                                >Cancelar aprobaciones
                            </button>):""*/} 
                       
                            {gridCards ? '' :
                                <div className="d-flex flex-row">
                                    <Form.Label column>Mostrar</Form.Label>
                                    <Col>
                                        {/* <Form.Control size="sm" as="select" onChange={(e) => handleSizePerPage(paginationProps, e.target.value)}>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value={listaPendientes.length}>Todos</option>
                                        </Form.Control> */}
                                        <SizePerPageDropdownStandalone {...paginationProps} btnContextual />
                                    </Col>
                                </div>}
                            <div className="d-flex flex-row">
                                <Col>
                                    <PaginationTotalStandalone {...paginationProps} />
                                </Col>
                            </div>
                            <div className="d-flex ml-auto flex-row-reverse">
                                <Col>
                                    <PaginationListStandalone {...paginationProps} />
                                </Col>
                                <Form.Label column>Página</Form.Label>
                            </div>
                        </div>
                        {
                            gridCards ?
                                <div className="table-responsive table-sm">
                                {
                                  token.userType==="SCHEMA_ADMIN"||token.userType==="ADMINISTRADOR"?(
                                    <BootstrapTable
                                      id="pagosPendientes"
                                      keyField='id'
                                      data={listaPendientes}
                                      columns={columns}
                                      filter={filterFactory()}
                                      bordered={false}
                                      selectRow={selectRow}
                                      noDataIndication={indication}
                                      {...paginationTableProps}
                                    />):
                                    <BootstrapTable
                                      id="pagosPendientes"
                                      keyField='id'
                                      data={listaPendientes}
                                      columns={columns}
                                      filter={filterFactory()}
                                      bordered={false}
                                      noDataIndication={indication}
                                      {...paginationTableProps}
                                    />
                                    } 
                                </div>
                                :
                                <div className="table-responsive table-sm text-center">
                                    {
                                  token.userType==="SCHEMA_ADMIN"||token.userType==="ADMINISTRADOR"?(
                                    <BootstrapTable
                                      keyField='id'
                                      data={listaPendientes}
                                      columns={columnsList}
                                      filter={filterFactory()}
                                      headerClasses="header-class-pago-pendientes"
                                      bordered={false}
                                      striped
                                      selectRow={selectRow}
                                      hover
                                      condensed
                                      noDataIndication={indication}
                                      {...paginationTableProps}
                                    />):
                                    <BootstrapTable
                                      keyField='id'
                                      data={listaPendientes}
                                      columns={columnsList}
                                      filter={filterFactory()}
                                      headerClasses="header-class-pago-pendientes"
                                      bordered={false}
                                      striped
                                      hover
                                      condensed
                                      noDataIndication={indication}
                                      {...paginationTableProps}
                                    />
                                    }
                                </div>
                        }
                    </div>
                )}
            </PaginationProvider >
        }
    }

    return (
        <section id="listaPagosPendientes">
            <Card id="cardPagosPendientes">
                <div>
                    {modalConsultarShow ? <ModalConsultar show={modalConsultarShow} onHide={modalConsultarClose} onUpdate={updateGetListado}>{pagoConsulta}</ModalConsultar> : ''}
                    {modalPagarShow ? <ModalSubirPago show={modalPagarShow} onHide={modalPagarClose} onUpdate={updateGetListado} >{pagoSubir}</ModalSubirPago> : ''}
                    {modalCancelarShow ? <ModalCancelarAprobacion show={modalCancelarShow} onHide={modalCancelarClose} onUpdate={updateGetListado}>{pagoCancelar}</ModalCancelarAprobacion> : ''}
                </div>
                <Card.Body className="cardBodyPagosPendientes">
                    {request ? "Cargando..." : mostrarListaPendientes()}
                </Card.Body>
            </Card>
        </section>
    );
}

export default PagosPendientes;