import React, { useState, useEffect } from "react";
import axios from 'axios';
import { KRAKEN_URL, SERVICIO_ID } from '../../../../constants'

import '../../css/Configuracion.css'
import { toast } from 'react-toastify';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next';
import { Trash } from 'react-bootstrap-icons';
import { Modal, Form, Col, Button } from "react-bootstrap";
import ModalAgregarEmpresaUsuario from './ModalAgregarEmpresaUsuario';
import ModalEliminarEmpresaUsuario from './ModalEliminarEmpresaUsuario';

function ModalUsuariosDisponibles(props) {
    const [request] = useState(false);
    const [status, setStatus] = useState(false);
    const [error, setError] = useState(false);
    const [messageError, setMessageError] = useState('');
    const [listEmpUsuarios, setListEmpUsuarios] = useState([]);
    const empresa = props.children;
    const [userId, setUserId] = useState('');
    const [empresaId, setEmpresaId] = useState(empresa.empresaId);
    const [empresaAlias, setEmpresaAlias] = useState(empresa.alias);
    const [servicioId, setServicioId] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [userType, setUserType] = useState('');
    const [telefono, setTelefono] = useState('');
    const [updateListado, setUpdateListado] = useState(false);
    const [usrDelete, setUsrDelete] = useState('');

    const [modalAgregarEmpresaUsuarioShow, setModalAgregarEmpresaUsuarioShow] = useState(false);
    const [modalUsuarioEliminarShow, setModalUsuarioEliminarShow] = useState(false);

    let modalAgregarEmpresaUsuarioClose = () => setModalAgregarEmpresaUsuarioShow(false);
    let modalUsuarioEliminarClose = () => setModalUsuarioEliminarShow(false);
    let updateGetListado = () => { setUpdateListado(!!!updateListado) }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getEmpUsuarios, [updateListado]);

    function getEmpUsuarios() {
        axios.post(KRAKEN_URL + 'empresa/usuarios/list',
            {
                "empresaId": empresaId
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                setListEmpUsuarios(res.data.list);
            } else {
                setListEmpUsuarios('');
                setError(true);
                setMessageError(res.data.result.message);
            }
        }).catch((error) => {
            setError(true);
            setListEmpUsuarios('');
            setMessageError("Error: No se han podido recuperar los usuarios de esta empresa.");
        })
    }

    function agregarUsuario() {
        setStatus(true);
        axios.post(KRAKEN_URL + 'empresa/servicio/usuarios/addUser',
            {
                "userId": userId,
                "empresaId": empresaId,
                "empresaAlias": empresaAlias,
                "servicioId": servicioId,
                "username": username,
                "email": email,
                "userType": userType,
                "telefono": telefono
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                setStatus(false);
                toast.success(res.data.result.message,{
                    position:"top-center",
                    autoClose: 4500, 
                });
                props.onUpdate();//Actualiza el listado, llamando a la función padre para actualizarlo  
                props.onHide();//Cierra el modal, llamando a la función padre para cerrarlo
            } else {
                setStatus(false);
                toast.warning(res.data.result.message,{
                    position:"top-center",
                    autoClose: 4500, 
                });
            }
        }).catch((error) => {
            setStatus(false);
            toast.error("Hubo un error, no se pudo agregar el usuario a este servicio.",{
                position:"top-center",
                autoClose: 4500, 
            });
        })
    }

    let handleSizePerPage = ({ page, onSizePerPageChange }, newSizePerPage) => {
        onSizePerPageChange(newSizePerPage, page);
    }

    function confirmDelete(usuario) {
        setUsrDelete(usuario);
        setModalUsuarioEliminarShow(true);
    }

    let handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            setUserId(row.userId);
            setEmpresaId(empresaId);
            setEmpresaAlias(row.empresaAlias);
            setServicioId(SERVICIO_ID);
            setUsername(row.username);
            setEmail(row.email);
            setUserType(row.userType);
            setTelefono(row.telefono);
        } else {
            toast.warning("Debes seleccionar un usuario",{
                position:"top-center",
                autoClose: 4500, 
            });
        }
    }

    let mostrarListaUsuarios = () => {
        if (error) {
            return (
                <div>{messageError}</div>
            );
        } else {
            const columns = [{
                dataField: 'username',
                text: 'Nombre usuario',
                filter: textFilter({
                    placeholder: 'Buscar...',
                    className: "form-control-sm"
                }),
                sort: true
            },
            {
                dataField: 'email',
                text: 'Email',
                filter: textFilter({
                    placeholder: 'Buscar...',
                    className: "form-control-sm"
                }),
                sort: true
            },
            {
                dataField: 'userType',
                text: 'Tipo usuario',
                filter: textFilter({
                    placeholder: 'Buscar...',
                    className: "form-control-sm"
                }),
                sort: true
            },
            {/*
                dataField: 'estatus',
                isDummyField: true,
                text: 'Activo',
                formatter: (cellContent, row) => {
                    return row.estatus === "1"
                        ? <Check size={24} color="#7edf77" />
                        : <X size={24} color="#e62f40" />
                },
                sort: true
            */},
            {
                dataField: 'acciones',
                text: 'Acciones',
                isDummyField: true,
                formatter: (cellContent, row) => {
                    return (
                        <div>
                            <Button id="eliminar" className="button-default-black" variant="light" size="sm"
                                data-toggle="tooltip" data-placement="bottom" title="Eliminar"
                                onClick={() => confirmDelete(row)}
                            ><Trash size={22} className="button-default-black" color="#000" />
                            </Button>
                        </div>
                    )
                }
            }];

            const selectRow = {
                mode: 'radio',
                clickToSelect: true,
                style: { backgroundColor: 'rgb(155, 227, 230)' },
                selectionHeaderRenderer: () => 'X',
                onSelect: handleOnSelect
            }

            function indication() {
                return listEmpUsuarios.length ? "La búsqueda no coincide con ningún criterio" : "No hay registros que mostrar";
            }

            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size}
                </span>
            );

            const options = {
                custom: true,
                sizePerPage: 5,
                totalSize: listEmpUsuarios.length,
                paginationTotalRenderer: customTotal
            }

            return <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div>
                        <Form.Row>
                            <div className="d-flex ml-auto mb-2 flex-row-reverse">
                                <button
                                    className="ml-1 button-default-req"
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="Agregar usuario a este listado"
                                    onClick={() => { setModalAgregarEmpresaUsuarioShow(true) }}
                                ><span>Agregar usuario a {empresaAlias} </span>
                                </button>
                            </div>
                        </Form.Row>
                        <Form.Row className="p-2">
                            <div className="d-flex flex-row">
                                <Form.Label column>Mostrar</Form.Label>
                                <Col>
                                    <Form.Control size="sm" as="select" onChange={(e) => handleSizePerPage(paginationProps, e.target.value)}>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value={listEmpUsuarios}>Todos</option>
                                    </Form.Control>
                                </Col>
                            </div>
                            <div className="d-flex flex-row">
                                <Col>
                                    <PaginationTotalStandalone {...paginationProps} />
                                </Col>
                            </div>
                            <div className="d-flex ml-auto flex-row-reverse">
                                <Col>
                                    <PaginationListStandalone {...paginationProps} />
                                </Col>
                                <Form.Label column>Página</Form.Label>
                            </div>
                        </Form.Row>
                        <div className="table-responsive table-sm text-center">
                            <BootstrapTable
                                id="listaUsuarios"
                                keyField='userId'
                                data={listEmpUsuarios}
                                columns={columns}
                                selectRow={selectRow}
                                filter={filterFactory()}
                                bordered={false}
                                striped
                                hover
                                condensed
                                noDataIndication={indication}
                                {...paginationTableProps}
                            />
                        </div>
                    </div>
                )}
            </PaginationProvider>
        }
    }

    return (
        <section className="text-center">
            <Modal
                show={props.show}
                //onHide={props.onHide}
                size="xl"
                id="modalAgregarUsuario"
                centered
            >
                <Modal.Header className="modal-header-agregar-usuario" closeButton onHide={props.onHide}>
                    Usuarios disponibles de {empresaAlias} <br></br>
                    Seleccione un usuario para agregarlo al portal
                </Modal.Header>
                <Modal.Body>
                    {request ? "Cargando..." : mostrarListaUsuarios()}
                    <div className="d-flex justify-content-center">
                        <button
                            className="m-1 button-default-confirmar"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Confirmar"
                            onClick={agregarUsuario}
                        >{status ?
                            <span>Agregando...</span>
                            : <span>Agregar</span>}
                        </button>
                        <button
                            className="m-1 button-default-cancelar"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Cancelar"
                            onClick={props.onHide}
                        ><span>Cancelar</span>
                        </button>
                    </div>
                </Modal.Body>
                {modalAgregarEmpresaUsuarioShow ? <ModalAgregarEmpresaUsuario show={modalAgregarEmpresaUsuarioShow} onHide={modalAgregarEmpresaUsuarioClose} onUpdate={updateGetListado}>{empresa}</ModalAgregarEmpresaUsuario> : ''}
                {modalUsuarioEliminarShow ? <ModalEliminarEmpresaUsuario show={modalUsuarioEliminarShow} onHide={modalUsuarioEliminarClose} onUpdate={updateGetListado}>{usrDelete}</ModalEliminarEmpresaUsuario> : ''}
            </Modal>
        </section>
    );
}
export default ModalUsuariosDisponibles;