import React, { useState, useEffect } from "react";
import axios from 'axios'
import { API_PROVEEDORES_URL } from '../../constants'

//Elementos bootstrap
//import { Card } from "react-bootstrap";
//Estilos
import './Dashboard.css';

//Componentes
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import NumberFormat from 'react-number-format';
import Moment from 'moment';
import { Doughnut } from 'react-chartjs-2';


function DeudasAntiguas(props) {
    const [empresa] = useState(props.children);
    const [request] = useState(false);
    const [error, setError] = useState(false);
    const [errorStatus, setErrorStatus] = useState('');
    const [listDeudasAntiguas, setListDeudasAntiguas] = useState([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getDeudasAntiguas, []);

    function getDeudasAntiguas() {
        axios.post(API_PROVEEDORES_URL + 'getDeudaAntigua',
            {
                empresa: empresa
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                setListDeudasAntiguas(res.data.list);
            } else {
                setError(true);
                setErrorStatus(res.data.result.message);
            }
        }).catch((error) => {
            setError(true);
            setErrorStatus("Error: No se ha podido recuperar deuda antigua");
        });
    }

    function grafica() {
        const data = {
            datasets: [
                {
                    data: [listDeudasAntiguas[0].saldoPagado, listDeudasAntiguas[0].saldoPendiente],
                    backgroundColor: ['#F6F2F1', '#4FD290'],
                    hoverBackgroundColor: ['#EEEEEE', '#4CB47F']
                }
            ]
        };
        return data;
    }

    let mostrarDeudasAntiguas = () => {
        if (error) {
            return (
                <div>{errorStatus}</div>
            );
        } else {
            const columns = [
                {
                    dataField: 'rowOne',
                    text: '',
                    isDummyField: true,
                    formatter: (cellContent, row) => {
                        return (
                            <div className="d-flex justify-content-around deudas-viejas-tbody-tr-td-div">
                                <div className=" deudas-viejas">
                                    <div className="title-empresa-deuda">{row.empresa}</div>
                                </div>
                            </div>
                        )
                    }
                }, {
                    dataField: 'rowTwo',
                    text: '',
                    isDummyField: true,
                    formatter: (cellContent, row) => {
                        return (
                            <div className="d-flex justify-content-around prov-ventas-tbody-tr-td-div">
                                <div>
                                    <div className="deudas-viejas-tbody-tr-td-div">
                                        <div className="font-col-prov-ventas">{'Proveedor: '}</div>
                                    </div>
                                    <div className="deudas-viejas-tbody-tr-td-div">
                                        <div>{row.proveedor}</div>
                                    </div>
                                    <div className="deudas-viejas-tbody-tr-td-div">
                                        <div className="font-col-prov-ventas">{'Fecha requerida: '}</div>
                                    </div>
                                    <div className="deudas-viejas-tbody-tr-td-div">
                                        <div>{Moment(row.fechaRequeridaPago).format('DD/MM/YYYY')}</div>
                                    </div>
                                    <div className="deudas-viejas-tbody-tr-td-div">
                                        <div className="font-col-prov-ventas">{'Saldo liquidado: '}</div>
                                    </div>
                                    <div className="deudas-viejas-tbody-tr-td-div">
                                        <div>
                                            <NumberFormat
                                                displayType={'text'} prefix={'$'}
                                                thousandSeparator={true}
                                                value={row.saldoPagado.toFixed(2)}
                                            ></NumberFormat>
                                        </div>
                                    </div>
                                    <div className="prov-ventas-tbody-tr-td-div">
                                        <div className="font-col-prov-ventas">{'Saldo pendiente: '}</div>
                                    </div>
                                    <div className="prov-ventas-tbody-tr-td-div">
                                        <div>
                                            <NumberFormat
                                                displayType={'text'} prefix={'$'}
                                                thousandSeparator={true}
                                                value={row.saldoPendiente.toFixed(2)}
                                            ></NumberFormat>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col items-center w-full max-w-md">
                                    <p className="texto-grafica">Saldo pendiente</p>
                                    <Doughnut
                                        data={grafica()}
                                        height={40}
                                        width={40}
                                    />
                                </div>
                            </div>
                        )
                    }
                }
            ];

            function indication() {
                return listDeudasAntiguas.length ? "La búsqueda no coincide con ningún criterio" : "No hay registros que mostrar";
            }

            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size}
                </span>
            );

            const options = {
                custom: true,
                sizePerPage: 4,
                totalSize: listDeudasAntiguas.length,
                paginationTotalRenderer: customTotal
            }

            return <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div className="fieldsetDeudasAntiguas">
                        <div className="table-responsive table-sm">
                            <BootstrapTable
                                id="deudasAntiguas"
                                keyField='id'
                                data={listDeudasAntiguas}
                                columns={columns}
                                bordered={false}
                                noDataIndication={indication}
                                {...paginationTableProps}
                            />
                        </div>
                    </div>
                )}
            </PaginationProvider >
        }
    }

    return request ? "Cargando..." : mostrarDeudasAntiguas();
}
export default DeudasAntiguas;