import { Decimal } from 'decimal.js';

var constants = {
    get typeFunction() { return typeof function () { }; },
    get typeUndefined() { return typeof undefined; },
    get typeString() { return typeof ''; },
    get typeObject() { return typeof {}; },
    get typeBoolean() { return typeof true; }
};

let oIsString = function () { return isString(...arguments); };
let oIsUndefined = function () { return isUndefined(...arguments); };

function isString(element) {
    return typeof element === constants.typeString;
}

function isUndefined(element) {
    return typeof element === constants.typeUndefined;
}

const removeComas = function (n) {
    if (n.toString().includes(",")) {
        return oIsUndefined(n) ? '0' : n.toString().replace(/,/g, '');
    } else {
        return n;
    }
};

//Función que sirve para quitar comas de un valor y regresar el valor como un bigDecimal
const bigDecimalFormat = function (n) {
    Decimal.set({ rounding: 6 });//el método set de Decimal, sirve para especificar 
    if (n.toString().includes(",")) {
        if (oIsUndefined(n)) {
            let nDecimal = new Decimal('0');
            return nDecimal;
        } else {
            let nString = n.toString().replace(/,/g, '');
            let nDecimal = new Decimal(nString);
            return nDecimal;
        }
    } else {
        return oIsUndefined(n) ? new Decimal('0') : (n === '') ? new Decimal('0') : new Decimal(n);
    }
};

let notD = /\D/g;
function currencyFormat(toFormat, decimals = 2, minDecimals = 2, mod = true) {
    if (!oIsString(toFormat)) { toFormat = toFormat.toString(); }
    if (toFormat === '- - -' || toFormat === 'NA') return toFormat;
    let intPart, floatPart, fill = 0, sign = toFormat.indexOf("-") !== -1;
    if (isString(decimals)) { decimals = !isNaN(decimals) ? parseInt(decimals) : 2; }
    if (isString(minDecimals)) { minDecimals = !isNaN(minDecimals) ? parseInt(minDecimals) : 2; }
    if (minDecimals > decimals) { minDecimals = decimals; }
    [intPart, floatPart] = toFormat.indexOf(".") !== -1 ?
        toFormat.split(".", 2) :
        [toFormat, '']
        ;
    intPart = intPart.replace(notD, '');
    floatPart = floatPart.replace(notD, '').substring(0, decimals).replace(/[0]+$/, '');
    if (intPart) {
        if (intPart.length > 3) {
            let groups;
            let fix = intPart.length % 3;
            if (fix) {
                groups = intPart.substring(fix).match(/\d{3}/g);
                groups.unshift(intPart.substring(0, fix));
            } else {
                groups = intPart.match(/\d{3}/g);
            }
            intPart = groups.join();
        }
    } else {
        intPart = '0';
    }
    if (floatPart.length < decimals) {
        fill = mod ? Math.abs(minDecimals - floatPart.length) : decimals - floatPart.length;
    }
    return `${sign ? '-' : ''}${intPart}${floatPart || fill ? '.' : ''}${floatPart}${'0'.repeat(fill)}`;
}

export { currencyFormat, removeComas, bigDecimalFormat };