import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { BubbleChart, ExpandLess, ExpandMore } from '@material-ui/icons';
import { Typography, ListItemText, ListItem, ListItemIcon } from '@material-ui/core';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    root: { 
        display: 'flex' 
    },
    appBar: {
        backgroundColor: '#1CBBC1',
        borderRadius: '0 0 30px 30px',
        boxShadow: '0 4px 5px 0 rgba(0,0,0,0.83)',
        minHeight: '174px',
        justifyContent: 'center',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        margin: '12px',
        display: 'flex',
        alignItems: 'start',
        height: '26px',
        padding: '0px',
        marginTop: '0px',
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        '& .MuiDrawer-paper': {
            display: 'block'
        }
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
        marginTop: '12rem',
        backgroundColor: '#FFF',
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    active: {
        background: '#CCD1D1',
        color: '#3498DB'
    }
}));

const IconProfileTypography = withStyles((theme) => ({
    root: {
        height: '62px',
        width: '62px',
    },
}))(Typography)

const IconMenuTypography = withStyles((theme) => ({
    root: {
        height: '33px',
        width: '28px',
    },
}))(Typography)

const IconMenuSmallTypography = withStyles((theme) => ({
    root: {
        height: '33px',
        width: '34px',
    },
}))(Typography)

const useStylesMenu = makeStyles((theme) => ({
    root: {
        display: 'inherit',
        flexDirection: 'column',
        width: 240,
    },
    lists: {
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(1),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    submenu: {
        paddingTop: theme.spacing(2),
    }
}));

const breadcrumbNameMap = {
    '/requisiciones/pendientes': 'Autorizar', //Por autorizar
    '/requisiciones/aprobadas': 'Aprobadas',
    '/requisiciones/pasadas': 'Pagados',
    '/nuevaRequisicion': 'Nueva requisición',
    '/pagos/pendientes': 'Pendiente', //Por pagar
    '/pagos/pasados': 'Pasados',
    '/pagoMultiple': 'Múltiple', //Pago multiple
    '/autorizacionMultiple': 'Autorización masiva',//autorización múltiple
    '/proveedores/lista': 'Listado proveedores',
    '/proveedores/nuevo': 'Nuevo proveedor',
    '/cfdis/pendientes': 'Consultar',
    '/cfdis/pasados': 'Por cargar',
    '/soporte/solicitud': 'Solicitud',
    '/soporte/consulta': 'Consulta solicitudes',
    '/configuracion/cuentasEmisoras': 'Cuenta emisora',
    '/configuracion': 'Razones sociales',
    '/configuracion/cuentasNotificacion': 'Cuentas notificaciones',
    '/configuracion/cambiarContrasena': 'Cambiar contraseña'
};

function ListItemLink(props) {
    const { to, open, ...other } = props;
    const primary = breadcrumbNameMap[to];

    return (
        <li>
            <ListItem button component={RouterLink} to={to} {...other}>
                <ListItemText primary={primary} />
                {open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
            </ListItem>
        </li>
    );
}

function SecondaryItemLink(props) {
    const { to, open, ...other } = props;
    const primary = breadcrumbNameMap[to];

    return (
        <ListItem button component={RouterLink} to={to} {...other}>
            <ListItemIcon>
                <BubbleChart></BubbleChart>
            </ListItemIcon>
            <ListItemText primary={primary} />
            {open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
        </ListItem>
    );
}

function SetItem(props) {
    const { to, open, ...other } = props;
    const primary = breadcrumbNameMap[to];
    const location = useLocation();
    const classes = useStyles();

    return (
        <div className={location.pathname === Object.keys(breadcrumbNameMap)[props.idpath] ? classes.active : null}>
            <ListItem button component={RouterLink} to={to} {...other}>
                {props.iconname !== '' ?
                    <ListItemIcon>
                        <IconMenuTypography className={props.iconname} ></IconMenuTypography>
                    </ListItemIcon> : ''
                }
                <ListItemText primary={primary} />
                {open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
            </ListItem>
        </div>
    );
}

ListItemLink.propTypes = {
    open: PropTypes.bool,
    to: PropTypes.string.isRequired,
};

SecondaryItemLink.propTypes = {
    open: PropTypes.bool,
    to: PropTypes.string.isRequired,
};

export {
    useStyles, IconProfileTypography, IconMenuTypography, IconMenuSmallTypography,
    useStylesMenu, ListItemLink, SecondaryItemLink, SetItem
    //ListItemNuevaReq, ListItemAutorizarReq,
    // ListItemAutorizacionMasiva, ListItemReqAprobadas, ListItemPagoPendiente, ListItemPagoMultiple,
    // ListItemPagados, ListItemProveedoresLista, ListItemNuevoProveedor, ListItemCfdisPendientes,
    // ListItemCfdisPasados, ListItemNuevaSolicitud, ListItemConsultaSolicitudes,
    // ListItemCuentas, ListItemRazonesSociales, ListItemCuentasNotificacion, ListItemCambiarContrasena
}

// function ListItemNuevaReq(props) {
//     const { to, open, ...other } = props;
//     const primary = breadcrumbNameMap[to];
//     const location = useLocation();
//     const classes = useStyles();

//     return (
//         <div className={location.pathname == Object.keys(breadcrumbNameMap)[3] ? classes.active : null}>
//             <ListItem button component={RouterLink} to={to} {...other}>
//                 <ListItemIcon>
//                     <IconMenuTypography className="icon-nueva-req" ></IconMenuTypography>
//                 </ListItemIcon>
//                 <ListItemText primary={primary} />
//                 {open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
//             </ListItem>
//         </div>
//     );
// }

// function ListItemAutorizarReq(props) {
//     const { to, open, ...other } = props;
//     const primary = breadcrumbNameMap[to];
//     const location = useLocation();
//     const classes = useStyles();

//     return (
//         <div className={location.pathname == Object.keys(breadcrumbNameMap)[0] ? classes.active : null}>
//             <ListItem button component={RouterLink} to={to} {...other}>
//                 <ListItemIcon>
//                     <IconMenuTypography className="icon-autorizar-req"></IconMenuTypography>
//                 </ListItemIcon>
//                 <ListItemText primary={primary} />
//                 {open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
//             </ListItem>
//         </div>
//     );
// }

// function ListItemAutorizacionMasiva(props) {
//     const { to, open, ...other } = props;
//     const primary = breadcrumbNameMap[to];
//     const location = useLocation();
//     const classes = useStyles();

//     return (
//         <div className={location.pathname == Object.keys(breadcrumbNameMap)[7] ? classes.active : null}>
//             <ListItem button component={RouterLink} to={to} {...other}>
//                 <ListItemIcon>
//                     <IconMenuTypography className="icon-atorizacion-masiva"></IconMenuTypography>
//                 </ListItemIcon>
//                 <ListItemText primary={primary} />
//                 {open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
//             </ListItem>
//         </div>
//     );
// }

// function ListItemReqAprobadas(props) {
//     const { to, open, ...other } = props;
//     const primary = breadcrumbNameMap[to];
//     const location = useLocation();
//     const classes = useStyles();

//     return (
//         <div className={location.pathname == Object.keys(breadcrumbNameMap)[1] ? classes.active : null}>
//             <ListItem button component={RouterLink} to={to} {...other}>
//                 <ListItemIcon>
//                     <IconMenuTypography className="icon-aprobadas"></IconMenuTypography>
//                 </ListItemIcon>
//                 <ListItemText primary={primary} />
//                 {open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
//             </ListItem>
//         </div>
//     );
// }

// function ListItemPagoPendiente(props) {
//     const { to, open, ...other } = props;
//     const primary = breadcrumbNameMap[to];
//     const location = useLocation();
//     const classes = useStyles();

//     return (
//         <div className={location.pathname == Object.keys(breadcrumbNameMap)[4] ? classes.active : null}>
//             <ListItem button component={RouterLink} to={to} {...other}>
//                 <ListItemIcon>
//                     <IconMenuTypography className="icon-pago-pendiente"></IconMenuTypography>
//                 </ListItemIcon>
//                 <ListItemText primary={primary} />
//                 {open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
//             </ListItem>
//         </div>
//     );
// }

// function ListItemPagoMultiple(props) {
//     const { to, open, ...other } = props;
//     const primary = breadcrumbNameMap[to];
//     const location = useLocation();
//     const classes = useStyles();

//     return (
//         <div className={location.pathname == Object.keys(breadcrumbNameMap)[6] ? classes.active : null}>
//             <ListItem button component={RouterLink} to={to} {...other}>
//                 <ListItemIcon>
//                     <IconMenuTypography className="icon-pago-multiple"></IconMenuTypography>
//                 </ListItemIcon>
//                 <ListItemText primary={primary} />
//                 {open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
//             </ListItem>
//         </div>
//     );
// }

// function ListItemPagados(props) {
//     const { to, open, ...other } = props;
//     const primary = breadcrumbNameMap[to];
//     const location = useLocation();
//     const classes = useStyles();

//     return (
//         <div className={location.pathname == Object.keys(breadcrumbNameMap)[5] ? classes.active : null}>
//             <ListItem button component={RouterLink} to={to} {...other}>
//                 <ListItemIcon>
//                     <IconMenuTypography className="icon-pagados" ></IconMenuTypography>
//                 </ListItemIcon>
//                 <ListItemText primary={primary} />
//                 {open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
//             </ListItem>
//         </div>
//     );
// }

// function ListItemProveedoresLista(props) {
//     const { to, open, ...other } = props;
//     const primary = breadcrumbNameMap[to];
//     const location = useLocation();
//     const classes = useStyles();

//     return (
//         <div className={location.pathname == Object.keys(breadcrumbNameMap)[8] ? classes.active : null}>
//             <ListItem button component={RouterLink} to={to} {...other}>
//                 <ListItemText primary={primary} />
//                 {open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
//             </ListItem>
//         </div>
//     );
// }

// function ListItemNuevoProveedor(props) {
//     const { to, open, ...other } = props;
//     const primary = breadcrumbNameMap[to];
//     const location = useLocation();
//     const classes = useStyles();

//     return (
//         <div className={location.pathname == Object.keys(breadcrumbNameMap)[9] ? classes.active : null}>
//             <ListItem button component={RouterLink} to={to} {...other}>
//                 <ListItemText primary={primary} />
//                 {open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
//             </ListItem>
//         </div>
//     );
// }

// function ListItemCfdisPendientes(props){
//     const { to, open, ...other } = props;
//     const primary = breadcrumbNameMap[to];
//     const location = useLocation();
//     const classes = useStyles();

//     return (
//         <div className={location.pathname == Object.keys(breadcrumbNameMap)[10] ? classes.active : null}>
//             <ListItem button component={RouterLink} to={to} {...other}>
//                 <ListItemText primary={primary} />
//                 {open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
//             </ListItem>
//         </div>
//     );
// }

// function ListItemCfdisPasados(props){
//     const { to, open, ...other } = props;
//     const primary = breadcrumbNameMap[to];
//     const location = useLocation();
//     const classes = useStyles();

//     return (
//         <div className={location.pathname == Object.keys(breadcrumbNameMap)[11] ? classes.active : null}>
//             <ListItem button component={RouterLink} to={to} {...other}>
//                 <ListItemText primary={primary} />
//                 {open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
//             </ListItem>
//         </div>
//     );
// }

// function ListItemNuevaSolicitud(props) {
//     const { to, open, ...other } = props;
//     const primary = breadcrumbNameMap[to];
//     const location = useLocation();
//     const classes = useStyles();

//     return (
//         <div className={location.pathname == Object.keys(breadcrumbNameMap)[12] ? classes.active : null}>
//             <ListItem button component={RouterLink} to={to} {...other}>
//                 <ListItemText primary={primary} />
//                 {open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
//             </ListItem>
//         </div>
//     );
// }

// function ListItemConsultaSolicitudes(props) {
//     const { to, open, ...other } = props;
//     const primary = breadcrumbNameMap[to];
//     const location = useLocation();
//     const classes = useStyles();

//     return (
//         <div className={location.pathname == Object.keys(breadcrumbNameMap)[13] ? classes.active : null}>
//             <ListItem button component={RouterLink} to={to} {...other}>
//                 <ListItemText primary={primary} />
//                 {open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
//             </ListItem>
//         </div>
//     );
// }





// function ListItemCuentas(props) {
//     const { to, open, ...other } = props;
//     const primary = breadcrumbNameMap[to];
//     const location = useLocation();
//     const classes = useStyles();

//     return (
//         <div className={location.pathname == Object.keys(breadcrumbNameMap)[14] ? classes.active : null}>
//             <ListItem button component={RouterLink} to={to} {...other}>
//                 <ListItemText primary={primary} />
//                 {open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
//             </ListItem>
//         </div>
//     )
// }

// function ListItemRazonesSociales(props) {
//     const { to, open, ...other } = props;
//     const primary = breadcrumbNameMap[to];
//     const location = useLocation();
//     const classes = useStyles();

//     return (
//         <div className={location.pathname == Object.keys(breadcrumbNameMap)[15] ? classes.active : null}>
//             <ListItem button component={RouterLink} to={to} {...other}>
//                 <ListItemText primary={primary} />
//                 {open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
//             </ListItem>
//         </div>
//     )
// }

// function ListItemCuentasNotificacion(props) {
//     const { to, open, ...other } = props;
//     const primary = breadcrumbNameMap[to];
//     const location = useLocation();
//     const classes = useStyles();

//     return (
//         <div className={location.pathname == Object.keys(breadcrumbNameMap)[16] ? classes.active : null}>
//             <ListItem button component={RouterLink} to={to} {...other}>
//                 <ListItemText primary={primary} />
//                 {open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
//             </ListItem>
//         </div>
//     )
// }

// function ListItemCambiarContrasena(props) {
//     const { to, open, ...other } = props;
//     const primary = breadcrumbNameMap[to];
//     const location = useLocation();
//     const classes = useStyles();

//     return (
//         <div className={location.pathname == Object.keys(breadcrumbNameMap)[17] ? classes.active : null}>
//             <ListItem button component={RouterLink} to={to} {...other}>
//                 <ListItemText primary={primary} />
//                 {open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
//             </ListItem>
//         </div>
//     )
// }
