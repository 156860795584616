import React, { useState } from 'react';
import clsx from 'clsx';
import { BrowserRouter as Router, Link as RouterLink, Switch, Route } from "react-router-dom";
import './SideNavMenu.css'
import { useTheme } from '@material-ui/core/styles';
import { LogoTypography } from '../Login/ElementsLogin';
import {
    Drawer, AppBar, List, CssBaseline, IconButton, Collapse,
    ListItem, ListItemIcon, ListItemText
} from '@material-ui/core';
import { useStyles, useStylesMenu, SetItem } from './ElementsSideNavMenu';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { Menu, ChevronRight, ChevronLeft, ExitToApp, Apps, ExpandLess, ExpandMore } from '@material-ui/icons';
import { IconProfileTypography, IconMenuTypography, IconMenuSmallTypography } from './ElementsSideNavMenu';

import Dashboard from '../DashBoard/Dashboard'
import RequisicionesPendientes from '../Requisiciones/RequisicionesPendientes'
import RequisicionesAprobadas from '../Requisiciones/RequisicionesAprobadas'
import RequisicionesPasadas from '../Requisiciones/Requisiciones'
import ModalAprobarRequisiciones from '../Requisiciones/ModalAprobarRequisiciones';
import ProveedoresByEmpresa from '../Requisiciones/SeleccionarProveedor'
import PagosPendientes from '../Pagos/PagosPendientes'
import PagosPasados from '../Pagos/Pagos'
import PagoMultiple from '../Pagos/PagoMultiple'
import Proveedores from '../ProveedoresCatalogo/ProveedoresCatalogo'
import NuevoProveedor from '../ProveedoresCatalogo/FormularioNuevoProveedor'
import CfdisPendientes from '../CFDIs/CfdiPendientes'
import CfdisPasados from '../CFDIs/CfdiPasados'
import CuentasEmisoras from '../CuentasEmisoras/ListaCuentasEmisoras'
import Configuracion from '../Configuracion/Configuracion'
import CuentasNotificacion from '../Configuracion/CuentasNotificacion';
import CambiarContrasena from '../Configuracion/CambiarContrasena';
import EmpresaUsuarios from '../Configuracion/Empresa/EmpresaUsuarios'
import SolicitudSoporte from '../SoporteTecnico/SolicitudSoporte'
import ListaSolicitudes from '../SoporteTecnico/ListaSolicitudes'
import Logout from '../Logout/Logout'
import Login from '../Login/Login'
function SideNavMenu() {
    const classes = useStyles();
    const classesMenu = useStylesMenu();
    const theme = useTheme();
    const token = JSON.parse(localStorage.getItem('token'));
    const username = token.username;
    const userType = token.userType;
    const [titleContent, setTitleContent] = useState('Inicio');
    const [open, setOpen] = useState(true);
    const [openRequisiciones, setOpenRequisiciones] = useState(false);
    const [openPagos, setOpenPagos] = useState(false);
    const [openProveedores, setOpenProveedores] = useState(false);
    const [openCfdis, setOpenCfdis] = useState(false);
    const [openSoporte, setOpenSoporte] = useState(false);
    const [openConfiguracion, setOpenConfiguracion] = useState(false);

    const handleInicio = () => {
        setTitleContent('Inicio');
    };
    const handleRequisiciones = () => {
        setOpenRequisiciones((prevOpen) => !prevOpen);
    };
    const handleRequisicionesPendientes = () => {
        setTitleContent('Requisiciones por autorizar');
    };
    const handleRequisicionesAprobadas = () => {
        setTitleContent('Requisiciones aprobadas');
    };
    /*const handleRequisicionesPasadas = () => {
        setTitleContent('Requisiciones pagadas');
    };*/
    const handleNuevaRequisicion = () => {
        setTitleContent('1. Nueva requisición - Selecciona un proveedor o agrega uno nuevo');
    };
    const handlePagos = () => {
        setOpenPagos((prevOpen) => !prevOpen);
    };
    /*const handleAprobaciones = () => {
        setOpenAprobMultiple((prevOpen) => !prevOpen);
    };*/
    const handlePagosPendientes = () => {
        setTitleContent('Pendientes de pago'); //Requisiciones por pagar
    };
    const handlePagosPasados = () => {
        setTitleContent('Requisiciones pagadas');
    };
    const handleAprobMultiple = () => {
        setTitleContent('Autorización múltiple: Selecciona las requisiciones que deseas aprobar');
    };
    const handlePagoMultiple = () => {
        setTitleContent('Pago múltiple (subir uno por uno)');
    };
    const handleProveedores = () => {
        setOpenProveedores((prevOpen) => !prevOpen);
    };
    const handleListaProveedores = () => {
        setTitleContent('Proveedores');
    };
    const handleNuevoProveedor = () => {
        setTitleContent('Nuevo proveedor');
    };
    const handleCuentas = () => {
        setTitleContent('Cuenta emisora');
    }
    const handleCfdis = () => {
        setOpenCfdis((prevOpen) => !prevOpen);
    };
    const handleCfdisPendientes = () => {
        setTitleContent('Consulta de CFDIs');
    };
    const handleCfdisPasados = () => {
        setTitleContent('CFDIs por cargar');
    };
    const handleConfiguracion = () => {
        setOpenConfiguracion((prevOpen) => !prevOpen);
    };
    const handleRazonesSociales = () => {
        setTitleContent('Razones sociales');
    };
    const handleCuentasNotificacion = () => {
        setTitleContent('Mis cuentas para notificar');
    };
    const handleCambiarContrasena = () => {
        setTitleContent('Cambiar contraseña');
    };
    const handleCerrarSesion = () => {
        setTitleContent('Cerrando sesión...');
    };
    const handleSoporteTecnico = () => {
        setOpenSoporte((prevOpen) => !prevOpen);
    };
    const handleNuevaSolicitud = () => {
        setTitleContent('Nueva solicitud de soporte');
    };
    const handleConsultaSolicitudes = () => {
        setTitleContent('Consulta solicitudes');
    };
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <div id="sidenavmenu" className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: open, })}>
                <div className="d-flex justify-content-between mt-2" >
                    <div>
                        <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerOpen}
                            className={clsx(classes.menuButton, {
                                [classes.hide]: open,
                            })}
                        ><Menu />
                        </IconButton>
                    </div>
                    <div className="d-flex">
                        <LogoTypography className="logo-proveedores"></LogoTypography>
                        <p id="logoProveedores" className="font-logo">Proveedores 2024</p>
                    </div>
                    <div></div>
                </div>
                <div id="cardTitleContent">
                    <h5>{titleContent}</h5>
                </div>
            </AppBar>
            <Router >
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={open}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <div className={classes.toolbar}>
                        <div className="div-profile">
                            <div className="d-flex justify-content-end">
                                <IconButton onClick={handleDrawerClose} title="Cerrar menú">
                                    {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
                                </IconButton>
                            </div>
                            <div className="div-perfil-two">
                                <div className="d-flex justify-content-center">
                                    <IconProfileTypography className="icon-profile"></IconProfileTypography>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <p className="font-profile-one">Bienvenido</p>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <p className="font-profile-two">{username}</p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end">
                                <RouterLink to='/logout'>
                                    <IconButton onClick={handleCerrarSesion} title="Cerrar sesión">
                                        <ExitToApp></ExitToApp>
                                    </IconButton>
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                    <div className={classesMenu.root}>
                        <nav className={classesMenu.lists} aria-label="mailbox folders">
                            <List>
                                <li onClick={handleInicio}>
                                    <ListItem button to='/inicio' component={RouterLink} className={classesMenu.submenu} >
                                        <ListItemIcon><Apps /></ListItemIcon>
                                        <ListItemText primary={"Inicio"} onClick={handleInicio}></ListItemText>
                                    </ListItem>
                                </li>
                                <li open={openRequisiciones} onClick={handleRequisiciones}>
                                    <ListItem button className={classesMenu.submenu}>
                                        <ListItemIcon>
                                            <IconMenuTypography className="icon-requisicion-menu"></IconMenuTypography>
                                        </ListItemIcon>
                                        <ListItemText primary={"Requisiciones"}></ListItemText>
                                        {openRequisiciones ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                </li>
                                <Collapse component="li" in={openRequisiciones} timeout="auto" unmountOnExit cl>
                                    {userType === 'SCHEMA_SOLICITANTE' || userType === 'SCHEMA_ADMIN' || userType === 'ADMINISTRADOR' ?
                                        <SetItem to="/nuevaRequisicion" idpath='3' iconname='icon-nueva-req' className={classesMenu.nested} onClick={handleNuevaRequisicion} />
                                        : ''}
                                    <SetItem to="/requisiciones/pendientes" idpath='0' iconname='icon-autorizar-req' className={classesMenu.nested} onClick={handleRequisicionesPendientes} />
                                    {userType === 'SCHEMA_PAGADOR' || userType === 'SCHEMA_ADMIN' || userType === 'ADMINISTRADOR' ?
                                        <SetItem to="/autorizacionMultiple" idpath='7' iconname='icon-atorizacion-masiva' className={classesMenu.nested} onClick={handleAprobMultiple} />
                                        : ''}
                                    {userType === 'SCHEMA_ADMIN' ?
                                        <SetItem to="/requisiciones/aprobadas" idpath='1' iconname='icon-aprobadas' className={classesMenu.nested} onClick={handleRequisicionesAprobadas} />
                                        : ''}
                                </Collapse>
                                <li open={openPagos} onClick={handlePagos}>
                                    <ListItem button className={classesMenu.submenu}>
                                        <ListItemIcon>
                                            <IconMenuTypography className="icon-pagos"></IconMenuTypography>
                                        </ListItemIcon>
                                        <ListItemText primary={"Pagos"}></ListItemText>
                                        {openPagos ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                </li>
                                <Collapse component="li" in={openPagos} timeout="auto" unmountOnExit>
                                    <SetItem to="/pagos/pendientes" idpath='4' iconname='icon-pago-pendiente' className={classesMenu.nested} onClick={handlePagosPendientes} />
                                    {userType === 'SCHEMA_PAGADOR' || userType === 'SCHEMA_ADMIN' || userType === 'ADMINISTRADOR' ?
                                        <SetItem to="/pagoMultiple" idpath='6' iconname='icon-pago-multiple' className={classesMenu.nested} onClick={handlePagoMultiple} /> : ''}
                                    <SetItem to="/pagos/pasados" idpath='5' iconname='icon-pagados' className={classesMenu.nested} onClick={handlePagosPasados} />
                                </Collapse>
                                <li open={openProveedores} onClick={handleProveedores}>
                                    <ListItem button className={classesMenu.submenu}>
                                        <ListItemIcon>
                                            <IconMenuSmallTypography className="icon-proveedor-menu"></IconMenuSmallTypography>
                                        </ListItemIcon>
                                        <ListItemText primary={"Proveedores"}></ListItemText>
                                        {openProveedores ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                </li>


                                <Collapse component="li" in={openProveedores} timeout="auto" unmountOnExit>
                                    <SetItem to="/proveedores/lista" idpath='8' iconname='' className={classesMenu.nested} onClick={handleListaProveedores} />

                                    <SetItem to="/proveedores/nuevo" idpath='9' iconname='' className={classesMenu.nested} onClick={handleNuevoProveedor} />
                                </Collapse>


                                <li open={openCfdis} onClick={handleCfdis}>
                                    <ListItem button className={classesMenu.submenu}>
                                        <ListItemIcon>
                                            <IconMenuTypography className="icon-cfdi-menu"></IconMenuTypography>
                                        </ListItemIcon>
                                        <ListItemText primary={"CFDI's"}></ListItemText>
                                        {openCfdis ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                </li>
                                <Collapse component="li" in={openCfdis} timeout="auto" unmountOnExit>
                                    <SetItem to="/cfdis/pendientes" idpath='10' iconname='' className={classesMenu.nested} onClick={handleCfdisPendientes} />

                                    <SetItem to="/cfdis/pasados" idpath='11' iconname='' className={classesMenu.nested} onClick={handleCfdisPasados} />
                                </Collapse>

                                <li open={openSoporte} onClick={handleSoporteTecnico}>
                                    <ListItem button className={classesMenu.submenu}>
                                        <ListItemIcon>
                                            <IconMenuTypography className="icon-soporte-menu"></IconMenuTypography>
                                        </ListItemIcon>
                                        <ListItemText primary={"Soporte"}></ListItemText>
                                        {openSoporte ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                </li>
                                <Collapse component="li" in={openSoporte} timeout="auto" unmountOnExit>
                                    <SetItem to="/soporte/solicitud" idpath='12' iconname='' className={classesMenu.nested} onClick={handleNuevaSolicitud} />


                                    <SetItem to="/soporte/consulta" idpath='13' iconname='' className={classesMenu.nested} onClick={handleConsultaSolicitudes} />
                                </Collapse>
                                <li open={openConfiguracion} onClick={handleConfiguracion}>
                                    <ListItem button className={classesMenu.submenu} >
                                        <ListItemIcon><SettingsOutlinedIcon /></ListItemIcon>
                                        <ListItemText primary={"Configuración"}></ListItemText>
                                        {openConfiguracion ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                </li>
                                <Collapse component="li" in={openConfiguracion} timeout="auto" unmountOnExit>
                                    <SetItem to="/configuracion/cuentasEmisoras" idpath='14' iconname='' className={classesMenu.nested} onClick={handleCuentas} />

                                    {userType === 'SCHEMA_PAGADOR' || userType === 'SCHEMA_ADMIN' || userType === 'ADMINISTRADOR' ?
                                        <SetItem to="/configuracion" idpath='15' iconname='' className={classesMenu.nested} onClick={handleRazonesSociales} /> : '' }

                                    <SetItem to="/configuracion/cuentasNotificacion" idpath='16' iconname='' className={classesMenu.nested} onClick={handleCuentasNotificacion} />

                                    <SetItem to="/configuracion/cambiarContrasena" idpath='17' iconname='' className={classesMenu.nested} onClick={handleCambiarContrasena} />
                                </Collapse>
                            </List>
                        </nav>
                    </div>
                </Drawer>
                <main className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}>
                    <div className={classes.drawerHeader} />
                    <Switch>
                        <Route exact path="/requisiciones/pendientes"><RequisicionesPendientes /></Route>
                        <Route exact path="/autorizacionMultiple">< ModalAprobarRequisiciones /></Route>
                        <Route exact path="/requisiciones/aprobadas"><RequisicionesAprobadas /></Route>
                        <Route exact path="/requisiciones/pasadas"> <RequisicionesPasadas /></Route>
                        <Route exact path="/nuevaRequisicion"><ProveedoresByEmpresa /></Route>
                        <Route exact path="/pagos/pendientes"><PagosPendientes /></Route>
                        <Route exact path="/pagos/pasados"><PagosPasados /></Route>
                        <Route exact path="/pagoMultiple"><PagoMultiple /></Route>
                        <Route exact path="/proveedores/lista"><Proveedores /></Route>
                        <Route exact path="/proveedores/nuevo"><NuevoProveedor /></Route>
                        <Route exact path="/cfdis/pendientes"><CfdisPendientes /></Route>
                        <Route exact path="/cfdis/pasados"><CfdisPasados /></Route>
                        <Route exact path="/configuracion"><Configuracion /></Route>
                        <Route exact path="/configuracion/cuentasEmisoras"><CuentasEmisoras /></Route>
                        <Route exact path="/configuracion/cuentasNotificacion"><CuentasNotificacion /></Route>
                        <Route exact path="/configuracion/cambiarContrasena"><CambiarContrasena /></Route>
                        <Route exact path="/configuracion/:aliasEmpresa" component={(props) => <EmpresaUsuarios{...props} />}></Route>{/**siempre dejar este elemento hasta abajo de configuracion */}
                        <Route exact path="/soporte/solicitud"><SolicitudSoporte /></Route>
                        <Route exact path="/soporte/consulta"><ListaSolicitudes /></Route>
                        <Route exact path="/inicio"><Dashboard /></Route>
                        <Route exact path="/logout"><Logout /></Route>
                        <Route exact path="/"><Login /></Route>
                    </Switch>
                </main>
            </Router>
        </div >
    );
}

export default SideNavMenu;