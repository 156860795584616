import React, { useEffect, useState } from "react";
import axios from 'axios';
import { API_PROVEEDORES_URL, KRAKEN_URL } from '../../constants'

//Estilos
import '../Requisiciones/css/Requisiciones.css'
import { XCircle } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';

//Elementos bootstrap
import { Modal, Form, Col, Spinner } from "react-bootstrap";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
//componentes
import Moment from 'moment';
import ModalAgregarUsuario from '../Configuracion/Empresa/Usuarios/UsuarioContactos/ModalAgregarUsuarioContacto';
import BootstrapTable from 'react-bootstrap-table-next';
function ModalCancelarAprobacion(props){
    const token = JSON.parse(localStorage.getItem('token'));
    //const userId = token.userId;
    const username = token.username;
    const [isMasivo,setIsMasivo] = useState(Array.isArray(props.children))
    const empresaAlias = props.children.empresa;
    const userId = token.userId;
    const [request] = useState(false); 
    const [status, setStatus] = useState(false);
    const [error, setError] = useState(false);
    const [statusError, setStatusError] = useState('');
    const [comentario, setComentario] = useState('');
    const [solicitante, setSolicitante] = useState([]);
    const[isLoading,setIsLoading]=useState(false)
    const [seleccionados, setSeleccionados] = useState([]);//Aquí se guardan sólo los id's de los contactos que se seleccionen
    const [ctcSeleccionados, setCtcSeleccionados] = useState([]);
    const [listContactos, setListContactos] = useState([]);
    const [modalAgregarUsuarioShow, setModalAgregarUsuarioShow] = useState(false);
    const [updateListado, setUpdateListado] = useState(false);
    const[showContactos,setShowContactos] = useState(false);
    let modalAgregarUsuarioClose = () => { setModalAgregarUsuarioShow(false); }
    let updateGetContactos = () => { setUpdateListado(!!!updateListado); setListContactos([]); }
    const usuario = {
        userId: userId,
        username: token.username,
        empresaAlias: empresaAlias
    }

    useEffect(() => {
        if (!isMasivo) {
          getSolicitante();
        }
      }, [props.children.idSolicitante, isMasivo]);
      useEffect(getContactos, [updateListado, empresaAlias, userId]);

      function getContactos() {
        axios.post(KRAKEN_URL + 'empresa/usuario/contactos/list', {
            userId: userId,
            empresa: empresaAlias
        }).then((res) => {
            if (res.data.result.code === 200 && res.data.list.length) {
                setListContactos(res.data.list);
                setSeleccionados([...seleccionados, res.data.list[0].contactoId]);
                setCtcSeleccionados([...ctcSeleccionados, res.data.list[0].emailContacto]);
            } else {
                setListContactos('');
                
            }
        }).catch((error) => {
            setError(true);
            setStatusError("Ocurrió un error, no se pudieron recuperar los contactos de este usuario");
        })
    }

    function handleShowContactos(e){
        setShowContactos(!showContactos);
        if(!showContactos){
            setSeleccionados([]);
            setCtcSeleccionados([]);
        }
    }
    function handleComentarioChange(e) {
        setComentario(e.target.value);
    }

    let handleSizePerPage = ({ page, onSizePerPageChange }, newSizePerPage) => {
        onSizePerPageChange(newSizePerPage, page);
    }
    function textButton(){
        return "Cancelar aprobación"
    }
    function cargando() {
        return (
            <div className="d-flex justify-content-center align-items-center" >
            <Spinner  animation="border" role="status">
              <span className="visually-hidden"></span>
            </Spinner>
          </div>)
      }

    function getSolicitante() {
       
        axios.post(KRAKEN_URL + 'empresa/usuario/solicitante/getSolicitante', {
            userId: props.children.idSolicitante
            //empresa: empresaAlias
        }).then((res) => {
            if (res.data.result.code === 200 ) {
                setSolicitante(res.data.list[0])
            } else {
                setSolicitante('')
                setError(true);
                setStatusError(res.data.result.message);
            }
        }).catch((error) => {
            setError(true);
            setStatusError("Ocurrió un error, no se pudieron recuperar los contactos de este usuario");
        })
    }


    let mostrarListaContactos = () => {
        if (error) {
            return (
                <div>{statusError}</div>
            )
        } else {
            const columns = [
                {
                    dataField: 'contacto',
                    text: 'Nombre de contacto',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                },
                {
                    dataField: 'emailContacto',
                    text: 'Email del contacto',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                }];

            const selectRow = {
                mode: 'checkbox',
                clickToSelect: true,
                style: { backgroundColor: 'rgb(155, 227, 230)' },
                selected: seleccionados,//renglones seleccionados de acuerdo al contactoId (keyField del BootstrapTable)
                onSelect: (row, isSelect, rowIndex, e) => {
                    if (isSelect) {
                        setSeleccionados([...seleccionados, row.contactoId]);
                        setCtcSeleccionados([...ctcSeleccionados, row.emailContacto]);
                    } else {
                        let eliminarSeleccionado = seleccionados.filter(x => x !== row.contactoId);
                        setSeleccionados(eliminarSeleccionado);
                        let eliminarContacto = ctcSeleccionados.filter(x => x !== row.emailContacto);
                        setCtcSeleccionados(eliminarContacto);
                    }
                },
                onSelectAll: (isSelect, rows, e) => {
                    const ids = rows.map(r => r.contactoId);
                    const emails = rows.map(r => r.emailContacto);
                    if (isSelect) {
                        setSeleccionados(ids);
                        setCtcSeleccionados(emails);
                    } else {
                        setSeleccionados([]);
                        setCtcSeleccionados([]);
                    }
                }
            }

            function indication() {
                return listContactos.length ? "La búsqueda no coincide con ningún criterio" : "No hay registros que mostrar, da clic en el botón 'Agregar contacto' para crear uno";
            }

            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size}
                </span>
            );

            const options = {
                custom: true,
                sizePerPage: 5,
                totalSize: listContactos.length,
                paginationTotalRenderer: customTotal
            }

            return <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div>
                        <Form.Row className="d-flex justify-content-center">
                            <button
                                className="m-1 button-default-req"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Registrar contacto"
                                onClick={() => { setModalAgregarUsuarioShow(true) }}
                            ><span>Agregar contacto</span>
                            </button>
                            <ModalAgregarUsuario show={modalAgregarUsuarioShow} onHide={modalAgregarUsuarioClose} onUpdate={updateGetContactos}>{usuario}</ModalAgregarUsuario>
                        </Form.Row>
                        <Form.Row className="p-2">
                            <div className="d-flex flex-row">
                                <Form.Label column>Mostrar</Form.Label>
                                <Col>
                                    <Form.Control size="sm" as="select" onChange={(e) => handleSizePerPage(paginationProps, e.target.value)}>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value={listContactos}>Todos</option>
                                    </Form.Control>
                                </Col>
                            </div>
                            <div className="d-flex flex-row">
                                <Col>
                                    <PaginationTotalStandalone {...paginationProps} />
                                </Col>
                            </div>
                            <div className="d-flex ml-auto flex-row-reverse">
                                <Col>
                                    <PaginationListStandalone {...paginationProps} />
                                </Col>
                                <Form.Label column>Página</Form.Label>
                            </div>
                        </Form.Row>
                        <div className="table-responsive table-sm">
                            <BootstrapTable
                                id="listaContactos"
                                keyField='contactoId'
                                data={listContactos}
                                columns={columns}
                                selectRow={selectRow}
                                filter={filterFactory()}
                                bordered={false}
                                striped
                                hover
                                condensed
                                noDataIndication={indication}
                                {...paginationTableProps}
                            />
                        </div>
                    </div>
                )}
            </PaginationProvider>
        }
    }

    async function getSolicitanteOnebyOne(idSolicitante) {
        return new Promise((resolve, reject) => {
          axios.post(KRAKEN_URL + 'empresa/usuario/solicitante/getSolicitante', {
            userId: idSolicitante
          }).then((res) => {
            ;
            ;
            if (res.data.result.code === 200) {
              setSolicitante(res.data.list[0]);
              resolve(res.data.list[0]);
            } else {
              setSolicitante('');
              setError(true);
              setStatusError(res.data.result.message);
              reject(res.data.result.message);
            }
          }).catch((error) => {
            ;
            setError(true);
            setStatusError("Ocurrió un error, no se pudieron recuperar los contactos de este usuario");
            reject(error);
          });
        });
      }
      function mapeoCompartidos(compartidos){
        if(compartidos !=="[]"){
          const compartidosArray = JSON.parse(compartidos);
         return compartidosArray.map(usuario => usuario.usuarioEmail);
        
        }
        return []
      }
 async function cancelarAprobacion(requisicion, e){
        e.preventDefault();
        setIsLoading(true)
        if(!isMasivo){ let auxArray = []
            let compartidos = mapeoCompartidos(requisicion.compartidos)
            if(!ctcSeleccionados.includes(solicitante.email)){
                ctcSeleccionados.push(solicitante.email)
                
               
            }
             auxArray = [...ctcSeleccionados,...compartidos]
            auxArray =auxArray.filter(array => array!=="asd")
               
             axios.post(API_PROVEEDORES_URL + 'cancelarAprobacion',
            {
                to: auxArray,
                idRequisicion: requisicion.idRequisicion,
                id: requisicion.id,
                empresa: requisicion.empresa,
                solicitante:  requisicion.solicitante,
                pagador: username,
                proveedor: requisicion.proveedor,
                fechaRequeridaPago: requisicion.fechaRequeridaPago,
                total: requisicion.total,
                porcentajeRequerido: requisicion.porcentajeTotal,
                comentario: comentario 
            }
        ).then((res) => {
            
            if(res.status===200){
                setStatus(false);
                toast.success("Se ha cancelado correctamente",{
                    position:"top-center",
                    autoClose: 4500, 
                });
                toast.success("Podrá encontrarla en el listado de 'Requiciones por autorizar'",{
                    position:"top-center",
                    autoClose: 4500, 
                });
                props.onUpdate();
                props.onHide();
                setIsLoading(false)
            } else {
                setIsLoading(false)
                setStatus(false);
                toast.error("Error: Algo salió mal",{
                    position:"top-center",
                    autoClose: 4500, 
                });
               
            }
        }).catch((error) => {
            if(error.status===200){
                setStatus(false);
                toast.success(error.data.result.message,{
                    position:"top-center",
                    autoClose: 4500, 
                });
                toast.success("Podrá encontrarla en el listado de 'Requiciones por autorizar'",{
                    position:"top-center",
                    autoClose: 4500, 
                });
                props.onUpdate();
                props.onHide();
                setIsLoading(false)}
                else{
                    
                    setStatus(false);
                    setIsLoading(false)
                    toast.error("No se ha podido cancelar la aprobación",{
                        position:"top-center",
                        autoClose: 4500, 
                    });
                }
          
        })
    }
    else{
        let pagos = []
       
        try {
            for (const req of props.children) {
              try {
                let solicitanteRes = await getSolicitanteOnebyOne(req.idSolicitante);
                
                if (solicitanteRes) {
                  let reqMapped = {
                    to: solicitanteRes.email,
                    idRequisicion: req.idRequisicion,
                    id: req.id,
                    empresa: req.empresa,
                    solicitante: req.solicitante,
                    pagador: username,
                    proveedor: req.proveedor,
                    fechaRequeridaPago: req.fechaRequeridaPago,
                    total: req.total,
                    porcentajeRequerido: req.porcentajeTotal,
                    comentario: comentario,
                    compartidos:req.compartidos
                  };
      
                  ;
                  pagos.push(reqMapped);
                }
              } catch (error) {
                console.error(error);
                
                toast.error("Ha ocurrido un error en la recuperacion de información del solicitante",{
                    position:"top-center",
                    autoClose: 4500, 
                });
              }
            }
            
          await  axios.post(API_PROVEEDORES_URL+'cancelarAprobacionMasiva', pagos)
            .then((res) => {
                setIsLoading(false)
                
                if (res.status===200) {
                    setStatus(false);
                    setIsLoading(false)

                    toast.success("Se ha cancelado la aprobación de forma masiva correctamente.",{
                        position:"top-center",
                        autoClose: 4500, 
                    });

                    props.onUpdate();
                    props.onHide();
                } else {
                    setIsLoading(false)
                    setStatus(false);

                    toast.error(res.data.result.message,{
                        position:"top-center",
                        autoClose: 4500, 
                    });

                    toast.error(res.data.result.suggestion,{
                        position:"top-center",
                        autoClose: 4500, 
                    });
                }
            }).catch((error) => {
                setIsLoading(false)
                
                setStatus(false);

                toast.error("No se ha podido enviar la requisición al solicitante",{
                    position:"top-center",
                    autoClose: 4500, 
                });
            })
         
       } catch (error) {
        toast.error("Ha ocurrido un error",{
            position:"top-center",
            autoClose: 4500, 
        });
    }
    }
}

    return (
        <section className="text-center">
            <Modal
                show={props.show}
                //onHide={props.onHide}
                size="md"
                id="modalCancelarAprobacion"
                centered
            >
                <Modal.Header className="modal-header-cancelar-aprobacion" onHide={props.onHide} closeButton>
                  <Form.Row><XCircle size={22} color="#5B4B51"/> {!isMasivo?  <p style={{marginLeft: "8px"}}>Cancelar requisición aprobada <b>#{props.children.id}</b></p>:<p style={{marginLeft: "8px"}}>Cancelación masiva</p>}</Form.Row>
                </Modal.Header>
                {isLoading?cargando():
                <Modal.Body>
                    
                    <div className="justify-content-center font-cancelar-requisicion">
                        {!isMasivo?<div>
                        <p>¿Seguro que desea cancelar la aprobación de esta requisición? <b>#{props.children.id}</b></p>
                        <p><b>Proveedor:</b> {props.children.proveedor}</p>
                        <p><b>Fecha requerida de pago:</b> {Moment(props.children.fechaRequeridaPago).format('DD/MM/YYYY')}</p>
                        <p><b>Total:</b> ${props.children.total}</p>
                        <p><b>Porcentaje requerido:</b> {props.children.porcentajeTotal}%</p>
                        <br/>
                        </div>:
                            <p>Al cancelar los registros seleccionados se moverán al listado Autorizar y se tendrá que volver a solicitar aprobación</p>
                        }
                        <Form.Control 
                            id="comentario" as="textarea" 
                            rows={1} 
                            placeholder="¿Desea agregar algún comentario?"
                            value={comentario} 
                            onChange={handleComentarioChange}>
                        </Form.Control>
                        <Form.Text muted><b>Nota:</b> Se enviará notificación al correo del solicitante</Form.Text>
                    </div>
                    {/*<Form.Row className="d-flex justify-content-center">
                    {!isMasivo?  <Form.Check type="checkbox" label="Notificar a mas usuarios" checked={showContactos} onChange={handleShowContactos}></Form.Check>:""}
                  
                                  
                          {showContactos?mostrarListaContactos():""}
                        </Form.Row>*/}
                    <div className="d-flex justify-content-center">
                        <button
                            className="m-1 button-default-confirmar"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Confirmar"
                            onClick={e => cancelarAprobacion(props.children, e)}
                        >{status ?
                            <span>Cancelando...</span>
                            : <span>{textButton()}</span>}
                        </button>
                        <button
                            className="m-1 button-default-cancelar"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Cerrar"
                            onClick={props.onHide}
                        ><span>Cerrar</span>
                        </button>
                    </div>
                   
                </Modal.Body>}
            </Modal>
        </section>
    );
}

export default ModalCancelarAprobacion;