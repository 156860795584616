import React ,  { useState, useEffect }from "react";

//Estilos
import './css/Requisiciones.css'
import { Eye } from 'react-bootstrap-icons';
import { ExpandMore, ExpandLess } from "@material-ui/icons";
//Componentes bootstrap
import { Container, InputGroup, Col, Form, Modal,Accordion,Card,
 Row,Table} from "react-bootstrap";

//Componentes
import Moment from 'moment';
import NumberFormat from 'react-number-format';
import ListadoCotizacionesConsulta from './CotizacionesArchivos/ListadoCotizacionesConsulta';
import ListadoPagosAnteriores from "../Pagos/ListadoPagosAnteriores";

function ModalConsultarRequisicion(props) {
  
    const monedaDecimales = props.children.monedaDecimales;
    const taxesChecked = props.children.objetoImpuesto
    
    const [contactos, setContactos] = useState(props.children.compartidos?JSON.parse(props.children.compartidos): []);
    let updateGetListado = () => { props.onUpdate() };

    const createInputTotalPesosMexicanos = () => {
        let inputTPM = (<span><Form.Label > Total pesos mexicanos</Form.Label>
            <InputGroup size="sm">
                <InputGroup.Prepend >
                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                </InputGroup.Prepend>
                <NumberFormat
                    id="totalPesosMexicanos"
                    className="input-signs-align form-control form-control-sm"
                    thousandSeparator={true} disabled
                    value={props.children.importePesosMexicanos.toFixed(monedaDecimales)}>
                </NumberFormat>
            </InputGroup>
        </span>
        );
        return inputTPM;
    }

    const createInputAnticipoPesosMexicanos = () => {
        let inputTPM = (<span><Form.Label > Anticipo/Total pesos mexicanos</Form.Label>
            <InputGroup size="sm">
                <InputGroup.Prepend >
                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                </InputGroup.Prepend>
                <NumberFormat
                    id="anticipoPesosMexicanos"
                    className="input-signs-align form-control form-control-sm"
                    thousandSeparator={true} disabled
                    value={props.children.anticipoPesosMexicanos.toFixed(monedaDecimales)}>
                </NumberFormat>
            </InputGroup>
        </span>
        );
        return inputTPM;
    }

    return (
        <section className="text-center">
            <Modal
                {...props}
                size="xl"
                centered
                id="modalConsultarRequisicion"
                scrollable={true}
            >
                <Modal.Header className="modal-header-consultar-req " closeButton>
                    <p><Eye size={22} color="#5B4B51" />   2.Consultar requisición #{props.children.id}</p>
                </Modal.Header>
                <Modal.Body >
                    <Container className="container-modals">
                        <Form.Row>
                            <Form.Group as={Col} md={12} lg={6}>
                                <fieldset >
                                    <legend className="legend-consultar-req">Proveedor</legend>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col}>
                                            <Form.Label>Nombre Proveedor</Form.Label>
                                            <Form.Control size="sm" id="proveedor" type="text" disabled defaultValue={props.children.proveedor}></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col}>
                                            <Form.Label>% Anticipo/Total</Form.Label>
                                            <InputGroup size="sm" >
                                                <NumberFormat id="anticipoPorcentaje"
                                                    className="input-signs-align form-control form-control-sm"
                                                    thousandSeparator={true}
                                                    defaultValue={props.children.porcentajeTotal}
                                                    disabled
                                                />
                                                <InputGroup.Append >
                                                    <InputGroup.Text className="input-signs-right">%</InputGroup.Text>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Anticipo/Total</Form.Label>
                                            <InputGroup size="sm">
                                                <InputGroup.Prepend >
                                                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <NumberFormat id="totalRequerido"
                                                    className="input-signs-align form-control form-control-sm"
                                                    thousandSeparator={true}
                                                    disabled defaultValue={props.children.totalRequerido.toFixed(monedaDecimales)}
                                                />
                                            </InputGroup>
                                            {props.children.monedaClaveSat !== 'MXN' ? createInputAnticipoPesosMexicanos() : ''}
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col}>
                                            <Form.Label>Banco</Form.Label>
                                            <Form.Control size="sm" id="banco" type="text" disabled defaultValue={props.children.banco}>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Cuenta CLABE</Form.Label>
                                            <NumberFormat
                                                id="cuentaClabe"
                                                format="#### #### #### #### ##"
                                                mask="_"
                                                className="form-control form-control-sm"
                                                disabled
                                                value={props.children.cuentaClabe}
                                            >
                                            </NumberFormat>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col}>
                                            <Form.Label>Cuenta banco</Form.Label>
                                            <NumberFormat
                                                id="cuentaBanco"
                                                format="#### #### #### #### #### ####"
                                                mask="_"
                                                className="form-control form-control-sm"
                                                disabled
                                                value={props.children.cuentaBanco}
                                            >
                                            </NumberFormat >
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Referencia</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text" required
                                                defaultValue={props.children.referencia}
                                                disabled
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    
                                </fieldset>
                                <ListadoCotizacionesConsulta>{props.children.idRequisicion}{props.children.idEmpresa}</ListadoCotizacionesConsulta>
                                <ListadoPagosAnteriores onUpdate={updateGetListado}>{props.children.idRequisicion}{props.children.idEmpresa}{props.children.id}</ListadoPagosAnteriores>

                                <Form.Row as={Col}>
                                  <Form.Group as={Col}>
                                    <legend className="modal-header-consultar-pagos">Notificaciones</legend>

                                    {/*<div style={{margin: "0px 0px 10px 0px"}}>
                                      <Form.Check
                                        type="checkbox"
                                        label="Notificar a usuario seleccionado"
                                        checked={props.children.notificarPagador}
                                        disabled
                                      />
                                    </div>*/}

                                    <Form.Label>Email del pagador</Form.Label>
                                    <Form.Control size="sm" id="pagador" type="text" disabled required value={props.children.pagadorEmail}>
                                    </Form.Control>
                                  </Form.Group>

                                  <Form.Group as={Col}></Form.Group> {contactos.length>0 ?(

                                  <Form.Group>
                                    <legend className="legend-crear-req"> Se esta compartiendo con los siguientes usuarios:</legend>

                                    <Table striped bordered hover>
                                      <thead>
                                        <tr>
                                          <th><b>Nombre de usuario</b></th>
                                          <th><b>Email</b></th>
                                        </tr>
                                      </thead>             
                                      <tbody> {contactos.map(elemento => (
                                        <tr key={elemento.usuarioId}>
                                        <td>{elemento.usuarioNombre}</td>
                                        <td>{elemento.usuarioEmail}</td>
                                        </tr>
                                      ))}</tbody>

                                    </Table>
                                    
                                  </Form.Group>):"" } 
                                </Form.Row>
                            </Form.Group>





                            <Form.Group as={Col} md={12} lg={6}>
                                <fieldset >
                                    <legend className="legend-consultar-req ">Requisición</legend>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col}>
                                            <Form.Label>Solicitante</Form.Label>
                                            <Form.Control size="sm" type="text" disabled defaultValue={props.children.solicitante }></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Fecha de creación</Form.Label>
                                            <Form.Control size="sm"
                                                type="text"
                                                disabled
                                                defaultValue={Moment(props.children.fechaCreacion).format('DD/MM/YYYY')}></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col} lg={12}>
                                            <Form.Label>Fecha requerida de pago</Form.Label>
                                            <Form.Control size="sm"
                                                type="text"
                                                disabled
                                                defaultValue={Moment(props.children.fechaRequeridaPago).format('DD/MM/YYYY')}></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col} lg={12}>
                                            <Form.Label>Empresa</Form.Label>
                                            <Form.Control size="sm" id="empresa" type="text" disabled defaultValue={props.children.empresa} />
                                        </Form.Group>
                                        <Form.Group as={Col} lg={12}>
                                            <Form.Label>Concepto general</Form.Label>
                                            <Form.Control size="sm" as="textarea" rows={3} disabled defaultValue={props.children.concepto}></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col} lg={12}>
                                        <Accordion.Toggle style={{ cursor: 'pointer' }}   as={Card.Header}  eventKey="1" className="legend-crear-prov">
                                            Impuestos{!taxesChecked?<ExpandMore />:<ExpandLess />} 
                                            </Accordion.Toggle> 
                                            <Form.Label hidden={!taxesChecked}>Método de pago</Form.Label>
                                            <Form.Control hidden={!taxesChecked}  size="sm" id="metodoPago" type="text" disabled defaultValue={props.children.metodoPagoClaveSat + ' - ' + props.children.metodoPagoSat} />
                                        </Form.Group>
                                        <Form.Group  hidden={!taxesChecked}as={Col} lg={12}>
                                            <Form.Label>Forma de pago</Form.Label>
                                            <Form.Control size="sm" id="formaPago" type="text" disabled defaultValue={props.children.formaPagoClaveSat + " - " + props.children.formaPagoSat} />
                                            
                                        </Form.Group>
                                    
                                        <Form.Row as={Col}>
                                    
                                    <Form.Group as={Col}>
                                           
                                            <Form.Check
                                                type="checkbox"
                                              
                                                checked={taxesChecked}
                                                 hidden={true} />
                                        </Form.Group>
                                    </Form.Row>
                                    </Form.Row>
                                    <Form.Row as={Col}>
                                    <Form.Group as={Col} lg={5}>
                                            <Form.Label>Moneda</Form.Label>
                                            <Form.Control size="sm" id="moneda" type="text" disabled defaultValue={props.children.monedaClaveSat + " - " + props.children.monedaSat + " Presición - " + props.children.monedaDecimales} />
                                        </Form.Group>
                                        <Form.Group hidden = {props.children.monedaClaveSat==="MXN" ? true:false} as={Col} lg={4}>
                                            <Form.Label hidden = {props.children.monedaClaveSat==="MXN" ? true:false}>Paridad</Form.Label>
                                            <InputGroup hidden = {props.children.monedaClaveSat==="MXN" ? true:false} size="sm">
                                                <InputGroup.Prepend >
                                                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control size="sm" id="paridad" type="number" className="input-signs-align" disabled defaultValue={props.children.paridad}></Form.Control>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group as={Col} lg={7}>
                                            <Form.Label>Importe</Form.Label>
                                            <InputGroup size="sm">
                                                <InputGroup.Prepend >
                                                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <NumberFormat id="importe" className="input-signs-align form-control form-control-sm" thousandSeparator={true} disabled defaultValue={props.children.importe.toFixed(monedaDecimales)}></NumberFormat>
                                            </InputGroup>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col}>
                                            <Form.Label hidden={!taxesChecked}>IVA Trasladado</Form.Label>
                                            <Form.Row hidden={!taxesChecked}>
                                                <Col>
                                                    <InputGroup size="sm">
                                                        <NumberFormat id="ivaTrasladadoTasaCuota" className="input-signs-align form-control form-control-sm" disabled defaultValue={props.children.ivaTrasladadoTasaCuota}></NumberFormat>
                                                        <InputGroup.Append >
                                                            <InputGroup.Text className="input-signs-right">%</InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </Col>
                                                <Col>
                                                    <InputGroup size="sm">
                                                        <InputGroup.Prepend >
                                                            <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <NumberFormat id="ivaTrasladadoImporte"
                                                            className="input-signs-align form-control form-control-sm"
                                                            disabled thousandSeparator={true}
                                                            defaultValue={props.children.ivaTrasladadoImporte.toFixed(monedaDecimales)}>
                                                        </NumberFormat>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Row>
                                            <Form.Label hidden={!taxesChecked}>IVA Retenido</Form.Label>
                                            <Form.Row hidden={!taxesChecked}>
                                                <Col>
                                                    <InputGroup size="sm">
                                                        <NumberFormat
                                                            id="ivaRetenido"
                                                            className="input-signs-align form-control form-control-sm"
                                                            thousandSeparator={true} disabled
                                                            defaultValue={props.children.ivaRetenidoTasaCuota}>
                                                        </NumberFormat>
                                                        <InputGroup.Append >
                                                            <InputGroup.Text className="input-signs-right">%</InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </Col>
                                                <Col>
                                                    <InputGroup size="sm">
                                                        <InputGroup.Prepend >
                                                            <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <NumberFormat
                                                            id="ivaRetenidoImporte"
                                                            className="input-signs-align form-control form-control-sm"
                                                            thousandSeparator={true} disabled
                                                            defaultValue={props.children.ivaRetenidoImporte.toFixed(monedaDecimales)}>
                                                        </NumberFormat>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Row>
                                            {/*<Form.Text muted>Debe ser entre 0.0 y 16.0</Form.Text>*/}
                                            <Form.Label hidden={!taxesChecked}>Porcentaje ISR</Form.Label>
                                            <Form.Row hidden={!taxesChecked}>
                                                <Col>
                                                    <InputGroup size="sm">
                                                        <NumberFormat
                                                            id="isr"
                                                            className="input-signs-align form-control form-control-sm"
                                                            thousandSeparator={true} disabled
                                                            defaultValue={props.children.isrTasaImporte}
                                                        >
                                                        </NumberFormat>
                                                        <InputGroup.Append >
                                                            <InputGroup.Text className="input-signs-right">%</InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </Col>
                                                <Col>
                                                    <InputGroup  hidden={!taxesChecked} size="sm">
                                                        <InputGroup.Prepend >
                                                            <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <NumberFormat
                                                            id="isrImporte"
                                                            className="input-signs-align form-control form-control-sm"
                                                            thousandSeparator={true} disabled
                                                            defaultValue={props.children.isrImporte.toFixed(monedaDecimales)}>
                                                        </NumberFormat>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Row>
                                            {/*<Form.Text muted>Debe ser entre 0.0 y 35.0</Form.Text>*/}
                                            <Form.Label hidden={!taxesChecked}>Más otros impuestos</Form.Label>
                                            <Form.Row hidden={!taxesChecked}>
                                                <Col>
                                                    <InputGroup size="sm">
                                                        <InputGroup.Append >
                                                            <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                        </InputGroup.Append>
                                                        <NumberFormat id="otrosImpuestos" className="input-signs-align form-control form-control-sm" disabled thousandSeparator={true} defaultValue={props.children.otrosImpuestosImporte.toFixed(monedaDecimales)}></NumberFormat>
                                                    </InputGroup>
                                                </Col>
                                                <Col>
                                                    <InputGroup  hidden={!taxesChecked} size="sm">
                                                        <InputGroup.Prepend >
                                                            <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <NumberFormat id="otrosImpuestosImporte" className="input-signs-align form-control form-control-sm" thousandSeparator={true} disabled defaultValue={props.children.otrosImpuestosImporte.toFixed(monedaDecimales)}></NumberFormat>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Row>
                                            <Form.Label hidden={!taxesChecked}>Descuento</Form.Label>
                                            <Form.Row  hidden={!taxesChecked}>
                                                <Col>
                                                    <InputGroup size="sm">
                                                        <NumberFormat
                                                            id="descuento"
                                                            className="input-signs-align form-control form-control-sm"
                                                            thousandSeparator={true} disabled
                                                            defaultValue={props.children.descuentoPorcentaje}>
                                                        </NumberFormat>
                                                        <InputGroup.Append >
                                                            <InputGroup.Text className="input-signs-right">%</InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </Col>
                                                <Col>
                                                    <InputGroup size="sm">
                                                        <InputGroup.Prepend >
                                                            <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <NumberFormat
                                                            id="descuentoImporte"
                                                            className="input-signs-align form-control form-control-sm"
                                                            thousandSeparator={true} disabled
                                                            defaultValue={props.children.descuentoImporte.toFixed(monedaDecimales)}>
                                                        </NumberFormat>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Row>
                                            <Form.Label  hidden={!taxesChecked} >Subtotal</Form.Label>
                                            <InputGroup  hidden={!taxesChecked} size="sm">
                                                <InputGroup.Prepend >
                                                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <NumberFormat
                                                    id="subtotal"
                                                    className="input-signs-align form-control form-control-sm"
                                                    disabled thousandSeparator={true}
                                                    defaultValue={props.children.subtotal.toFixed(monedaDecimales)}
                                                />
                                            </InputGroup>
                                            <Form.Label  hidden={!taxesChecked} >Subtotal con descuento</Form.Label>
                                            <InputGroup  hidden={!taxesChecked} size="sm">
                                                <InputGroup.Prepend >
                                                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <NumberFormat
                                                    id="subtotalConDescuento"
                                                    className="input-signs-align form-control form-control-sm"
                                                    disabled thousandSeparator={true}
                                                    defaultValue={props.children.subtotalDescuento.toFixed(monedaDecimales)}>
                                                </NumberFormat>
                                            </InputGroup>
                                            <Form.Label  hidden={!taxesChecked} >Total con impuestos</Form.Label>
                                            <InputGroup   hidden={!taxesChecked} size="sm">
                                                <InputGroup.Prepend >
                                                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <NumberFormat
                                                    id="totalConImpuestos"
                                                    className="input-signs-align form-control form-control-sm"
                                                    thousandSeparator={true} disabled
                                                    defaultValue={props.children.total.toFixed(monedaDecimales)}>
                                                </NumberFormat>
                                            </InputGroup>
                                            {props.children.monedaClaveSat !== 'MXN' ? createInputTotalPesosMexicanos() : ''}
                                        </Form.Group>
                                    </Form.Row>
                                </fieldset>
                            </Form.Group>
                        </Form.Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </section>
    );
}

export default ModalConsultarRequisicion;