import React from "react";
import axios from "axios";
import { useState } from "react";
import { useSelector } from 'react-redux';
import { API_PROVEEDORES_URL } from "../../constants";

//Estilos
import './css/ProveedoresCatalogo.css'
import { FileEarmarkPerson } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';

//Componentes bootstrap
import { Container, Col, Form, Modal, Accordion, Card, Row } from "react-bootstrap";
//import SeleccionarEmpresa from '../Requisiciones/SeleccionarEmpresa';

//Componentes
import NumberFormat from 'react-number-format';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import { ExpandMore } from "@material-ui/icons";
import InputGroup from 'react-bootstrap/InputGroup';
import { faMagnifyingGlass, faRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ModalCrearProveedor(props) {
    const [deactivate, setDeactivate] = useState(false);
    const [status, setStatus] = useState(false);
    const [request] = useState(false);
    const empresasDisponibles = useSelector(state => state.empresas.empresasDisponibles);
    /*const [empresa, setEmpresa] = useState('');
    const [idEmpresa, setIdEmpresa] = useState('');
    const [idProveedor, setIdProveedor] = useState('');*/
    const [proveedorRfc, setProveedorRfc] = useState('');
    const [razonSocial, setRazonSocial] = useState('');
    const [nombreComercial, setNombreComercial] = useState('');
    const [correoElectronico, setCorreoElectronico] = useState('');
    const [telefono, setTelefono] = useState('');
    const [giro, setGiro] = useState('');
    /** datos de direcciones */
    const [tipo, setTipo] = useState('');
    const [calle, setCalle] = useState('');
    const [numeroExterior, setNumeroExterior] = useState('');
    const [numeroInterior, setNumeroInterior] = useState('');
    const [colonia, setColonia] = useState('');
    const [ciudad, setCiudad] = useState('');
    const [codigoPostal, setCodigoPostal] = useState('');
    const [municipio, setMunicipio] = useState('');
    const [estado, setEstado] = useState('');
    const [pais, setPais] = useState('');

    const [banco, setBanco] = useState('');
    const [comentario, setComentario] = useState('');
    const [cuenta, setCuenta] = useState('');
    const [cuentaClabe, setCuentaClabe] = useState('');
    const [referencia, setReferencia] = useState('');

    const [idEmpresasSeleccionadas, setIdEmpresasSeleccionadas] = useState([]); //Ids seleccionados
    const [empresasSeleccionadas, setEmpresasSeleccionadas] = useState([]); //empresa seleccionados
    const errorEmpresas = false;


    const [errorCPMessage, setErrorCPMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [idProveedor, setIdProveedor] = useState('');
    const [isFiscal, setIsFiscal] = useState(false);
    const [isSucursal, setIsSucursal] = useState(false);
    const [isBodega, setIsBodega] = useState(false);
    const [colonias, setColonias] = useState([]);
    const [isSearch, setIsSearch] = useState(false);
    const [active, setActive] = useState(false);
    const [dataBank, setDataBank] = useState([]);
    const [returnToActivate, setReturnToActivate] = useState(false);
    const [empresa, setEmpresa] = useState([]);
    /*function empresaSelected(empresa) {
        var str = empresa;
        var array = str.split(",");
        setEmpresa(array[0]);
        setIdEmpresa(array[1]);
    }*/

    let handleSizePerPage = ({ page, onSizePerPageChange }, newSizePerPage) => {
        onSizePerPageChange(newSizePerPage, page);
    }

    function handleProveedorRfcChange(e) {
        setProveedorRfc(e.target.value);
    }
    function handleNombreComercialChange(e) {
        setNombreComercial(e.target.value);
    }
    function handleRazonSocialChange(e) {
        setRazonSocial(e.target.value);
    }
    function handleCorreoElectronicoChange(e) {
        setCorreoElectronico(e.target.value);
    }
    function handleTelefonoChange(e) {
        setTelefono(e.target.value);
    }
    function handleGiroChange(e) {
        setGiro(e.target.value);
    }
    function handleBancoChange(e) {
        setBanco(e.target.value);
    }
    function handleComentarioChange(e) {
        setComentario(e.target.value);
    }
    function handleCuentaChange(e) {
        setCuenta(e.target.value);
    }
    function handleCuentaClabeChange(e) {
        setCuentaClabe(e.target.value);
    }
    function handleReferenciaChange(e) {
        setReferencia(e.target.value);
    }
    function handleTipoChange(e) {
        setTipo(e.target.value);
    }
    function handleCalleChange(e) {
        setCalle(e.target.value);
    }
    function handleNumeroExteriorChange(e) {
        setNumeroExterior(e.target.value);
    }
    function handleNumeroInteriorChange(e) {
        setNumeroInterior(e.target.value);
    }
    function handlerColoniaChange(e) {
        setColonia(e.target.value);
    }
    function handleCiudadChange(e) {
        setCiudad(e.target.value);
    }
    function handleCodigoPostalChange(e) {
        setCodigoPostal(e.target.value);
    }
    function handleMunicipioChange(e) {
        setMunicipio(e.target.value);
    }
    function handleEstadoChange(e) {
        setEstado(e.target.value);
    }
    function handlePaisChange(e) {
        setPais(e.target.value);
    }

    function handleKeyPressRfc(e){
        if(e.key === 'Enter'){
            searchSupplier();
        }
    }

    function enterpriseSelected(empresaSelected){
        toast.warning('La empresa "' + empresaSelected + '" ya cuenta con este proveedor dado de alta, selecccione otro por favor', {
            position: "top-center",
            autoClose: 5000,
        })
    }

    function enterpriseValidation(empresaSelected) {
        let repeated = false;
        for(let i = 0; i<empresa.length; i++){
            if(empresa[i] === empresaSelected){
                repeated = true;
                break;
            }
        }

        return repeated;
    }

    let mostrarListEmpresas = () => {
        if (errorEmpresas) {
            return (
                <div>Error: No se han podido cargar las empresas disponibles</div>
            );
        } else {
            const columns = [
                {
                    dataField: 'empresa',
                    //text: 'EMPRESA',
                    filter: textFilter({
                        placeholder: 'Buscar empresa...',
                        className: "form-control-sm"
                    })
                }
            ];

            const selectRow = {
                mode: "checkbox",
                clickToSelect: true,
                style: { backgroundColor: 'rgb(29, 181, 113)' },
                selected: idEmpresasSeleccionadas,
                onSelect: (row, isSelect, rowIndex, e) => {
                    if(isSelect && enterpriseValidation(row.empresa)){
                        isSelect = false;
                        enterpriseSelected(row.empresa);
                    }

                    if (isSelect) {
                        setIdEmpresasSeleccionadas([...idEmpresasSeleccionadas, row.empresaId]);
                        setEmpresasSeleccionadas([...empresasSeleccionadas, row]);

                    } else {
                        let eliminarIdEmpresaSeleccionada = idEmpresasSeleccionadas.filter(x => x !== row.empresaId);
                        setIdEmpresasSeleccionadas(eliminarIdEmpresaSeleccionada);
                        let eliminarEmpresaSeleccionada = empresasSeleccionadas.filter(x => x !== row);
                        setEmpresasSeleccionadas(eliminarEmpresaSeleccionada);
                    }
                },
                onSelectAll: (isSelect, rows, e) => {
                    const ids = rows.map(row => row.empresaId);
                    const empresasS = rows.map(row => row);
                    if (isSelect) {
                        setIdEmpresasSeleccionadas(ids);
                        setEmpresasSeleccionadas(empresasS);
                    } else {
                        setIdEmpresasSeleccionadas([]);
                        setEmpresasSeleccionadas([]);
                    }
                },
            };

            function indication() {
                return empresasDisponibles.length ? "La búsqueda no coincide con ningún criterio" : "No hay registros que mostrar";
            }

            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size}
                </span>
            );

            const options = {
                custom: true,
                sizePerPage: 5,
                totalSize: empresasDisponibles.length,
                paginationTotalRenderer: customTotal
            }

            return <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div>
                        <Form.Row className="p-2">
                            {<div className="d-flex flex-row">
                                <Form.Label column>Mostrar</Form.Label>
                                <Col>
                                    <Form.Control size="sm" as="select" onChange={(e) => handleSizePerPage(paginationProps, e.target.value)}>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value={empresasDisponibles.length}>Todos</option>
                                    </Form.Control>
                                </Col>
                            </div>}
                            <div className="flex-row">
                                <Col>
                                    <PaginationTotalStandalone {...paginationProps} />
                                </Col>
                            </div>
                            <div className="d-flex ml-auto flex-row-reverse">
                                <Col>
                                    <PaginationListStandalone {...paginationProps} />
                                </Col>
                                <Form.Label column>Página</Form.Label>
                            </div>
                        </Form.Row>
                        <div className="table-responsive table-sm">
                            <BootstrapTable
                                id="id"
                                keyField="empresaId"
                                data={empresasDisponibles}
                                columns={columns}
                                selectRow={selectRow}
                                filter={filterFactory()}
                                bordered={false}
                                striped
                                hover
                                condensed
                                noDataIndication={indication}
                                {...paginationTableProps}
                            />
                        </div>
                    </div>
                )}
            </PaginationProvider>
        }
    }

    function changeToSelect() {
        setReturnToActivate(false);
        setActive(true);
    }

    function removeDataField() {
        setIdProveedor('');
        setRazonSocial('');
        setNombreComercial('');
        setGiro('');
        setCorreoElectronico('');
        setTelefono('');

        setIsFiscal(false);
        setIsSucursal(false);
        setIsBodega(false);
        setTipo('');
        setCalle('');
        setNumeroExterior('');
        setNumeroInterior('');
        setColonia('');
        setCiudad('');
        setCodigoPostal('');
        setMunicipio('');
        setEstado('');
        setPais('');

        setActive(false);
        setBanco('');
        setComentario('');
        setCuenta('');
        setCuentaClabe('');
        setReferencia('');
    }

    function handleKeyPress(e) {
        if(e.key === 'Enter'){
            searchCP();
        }
    }

    function searchCP() {
        axios.post("https://dev.hixsa.net/API-DIRECCIONES/api/direccion", {
            codigoPostal: codigoPostal,
        }).then(res => {
            if ((res.data.respuesta.estados).length !== 0) {
                console.log(res.data.respuesta);

                console.log('-->');
                console.log(res.data.respuesta.estados[0].descripcion);

                setErrorCPMessage('');
                setIsSearch(true);
                setCalle(calle);
                setNumeroExterior(numeroExterior);
                setNumeroInterior(numeroInterior);
                setCodigoPostal(codigoPostal);

                setCiudad(res.data.respuesta.estados[0].descripcion);
                setMunicipio(res.data.respuesta.municipios[0].descripcion);
                setColonias(res.data.respuesta.colonias);
                setEstado(res.data.respuesta.estados[0].descripcion);
                setPais('México');
            } else {
                setErrorCPMessage('CP sin registros');
            }
            console.log(res);
        }).catch(err => {
            setErrorCPMessage('CP sin registros');
        }).finally(() => {
            console.log('se acabo');
        })
    }

    function deleteDataBankFields() {
        setComentario('');
        setCuenta('');
        setCuentaClabe('');
        setReferencia('');
    }

    function setDataAccount(e) {
        if (e.target.value !== '-1') {
            if (e.target.value == "otro") {
                setActive(false);
                setReturnToActivate(true);

                deleteDataBankFields();
            }
            else {
                let ind = parseInt(e.target.value);
                let index = dataBank.findIndex(x => x.id === ind);
                console.log(dataBank[index]);

                setBanco(dataBank[index].banco);
                setComentario(dataBank[index].comentario);
                setCuenta(dataBank[index].cuenta);
                setCuentaClabe(dataBank[index].cuentaClabe);
                setReferencia(dataBank[index].referencia);
            }
        } else {
            deleteDataBankFields();
        }
    }

    function searchDataBankSupplier(proveedorId) {
        axios.post(API_PROVEEDORES_URL + 'listBancoCuentas', {
            idProveedor: proveedorId
        }).then((res) => {
            if (res.data.result.code === 200) {
                setDataBank(res.data.cuentasBancarias[0].lista);
                setActive(true);
            } else {
                setErrorMessage('El proveedor no cuenta con datos bancarios');
            }
        }).catch((error) => {
            setErrorMessage('Error en el servidor, intente mas tarde');
        }).finally(() => {
            console.log('Fin de busqueda de datos bancarios del RFC proveedor');
        })
    }
    function searchAddressSupplier(proveedorId) {
        axios.post(API_PROVEEDORES_URL + 'listDirecciones', {
            idProveedor: proveedorId
        }).then((res) => {
            if (res.data.result.code === 200) {
                if (res.data.listDireccion[0].tipo == 'Fiscal') { setIsFiscal(true) }
                if (res.data.listDireccion[0].tipo == 'Sucursal') { setIsSucursal(true) }
                if (res.data.listDireccion[0].tipo == 'Bodega') { setIsBodega(true) }

                setCalle(res.data.listDireccion[0].calle);
                setNumeroExterior(res.data.listDireccion[0].numeroExterior);
                setNumeroInterior(res.data.listDireccion[0].numeroInterior);
                setColonia(res.data.listDireccion[0].colonia);
                setCiudad(res.data.listDireccion[0].ciudad);
                setCodigoPostal(res.data.listDireccion[0].codigoPostal);
                setMunicipio(res.data.listDireccion[0].municipio);
                setEstado(res.data.listDireccion[0].estado);
                setPais(res.data.listDireccion[0].pais);
            } else {
                setErrorMessage('El proveedor no cuenta con datos datos de dirección');
            }
        }).catch((error) => {
            setErrorMessage('Error en el servidor, intente mas tarde');
        }).finally(() => {
            searchDataBankSupplier(proveedorId);
            console.log('Fin de busqueda de direccion de proveedor por RFC');
        })
    }



    function setArrayEmpresas(supplierList){
        const aux = [];

        for(let i = 0; i<supplierList.length; i++){
            aux.push(supplierList[i].empresa);
        }
        setEmpresa(aux);
    }

    function searchSupplier() {
        axios.post(API_PROVEEDORES_URL + 'getProveedorByRfc', {
            rfc: proveedorRfc
        }).then((res) => {
            if (res.data.result.code === 200) {
                setArrayEmpresas(res.data.listProvId);
                console.log(res.data.listProvId)

                //console.log(empresa);
                //setEmpresa(res.data.listProvId[0].empresa);

                setIdProveedor(res.data.listProvId[0].hashMapCuentaBancaria.idProveedor);
                setRazonSocial(res.data.listProvId[0].razonSocial);
                setNombreComercial(res.data.listProvId[0].nombreComercial);
                setGiro(res.data.listProvId[0].hashMapCuentaBancaria.giro);
                setCorreoElectronico(res.data.listProvId[0].hashMapCuentaBancaria.correoElectronico);
                setTelefono(res.data.listProvId[0].hashMapCuentaBancaria.telefono);

                setErrorMessage('');
                searchAddressSupplier(res.data.listProvId[0].hashMapCuentaBancaria.idProveedor);
            } else {
                removeDataField();
                setErrorMessage('Sin coincidencias');
            }
        }).catch((error) => {
            console.log('Problema en servidor intente');
            setErrorMessage('Error en el servidor, intente mas tarde');
        }).finally(() => {
            console.log('Fin de Busqueda de proveedor por RFC');
        })
    }

    function validateBankDetails() {
        if (banco === '') {
            setDeactivate(true);
            setTimeout(() => {
                setDeactivate(false);
            }, 4500);

            toast.error('El campo "Banco" es obligatorio, por favor ingrese la información correspondiente antes de registrar', {
                position: "top-center",
                autoClose: 4500,
            });
        }

        else if (banco !== '') {
            if (cuenta !== '' || cuentaClabe !== '') {
                insertProveedor();
            }
            else if (cuenta === '') {
                setDeactivate(true);
                setTimeout(() => {
                    setDeactivate(false);
                }, 5000);

                toast.error('Para poder registrar al proveedor, debe completar el campo "Cuenta" o "Cuenta Clabe" de los Datos Bancarios', {
                    position: "top-center",
                    autoClose: 5000,
                });
            } 
        }
    }

    function insertProveedor() {
        //e.preventDefault();
        setStatus(true);

        if (empresasSeleccionadas.length && proveedorRfc.length && razonSocial.length) {

            axios.post(API_PROVEEDORES_URL + 'insertProveedor',
                {
                    empresa: empresasSeleccionadas,//proveedor.empresa,
                    idEmpresa: idEmpresasSeleccionadas,//proveedor.idEmpresa,
                    nombreComercial: nombreComercial,
                    razonSocial: razonSocial,
                    proveedorRfc: proveedorRfc,
                    correoElectronico: correoElectronico,
                    telefono: telefono,
                    giro: giro,
                    banco: banco,
                    cuenta: cuenta,
                    cuentaClabe: cuentaClabe,
                    comentario: comentario,
                    referencia: referencia,
                    tipo: tipo,
                    calle: calle,
                    numeroExterior: numeroExterior,
                    numeroInterior: numeroInterior,
                    colonia: colonia,
                    ciudad: ciudad,
                    codigoPostal: codigoPostal,
                    municipio: municipio,
                    estado: estado,
                    pais: pais
                }
            ).then((res) => {
                if (res.data.result.success) {
                    setStatus(false);
                    toast.success(res.data.result.message, {
                        position: "top-center",
                        autoCerrar: 4500,
                    });
                    props.onHide();
                    props.onUpdate();
                } else {
                    let output = [];
                    setStatus(false);
                    toast.error(res.data.result.message, {
                        position: "top-center",
                        autoClose: 4500,
                    });
                    toast.warning(res.data.proveedor.suggestion, {
                        position: "top-center",
                        autoClose: 4500,
                    });

                    props.onUpdate();//Actualiza el listado, llamando a la función padre para actualizarlo                
                    output.push('<strong>', "Empresas correctas: " + res.data.correctos, '</strong>');
                    output.push('<strong>', "Empresas incorrectas: " + res.data.errores, '</strong>');
                    res.data.registros_con_errores.forEach(incorrecto => {
                        output.push('<li><strong>', incorrecto.message, '</strong> (', incorrecto.suggestion, ')');
                    });
                    document.getElementById("listResponse").innerHTML = '<ol>' + output.join('') + '</ol>';
                }
            }).catch((error) => {
                toast.error("No se ha podido registrar el proveedor", {
                    position: "top-center",
                    autoClose: 4500,
                });
                setStatus(false);
            });
        } else {
            setDeactivate(true);

            setTimeout(() => {
                setDeactivate(false);
            }, 4500);

            !empresasSeleccionadas.length ?
                toast.warning("Debes seleccionar al menos una empresa", {
                    position: "top-center",
                    autoClose: 4500,
                })
                : !proveedorRfc.length ?
                    toast.warning("El campo 'RFC' no puede estar vacío", {
                        position: "top-center",
                        autoClose: 4500,
                    })
                    :
                    toast.warning("El campo 'Razón social' no puede estar vacío", {
                        position: "top-center",
                        autoClose: 4500,
                    })
            setStatus(false);
        }
    }

    return (
        <section className="text-center">
            <Modal
                show={props.show}
                //onHide={props.onHide}
                size="xl"
                id="modalCrearProveedor"
                centered
                scrollable={true}
            >
                <Modal.Header className="modal-header-crear-prov" closeButton onHide={props.onHide}>
                    <h5 className="legend-crear-prov"><FileEarmarkPerson size={22} color="#5B4B51" />   Registrar nuevo proveedor</h5>
                </Modal.Header>
                <Modal.Body className="modal-body-proveedor">
                    <Container className="container-modals">
                        <div className="p-2">
                            <output id="listResponse"></output>
                        </div>
                        <Form.Row as={Col}>
                            <Form.Group as={Col} md={6} lg={6}>
                                <fieldset>
                                    <legend className="legend-crear-prov">Información general</legend>
                                    <Form.Row as={Col}>
                                        <Form.Group></Form.Group><br />
                                        {/*<SeleccionarEmpresa onEmpresaSelected={empresaSelected}>{empresa}</SeleccionarEmpresa>*/}
                                        {<Form.Group as={Col}>
                                            {request ? "Cargando..." : mostrarListEmpresas()}
                                        </Form.Group>}
                                    </Form.Row>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col}>
                                            <Form.Label>RFC</Form.Label>
                                            <InputGroup size="sm">
                                                <Form.Control type="text" required
                                                    className="form-control form-control-sm"
                                                    placeholder="Ingrese rfc"
                                                    onChange={handleProveedorRfcChange}
                                                    onKeyPress = {handleKeyPressRfc}
                                                ></Form.Control>
                                                <button
                                                    className="m-1 button-default-subir-archivo"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title="Buscar"
                                                    onClick={searchSupplier}
                                                >
                                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                                </button>
                                            </InputGroup>
                                            {
                                                errorMessage.length > 0 ?
                                                    <p>{errorMessage}</p> : ''
                                            }
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col}>
                                            <Form.Label>Identificador</Form.Label>
                                            <Form.Control type="text" disabled
                                                className="form-control form-control-sm"
                                                placeholder="Este campo se generará en automático"
                                            ></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Razón social</Form.Label>
                                            <Form.Control type="text"
                                                required className="form-control form-control-sm"
                                                placeholder="Ingrese razón social"
                                                value={razonSocial.length != 0 ? razonSocial : ''}
                                                onChange={handleRazonSocialChange}>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col}>
                                            <Form.Label>Nombre comercial</Form.Label>
                                            <Form.Control type="text" required
                                                className="form-control form-control-sm"
                                                placeholder="Ingrese nombre comercial"
                                                value={nombreComercial.length != 0 ? nombreComercial : ''}
                                                onChange={handleNombreComercialChange}>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Giro</Form.Label>
                                            <Form.Control type="text"
                                                className="form-control form-control-sm"
                                                placeholder="Ingrese giro"
                                                value={giro.length != 0 ? giro : ''}
                                                onChange={handleGiroChange}>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col}>
                                            <Form.Label>Correo electrónico</Form.Label>
                                            <Form.Control type="text" required
                                                className="form-control form-control-sm"
                                                placeholder="Ingrese correo electrónico"
                                                value={correoElectronico.length != 0 ? correoElectronico : ''}
                                                onChange={handleCorreoElectronicoChange}>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Teléfono</Form.Label>
                                            <Form.Control type="text" required
                                                className="form-control form-control-sm"
                                                placeholder="Ingrese teléfono"
                                                value={telefono.length != 0 ? telefono : ''}
                                                onChange={handleTelefonoChange}></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                </fieldset>
                            </Form.Group>
                            <Form.Group as={Col} md={6} lg={6}>
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Toggle as={Card.Header} eventKey="0" className="legend-crear-prov">
                                        Dirección <ExpandMore />
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Form.Group md={6} lg={12}>
                                            <fieldset>
                                                {/*<legend className="legend-crear-prov">Dirección</legend>*/}
                                                <Form.Row as={Col}>
                                                    <Form.Group as={Col}>
                                                        <Form.Label>Tipo de dirección</Form.Label>
                                                        <Form.Control as="select"
                                                            placeholder="Elige un tipo de dirección"
                                                            className="form-control form-control-sm"
                                                            onChange={handleTipoChange}>
                                                            <option default>Elige una opción</option>
                                                            <option value="Fiscal" selected={isFiscal}>Dirección fiscal</option>
                                                            <option value="Sucursal" selected={isSucursal}>Dirección de Sucursal</option>
                                                            <option value="Bodega" selected={isBodega}>Dirección de Bodega</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group as={Col}>
                                                        <Form.Label>Calle</Form.Label>
                                                        <Form.Control type="text" required
                                                            placeholder="Ingrese calle"
                                                            className="form-control form-control-sm"
                                                            value={calle.length != 0 ? calle : ''}
                                                            onChange={handleCalleChange}>
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row as={Col}>
                                                    <Form.Group as={Col}>
                                                        <Form.Label>No. Exterior</Form.Label>
                                                        <Form.Control type="text" required
                                                            placeholder="Ingrese no. ext."
                                                            className="form-control form-control-sm"
                                                            value={numeroExterior.length != 0 ? numeroExterior : ''}
                                                            onChange={handleNumeroExteriorChange}>
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group as={Col}>
                                                        <Form.Label>No. Interior</Form.Label>
                                                        <Form.Control type="text" required
                                                            placeholder="Ingrese no. int."
                                                            className="form-control form-control-sm"
                                                            value={numeroInterior.length != 0 ? numeroInterior : ''}
                                                            onChange={handleNumeroInteriorChange}>
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row as={Col} >
                                                    <Form.Group as={Col}>
                                                        <Form.Label>Código postal</Form.Label>
                                                        <InputGroup size="sm">
                                                            <Form.Control type="text" required
                                                                placeholder="Ingrese cp"
                                                                className="form-control form-control-sm"
                                                                value={codigoPostal.length != 0 ? codigoPostal : ''}
                                                                onKeyPress = { handleKeyPress }
                                                                onChange={handleCodigoPostalChange}>
                                                            </Form.Control>

                                                            <button
                                                                className="m-1 button-default-subir-archivo"
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="Buscar"
                                                                onClick={searchCP}
                                                            >
                                                                <FontAwesomeIcon icon={faMagnifyingGlass} />
                                                            </button>
                                                        </InputGroup>
                                                        {
                                                            errorCPMessage.length > 0 ?
                                                                <p>{errorCPMessage}</p> : ''
                                                        }
                                                    </Form.Group>

                                                    <Form.Group as={Col}>
                                                        <Form.Label>Ciudad</Form.Label>
                                                        <Form.Control type="text" required
                                                            placeholder="Ingrese ciudad"
                                                            className="form-control form-control-sm"
                                                            value={ciudad.length != 0 ? ciudad : ''}
                                                            onChange={handleCiudadChange}>
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row as={Col}>
                                                    <Form.Group as={Col}>
                                                        <Form.Label>Municipio</Form.Label>
                                                        <Form.Control type="text" required
                                                            placeholder="Ingrese municipio"
                                                            className="form-control form-control-sm"
                                                            value={municipio.length != 0 ? municipio : ''}
                                                            onChange={handleMunicipioChange}>
                                                        </Form.Control>
                                                    </Form.Group>

                                                    <Form.Group as={Col}>
                                                        <Form.Label>Colonia</Form.Label>

                                                        {
                                                            !isSearch ? <Form.Control type="text" required
                                                                placeholder="Ingrese colonia"
                                                                className="form-control form-control-sm"
                                                                value={colonia.length != 0 ? colonia : ''}
                                                                onChange={handlerColoniaChange}>
                                                            </Form.Control>
                                                                :
                                                                <Form.Control as="select"
                                                                    placeholder="Elige la colonia"
                                                                    className="form-control form-control-sm"
                                                                    onChange={handlerColoniaChange}>
                                                                    <option default value={-1}>Elige una opción</option>
                                                                    {colonias.map(el => (
                                                                        <option key={el.clave} value={el.descripcion}>{el.descripcion}</option>
                                                                    ))}
                                                                </Form.Control>
                                                        }
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row as={Col}>
                                                    <Form.Group as={Col}>
                                                        <Form.Label>Estado</Form.Label>
                                                        <Form.Control type="text" required
                                                            placeholder="Ingrese estado"
                                                            className="form-control form-control-sm"
                                                            value={estado.length != 0 ? estado : ''}
                                                            onChange={handleEstadoChange}>
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group as={Col}>
                                                        <Form.Label>País</Form.Label>
                                                        <Form.Control type="text" required
                                                            placeholder="Ingrese país"
                                                            className="form-control form-control-sm"
                                                            value={pais.length != 0 ? pais : ''}
                                                            onChange={handlePaisChange}>
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Form.Row>
                                            </fieldset>
                                        </Form.Group>
                                    </Accordion.Collapse>
                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="legend-crear-prov">
                                        Datos bancarios <ExpandMore />
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="1">
                                        <Form.Row >
                                            <Form.Group as={Col} md={6} lg={12}>
                                                <fieldset>
                                                    {/*<legend className="legend-crear-prov">Datos bancarios</legend>*/}
                                                    <Form.Row as={Col}>
                                                        <Form.Group as={Col}>
                                                            <Form.Label>Banco</Form.Label>
                                                            {
                                                                !active ?
                                                                    <InputGroup size="sm">
                                                                        <Form.Control type="text" required
                                                                            placeholder="Ingrese banco"
                                                                            className="form-control form-control-sm"
                                                                            onChange={handleBancoChange}>
                                                                        </Form.Control>
                                                                        {
                                                                            returnToActivate ? <button
                                                                                className="m-1 button-default-subir-archivo"
                                                                                data-toggle="tooltip"
                                                                                data-placement="bottom"
                                                                                title="Buscar"
                                                                                onClick={changeToSelect}
                                                                            >
                                                                                <FontAwesomeIcon icon={faRotateLeft} />
                                                                            </button> : ''
                                                                        }
                                                                    </InputGroup> :
                                                                    <Form.Control as="select"
                                                                        placeholder="Elige un tipo de dirección"
                                                                        className="form-control form-control-sm"
                                                                        onChange={setDataAccount}>
                                                                        <option default value={-1}>Elige una opción</option>
                                                                        {dataBank.map(el => (
                                                                            <option key={el.id} value={el.id}>{el.banco}</option>
                                                                        ))}
                                                                        <option value="otro">Otro</option>
                                                                    </Form.Control>
                                                            }
                                                        </Form.Group>
                                                        <Form.Group as={Col}>
                                                            <Form.Label>Comentario</Form.Label>
                                                            <Form.Control type="text" required
                                                                placeholder="Ingrese notas adicionales"
                                                                className="form-control form-control-sm"
                                                                value={comentario.length != 0 ? comentario : ''}
                                                                onChange={handleComentarioChange}>
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row as={Col}>
                                                        <Form.Group as={Col}>
                                                            <Form.Label>Cuenta</Form.Label>
                                                            <NumberFormat
                                                                format="#### #### #### #### #### ####"
                                                                mask="_"
                                                                className="form-control form-control-sm"
                                                                required placeholder="Ingrese número de cuenta"
                                                                value={cuenta.length != 0 ? cuenta : ''}
                                                                onChange={handleCuentaChange}
                                                            />
                                                            <Form.Text muted>Hasta 24 dígitos </Form.Text>
                                                        </Form.Group>
                                                        <Form.Group as={Col}>
                                                            <Form.Label>Cuenta CLABE</Form.Label>
                                                            <NumberFormat
                                                                format="#### #### #### #### ##"
                                                                mask="_"
                                                                className="form-control form-control-sm"
                                                                required placeholder="Ingrese cuenta clabe"
                                                                value={cuentaClabe.length != 0 ? cuentaClabe : ''}
                                                                onChange={handleCuentaClabeChange}
                                                            />
                                                            <Form.Text muted>18 dígitos </Form.Text>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row as={Col}>
                                                        <Form.Group as={Col}>
                                                            <Form.Label>Referencia</Form.Label>
                                                            <Form.Control
                                                                type="text" required
                                                                placeholder="Ingrese referencia"
                                                                className="form-control form-control-sm"
                                                                value={referencia.length != 0 ? referencia : ''}
                                                                onChange={handleReferenciaChange}
                                                            >
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </fieldset>
                                            </Form.Group>
                                        </Form.Row>
                                    </Accordion.Collapse>
                                </Accordion>
                            </Form.Group>
                        </Form.Row>
                        <Row>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="justify-content-end">
                    <button
                        className="m-1 button-default-subir-archivo"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Registrar proveedor"
                        disabled={deactivate}
                        onClick={validateBankDetails}
                    >{status ?
                        <span>Guardando...</span>
                        : <span>Registrar</span>}
                    </button>
                    <button
                        className="m-1 button-cancelar"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Cancelar"
                        onClick={props.onHide}
                    ><span>Cancelar</span>
                    </button>
                </Modal.Footer>
            </Modal>
        </section >
    );
}

export default ModalCrearProveedor;