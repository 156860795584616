import React, { useState } from "react";
import axios from 'axios';
import { KRAKEN_URL } from '../../../../constants'

//Estilos
import '../../css/Configuracion.css'

//Componentes bootstrap
import { Modal, Form, Col, Container } from "react-bootstrap";
import { PencilSquare } from 'react-bootstrap-icons';
import { toast } from "react-toastify";

function ModalEditarEmpresaUsuario(props) {    
    const usuario = props.children;
    const [status, setStatus] = useState(false);
    const [username, setUsername] = useState(usuario.username);
    const [email, setEmail] = useState(usuario.email);
    const [userType, setUserType] = useState(usuario.userType);
    const [telefono, setTelefono] = useState(usuario.telefono);

    function handleUsernameChange(e) {
        setUsername(e.target.value);
    }

    function handleEmailChange(e) {
        setEmail(e.target.value);
    }

    function handleUserTypeChange(e) {
        setUserType(e.target.value);
    }

    function handleTelefonoChange(e) {
        setTelefono(e.target.value);
    }

    function editarUsuario() {
        setStatus(true);
        axios.post(KRAKEN_URL + 'empresa/servicio/usuarios/update',
            {
                "userId": usuario.userId,
                "username": username,
                "email": email,
                "userType": userType,
                "telefono": telefono
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                setStatus(false);
                toast.success(res.data.result.message,{
                    position:"top-center",
                    autoClose: 4500, 
                });
                props.onUpdate();//Actualiza el listado, llamando a la función padre para actualizarlo  
                props.onHide();//Cierra el modal, llamando a la función padre para cerrarlo 
            } else {
                setStatus(false);
                toast.warning(res.data.result.message,{
                    position:"top-center",
                    autoClose: 4500, 
                });
                toast.warning(res.data.result.suggestion,{
                    position:"top-center",
                    autoClose: 4500, 
                });
            }
        }).catch((error) => {
            setStatus(false);
            toast.error("Hubo un error, no se pudo editar la información de este usuario.",{
                position:"top-center",
                autoClose: 4500, 
            });
        })
    }

    return (
        <section className="text-center">
            <Modal
                show={props.show}
                //onHide={props.onHide}
                size="md"
                centered
                id="modalEditarEmpresaUsuario"
                scrollable={true}
            >
                <Modal.Header className="modal-header-empresa-usuario" closeButton onHide={props.onHide}>
                    <p><PencilSquare size={22} color="#5B4B51" />   Editar usuario</p>
                </Modal.Header>
                <Modal.Body>
                    <Container className="container-modals">
                        <Form.Row>
                            <Form.Group as={Col} md={12} lg={12}>
                                <fieldset>
                                    <legend className="legend-empresa-usuario">Datos del usuario</legend>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Nombre de usuario</Form.Label>
                                            <Form.Control size="sm" id="username" type="text" required placeholder="Ingrese nombre de usuario" value={username} onChange={handleUsernameChange}>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control size="sm" id="email" type="text" required placeholder="Ingrese email" value={email} onChange={handleEmailChange} ></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Tipo de usuario</Form.Label>
                                            <Form.Control as="select" size="sm" type="text" required placeholder="Ingrese tipo de usuario" value={userType} onChange={handleUserTypeChange}>
                                                <option value="ADMINISTRADOR">ADMINISTRADOR</option>
                                                <option value="SCHEMA_SOLICITANTE">SCHEMA_SOLICITANTE</option>
                                                <option value="SCHEMA_PAGADOR">SCHEMA_PAGADOR</option>
                                                <option value="SCHEMA_ADMIN">SCHEMA_ADMIN</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Teléfono</Form.Label>
                                            <Form.Control size="sm" id="telefono" type="text" required placeholder="Ingrese teléfono" value={telefono} onChange={handleTelefonoChange} ></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                </fieldset>
                            </Form.Group>
                        </Form.Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="justify-content-end">
                    <button
                        className="m-1 button-default-confirmar"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Agregar"
                        onClick={editarUsuario}
                    >{status ? <span>Guardando...</span> : <span>Editar</span>}
                    </button>
                    <button
                        className="m-1 button-default-cancelar"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Cancelar"
                        onClick={props.onHide}
                    ><span>Cancelar</span>
                    </button>
                </Modal.Footer>
            </Modal>
        </section>
    )
}

export default ModalEditarEmpresaUsuario;