import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../acciones/user-actions'

//Estilos
import './Login.css';
import { Container, Form, Row, Col, Button } from "react-bootstrap"
import {
    LoginAccordion, LoginAccordionSummary, LoginAccordionDetails, LogoTypography,
    TextFieldLogin, ButtonLogin, ButtonRestorePassword, IconTypography, IconTypographySmall
} from './ElementsLogin';
import { Key } from "react-bootstrap-icons";
import ModalRestorePassword from "./ModalRestorePassword";

function Login() {
    const [expanded, setExpanded] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const loggingIn = useSelector(state => state.authentication.loggedIn);
    const dispatch = useDispatch();

    const [modalRestorePasswordShow, setModalRestorePasswordShow] = useState(false);
    let modalRestorePasswordClose = () => { setModalRestorePasswordShow(false); }

    // reset login status
    useEffect(() => {
        dispatch(userActions.logout());
    }, [dispatch]);

    function handleEmailChange(e) {
        setEmail(e.target.value);
    }

    function handlePasswordChange(e) {
        setPassword(e.target.value);
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (email && password) {
            dispatch(userActions.login(email, password));
        }
    }

    function openModalRestorePassword() {
        setModalRestorePasswordShow(true);
    }

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div id="containerLogin">
            <LoginAccordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <LoginAccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
                    <Row>
                        <LogoTypography className="logo-proveedores"></LogoTypography>
                        <p id="logoProveedores" className="font-logo">Proveedores</p>
                        {expanded !== 'panel1' ?
                            <Form.Group as={Col} md={12} lg={12} className="b-login">
                                <Button size="md" className="m-1 button-login" variant="light" data-toggle="tooltip" data-placement="bottom" title="Iniciar sesión">
                                    <Key size={22} className="iconLogin" color="#1CBBC1" />Login
                                </Button>
                            </Form.Group>
                            :
                            ''}
                    </Row>
                </LoginAccordionSummary>
                <LoginAccordionDetails>
                    <Container fluid="sm" className="container-login">
                        <Row className="row-login-header" >
                            <p className="font-login-logo">Iniciar sesión</p>
                        </Row>
                        <div>
                            {modalRestorePasswordShow ? <ModalRestorePassword show={modalRestorePasswordShow} onHide={modalRestorePasswordClose}></ModalRestorePassword> : ''}
                        </div>
                        <Form className="row-login-form">
                            <Form.Group className="m-0">
                                <Form.Label className="font-proveedores">Usuario</Form.Label>
                                <TextFieldLogin variant="outlined" type="email" size="small" fullWidth
                                    name="email" value={email} onChange={handleEmailChange} />
                            </Form.Group>
                            <Form.Group className="m-0">
                                <Form.Label className="font-proveedores">Contraseña</Form.Label>
                                <TextFieldLogin variant="outlined" type="password" size="small" fullWidth
                                    name="password" value={password} onChange={handlePasswordChange} />
                                <ButtonRestorePassword onClick={() => openModalRestorePassword()}>Recuperar contraseña</ButtonRestorePassword>
                            </Form.Group>
                            <Form.Group className="group-button-login">
                                <ButtonLogin variant="contained" type="submit" onClick={handleSubmit}>Ingresar</ButtonLogin>
                                {loggingIn && <span className="spinner-border spinner-border-sm mr-1"></span>}
                            </Form.Group>
                            <Form.Row>
                                <Form.Group as={Col} className="form-group-login">
                                    <IconTypographySmall className="icon-requisicion"></IconTypographySmall>
                                </Form.Group>
                                <Form.Group as={Col} className="form-group-login">
                                    <IconTypography className="icon-pago"></IconTypography>
                                </Form.Group>
                                <Form.Group as={Col} className="form-group-login">
                                    <IconTypography className="icon-proveedor"></IconTypography>
                                </Form.Group>
                                <Form.Group as={Col} className="form-group-login">
                                    <IconTypographySmall className="icon-cfdi"></IconTypographySmall>
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </Container>
                </LoginAccordionDetails>
            </LoginAccordion>
        </div >
    );
}
export default Login;