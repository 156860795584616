import React, { useState } from "react";
import axios from 'axios';
import { API_PROVEEDORES_URL } from '../../constants'

//Estilos
import './css/Cfdis.css'
import { FileEarmarkText, Trash } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';

//Componentes bootstrap
import { Modal } from 'react-bootstrap';

function ModalDeleteFactura(props) {
    const [status, setStatus] = useState(false);
    const [deactivate, setDeactivate] = useState(false);

    function deleteFactura(idCfdi, idRequisicion, e) {
        e.preventDefault();
        setStatus(true);
        axios.post(API_PROVEEDORES_URL + 'deleteCFDI',
            {
                idCfdi: idCfdi,
                idRequisicion: idRequisicion
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                setStatus(false);

                toast.sucess(res.data.result.message,{
                    position:"top-center",
                    autoClose: 4500, 
                });
                props.onHide();//Cierra el modal, llamando a la función padre para cerrarlo
                props.onUpdate();//Actualiza el listado, llamando a la función padre para actualizarlo                
            } else {
                setStatus(false);
                toast.error(res.data.result.message,{
                    position:"top-center",
                    autoClose: 4500, 
                });
                toast.error(res.data.result.suggestion,{
                    position:"top-center",
                    autoClose: 4500, 
                });
            }
        }).catch((error) => {
            setStatus(false);

            setDeactivate(true);

            setTimeout(() => {
                setDeactivate(false);
            }, 4500);

            toast.error("No se ha podido eliminar el archivo",{
                position:"top-center",
                autoClose: 4500, 
            });
        })
    }

    return (
        <section className="text-center">
            <Modal
                show={props.show}
                //onHide={props.onHide}
                size="sm"
                id="modalDeleteFactura"
                centered
            >
                <Modal.Header className="modal-header-eliminar-factura" closeButton onHide={props.onHide}>
                    <p><Trash size={22} color="#5B4B51" />   Eliminar archivo</p>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <FileEarmarkText size={22} color="#5B4B51" />
                        &nbsp;¿Está seguro que desea eliminar este archivo?
                        &nbsp;<strong> {props.children.nombre} </strong>
                    </p>
                    <div className="d-flex justify-content-center">
                        <button
                            className="m-1 button-default-confirmar"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Confirmar"
                            disabled = { deactivate }
                            onClick={e => deleteFactura(props.children.id.idCfdi, props.children.idRequisicion, e)}
                        >{status ?
                            <span>Eliminando...</span>
                            : <span>Confirmar</span>}
                        </button>
                        <button
                            className="m-1 button-default-cancelar"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Cancelar"
                            onClick={props.onHide}
                        ><span>Cancelar</span>
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    );
}

export default ModalDeleteFactura;