import React, { useState, useEffect } from "react";
import axios from 'axios';
import { API_PROVEEDORES_URL } from '../../constants'

//Estilos
import '../Requisiciones/css/Requisiciones.css'
import './css/ProveedoresCatalogo.css'
import { Eye } from 'react-bootstrap-icons';

//Componentes bootstrap
import { Container, Col, Form, Modal } from "react-bootstrap";

//Componentes
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';

function ModalConsultarProveedor(props) {
    const [request] = useState(false);
    const [status, setStatus] = useState('');
    const [error, setError] = useState(false);
    const idProveedor = props.children.idProveedor;
    const [arrayCuentas, setArrayCuentas] = useState([]);

    useEffect(getCuentasBancarias, [idProveedor])//Se actualiza cada que se elige un proveedor diferente

    function getCuentasBancarias() {
        axios.post(API_PROVEEDORES_URL + 'listCuentas',
            {
                "idProveedor": idProveedor
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                setArrayCuentas(res.data.listCuenta)
            } else {
                setError(true);
                setStatus(res.data.result.message);
            }
        }).catch((error) => {
            setError(true);
            setStatus("Error: No se han podido recuperar las cuentas bancarias de este proveedor");
        })
    }

    let handleSizePerPage = ({ page, onSizePerPageChange }, newSizePerPage) => {
        onSizePerPageChange(newSizePerPage, page);
    }

    let listarCuentasBancarias = () => {
        if (error) {
            return (
                <div>{status}</div>
            );
        } else {
            const columns = [
                {
                    dataField: 'id',
                    text: 'ID'
                }, {
                    dataField: 'banco',
                    text: 'Banco'
                }, {
                    dataField: 'comentario',
                    text: 'Comentario'
                }, {
                    dataField: 'cuenta',
                    text: 'Cuenta'
                }, {
                    dataField: 'cuentaClabe',
                    text: 'CuentaClabe'
                }, {
                    dataField: 'referencia',
                    text: 'Referencia'
                }
            ];

            function indication() {
                return arrayCuentas.length ? "Cargando..." : "No hay registros que mostrar";
            }
            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size}
                </span>
            );
            const options = {
                custom: true,
                totalSize: arrayCuentas.length,
                paginationTotalRenderer: customTotal
            };

            return <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div>
                        <Form>
                            <fieldset className="fieldsetCuentas">
                                <Form.Row>{/**className="formRow"*/}
                                    <div className="d-flex flex-row">
                                        <Form.Label>Mostrar</Form.Label>
                                        <Col>
                                            <Form.Control size="sm" id="sizePerPage" as="select" onChange={(e) => handleSizePerPage(paginationProps, e.target.value)}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value={arrayCuentas.length}>Todos</option>
                                            </Form.Control>
                                        </Col>
                                    </div>
                                    <Col>
                                        <PaginationTotalStandalone {...paginationProps} />
                                    </Col>
                                    <div className="d-flex  ml-auto flex-row-reverse">
                                        <Col>
                                            <PaginationListStandalone {...paginationProps} />
                                        </Col>
                                        <Form.Label column>Página</Form.Label>
                                    </div>
                                </Form.Row>
                            </fieldset>
                        </Form>
                        <div className="table-responsive table-sm">
                            <BootstrapTable
                                keyField='id'
                                data={arrayCuentas}
                                columns={columns}
                                bordered={false}
                                noDataIndication={indication}
                                {...paginationTableProps}
                            />
                        </div>
                    </div>
                )}
            </PaginationProvider>
        }
    }
    return (
        <section className="text-center">
            <Modal
                {...props}
                size="xl"
                id="modalConsultarProveedor"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className="modal-header-consultar-prov" closeButton>
                    <h5 className="legend-consultar-prov"><Eye size={22} color="#5B4B51" />   Consultar proveedor</h5>
                </Modal.Header>
                <Modal.Body className="modal-body-proveedor">
                    <Container>
                        <Form.Row as={Col}>
                            <Form.Group as={Col} md={6} lg={6}>
                                <fieldset className="fieldset-consultar-prov-info">
                                    <legend className="legend-consultar-prov">Información general</legend>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Empresa</Form.Label>
                                            <Form.Control type="text" className="form-control form-control-sm" 
                                                disabled value={props.children.empresa}
                                                placeholder="No hay empresa registrada"
                                            ></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Identificador</Form.Label>
                                            <Form.Control type="text" className="form-control form-control-sm" 
                                                disabled value={props.children.id}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row >
                                        <Form.Group as={Col}>
                                            <Form.Label>RFC</Form.Label>
                                            <Form.Control type="text" className="form-control form-control-sm" 
                                                disabled value={props.children.proveedorRfc}
                                                placeholder="No hay rfc registrado"
                                            ></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Razón social</Form.Label>
                                            <Form.Control type="text" className="form-control form-control-sm" 
                                                disabled value={props.children.razonSocial}
                                                placeholder="No hay razón social registrada"    
                                            ></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row >
                                        <Form.Group as={Col}>
                                            <Form.Label>Nombre comercial</Form.Label>
                                            <Form.Control type="text" className="form-control form-control-sm" 
                                                disabled value={props.children.nombreComercial}
                                                placeholder="No hay nombre comercial registrado"
                                            ></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Giro</Form.Label>
                                            <Form.Control type="text" className="form-control form-control-sm"
                                                disabled value={props.children.giro}
                                                placeholder="No hay giro registrado"
                                            ></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row >
                                        <Form.Group as={Col}>
                                            <Form.Label>Correo electrónico</Form.Label>
                                            <Form.Control type="text" className="form-control form-control-sm" 
                                                disabled value={props.children.correoElectronico}
                                                placeholder="No hay correo electrónico registrado"
                                            ></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Teléfono</Form.Label>
                                            <Form.Control type="text" className="form-control form-control-sm" 
                                                disabled value={props.children.telefono}
                                                placeholder="No hay teléfono registrado"
                                            ></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                </fieldset>
                            </Form.Group>
                            <Form.Group as={Col} md={6} lg={6}>
                                <fieldset className="fieldset-consultar-prov-direccion">
                                <legend className="legend-consultar-prov">Dirección</legend>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Tipo de dirección</Form.Label>
                                            <Form.Control type="text" disabled value={props.children.tipo}
                                                className="form-control form-control-sm"
                                                placeholder="No hay tipo de dirección registrado"
                                            ></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Calle</Form.Label>
                                            <Form.Control type="text" disabled value={props.children.calle}
                                                className="form-control form-control-sm"
                                                placeholder="No hay calle registrada"
                                            ></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>No. Exterior</Form.Label>
                                            <Form.Control type="text" disabled value={props.children.numeroExterior}
                                                className="form-control form-control-sm"
                                                placeholder="No hay no. ext registrado"
                                            ></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>No. Interior</Form.Label>
                                            <Form.Control type="text" disabled value={props.children.numeroInterior}
                                                className="form-control form-control-sm"
                                                placeholder="No hay no. int registrado"
                                            ></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Código postal</Form.Label>
                                            <Form.Control type="text" disabled value={props.children.codigoPostal}
                                                className="form-control form-control-sm"
                                                placeholder="No hay código postal registrado"
                                            ></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Colonia</Form.Label>
                                            <Form.Control type="text" disabled value={props.children.colonia}
                                                className="form-control form-control-sm"
                                                placeholder="No hay colonia registrada"
                                            ></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Ciudad</Form.Label>
                                            <Form.Control type="text" disabled value={props.children.ciudad}
                                                className="form-control form-control-sm"
                                                placeholder="No hay ciudad registrada"
                                            ></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Municipio</Form.Label>
                                            <Form.Control type="text" disabled value={props.children.municipio}
                                                className="form-control form-control-sm"
                                                placeholder="No hay municipio registrado"
                                            ></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Estado</Form.Label>
                                            <Form.Control type="text" disabled value={props.children.estado}
                                                className="form-control form-control-sm"
                                                placeholder="No hay estado registrado"
                                            ></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>País</Form.Label>
                                            <Form.Control type="text" disabled value={props.children.pais}
                                                className="form-control form-control-sm"
                                                placeholder="No hay país registrado"
                                            ></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                </fieldset>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row as={Col}>
                            <Form.Group as={Col} md={6} lg={12}>
                                <h5 className="legend-editar-prov">Datos bancarios</h5>
                                <fieldset className="fieldset-editar-prov">
                                    <legend></legend>
                                    {request ? "Cargando..." : listarCuentasBancarias()}
                                </fieldset>
                            </Form.Group>
                        </Form.Row>
                    </Container>
                </Modal.Body>
                {/**<Modal.Footer className="justify-content-end">
                    <ButtonGroup>
                        <Button
                            size="md"
                            className="m-1 buttonCancelar"
                            variant="light"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Cerrar"
                            onClick={props.onHide}
                        ><span>Cerrar</span>
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>*/}
            </Modal>
        </section>
    );
}

export default ModalConsultarProveedor;