import React, { useState } from "react";
import axios from 'axios';
import { KRAKEN_URL } from '../../constants'

//Estilos
import './Login.css';
import { LockFill } from "react-bootstrap-icons";
import { toast } from 'react-toastify';

//Componentes bootstrap
import { Modal, Form, Col } from 'react-bootstrap';

function ModalRestorePassword(props) {
    const [status, setStatus] = useState(false);
    const [email, setEmail] = useState('');

    function handleEmailChange(e) {
        setEmail(e.target.value);
    }

    function restorePassword(e) {
        e.preventDefault();
        setStatus(true);
        axios.post(KRAKEN_URL + 'empresa/usuarios/restorePassword',
            {
                email: email
            }
        ).then((res) => {
            if (res.data.result.success) {
                setStatus(false);
                toast.success(res.data.result.message,{
                    position:"top-center",
                    autoClose: 4500, 
                });
                toast.success(res.data.result.suggestion,{
                    position:"top-center",
                    autoClose: 4500, 
                });
                props.onHide();//Cierra el modal, llamando a la función padre para cerrarlo                
            } else {
                setStatus(false);
                toast.error(res.data.result.message,{
                    position:"top-center",
                    autoClose: 4500, 
                });
                toast.error(res.data.result.suggestion,{
                    position:"top-center",
                    autoClose: 4500, 
                });
            }
        }).catch((error) => {
            setStatus(false);
            toast.error("No se ha podido restablecer la contraseña",{
                position:"top-center",
                autoClose: 4500, 
            });
        })
    }

    return (
        <section className="text-center">
            <Modal
                show={props.show}
                //onHide={props.onHide}
                size="md"
                id="modalRestorePassword"
                centered
            >
                <Modal.Header className="modal-header-restore-password" closeButton onHide={props.onHide}>
                    <p><LockFill size={22} color="#5B4B51" />Ingrese el correo con el que está registrado</p>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row as={Col}>
                        <Form.Control id="email" size="sm" type="text" value={email} placeholder="Email" onChange={handleEmailChange}></Form.Control>
                    </Form.Row>
                    <div className="d-flex justify-content-center">
                        <button
                            className="m-1 button-default-confirmar"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Enviar"
                            onClick={e => restorePassword(e)}
                        >{status ?
                            <span>Enviando...</span>
                            : <span>Enviar</span>
                            }
                        </button>
                        <button
                            className="m-1 button-default-cancelar"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Cancelar"
                            onClick={props.onHide}
                        ><span>Cancelar</span>
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    )
}

export default ModalRestorePassword;