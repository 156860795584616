import {React, useState, useEffect} from 'react';
import { bigDecimalFormat } from '../../constantes/general-require';

import axios from "axios";
import { API_PROVEEDORES_URL, KRAKEN_URL } from "../../constants";
//Estilos
import "./css/Cfdis.css"
import { FileText } from 'react-bootstrap-icons';

//Elementos bootstrap
import { Form, Modal, Col, Container, InputGroup,Table } from "react-bootstrap";

//Componentes
import NumberFormat from 'react-number-format';
import Moment from 'moment';
import ListadoFacturasSubidas from './ListadoFacturasSubidas';
import { ContactSupportOutlined } from '@material-ui/icons';

function ModalConsultaFacturas(props) {
    console.log(props)
    console.log("PROPS: ", props)
    const [notificarPagador, setNotificarPagador] = useState(false);
    const [pagadores, setPagadores] = useState("");
    const [pagadorName, setPagadorName] = useState("");
    const [pagadorEmail, setPagadorEmail] = useState("");
    const [idEmpresa, setIdEmpresa] = useState(props.children.idEmpresa);
    const token = JSON.parse(localStorage.getItem("token"));
    const username = token.username;
    const [solicitante] = useState(username);


    const monedaDecimales = props.children.monedaDecimalesSat;
    const[compartidos,setCompartidos] = useState(props.children.compartidos?JSON.parse(props.children.compartidos): []);
    const createInputSaldoPendientePesosMexicanos = () => {
        let paridad = props.children.paridad;
        let paridadDecimal = bigDecimalFormat(paridad);
        let saldoPendiente = props.children.saldoPendiente;
        let saldoPendienteDecimal = bigDecimalFormat(saldoPendiente);
        let saldoPendienteConParidad = saldoPendienteDecimal.times(paridadDecimal);
        let inputTPM = (<span><Form.Label > Saldo pendiente pesos mexicanos</Form.Label>
            <InputGroup size="sm">
                <InputGroup.Prepend >
                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                </InputGroup.Prepend>
                <NumberFormat id="saldoPendientePesosMexicanos"
                    className="input-signs-align form-control form-control-sm"
                    thousandSeparator={true} disabled
                    value={saldoPendienteConParidad.toFixed(monedaDecimales)}>
                </NumberFormat>
            </InputGroup>
        </span>
        );
        return inputTPM;
    }

    function handleNotificarPagador(e) {
        setNotificarPagador(!notificarPagador);
    }
    function handlePagador(e) {
        let value = e.target.value;
        let aux = value.split("-");
        setPagadorName(aux[0]);
        setPagadorEmail(aux[1]);
    }
    useEffect(() => {
        console.log(idEmpresa);
        getPagadores();
    }, [idEmpresa]);
    function getPagadores() {
        console.log("entra a la peticion "+ idEmpresa);
        axios
          .post(KRAKEN_URL + "empresa/usuarios/payers", {
            empresaId: idEmpresa,
          })
          .then((res) => {
            console.log("xxxxxxxxxxxxxxxxxx");
            console.log(res);
    
            
            if (res.data.list)
            res.data.list.map((pagador) => { 
                console.log(pagador.userId, " sss")
                console.log(props.children.pagadorId)
               if(pagador.userId===props.children.pagadorId){
                console.log("entra aqui ?")
                setPagadores(pagador.username)
               }
               console.log(pagadores)
              });
            
          })
          .catch((error) => {
            console.log(error);
            console.log("ALGO SALIÓ MAL");
        });
    }


    return (
        <section className="text-center">
            <Modal
                {...props}
                size="xl"
                centered
                id="modalConsultarFacturas"
                scrollable={true}
            >
                <Modal.Header className="modal-header-consulta-facturas" closeButton  onClick={props.onHide}>
                    <p><FileText size={22} color="#5B4B51" />&nbsp;Consulta de CFDI's</p>
                </Modal.Header>
                <Modal.Body >
                    <Container className="container-modals">
                        <Form.Row>
                            <Form.Group as={Col} md={12} lg={6}>
                                <fieldset >
                                    <legend className="legend-consulta-facturas">Emisor: </legend>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col}>
                                            <Form.Label>Empresa</Form.Label>
                                            <Form.Control size="sm" id="empresa" type="text" disabled defaultValue={props.children.empresa}></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                </fieldset>
                                <fieldset >
                                    <legend className="legend-consulta-facturas">Receptor: comprobantes subidos</legend>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col}>
                                            <Form.Label>Nombre proveedor</Form.Label>
                                            <Form.Control size="sm" id="proveedor" type="text" disabled defaultValue={props.children.proveedor}></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <ListadoFacturasSubidas>{props.children.idRequisicion}{props.children.idEmpresa}</ListadoFacturasSubidas>
                                    <Form.Row as={Col}>
                                    <legend className="legend-crear-req-not">Notificaciones</legend>
                         <Form.Label>Usuario pagador</Form.Label>
                           <Form.Control
                             size="sm"
                             type="text"
                             disabled
                             value={pagadores}
                           ></Form.Control>
                        
                     </Form.Row>
                                    <Form.Row as={Col}>
                               
                                    {compartidos.length>0 ?(
                    <Form.Group>





                       

                       
                        
                          <legend className="legend-crear-req">Se comparte con los siguientes usuarios:</legend>

                   <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th><b>Nombre de usuario</b></th>
                          <th><b>Email</b></th>
                        </tr>
                      </thead>
                      <tbody> {compartidos.map(elemento => (
                        <tr key={elemento.usuarioId}>
                        <td>{elemento.usuarioNombre}</td>
                        <td>{elemento.usuarioEmail}</td>
             
            </tr>
          ))}</tbody>
                    </Table></Form.Group>):"" } 
                                    </Form.Row>
                                </fieldset>
                            </Form.Group>
                            <Form.Group as={Col} md={12} lg={6}>
                                <fieldset >
                                    <legend className="legend-consulta-facturas">Información general de la requisición</legend>

                                    <Form.Row as={Col}>
                                      <Form.Group as={Col} lg={6}>
                                        <Form.Label>Solicitante</Form.Label>
                                          <Form.Control
                                            size="sm"
                                            type="text"
                                            disabled
                                            value={solicitante}
                                          ></Form.Control>
                                      </Form.Group>
                                    </Form.Row>

                                    <Form.Row as={Col}>
                                        <Form.Group as={Col} lg={6}>
                                            <Form.Label>Concepto general</Form.Label>
                                            <Form.Control size="sm" id="concepto" as="textarea" rows={3} disabled defaultValue={props.children.concepto}></Form.Control>
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row as={Col}>
                                        <Form.Group as={Col} lg={6}>
                                            <Form.Label>Fecha requerida de pago</Form.Label>
                                            <Form.Control size="sm"
                                                type="text"
                                                disabled
                                                defaultValue={Moment(props.children.fechaRequeridaPago).format('DD/MM/YYYY')}></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                </fieldset>
                                <fieldset >
                                    <legend className="legend-consulta-facturas">Saldos</legend>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col} lg={6}>
                                            <Form.Label>Moneda</Form.Label>
                                            <Form.Control size="sm" id="moneda" type="text" disabled defaultValue={props.children.monedaClaveSat + " - " + props.children.monedaSat + " - Presición - " + props.children.monedaDecimalesSat} />
                                            <Form.Label>Paridad</Form.Label>
                                            <InputGroup size="sm">
                                                <InputGroup.Prepend >
                                                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control size="sm" id="paridad" type="number" className="input-signs-align" disabled defaultValue={props.children.paridad}></Form.Control>
                                            </InputGroup>
                                            <Form.Label>Importe total</Form.Label>
                                            <InputGroup size="sm">
                                                <InputGroup.Prepend >
                                                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <NumberFormat id="importe" className="input-signs-align form-control form-control-sm" thousandSeparator={true} disabled defaultValue={props.children.total.toFixed(monedaDecimales)}></NumberFormat>
                                            </InputGroup>
                                            <Form.Label>Importe requerido</Form.Label>
                                            <InputGroup size="sm">
                                                <InputGroup.Prepend >
                                                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <NumberFormat id="importeRequerido" className="input-signs-align form-control form-control-sm" thousandSeparator={true} disabled defaultValue={props.children.totalRequerido.toFixed(monedaDecimales)}></NumberFormat>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group as={Col} lg={6}>
                                            <Form.Label>Saldo anterior</Form.Label>
                                            <InputGroup size="sm">
                                                <InputGroup.Prepend >
                                                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <NumberFormat id="saldoAnterior" className="input-signs-align form-control form-control-sm" disabled thousandSeparator={true} value={props.children.saldoAnterior.toFixed(monedaDecimales)}></NumberFormat>
                                            </InputGroup>
                                            <Form.Label>Saldo pagado</Form.Label>
                                            <InputGroup size="sm">
                                                <InputGroup.Prepend >
                                                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <NumberFormat id="saldoPagado" className="input-signs-align form-control form-control-sm" disabled thousandSeparator={true} value={props.children.saldoPagado.toFixed(monedaDecimales)}></NumberFormat>
                                            </InputGroup>
                                            <Form.Label>Saldo pendiente</Form.Label>
                                            <InputGroup size="sm">
                                                <InputGroup.Prepend >
                                                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <NumberFormat id="saldoPendiente" className="input-signs-align form-control form-control-sm" disabled thousandSeparator={true} value={props.children.saldoPendiente.toFixed(monedaDecimales)}></NumberFormat>
                                            </InputGroup>
                                            {props.children.monedaClaveSat !== 'MXN' ? createInputSaldoPendientePesosMexicanos() : ''}
                                        </Form.Group>
                                    </Form.Row>
                                </fieldset>
                            </Form.Group>
                        </Form.Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </section>
    );

}

export default ModalConsultaFacturas;