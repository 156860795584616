import React from "react";

//Estilos
import "./CuentasEmisoras.css"
import { Eye } from 'react-bootstrap-icons';

//Elementos bootstrap
import { Form, Col, Container, Modal } from "react-bootstrap";

//Componentes
import NumberFormat from 'react-number-format';

function ModalConsultarCuentaEmisora(props) {
    return (
        <section className="text-center">
            <Modal
                show={props.show}
                //onHide={props.onHide}
                size="md"
                aria-labelledby="example-modal-sizes-title-vcenter"
                id="modalConsultarCuentaEmisora"
                centered
            >
                <Modal.Header className="modal-header-consultar-cuenta-emisora" closeButton onHide={props.onHide}>
                    <h5 className="legend-consultar-cuenta-emisora"><Eye size={22} color="#5B4B51" />   Consultar cuenta emisora de pago</h5>
                </Modal.Header>
                <Container>
                    <Modal.Body className="modal-body-cuenta-emisora">
                        <Form.Row >
                            <Form.Group as={Col}>
                                <Form.Label>Empresa</Form.Label>
                                <Form.Control type="text" className="form-control form-control-sm" disabled value={props.children.empresa}></Form.Control>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Banco</Form.Label>
                                <Form.Control type="text" disabled className="form-control form-control-sm" value={props.children.banco}></Form.Control>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row >
                            <Form.Group as={Col}>
                                <Form.Label>Comentario</Form.Label>
                                <Form.Control type="text" disabled className="form-control form-control-sm" value={props.children.comentario}></Form.Control>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row >
                            <Form.Group as={Col}>
                                <Form.Label>Cuenta</Form.Label>
                                <NumberFormat format="#### #### #### #### #### ####"
                                    mask="_"
                                    className="form-control form-control-sm"
                                    disabled
                                    value={props.children.cuenta}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Cuenta CLABE</Form.Label>
                                <NumberFormat format="#### #### #### #### ##"
                                    mask="_"
                                    className="form-control form-control-sm"
                                    disabled
                                    value={props.children.cuentaClabe}

                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row >
                            <Form.Group as={Col}>
                                <Form.Label>Referencia</Form.Label>
                                <Form.Control type="text" disabled className="form-control form-control-sm" value={props.children.referencia}></Form.Control>
                            </Form.Group>
                        </Form.Row>
                    </Modal.Body>
                </Container>
                {/*<Modal.Footer className="justify-content-end">
                    <ButtonGroup>
                        <Button
                            size="md"
                            className="m-1 buttonCancelar"
                            variant="light"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Cerrar"
                            onClick={props.onHide}
                        ><span>Cerrar</span>
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>*/}
            </Modal>
        </section>
    );
}

export default ModalConsultarCuentaEmisora;