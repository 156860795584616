import { withStyles } from '@material-ui/core/styles';
import {
    Accordion, AccordionSummary, AccordionDetails, Typography,
    Button, TextField
} from '@material-ui/core';

const LoginAccordion = withStyles({
    root: {
        border: 'none',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(Accordion);

const LoginAccordionSummary = withStyles({
    root: {
        minHeight: '100vh',
        borderRadius: '0 0 30px 30px',
        backgroundColor: '#1CBBC1',
        boxShadow: '0 4px 5px 0 rgba(0,0,0,0.83)',
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
        justifyContent: 'center',
        alignItems: 'center'
    },
    expanded: {
        minHeight: '150px',
    },
})(AccordionSummary);

const LogoTypography = withStyles((theme) => ({
    root: {
        height: 127,
        width: 136,
        color: '#FFF',
        fontSize: '80px',
        fontWeight: '600',
    },
}))(Typography)

const LoginAccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(7.5),
        justifyContent: 'center',
    },
}))(AccordionDetails);

const TextFieldLogin = withStyles((theme) => ({
    root: {
        backgroundColor: '#FFF',
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& label.Mui-focused': {
            color: '#1CBBC1',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#1CBBC1',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#1CBBC1',
            },
            '&:hover fieldset': {
                borderColor: '#1CBBC1',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#1CBBC1',
            },
        },
    },
}))(TextField)

const ButtonLogin = withStyles((theme) => ({
    root: {
        fontSize: 20,
        color: '#FFF',
        fontWeight: 600,
        height: '55px',
        width: '171px',
        borderRadius: '15px',
        backgroundColor: '#1CBBC1',
        '&:hover': {
            backgroundColor: '#1CBBC1',
            borderColor: '#1CBBC1',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#1CBBC1',
            borderColor: '#1CBBC1',
        },
    },

}))(Button);

const ButtonRestorePassword = withStyles((theme) => ({
    root: {
        color: '#1CBBC1',
        '&:hover': {
            borderColor: 'none',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            borderColor: '#1CBBC1',
        },
    },
}))(Button);

const IconTypography = withStyles((theme) => ({
    root: {
        height: '40px',
        width: '45px',
    },
}))(Typography)

const IconTypographySmall = withStyles((theme) => ({
    root: {
        height: '40px',
        width: '33px',
    },
}))(Typography)

export {
    LoginAccordion, LoginAccordionSummary, LogoTypography, LoginAccordionDetails,
    TextFieldLogin, ButtonLogin, ButtonRestorePassword, IconTypography, IconTypographySmall
}