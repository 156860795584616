import React, { useState } from "react";
import axios from 'axios'
import { useHistory } from "react-router-dom";
import { API_PROVEEDORES_URL } from '../../constants';

//Estilos
import '../SoporteTecnico/css/SoporteTecnico.css'
//import "./styles.css";

//Elementos bootstrap
import { Col, Form, Container } from 'react-bootstrap';

//Componentes
import SeleccionarEmpresa from '../Requisiciones/SeleccionarEmpresa';
//import { useRecaptcha } from "react-hook-recaptcha";
import { toast } from "react-toastify";

function SoporteTecnico(props) {
    const [status, setStatus] = useState(false);
    const token = JSON.parse(localStorage.getItem('token'));
    const username = token.username;
    //const empresasDisponibles = useSelector(state => state.empresas.empresasDisponibles);
    //let empresas = empresasDisponibles.map(e => { return e.empresa; });
    const [empresa, setEmpresa] = useState('');
    //const [idEmpresa, setIdEmpresa] = useState('');
    //const username = useSelector(state => state.authentication.user);
    const [solicitante] = useState(username);
    const [motivo, setMotivo] = useState('');
    const [correoElectronico, setCorreoElectronico] = useState('');
    const [telefono, setTelefono] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [modulo, setModulo] = useState('');
    //const [archivos, setArchivos] = useState('');

    /*const sitekey = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
    const containerId = "recaptcha";  // this id can be customized
    const [captchaResponse, setCaptchaResponse] = useState(null);
    const successCallback = (response) => {
        setCaptchaResponse(response);
    };
    const expiredCallback = () => setCaptchaResponse(null);*/

    let history = useHistory();
    const redirectPage = () => {
        history.push('/soporte/consulta', 3000);
    };

    function empresaSelected(empresa) {
        var str = empresa;
        var array = str.split(",");
        setEmpresa(array[0]);
        //setIdEmpresa(array[1]);
    }
    function handleCorreoElectronicoChange(e) {
        setCorreoElectronico(e.target.value);
    }
    function handleTelefonoChange(e) {
        setTelefono(e.target.value);
    }
    function handleMotivoChange(e) {
        setMotivo(e.target.value);
    }
    function handleModuloChange(e) {
        setModulo(e.target.value);
    }
    function handleDescripcionChange(e) {
        setDescripcion(e.target.value);

    }
    /*function handleFilesChange(e) {
        setArchivos(e.target.files);
        let files = e.target.files;
        let output = [];

        for (var i = 0; i < files.length; i++) {
            output.push('<li><strong>', escape(files[i].name), '</strong> (', files[i].type || 'n/a', ') - ', files[i].size, ' bytes</li>')
        }
        document.getElementById("list").innerHTML = '<ol>' + output.join('') + '</ol>';
    }*/
    /*useRecaptcha({
        containerId,
        successCallback,
        expiredCallback,
        sitekey,
        size: "normal",
    });*/

    function solicitarSoporte(e) {
        e.preventDefault();
        setStatus(true);
        /*const formData = new FormData();
        for(var i=0; i<archivos.length; i++){
            formData.append("archivos", archivos[i]);
        }
        formData.append("to", "karen.aguilar@hixsa.com");
        formData.append("empresa", empresa);
        formData.append("solicitante", solicitante);
        formData.append("correoElectronico", correoElectronico);
        formData.append("telefono", telefono);
        formData.append("motivo", motivo);
        formData.append("modulo", modulo);
        formData.append("descripcion", descripcion);*/
        axios.post(API_PROVEEDORES_URL + 'enviarSolicitud', /*formData,*/
            {
                //headers: { 'Content-Type': `multipart/form-data;` }
                to: 'desarrollo@hixsa.com',
                empresa: empresa,
                solicitante: solicitante,
                correoElectronico: correoElectronico,
                telefono: telefono,
                motivo: motivo,
                modulo: modulo,
                descripcion: descripcion
            }
        ).then((res) => {
            if (res.status === 200) {
                setStatus(false);
                toast.success("Solicitud enviada a equipo de desarrollo",{
                    position:"top-center",
                    autoClose: 4500, 
                });
                //toast.success(res.data.result.success);                
            } else {
                setStatus(false);
                toast.error("No se pudo enviar el correo al equipo de desarrollo",{
                    position:"top-center",
                    autoClose: 4500, 
                });
                //toast.error(res.data.result.message);
                //toast.error(res.data.result.suggestion);
            }
        }).catch((error) => {
            setStatus(false);
            toast.error("No se ha podido enviar la solicitud de soporte",{
                position:"top-center",
                autoClose: 4500, 
            });
        });
        redirectPage()
    }

    return (
        <section className="text-center">
            <Form id="formSoporte" >
                <fieldset className="form-body-soporte">
                    <p>NOTA: Todos los campos son obligatorios</p>
                    {/*<legend><Envelope size={22} color="#5B4B51" />Enviar solitud de soporte</legend>*/}
                    <Container className="container-modals">
                        {/*<Form.Group>*/}
                        <Form.Row as={Col}>
                            <SeleccionarEmpresa onEmpresaSelected={empresaSelected} className="labelSoporte">{empresa}</SeleccionarEmpresa>
                            <Form.Group as={Col} md={6} lg={6}>
                                <Form.Label className="labelSoporte">Solicitante</Form.Label>
                                <Form.Control type="text" disabled
                                    value={solicitante}
                                    size="sm"
                                ></Form.Control>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row as={Col}>
                            <Form.Group as={Col} md={6} lg={6}>
                                <Form.Label className="labelSoporte">Correo electrónico</Form.Label>
                                <Form.Control type="text" size="sm" required id="correoElectronico"
                                    placeholder="Ingrese su correo electrónico"
                                    onChange={handleCorreoElectronicoChange}
                                ></Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} md={6} lg={6}>
                                <Form.Label className="labelSoporte">Teléfono</Form.Label>
                                <Form.Control type="text" size="sm" required id="telefono"
                                    placeholder="Ingrese su número telefónico"
                                    onChange={handleTelefonoChange}
                                ></Form.Control>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row as={Col}>
                            <Form.Group as={Col} md={6} lg={12}>
                                <Form.Label className="labelSoporte">Motivo</Form.Label>
                                <Form.Control type="text" size="sm" required id="motivo"
                                    placeholder="Ingrese motivo de solicitud"
                                    onChange={handleMotivoChange}
                                ></Form.Control>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row as={Col}>
                            <Form.Group as={Col} md={6} lg={12}>
                                <Form.Label className="labelSoporte">Módulo</Form.Label>
                                <Form.Control type="text" size="sm" required id="modulo"
                                    placeholder="Selecciona módulo" as="select"
                                    onChange={handleModuloChange}
                                >
                                    <option default value="">Seleccione módulo</option>
                                    <option value="Dashboard">Dashboard</option>
                                    <option value="Requisiciones">Requisiciones</option>
                                    <option value="Pagos">Pagos</option>
                                    <option value="Proveedores">Proveedores</option>
                                    <option value="Cuentas emisoras">Cuentas emisoras</option>
                                    <option value="CFDIs">CFDI's</option>
                                    <option value="Configuración">Requisiciones</option>
                                    <option value="Otro">Otro</option>
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row as={Col}>
                            <Form.Group as={Col} md={12} lg={12}>
                                <Form.Label className="labelSoporte">Descripción del problema</Form.Label>
                                <textarea id="descripcion" className="md-textarea form-control"
                                    rows="6" placeholder="Describa el problema que presenta el sistema"
                                    size="sm" onChange={handleDescripcionChange}
                                    required
                                ></textarea>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row as={Col}>
                            {/*<Form.Group as={Col} md={6} lg={4}>
                                <Form.Label className="labelSoporte">Adjuntar archivos</Form.Label>
                                <input className="input-file-req" type="file" id="archivos" name="archivos[]"
                                    multiple onChange={ handleFilesChange }
                                    accept=".jpg, .jpeg, .png, .pdf, .txt, .xls, .xlsx, .xml, .zip"
                                />
                                <output id="list"></output>
                                <output id="listResponse"></output>
                            </Form.Group>
                            {/*<Form.Group as={Col} md={6} lg={4}>
                                <div id={containerId} className="g-recaptcha robotCaptcha" />
                            </Form.Group>*/}
                        </Form.Row>
                        {/*</Form.Group>*/}
                    </Container>
                </fieldset>
                <div className="d-flex justify-content-center">
                    <button
                        className="m-1 button-default-confirmar"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Enviar solicitud"
                        onClick={e => solicitarSoporte(e)}
                    >{status ?
                        <span>Enviando...</span>
                        : <span>Enviar solicitud</span>}
                    </button>
                    <button
                        className="m-1 button-default-cancelar"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Cancelar"
                        onClick={redirectPage}
                    ><span>Cancelar</span>
                    </button>
                </div>
            </Form>
        </section>
    )
}

export default SoporteTecnico;
