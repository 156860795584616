//Función para convertir una cadena de texto de base64 a arrayBuffer
const base64ToArrayBuffer = function (base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

//Función que convierte un arrayBuffer a un archivo de acuerdo al mimeType y hace la descarga del mismo
const saveByteArray = function (nombre, mimeType, byte) {
    var blob = new Blob([byte], {
        type: mimeType
    });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = nombre;
    link.download = fileName;
    link.click();
}

export { base64ToArrayBuffer, saveByteArray };