import React, { useState, useEffect } from "react";
import axios from 'axios'
import { useSelector } from 'react-redux';
import { API_PROVEEDORES_URL } from '../../constants';

//Estilos
import '../SoporteTecnico/css/SoporteTecnico.css'
import { Eye, ListTask, Grid3x2GapFill, Check, Toggle2Off, Toggle2On, HourglassSplit, CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';

//Elementos bootstrap
import { Card, Col, Form, Button, ButtonGroup, Tabs, Tab } from 'react-bootstrap';

//Componentes
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import Moment from 'moment';
import ModalConsultarSolicitud from './ModalConsultarSolicitud';
import ModalUpdateEstatus from './ModalUpdateEstatus';
import Loader from "../Loader";

function ListaSolicitudes() {
    const [error, setError] = useState(false);
    const [status, setStatus] = useState('');
    const [cargando, setCargando] = useState(false);
    const empresasDisponibles = useSelector(state => state.empresas.empresasDisponibles);
    let empresas = empresasDisponibles.map(e => { return e.empresa; });
    const empresaDefault = empresas[0];
    const [key, setKey] = useState(empresaDefault);
    const [listaSolicitudes, setListaSolicitudes] = useState([]);
    const [updateListado, setUpdateListado] = useState(false);
    const user = JSON.parse(localStorage.getItem('token'));
    let updateGetSolicitudes = () => { setUpdateListado(!!!updateListado); setListaSolicitudes([]); }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getSolicitudes, [updateListado]);

    function getSolicitudes() {
        let user = JSON.parse(localStorage.getItem('token'));

        setCargando(true);
        axios.post(API_PROVEEDORES_URL + 'listarSolicitudes', { empresas: empresas }
        ).then((res) => {
            if (res.data.result.code === 200) {
                user.userType === 'SCHEMA_ADMIN'? setListaSolicitudes(res.data.solicitudes) : 
                filterDataToUser(res.data.solicitudes);
                setListaSolicitudes(res.data.solicitudes)
            } else {
                setError(true);
                setListaSolicitudes('');
                setStatus(res.data.result.message);
            }
        }).catch((error) => {
            setError(true);
            setStatus("Error: No se han podido recuperar registros de solicitudes");
        }).finally(() => {
            setCargando(false);
        })
    }

    function filterDataToUser(empresas){ 
        empresas.map((empresa)=>{
         empresa.lista = empresa.lista.filter(function(objeto) {
            return ((objeto.solicitante ===user.username)||objeto.usuarioCompartido ===user.username||objeto.pagadorId ===user.userId );
         });
         })
         return empresas;
     }

    function createTabs() {
        let empresasTabs;
        listaSolicitudes.length ?
            empresasTabs = listaSolicitudes.map(empresa => (
                <Tab key={empresa.id} eventKey={empresa.id} title={empresa.nombreEmpresa}>
                    <Solicitudes onUpdate={updateGetSolicitudes}>{empresa.lista}</Solicitudes>
                </Tab>
            )) : empresasTabs = empresas.map(empresa => (
                <Tab key={empresa} eventKey={empresa} title={empresa}>
                    <section id="listaSolicitudes">
                        <Card id="cardSolicitudes">
                            <Card.Body className="cardBodySolicitudes">
                                {error ? status : 'Error: No se ha podido cargar la información'}
                            </Card.Body>
                        </Card>
                    </section>
                </Tab>
            ));
        return empresasTabs;
    }

    if (cargando) {
        return <Loader />
    }

    return (
        <div>
            <Tabs id="controlled-tab-solicitudes" activeKey={key} onSelect={(k) => setKey(k)}>
                {createTabs()}
            </Tabs>
        </div>
    );
}

function Solicitudes(props) {
    const token = JSON.parse(localStorage.getItem('token'));
    const userType = token.userType;
    const [error] = useState(false);
    const [status] = useState('Error: No se ha podido recuperar la lista de solicitudes');
    const [request] = useState(false);
    const [gridCards, setGridCards] = useState(false);
    const [listaSolicitudes] = useState(props.children);
    const [modalConsultarSolicitudShow, setModalConsultarSolicitudShow] = useState(false);
    const [modalUpdateEstatusShow, setModalUpdteEstatusShow] = useState(false);
    const [solicitud, setSolicitud] = useState('');
    const [estatus, setEstatus] = useState('');
    let modalConsultarSolicitudClose = () => { setModalConsultarSolicitudShow(false); setSolicitud('') };
    let modalUpdateEstatusClose = () => { setModalUpdteEstatusShow(false); setEstatus('') };
    let updateListado = () => { props.onUpdate() };

    function getSolicitud(solicitud) {
        setSolicitud(solicitud);
        setModalConsultarSolicitudShow(true);
    }

    function updateEstatus(estatus) {
        setEstatus(estatus);
        setModalUpdteEstatusShow(true);
    }

    let mostrarListaSolicitudes = () => {
        if (error) {
            return (
                <div>{status}</div>
            );
        } else {
            const columnsList = [
                {
                    dataField: 'id',
                    text: 'ID',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true,
                    sortCaret: (order, column) => {
                        if (!order) return (<span>&nbsp;&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000" /><CaretUpFill size={10} className="button-default-black" color="#000" /></span>);
                        else if (order === "desc") return (<span>&nbsp;&nbsp;<CaretUpFill size={10} className="button-default-black" color="#000" /><CaretDownFill size={10} className="button-default-black" color="#dee2e6" /></span>);
                        else if (order === "asc") return (<span>&nbsp;&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000" /><CaretUpFill size={10} className="button-default-black" color="#dee2e6" /></span>);
                        return null;
                    }
                }, {
                    dataField: 'fecha',
                    text: 'Fecha',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    formatter: (cellContent, row) => {
                        return (
                            Moment(row.fecha).format('DD/MM/YYYY')
                        )
                    },
                    sort: true,
                    sortCaret: (order, column) => {
                        if (!order) return (<span>&nbsp;&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000" /><CaretUpFill size={10} className="button-default-black" color="#000" /></span>);
                        else if (order === "desc") return (<span>&nbsp;&nbsp;<CaretUpFill size={10} className="button-default-black" color="#000" /><CaretDownFill size={10} className="button-default-black" color="#dee2e6" /></span>);
                        else if (order === "asc") return (<span>&nbsp;&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000" /><CaretUpFill size={10} className="button-default-black" color="#dee2e6" /></span>);
                        return null;
                    }
                }, {
                    dataField: 'solicitante',
                    text: 'Solicitante',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                }, {
                    dataField: 'correoElectronico',
                    text: 'Correo Electrónico',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                }, {
                    dataField: 'motivo',
                    text: 'Motivo',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                }, {
                    dataField: 'modulo',
                    text: 'Módulo',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                }, {
                    dataField: 'estatus',
                    isDummyField: true,
                    text: 'Estatus',
                    formatter: (cellContent, row) => {
                        return row.estatus === 'R'
                            ? <Check size={24} color="#7edf77" title="Resuelto" />
                            : <HourglassSplit size={24} color="#e62f40" title="Pendiente" />
                    }
                }, {
                    dataField: 'acciones',
                    text: 'Acciones',
                    isDummyField: true,
                    formatter: (cellContent, row) => {
                        return (
                            <div>
                                <Button id="consultarSolicitud" className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Consultar"
                                    onClick={() => getSolicitud(row)}>
                                    <Eye size={22} className="button-default-black" color="#000" />
                                </Button>
                                {
                                    userType === 'SCHEMA_ADMIN' ?
                                        row.estatus === 'P' ?
                                            <Button id="resuelto" className="button-default-black" variant="light" size="sm"
                                                data-toggle="tooltip" data-placement="bottom" title="Cambiar a resuelto"
                                                onClick={() => updateEstatus(row)}
                                            ><Toggle2Off size={22} className="button-default-black" color="#000" />
                                            </Button>
                                            : <Button id="pendiente" className="button-default-black" variant="light" size="sm"
                                                data-toggle="tooltip" data-placement="bottom" title="Cambiar a pendiente"
                                                onClick={() => updateEstatus(row)}>
                                                <Toggle2On size={22} className="button-default-black" color="#000"></Toggle2On>
                                            </Button>
                                        :
                                        ''
                                }
                            </div>
                        )
                    }
                }
            ];

            const columns = [{
                id: 'titleSolicitud',
                dataField: 'solicitud',
                text: '',
                isDummyField: true,
                formatter: (cellContent, row) => {
                    return (
                        <div className="font-title-solicitudes">
                            <div >{'Solicitud'}</div>
                        </div>
                    )
                },
            }, {
                dataField: 'id',
                text: 'ID',
                isDummyField: true,
                filter: textFilter({
                    placeholder: 'Buscar...',
                    className: "form-control-sm"
                }),
                sort: true
            }, {
                dataField: 'fecha',
                text: 'fecha',
                isDummyField: true,
                filter: textFilter({
                    placeholder: 'Buscar...',
                    className: "form-control-sm"
                }),
                sort: true
            }, {
                dataField: 'solicitante',
                text: 'Solicitante',
                isDummyField: true,
                filter: textFilter({
                    placeholder: 'Buscar...',
                    className: "form-control-sm"
                }),
                sort: true
            }, {
                dataField: 'correoElectronico',
                text: 'Correo Electrónico',
                isDummyField: true,
                filter: textFilter({
                    placeholder: 'Buscar...',
                    className: "form-control-sm"
                }),
                sort: true
            }, {
                dataField: 'motivo',
                text: 'Motivo',
                isDummyField: true,
                filter: textFilter({
                    placeholder: 'Buscar...',
                    className: "form-control-sm"
                }),
                sort: true
            },
            {
                dataField: 'modulo',
                text: 'Módulo',
                isDummyField: true,
                filter: textFilter({
                    placeholder: 'Buscar...',
                    className: "form-control-sm"
                }),
                sort: true
            }, {
                dataField: 'acciones',
                isDummyField: true,
                text: '',
                formatter: (cellContent, row) => {
                    return (
                        <div className="d-flex justify-content-center solicitud-tbody-tr-td-div">
                            <ButtonGroup >
                                <Button id="consultarSolicitud" className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Consultar"
                                    onClick={() => getSolicitud(row)}>
                                    <Eye size={22} className="button-default-black" color="#000" />
                                </Button>
                                {
                                    userType === 'SCHEMA_ADMIN' ?
                                        row.estatus === 'P' ?
                                            <Button id="resuelto" className="button-default-black" variant="light" size="sm"
                                                data-toggle="tooltip" data-placement="bottom" title="Cambiar a resuelto"
                                                onClick={() => updateEstatus(row)}
                                            ><Toggle2Off size={22} className="button-default-black" color="#000" />
                                            </Button>
                                            : <Button id="pendiente" className="button-default-black" variant="light" size="sm"
                                                data-toggle="tooltip" data-placement="bottom" title="Cambiar a pendiente"
                                                onClick={() => updateEstatus(row)}>
                                                <Toggle2On size={22} className="button-default-black" color="#000"></Toggle2On>
                                            </Button>
                                        :
                                        ''
                                }
                            </ButtonGroup>
                        </div>
                    )
                }
            }, {
                dataField: 'rowOne',
                text: '',
                isDummyField: true,
                formatter: (cellContent, row) => {
                    return (
                        <div className="d-flex justify-content-around">
                            <div>
                                <div className="solicitud-tbody-tr-td-div">
                                    <div className="font-col-solicitud">{'ID: '}</div>
                                    <div>{row.id}</div>
                                </div>
                            </div>
                            <div>
                                <div className="solicitud-tbody-tr-td-div">
                                    <div className="font-col-solicitud">{'Solicitante: '}</div>
                                    <div>{row.solicitante}</div>
                                </div>
                            </div>
                        </div>
                    )
                }
            }, {
                dataField: 'rowTwo',
                text: '',
                isDummyField: true,
                formatter: (cellContent, row) => {
                    return (
                        <div className="d-flex justify-content-around">
                            <div>
                                <div>
                                    <div className="solicitud-tbody-tr-td-div">
                                        <div className="font-col-solicitud">{'Fecha: '}</div>
                                        <div>{Moment(row.fecha).format('DD/MM/YYYY')}</div>
                                    </div>
                                </div>
                                <div>
                                    <div className="solicitud-tbody-tr-td-div">
                                        <div className="solicitudfont-col-">{'Motivo: '}</div>
                                        <div>{row.motivo}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            }, {
                dataField: 'rowThree',
                text: '',
                isDummyField: true,
                formatter: (cellContent, row) => {
                    return (
                        <div className="d-flex justify-content-around">
                            <div>
                                <div>
                                    <div className="solicitud-tbody-tr-td-div">
                                        <div className="font-col-solicitud">{'Módulo: '}</div>
                                        <div>{row.modulo}</div>
                                    </div>
                                </div>
                                <div>
                                    <div className="solicitud-tbody-tr-td-div">
                                        <div className="font-col-solicitud">{'Estatus: '}</div>
                                        <div></div>
                                        <div>{row.estatus === 'P' ? 'Pendiente' : 'Resuelto'}
                                            {row.estatus === 'P'
                                                ? <HourglassSplit size={24} color="#e62f40" title="Pendiente" />
                                                : <Check size={24} color="#7edf77" title="Resuelto" />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            },
            ];

            function indication() {
                return listaSolicitudes.length ? "Cargando..." : "No hay registros que mostrar";
            }

            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size}
                </span>
            );

            const options = {
                custom: true,
                sizePerPage: 10,
                totalSize: listaSolicitudes.length,
                paginationTotalRenderer: customTotal
            }

            return <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div>
                        <div className="d-flex ml-auto mb-2 flex-row-reverse">
                            {gridCards ?
                                <Button className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Listado"
                                    onClick={() => { setGridCards(false) }}
                                >
                                    <ListTask size={24} className="button-default-black" color="#000" />
                                </Button> :
                                <Button className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Cuadrícula"
                                    onClick={() => { setGridCards(true) }}
                                >
                                    <Grid3x2GapFill size={24} className="button-default-black" color="#000" />
                                </Button>
                            }
                        </div>
                        <div className="d-flex">
                            {gridCards ? '' :
                                <div className="d-flex flex-row">
                                    <Form.Label column>Mostrar</Form.Label>
                                    <Col>
                                        {/* <Form.Control size="sm" as="select" onChange={(e) => handleSizePerPage(paginationProps, e.target.value)}>
                                            <option value="4">4</option>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value={listaSolicitudes.length}>Todos</option>
                                        </Form.Control> */}
                                        <SizePerPageDropdownStandalone btnContextual {...paginationProps} />
                                    </Col>
                                </div>
                            }
                            <div className="d-flex flex-row">
                                <Col>
                                    <PaginationTotalStandalone {...paginationProps} />
                                </Col>
                            </div>
                            <div className="d-flex ml-auto flex-row-reverse">
                                <Col>
                                    <PaginationListStandalone {...paginationProps} />
                                </Col>
                                <Form.Label column>Página</Form.Label>
                            </div>
                        </div>
                        {
                            gridCards ?
                                <div className="table-responsive table-sm">
                                    <BootstrapTable
                                        id="solicitudes"
                                        keyField='id'
                                        data={listaSolicitudes}
                                        columns={columns}
                                        filter={filterFactory()}
                                        bordered={false}
                                        noDataIndication={indication}
                                        {...paginationTableProps}
                                    />
                                </div>
                                :
                                <div className="table-responsive table-sm text-center">
                                    <BootstrapTable
                                        keyField='id'
                                        data={listaSolicitudes}
                                        columns={columnsList}
                                        filter={filterFactory()}
                                        headerClasses="header-class-soporte"
                                        bordered={false}
                                        striped
                                        hover
                                        condensed
                                        noDataIndication={indication}
                                        {...paginationTableProps}
                                    />
                                </div>
                        }
                    </div>
                )}
            </PaginationProvider >
        }
    }

    return (
        <section id="listaSolicitudes" className="text-center">
            <Card id="cardListaSolicitudes">
                <div>
                    {solicitud !== '' ? <ModalConsultarSolicitud show={modalConsultarSolicitudShow} onHide={modalConsultarSolicitudClose}>{solicitud}</ModalConsultarSolicitud> : ''}
                    {estatus !== '' ? <ModalUpdateEstatus show={modalUpdateEstatusShow} onHide={modalUpdateEstatusClose} onUpdate={updateListado}>{estatus}</ModalUpdateEstatus> : ''}
                </div>
                <Card.Body className="cardBodySolicitudes">
                    {request ? "Cargando..." : mostrarListaSolicitudes()}
                </Card.Body>
            </Card>
        </section>
    )
}

export default ListaSolicitudes;