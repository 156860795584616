const formaPagoSAT = [
    { clave: "01", nombre: "Efectivo", bancarizado: false },
    { clave: "02", nombre: "Cheque nominativo", bancarizado: true, patronCuentaOrdenante: "[0-9]{11}|[0-9]{18}", patronCuentaBeneficiaria: "[0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}" },
    { clave: "03", nombre: "Transferencia electrónica de fondos", bancarizado: true, patronCuentaOrdenante: "[0-9]{10}|[0-9]{16}|[0-9]{18}", patronCuentaBeneficiaria: "[0-9]{10}|[0-9]{18}" },
    { clave: "04", nombre: "Tarjeta de crédito", bancarizado: true, patronCuentaOrdenante: "[0-9]{16}", patronCuentaBeneficiaria: "[0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}" },
    { clave: "05", nombre: "Monedero electrónico", bancarizado: true, patronCuentaOrdenante: "[0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}", patronCuentaBeneficiaria: "[0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}" },
    { clave: "06", nombre: "Dinero electrónico", bancarizado: true, patronCuentaOrdenante: "[0-9]{10}" },
    { clave: "08", nombre: "Vales de despensa", bancarizado: false },
    { clave: "12", nombre: "Dación en pago", bancarizado: false },
    { clave: "13", nombre: "Pago por subrogación", bancarizado: false },
    { clave: "14", nombre: "Pago por consignación", bancarizado: false },
    { clave: "15", nombre: "Condonación", bancarizado: false },
    { clave: "17", nombre: "Compensación", bancarizado: false },
    { clave: "23", nombre: "Novación", bancarizado: false },
    { clave: "24", nombre: "Confusión", bancarizado: false },
    { clave: "25", nombre: "Remisión de deuda", bancarizado: false },
    { clave: "26", nombre: "Prescripción o caducidad", bancarizado: false },
    { clave: "27", nombre: "A satisfacción del acreedor", bancarizado: false },
    { clave: "28", nombre: "Tarjeta de débito", bancarizado: true, patronCuentaOrdenante: "[0-9]{16}", patronCuentaBeneficiaria: "[0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}" },
    { clave: "29", nombre: "Tarjeta de servicios", bancarizado: true, patronCuentaOrdenante: "[0-9]{15,16}", patronCuentaBeneficiaria: "[0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50}" },
    { clave: "30", nombre: "Aplicación de anticipos", bancarizado: false },
    { clave: "31", nombre: "Intermediario pagos", bancarizado: false },
    { clave: "99", nombre: "Por definir", bancarizado: true, patronCuentaOrdenante: "OPCIONAL", patronCuentaBeneficiaria: "OPCIONAL" },
];

export { formaPagoSAT };