import React, { useState } from "react";
import axios from 'axios';
import { API_PROVEEDORES_URL } from '../../../constants';

//Estilos
import "../css/CuentasBancarias.css"
import { Trash } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';

//Elementos bootstrap
import { Modal } from "react-bootstrap";

function ModalBorrarCuenta(props) {
    const [status, setStatus] = useState(false);

    function deleteCuenta(e) {
        e.preventDefault();
        setStatus(true);
        axios.post(API_PROVEEDORES_URL + 'deleteCuenta',
            {
                'idProveedor': props.children.idProveedor,
                'idCuenta': props.children.idProveedorCuenta
            }
        ).then((res) => {
            if (res.data.result.success) {
                setStatus(false);
                toast.success(res.data.result.message,{
                    position:"top-center",
                    autoClose: 4500, 
                });
                props.onHide();//Cierra el modal, llamando a la función padre para cerrarlo
                props.onUpdate();//Actualiza el listado, llamando a la función padre para actualizarlo                
            } else {
                setStatus(false);
                toast.error(res.data.result.message,{
                    position:"top-center",
                    autoClose: 4500, 
                });
                toast.error(res.data.result.suggestion,{
                    position:"top-center",
                    autoClose: 4500, 
                });
            }
        }).catch((error) => {
            toast.error("No se ha podido eliminar la cuenta bancaria",{
                position:"top-center",
                autoClose: 4500, 
            });
            setStatus(false);
        });
    }

    return (
        <section className="text-center">
            <Modal
                show={props.show}
                //onHide={props.onHide}
                size="sm"
                id="modalDeleteCuenta"
                aria-labelledby="example-modal-sizes-title-sm"
                centered
            >
                <Modal.Header className="modal-header-eliminar-cuenta" closeButton onHide={props.onHide}>
                    <h5 className="legend-eliminar-cuenta"><Trash size={22} color="#5B4B51" />   Eliminar cuenta bancaria</h5>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {/*<CreditCard2Front size={22} color="#da6570" />*/}
                        &nbsp;¿Está seguro que desea eliminar esta cuenta?
                    </p>
                    <p>Banco: <strong>{props.children.banco}</strong></p>
                    <p>Cuenta: <strong>{props.children.cuenta}</strong></p>
                    <p>CLABE: <strong>{props.children.cuentaClabe}</strong></p>
                    <div className="d-flex justify-content-center">
                        <button
                            className="m-1 button-default-confirmar"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Confirmar"
                            onClick={deleteCuenta}
                        >{status ?
                            <span>Eliminado...</span>
                            : <span>Confirmar</span>}
                        </button>
                        <button
                            className="m-1 button-default-cancelar"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Cancelar"
                            onClick={props.onHide}
                        ><span>Cancelar</span>
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    );
}
export default ModalBorrarCuenta;