import React, { useState } from "react";
import axios from 'axios';
import { KRAKEN_URL } from '../../constants'

//Estilos
import './css/Configuracion.css'
import { Card, Form, Col } from 'react-bootstrap'
import { toast } from 'react-toastify';

function CambiarContrasena() {
    const [deactivate, setDeactivate] = useState(false);
    const token = JSON.parse(localStorage.getItem('token'));
    const username = token.username;
    const userId = token.userId;
    const [status, setStatus] = useState(false);
    const [nuevaContrasena, setNuevaContrasena] = useState('');
    const [confirmarContrasena, setConfirmarContrasena] = useState('');

    function handleNuevaContrasenaChange(e) {
        setNuevaContrasena(e.target.value);
    }

    function handleConfirmarContrasenaChange(e) {
        setConfirmarContrasena(e.target.value);
    }

    function changePassword() {
        setStatus(true);
        if (nuevaContrasena === confirmarContrasena) {
            axios.post(KRAKEN_URL + 'empresa/usuarios/changePassword',
                {
                    userId: userId,
                    newPassword: nuevaContrasena
                }
            ).then((res) => {
                if (res.data.result.success) {
                    setStatus(false);
                    
                    toast.success(res.data.result.message,{
                        position:"top-center",
                        autoClose: 4500, 
                    });
                } else {
                    setStatus(false);
                    toast.error(res.data.result.message,{
                        position:"top-center",
                        autoClose: 4500, 
                    });
                    toast.error(res.data.result.suggestion,{
                        position:"top-center",
                        autoClose: 4500, 
                    });
                }
            }).catch((error) => {
                setStatus(false);

                toast.error("No se ha podido cambiar la contraseña",{
                    position:"top-center",
                    autoClose: 4500, 
                })
            })
        } else {
            setStatus(false);

            setDeactivate(true);

            setTimeout(() => {
                setDeactivate(false);
            }, 4500);

            toast.warning("Las contraseñas no coinciden",{
                position:"top-center",
                autoClose: 4500, 
            });
        }
    }

    return (
        <section className="text-center">
            <Card id="cardCambiarContrasena">
                <Card.Body className="justify-content-center">
                    <Form.Row as={Col}>
                        <Form.Group as={Col} >
                            <Form.Label>Usuario</Form.Label>
                            <Form.Control size="sm" type="text" disabled defaultValue={username}></Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} >
                            <Form.Label>Nueva contraseña</Form.Label>
                            <Form.Control size="sm" type="password" value={nuevaContrasena} onChange={handleNuevaContrasenaChange}></Form.Control>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row as={Col}>
                        <Form.Group as={Col}></Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Confirmar contraseña</Form.Label>
                            <Form.Control size="sm" type="password" value={confirmarContrasena} onChange={handleConfirmarContrasenaChange}></Form.Control>
                        </Form.Group>
                    </Form.Row>
                </Card.Body>
                <Card.Footer className="justify-content-center">
                    <button
                        className="m-1 button-default-confirmar"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Cambiar contraseña"
                        disabled = { deactivate }
                        onClick={changePassword}
                    >{status ?
                        <span>Enviando...</span>
                        : <span>Cambiar contraseña</span>
                        }
                    </button>
                </Card.Footer>
            </Card>
        </section>
    )
}

export default CambiarContrasena;