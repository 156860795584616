import React, { useState, useEffect } from "react";
import axios from 'axios'
import { useSelector } from 'react-redux';
import { API_PROVEEDORES_URL } from '../../constants'
//Estilos
import "../CFDIs/css/Cfdis.css"
import "../CFDIs/css/CfdiPasados.css"
import { Eye, FileEarmarkSpreadsheet, FileEarmarkCode, ListTask, Grid3x2GapFill, CaretDownFill, CaretUpFill,PeopleFill } from 'react-bootstrap-icons';
//Elementos bootstrap
import { Card, Form, Col, ButtonGroup, Button, Tabs, Tab, Badge } from "react-bootstrap";
//Componentes
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import Moment from 'moment';
import NumberFormat from 'react-number-format';
import ModalConsultaFacturas from './ModalConsultaFacturas';
import ModalSubirPDF from './ModalSubirPDF';
import ModalSubirXML from './ModalSubirXML';
import Loader from "../Loader";

function CfdiPasados() {
    const token = JSON.parse(localStorage.getItem('token'));
    const empresasDisponibles = useSelector(state => state.empresas.empresasDisponibles);
    const empresas = empresasDisponibles.map(e => { return e.empresa; });
    const empresaDefault = empresas[0];
    const [key, setKey] = useState(empresaDefault);
    const [error, setError] = useState(false);
    const [cargando, setCargando] = useState(false);
    const [statusError, setStatusError] = useState('');
    const [listEmpresas, setListEmpresas] = useState([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getCfdisPasados, []);

    function getCfdisPasados() {
        let user = JSON.parse(localStorage.getItem('token'));
        setCargando(true);
        axios.post(API_PROVEEDORES_URL + 'cfdiPasados',
            {
                empresas: empresas
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                user.userType === 'SCHEMA_ADMIN'? filterDataToMaster(res.data.pagos) : 
                filterDataToUser(res.data.pagos);

                setListEmpresas(res.data.pagos);
            } else {
                setError(true);
                setStatusError(res.data.result.message);
            }
        }).catch((error) => {
            setError(true);
            setStatusError("Ocurrió un error, no se pudieron recuperar los CFDI's pasados");
        }).finally(() => {
            setCargando(false);
        })
    }
    function filterDataToUser(empresas){ 
        empresas.map((empresa)=>{
         empresa.lista = empresa.lista.filter(function(objeto) {
            return ((objeto.solicitante ===token.username)||objeto.usuarioCompartido ===token.username||objeto.pagadorId ===token.userId );
         });
         })
         return empresas;
     }
 
     function filterDataToMaster(empresas){
         empresas.map((empresa)=>{
        
             empresa.lista = empresa.lista.filter(function(objeto) {
                 
                 return (objeto.usuarioCompartido !==token.username );
             });
             })
             return empresas;
 
     }

    function createTabs() {
        let empresasTabs;
        listEmpresas.length ?
            empresasTabs = listEmpresas.map(empresa => (
                <Tab key={empresa.id} eventKey={empresa.id} title={empresa.nombreEmpresa}>
                    <ListadoCfdiPasados>{empresa.lista}</ListadoCfdiPasados>
                </Tab>
            )) : empresasTabs = empresas.map(empresa => (
                <Tab key={empresa} eventKey={empresa} title={empresa}>
                    <section id="listaCfdiPasados">
                        <Card id="cardCfdiPasados">
                            <Card.Body className="cardBodyCfdiPasados">
                                {error ? statusError : 'Error: No se ha podido cargar la información'}
                            </Card.Body>
                        </Card>
                    </section>
                </Tab>
            ))
        return empresasTabs;
    }

    if (cargando) return <Loader />

    return (
        <div >
            <Tabs id="controlled-tab-cfdis" activeKey={key} onSelect={(k) => setKey(k)}>
                {createTabs()}
            </Tabs>
        </div>
    );
}

function ListadoCfdiPasados(props) {
    const token =JSON.parse(localStorage.getItem('token'))
    const [listaPasados] = useState(eliminarObjetosCompartidos(props.children));
    const [gridCards, setGridCards] = useState(false);
    const [error] = useState(false);
    const [request] = useState(false);
    const [modalConsultaShow, setModalConsultaShow] = useState(false);
    const [modalPDFShow, setModalPDFShow] = useState(false);
    const [modalXMLShow, setModalXMLShow] = useState(false);
    const [factConsulta, setFactConsulta] = useState('');
    const [factPdf, setFactPdf] = useState('');
    const [factXml, setFactXml] = useState('');
    function eliminarObjetosCompartidos(arreglo) {
        arreglo = arreglo.filter(function(objeto) {
        
            return !(objeto.esCompartida === 1 && (objeto.usuarioCompartido !== token.username && objeto.usuarioCompartido !== ""));
        });
        return arreglo;
    }
    let modalConsultaClose = () => { setModalConsultaShow(false); setFactConsulta('') };
    let modalPDFClose = () => { setModalPDFShow(false); setFactPdf('') }
    let modalXMLClose = () => { setModalXMLShow(false); setFactXml('') }

    function getConsulta(pago) {
        console.log(".22222222.......")
        console.log(pago)
        setFactConsulta(pago);
        setModalConsultaShow(true);
    }

    function subirPdf(pago) {
        setFactPdf(pago);
        setModalPDFShow(true);
    }

    function subirXml(pago) {
        setFactXml(pago);
        setModalXMLShow(true);
    }

    let mostrarListaPendientes = () => {
        if (error) {
            return (
                <div>Error: No se han podido recuperar las requisiciones pendientes</div>
            );
        } else {
            const columnsList = [
                {
                    dataField: 'id',
                    text: 'ID',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'concepto',
                    text: 'Concepto',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'solicitante',
                    text: 'Solicitante',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'proveedorRfc',
                    text: 'RFC Proveedor',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'proveedor',
                    text: 'Proveedor',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true,
                    footer: '',
                },
                {
                    dataField: 'fechaRequeridaPago',
                    text: 'Fecha requerida',
                    formatter: (cellContent, row) => {
                        return (
                            Moment(row.fechaRequeridaPago).format('DD/MM/YYYY')
                        )
                    },
                    sort: true,
                    footer: 'Totales:',
                    sortCaret: (order, column) => {
                        if (!order) return (<span>&nbsp;&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000" /><CaretUpFill size={10} className="button-default-black" color="#000" /></span>);
                        else if (order === "desc") return (<span>&nbsp;&nbsp;<CaretUpFill size={10} className="button-default-black" color="#000" /><CaretDownFill size={10} className="button-default-black" color="#dee2e6" /></span>);
                        else if (order === "asc") return (<span>&nbsp;&nbsp;<CaretUpFill size={10} className="button-default-black" color="#dee2e6" /><CaretDownFill size={10} className="button-default-black" color="#000" /></span>);
                        return null;
                    }
                },
                {
                    dataField: 'importePesosMexicanos',
                    text: 'Total',
                    sort: true,
                    footer: columnData => {
                        let sumatoria = columnData.reduce((acc, item) => acc + item, 0).toFixed(2)
                        return (<h5><Badge variant="success">
                            <NumberFormat displayType={'text'} thousandSeparator={true} value={sumatoria} />
                        </Badge></h5>)
                    },
                    sortCaret: (order, column) => {
                        if (!order) return (<span>&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000" /><CaretUpFill size={10} className="button-default-black" color="#000" /></span>);
                        else if (order === "desc") return (<span>&nbsp;<CaretUpFill size={10} className="button-default-black" color="#000" /><CaretDownFill size={10} className="button-default-black" color="#dee2e6" /></span>);
                        else if (order === "asc") return (<span>&nbsp;<CaretUpFill size={10} className="button-default-black" color="#dee2e6" /><CaretDownFill size={10} className="button-default-black" color="#000" /></span>);
                        return null;
                    },
                    formatter: (cellContent, row) => {
                        return (
                            <NumberFormat
                                displayType={'text'}
                                thousandSeparator={true}
                                value={row.importePesosMexicanos.toFixed(2/*row.monedaDecimales*/)}
                            ></NumberFormat>
                        )
                    }
                },
                {
                    dataField: '',
                    text: 'Acciones',
                    isDummyField: true,
                    formatter: (cellContent, row) => {
                        return (
                            <div >
                                <Button className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Ver"
                                    onClick={() => getConsulta(row)}>
                                    <Eye size={22} color="#000" className="button-default-black" />
                                </Button>
                                {row.esCompartida!==1 ?( 
                                <Button className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Subir PDF"
                                    onClick={() => subirPdf(row)}>
                                    <FileEarmarkSpreadsheet size={22} color="#000" className="button-default-black"></FileEarmarkSpreadsheet>
                                </Button>):""}
                                    
                                {row.esCompartida!==1 ?( 
                                <Button className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Subir XML"
                                    onClick={() => subirXml(row)}>
                                    <FileEarmarkCode size={22} color="#000" className="button-default-black"></FileEarmarkCode>
                                    </Button>):""}
                                    {row.compartidos!=="[]" ? (<Button  className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Compartida"                         
                                ><PeopleFill size={22} className="button-default-black" color="#000" />
                                </Button>):""} 
                            </div>
                        );
                    }
                }
            ];

            const columns = [
                {
                    id: 'titlePasado',
                    dataField: 'pasado',
                    text: '',
                    isDummyField: true,
                    formatter: (cellContent, row) => {
                        return (
                            <div className="font-title-cfdi-pasados">
                                <div >{'Pagado'}</div>
                            </div>
                        )
                    },
                },
                {
                    dataField: 'id',
                    text: 'ID',
                    isDummyField: true,
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                },
                {
                    dataField: 'concepto',
                    text: 'Concepto',
                    isDummyField: true,
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                }, {
                    dataField: 'proveedorRfc',
                    text: 'RFC Proveedor',
                    isDummyField: true,
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                }, {
                    dataField: 'proveedor',
                    text: 'Proveedor',
                    isDummyField: true,
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                },
                {
                    dataField: 'rowOne',
                    text: '',
                    isDummyField: true,
                    formatter: (cellContent, row) => {
                        return (
                            <div className="d-flex justify-content-around cfdi-tbody-tr-td-div">
                                <div>
                                    <div className="cfdi-tbody-tr-td-div">
                                        <div className="font-col-cfdi">{'ID: '}</div>
                                        <div>{row.id}</div>
                                    </div>
                                    <div className="cfdi-tbody-tr-td-div">
                                        <div className="font-col-cfdi">{'Empresa: '}</div>
                                        <div>{row.empresa}</div>
                                    </div>
                                </div>
                                <div>
                                    <div className="cfdi-tbody-tr-td-div">
                                        <div className="font-col-cfdi">{'Fecha del pago: '}</div>
                                        <div>{Moment(row.fechaRequeridaPago).format('DD/MM/YYYY')}</div>
                                    </div>
                                    <div className="cfdi-tbody-tr-td-div">
                                        <div className="font-col-cfdi">{'Saldo pendiente: '}</div>
                                        <NumberFormat
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            value={row.saldoPendiente.toFixed(2/*row.monedaDecimalesSat*/)}
                                        ></NumberFormat>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                },
                {
                    dataField: 'rowTwo',
                    text: '',
                    isDummyField: true,
                    formatter: (cellContent, row) => {
                        return (
                            <div>
                                <div className="d-flex justify-content-center cfdi-tbody-tr-td-div">
                                    <ButtonGroup >
                                        <button className="button-default-black" title="Ver" onClick={() => getConsulta(row)}>
                                            <Eye size={22} color="#000" className="button-default-black" />
                                        </button>
                                        {row.esCompartida!==1 ?( 
                                    <button className="button-default-black" title="Subir PDF" onClick={() => subirPdf(row)}>
                                        <FileEarmarkSpreadsheet size={22} color="#000" className="button-default-black"></FileEarmarkSpreadsheet>
                                    </button>):""}
                                    {row.esCompartida!==1 ?( 
                                    <button className="button-default-black" title="Subir XML" onClick={() => subirXml(row)}>
                                        <FileEarmarkCode size={22} color="#000" className="button-default-black"></FileEarmarkCode>
                                        </button>):""}
                                    {row.compartidos!=="[]" ? (<Button  className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Compartida"                         
                                ><PeopleFill size={22} className="button-default-black" color="#000" />
                                </Button>):""} 
                                    </ButtonGroup>
                                </div>
                                <div className="cfdi-tbody-tr-td-div font-rowTwo-cfdi-pasados">
                                    <div>
                                        <div className="cfdi-tbody-tr-td-div">
                                            <div className="font-col-cfdi">{'Concepto: '}</div>
                                            <div>{row.concepto}</div>
                                        </div>
                                        <div className="cfdi-tbody-tr-td-div">
                                            <div className="font-col-cfdi">{'Solicitante: '}</div>
                                            <div>{row.solicitante}</div>
                                        </div>
                                        <div className="cfdi-tbody-tr-td-div">
                                            <div className="font-col-cfdi">{'Proveedor: '}</div>
                                            <div>{row.proveedor}</div>
                                        </div>
                                        <div className="cfdi-tbody-tr-td-div">
                                            <div className="font-col-cfdi">{'RFC Proveedor: '}</div>
                                            <div>{row.proveedorRfc}</div>
                                        </div>
                                    </div>
                                    <div >
                                        <div className="cfdi-tbody-tr-td-div">
                                            <div className="font-col-cfdi">{'Método de pago: '}</div>
                                            <div>{row.metodoPagoClaveSat}</div>
                                        </div>
                                        <div className="cfdi-tbody-tr-td-div">
                                            <div className="font-col-cfdi">{'Total: '}</div>
                                            <NumberFormat
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                value={row.total.toFixed(2/*row.monedaDecimalesSat*/)}
                                            ></NumberFormat>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                },
            ];

            function indication() {
                return listaPasados.length ? "La búsqueda no coincide con ningún criterio" : "No hay registros que mostrar";
            }

            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size}
                </span>
            );

            const options = {
                custom: true,
                sizePerPage: gridCards ? 4 : 10,
                totalSize: listaPasados.length,
                paginationTotalRenderer: customTotal
            }

            return <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div>
                        <div className="d-flex ml-auto mb-2 flex-row-reverse">
                            {gridCards ?
                                <Button className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Listado"
                                    onClick={() => { setGridCards(false) }}
                                >
                                    <ListTask size={24} className="button-default-black" color="#000" />
                                </Button> :
                                <Button className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Cuadrícula"
                                    onClick={() => { setGridCards(true) }}
                                >
                                    <Grid3x2GapFill size={24} className="button-default-black" color="#000" />
                                </Button>
                            }
                        </div>
                        <div className="d-flex">
                            {gridCards ? '' :
                                <div className="d-flex flex-row">
                                    <Form.Label column>Mostrar</Form.Label>
                                    <Col>
                                        <SizePerPageDropdownStandalone btnContextual {...paginationProps}/>
                                    </Col>
                                </div>}
                            <div className="d-flex flex-row">
                                <Col>
                                    <PaginationTotalStandalone {...paginationProps} />
                                </Col>
                            </div>
                            <div className="d-flex ml-auto flex-row-reverse">
                                <Col>
                                    <PaginationListStandalone {...paginationProps} />
                                </Col>
                                <Form.Label column>Página</Form.Label>
                            </div>
                        </div>
                        {
                            gridCards ?
                                <div className="table-responsive table-sm">
                                    <BootstrapTable
                                        id="cfdiPasados"
                                        keyField='id'
                                        data={listaPasados}
                                        columns={columns}
                                        filter={filterFactory()}
                                        bordered={false}
                                        noDataIndication={indication}
                                        {...paginationTableProps}
                                    />
                                </div>
                                :
                                <div className="table-responsive table-sm text-center">
                                    <BootstrapTable
                                        keyField='id'
                                        data={listaPasados}
                                        columns={columnsList}
                                        filter={filterFactory()}
                                        headerClasses="header-class-pago"
                                        bordered={false}
                                        striped
                                        hover
                                        condensed
                                        noDataIndication={indication}
                                        {...paginationTableProps}
                                    />
                                </div>
                        }
                    </div>
                )}
            </PaginationProvider >
        }
    }

    return (
        <section id="listaCfdiPasados">
            <Card id="cardCfdiPasados">
                <div>
                    {modalConsultaShow ? <ModalConsultaFacturas show={modalConsultaShow} onHide={modalConsultaClose}>{factConsulta}</ModalConsultaFacturas> : ''}
                    {modalPDFShow ? <ModalSubirPDF show={modalPDFShow} onHide={modalPDFClose}>{factPdf}</ModalSubirPDF> : ''}
                    {modalXMLShow ? <ModalSubirXML show={modalXMLShow} onHide={modalXMLClose}>{factXml}</ModalSubirXML> : ''}
                </div>
                <Card.Body className="cardBodyCfdiPasados">
                    {request ? "Cargando..." : mostrarListaPendientes()}
                </Card.Body>
            </Card>
        </section>
    );
}

export default CfdiPasados;