import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_PROVEEDORES_URL } from '../../constants'

//Componentes bootstrap
import { Button, Col, Form } from 'react-bootstrap';
import { FileArrowDown, PencilSquare } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';

//Componentes
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import Moment from 'moment';
import { base64ToArrayBuffer, saveByteArray } from '../../constantes/download-file-handler'
import ModalEditarPago from './ModalEditarPago'

function ListadoPagosAnteriores(props) {    
    const token = JSON.parse(localStorage.getItem('token'));
    const userType = token.userType;
    const idRequisicion = props.children[0];
    const idEmpresa = props.children[1];
    const id = props.children[2];
    const [error, setError] = useState(false);
    const [status, setStatus] = useState('');
    const [listaPagosAnteriores, setListaPagosAnteriores] = useState([]);
    const [pago, setPago] = useState('');

    const [modalEditarPagoShow, setModalEditarPagoShow] = useState(false);
    const [updateListado, setUpdateListado] = useState(false);
    let modalEditarPagoClose = () => { setModalEditarPagoShow(false); setPago(''); }
    let updateGetListado = () => { setUpdateListado(!!!updateListado); props.onUpdate(); };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getListadoPagosAnteriores, [updateListado]);//Se actualiza cada que se elige una requisición diferente*/

    function getListadoPagosAnteriores() {
        axios.post(API_PROVEEDORES_URL + 'getPagosAnteriores',
            {
                "idRequisicion": idRequisicion
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                setListaPagosAnteriores(res.data.list)
            } else {
                setError(true);
                setStatus(res.data.result.message);
            }
        }).catch((error) => {
            setError(true);
            setStatus("Error: No se han podido recuperar los registros de los pagos anteriores.")
        })
    };

    function getPago(pago) {
        axios.post(API_PROVEEDORES_URL + 'getPago',
            {
                idPago: pago.idPago
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                setPago(res.data.pago);
                setModalEditarPagoShow(true);
            } else {
                setError(true);
                setStatus(res.data.result.message);
            }
        }).catch((error) => {
            setError(true);
            setStatus("Error: No se ha podido recuperar el pago solicitado");
        })
    }

    function getArchivoDownload(archivo) {
        axios.post(API_PROVEEDORES_URL + 'downloadComprobantePago',
            {
                idEmpresa: idEmpresa,
                idRequisicion: archivo.id.idRequisicion,
                nombre: archivo.id.archivo,
                ruta: archivo.id.ruta
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                let arrayBuffer = base64ToArrayBuffer(res.data.bytes);
                saveByteArray(archivo.id.archivo, archivo.id.type, arrayBuffer);
            } else {
                toast.warning(res.data.result.message,{
                    position:"top-center",
                    autoClose: 4500, 
                });
            }
        }).catch((error) => {
            toast.error("No se ha podido descargar el archivo",{
                position:"top-center",
                autoClose: 4500, 
            });
        })
    }

    let handleSizePerPage = ({ page, onSizePerPageChange }, newSizePerPage) => {
        onSizePerPageChange(newSizePerPage, page);
    }

    let listarPagosAnteriores = () => {
        if (error) {
            return (
                <div>{status}</div>
            );
        } else {
            const columns = [
                {
                    dataField: 'id.fechaPago',
                    text: 'Fecha pago',
                    formatter: (cellContent, row) => {
                        let stringDate = row.id.fechaPago.substring(0, 10);
                        return (
                            Moment(stringDate).format('DD/MM/YYYY')
                        )
                    }
                }, {
                    dataField: 'id.id',
                    text: 'ID Pago'
                }, {
                    dataField: 'id.banco',
                    text: 'Banco'
                }, {
                    dataField: 'id.referencia',
                    text: 'Referencia'
                }, {
                    dataField: 'id.saldoPagado',
                    text: 'Saldo pagado',
                    formatter: (cellContent, row) => {
                        let fillwithZeros = row.id.saldoPagado.toFixed(2);
                        //return fillwithZeros + ' ' + row.monedaClaveSat;
                        return fillwithZeros;
                    }
                }, {
                    dataField: 'archivo',
                    isDummyField: true,
                    text: 'Pago',
                    formatter: (cellContent, row) => {
                        return row.id.archivo
                            ?
                            <Button variant="light" size="sm" className="button-default-black-transparent" title="Descargar archivo"
                                onClick={() => getArchivoDownload(row)}>
                                <FileArrowDown size={24} className="button-default-black-transparent" color="#000" />
                            </Button>
                            : ''
                    }
                },
                userType === 'ADMINISTRADOR' || userType === 'SCHEMA_ADMIN' ? {
                    dataField: 'editarPago',
                    isDummyField: true,
                    text: 'Editar',
                    formatter: (cellContent, row) => {
                        return <Button variant="light" size="sm" className="button-default-black-transparent" title="Editar pago"
                            onClick={() => getPago(row.id)}>
                            <PencilSquare size={24} className="button-default-black-transparent" color="#000" />
                        </Button>
                    }
                } : ''
            ];

            function indication() {
                return listaPagosAnteriores.length ? "Cargando..." : "No hay registros que mostrar";
            }

            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size}
                </span>
            );

            const options = {
                custom: true,
                sizePerPage: 5,
                totalSize: listaPagosAnteriores.length,
                paginationTotalRenderer: customTotal
            };

            return <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div className="div-pagos-anteriores">
                        <Form.Row className="p-2">
                            <div className="d-flex flex-row">
                                <Form.Label>Mostrar</Form.Label>
                                <Col>
                                    <Form.Control size="sm" id="sizePerPage" as="select" onChange={(e) => handleSizePerPage(paginationProps, e.target.value)}>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="15">15</option>
                                        <option value={listaPagosAnteriores.length}>Todos</option>
                                    </Form.Control>
                                </Col>
                            </div>
                            <Col>
                                <PaginationTotalStandalone {...paginationProps} />
                            </Col>
                            <div className="d-flex  ml-auto flex-row-reverse">
                                <Col>
                                    <PaginationListStandalone {...paginationProps} />
                                </Col>
                                <Form.Label column>Página</Form.Label>
                            </div>
                        </Form.Row>
                        <div className="table-responsive table-sm text-center">
                            <BootstrapTable
                                id="pagosAnteriores"
                                keyField='id.id'
                                data={listaPagosAnteriores}
                                columns={columns}
                                bordered={false}
                                noDataIndication={indication}
                                {...paginationTableProps}
                            />
                        </div>
                    </div>
                )}
            </PaginationProvider>
        }
    }

    return (
        <fieldset>
            {modalEditarPagoShow ? <ModalEditarPago show={modalEditarPagoShow} onHide={modalEditarPagoClose} onUpdate={updateGetListado}>{pago}{id}</ModalEditarPago> : ''}
            <legend className="legend-consultar-req">Comprobantes de pago subidos</legend>
            <Form.Row as={Col}>
                <Form.Group as={Col}>
                    {listaPagosAnteriores.length ? listarPagosAnteriores() : status}
                </Form.Group>
            </Form.Row>
        </fieldset>
    );
}

export default ListadoPagosAnteriores;