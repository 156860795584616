import React, { useEffect, useState } from "react";
import axios from 'axios';
import { API_PROVEEDORES_URL, KRAKEN_URL } from '../../constants'

//Estilos
import '../Requisiciones/css/Requisiciones.css'
import { CheckCircle } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next';

//Componentes bootstrap
import { Modal, Form, Col } from "react-bootstrap";

//componentes
import ModalAgregarUsuarioContacto from '../Configuracion/Empresa/Usuarios/UsuarioContactos/ModalAgregarUsuarioContacto';

function ModalPedirAprobacion(props) {
    const [deactivate, setDeactivate] = useState(false);
    const token = JSON.parse(localStorage.getItem('token'));
    const userId = token.userId;
    const empresaAlias = props.children.empresa;
    const [request] = useState(false);
    const [listContactos, setListContactos] = useState([]);
    const [status, setStatus] = useState(false);
    const [error, setError] = useState(false);
    const [statusError, setStatusError] = useState('');
    const [comentario, setComentario] = useState('');
    const [seleccionados, setSeleccionados] = useState([]);//Aquí se guardan sólo los id's de los contactos que se seleccionen
    const [ctcSeleccionados, setCtcSeleccionados] = useState([]);//Aquí se guardan sólo los emails que se seleccionen
    const [updateListado, setUpdateListado] = useState(false);
    const [solicitante, setSolicitante] = useState(false);
    const [pagadores, setPagadores ] = useState([])
    const usuario = {
        userId: userId,
        username: token.username,
        empresaAlias: empresaAlias
    }
    

    const [modalUsuarioAgregarShow, setModalUsuarioAgregarShow] = useState(false);
    let modalUsuarioAgregarClose = () => setModalUsuarioAgregarShow(false);
    let updateGetListado = () => { setUpdateListado(!!!updateListado); }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getContactos, [updateListado, userId, empresaAlias]);

    function getContactos() {
        axios.post(KRAKEN_URL + 'empresa/usuario/contactos/list', {
            userId: userId,
            empresa: empresaAlias
        }).then((res) => {
            if (res.data.result.code === 200) {
                setListContactos(res.data.list);
                setSeleccionados([...seleccionados, res.data.list[0].contactoId]);
                setCtcSeleccionados([...ctcSeleccionados, res.data.list[0].emailContacto]);
            } else {
                setListContactos('');
                //setError(true);
                setStatusError(res.data.result.message);
            }
        }).catch((error) => {
            setError(true);
            setStatusError("Ocurrió un error, no se pudieron recuperar los contactos de este usuario");
        })
    }
    async function getSolicitanteOnebyOne(idSolicitante) {
        return new Promise((resolve, reject) => {
          axios.post(KRAKEN_URL + 'empresa/usuario/solicitante/getSolicitante', {
            userId: idSolicitante
          }).then((res) => {
            console.log("aqui en res ");
            console.log(res);
            console.log(res.data.result.code)
            if (res.data.result.code === 200) {
              setSolicitante(res.data.list[0]);
              resolve(res.data.list[0]);
            } else {
              setSolicitante('');
              setStatusError(res.data.result.message);
              reject(res.data.result.message);
            }
          }).catch((error) => {
            console.log(error);
          
            setStatusError("Ocurrió un error, no se pudieron recuperar los contactos de este usuario");
            reject(error);
          });
        });
      }
      function mapeoCompartidos(compartidos){
        if(compartidos !=="[]"){
          const compartidosArray = JSON.parse(compartidos);
         return compartidosArray.map(usuario => usuario.usuarioEmail);
        
        }
        return []
      }
      function getPagadores(requisicion) {
        console.log("entra a la peticion " + requisicion.empresa);
        return axios
            .post(KRAKEN_URL + "empresa/usuarios/payers", {
                empresaId: requisicion.idEmpresa,
            })
            .then((res) => {
                console.log("........");
                console.log(res);
    
           
                if (res.data && res.data.list) {
                    // Retornamos solo los correos de los pagadores, asumiendo que esta es la información relevante
                    return res.data.list.map(pagador => pagador.email);
                } else {
                    // Si no hay datos, retornamos un arreglo vacío
                    return [];
                }
              
            })
            .catch((error) => {
                console.log(error);
                console.log("ALGO SALIÓ MAL");
                throw error; // Propagar el error para que se maneje en el bloque catch de aprobarRequisicion
            });
    }
      async function  aprobarRequisicion(requisicion, e) {
        e.preventDefault();
        setStatus(true);
        
        console.log(requisicion)
      
           let compartidos = mapeoCompartidos(requisicion.compartidos)
          
           let pagadoresArray ={}
            compartidos.push(requisicion.pagadorEmail)
            if (compartidos.includes("pendiente")){
                console.log("Buscar todos los correos desde Kraken")
                try {
                let payers  =  await getPagadores(requisicion);
                 console.log(pagadores)
                 pagadoresArray  =payers
                 compartidos = compartidos.concat(pagadoresArray)
                 compartidos = compartidos.filter(item => item!=="pendiente")
                } catch (error) {
                    console.error("Error al obtener pagadores:", error);
                    setStatus(false);
                    toast.error("No se ha podido obtener la lista de pagadores", {
                        position: "top-center",
                        autoClose: 4500,
                    });
                    return;
                }
               
               
             
            }
            console.log("ENVIAR",  {
                to: compartidos,
                idRequisicion: requisicion.idRequisicion,
                id: requisicion.id,
                empresa: requisicion.empresa,
                proveedor: requisicion.proveedor,
                fechaRequeridaPago: requisicion.fechaRequeridaPago,
                total: requisicion.total,
                porcentajeRequerido: requisicion.porcentajeTotal,
                solicitante: requisicion.solicitante,
                comentario: comentario
            })
            axios.post(API_PROVEEDORES_URL + 'pedirAprobacion',
                {
                    to: compartidos,
                    idRequisicion: requisicion.idRequisicion,
                    id: requisicion.id,
                    empresa: requisicion.empresa,
                    proveedor: requisicion.proveedor,
                    fechaRequeridaPago: requisicion.fechaRequeridaPago,
                    total: requisicion.total,
                    porcentajeRequerido: requisicion.porcentajeTotal,
                    solicitante: requisicion.solicitante,
                    comentario: comentario
                }
            ).then((res) => {
                if (res.data.result.success) {
                    setStatus(false);
                    toast.success(res.data.result.message,{
                        position:"top-center",
                        autoClose: 4500, 
                    });
                    
                    props.onUpdate();
                    props.onHide(); 
                } else {
                    setStatus(false);
                    toast.error(res.data.result.message,{
                        position:"top-center",
                        autoClose: 4500, 
                    });
                    toast.error(res.data.result.suggestion,{
                        position:"top-center",
                        autoClose: 4500, 
                    });
                }
            }).catch((error) => {
                setStatus(false);
                toast.error("No se ha podido enviar la requisición al pagador",{
                    position:"top-center",
                    autoClose: 4500, 
                });
            })
    }

    function handleComentarioChange(e) {
        setComentario(e.target.value);
    }

    let handleSizePerPage = ({ page, onSizePerPageChange }, newSizePerPage) => {
        onSizePerPageChange(newSizePerPage, page);
    }

    let mostrarListaContactos = () => {
        if (error) {
            return (
                <div>{statusError}</div>
            )
        } else {
            const columns = [
                {
                    dataField: 'contacto',
                    text: 'Nombre de contacto',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                },
                {
                    dataField: 'emailContacto',
                    text: 'Email del contacto',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                }];

            const selectRow = {
                mode: 'checkbox',
                clickToSelect: true,
                style: { backgroundColor: 'rgb(155, 227, 230)' },
                selected: seleccionados,//renglones seleccionados de acuerdo al contactoId (keyField del BootstrapTable)
                onSelect: (row, isSelect, rowIndex, e) => {
                    console.log(row)
                    if (isSelect) {
                        setSeleccionados([...seleccionados, row.contactoId]);
                        setCtcSeleccionados([...ctcSeleccionados, row.emailContacto]);
                    } else {
                        let eliminarSeleccionado = seleccionados.filter(x => x !== row.contactoId);
                        setSeleccionados(eliminarSeleccionado);
                        let eliminarContacto = ctcSeleccionados.filter(x => x !== row.emailContacto);
                        setCtcSeleccionados(eliminarContacto);
                    }
                },
                onSelectAll: (isSelect, rows, e) => {
                    const ids = rows.map(r => r.contactoId);
                    const emails = rows.map(r => r.emailContacto);
                    if (isSelect) {
                        setSeleccionados(ids);
                        setCtcSeleccionados(emails);
                    } else {
                        setSeleccionados([]);
                        setCtcSeleccionados([]);
                    }
                }
            }

            function indication() {
                return listContactos.length ? "La búsqueda no coincide con ningún criterio" : "No hay registros que mostrar, da clic en el botón 'Agregar contacto' para crear uno";
            }

            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size}
                </span>
            );

            const options = {
                custom: true,
                sizePerPage: 5,
                totalSize: listContactos.length,
                paginationTotalRenderer: customTotal
            }

            return <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div>
                        <Form.Row className="d-flex justify-content-center">
                            <div className="d-flex ">
                                <button
                                    className="m-1 button-default-req"
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="Agregar contacto"
                                    onClick={() => { setModalUsuarioAgregarShow(true) }}
                                ><span>Agregar contacto</span>
                                </button>
                                {modalUsuarioAgregarShow ? <ModalAgregarUsuarioContacto show={modalUsuarioAgregarShow} onHide={modalUsuarioAgregarClose} onUpdate={updateGetListado}>{usuario}</ModalAgregarUsuarioContacto> : ''}
                            </div>
                        </Form.Row>
                        <Form.Row className="p-2">
                            <div className="d-flex flex-row">
                                <Form.Label column>Mostrar</Form.Label>
                                <Col>
                                    <Form.Control size="sm" as="select" onChange={(e) => handleSizePerPage(paginationProps, e.target.value)}>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value={listContactos}>Todos</option>
                                    </Form.Control>
                                </Col>
                            </div>
                            <div className="d-flex flex-row">
                                <Col>
                                    <PaginationTotalStandalone {...paginationProps} />
                                </Col>
                            </div>
                            <div className="d-flex ml-auto flex-row-reverse">
                                <Col>
                                    <PaginationListStandalone {...paginationProps} />
                                </Col>
                                <Form.Label column>Página</Form.Label>
                            </div>
                        </Form.Row>
                        <div className="table-responsive table-sm">
                            <BootstrapTable
                                id="listaContactos"
                                keyField='contactoId'
                                data={listContactos}
                                columns={columns}
                                selectRow={selectRow}
                                filter={filterFactory()}
                                bordered={false}
                                striped
                                hover
                                condensed
                                noDataIndication={indication}
                                {...paginationTableProps}
                            />
                        </div>
                    </div>
                )}
            </PaginationProvider>
        }
    }

    return (
        <section className="text-center">
            <Modal
                show={props.show}
                //onHide={props.onHide}
                size="lg"
                id="modalPedirAprobacion"
                centered
            >
                <Modal.Header className="modal-header-pedir-aprobacion" closeButton onHide={props.onHide}>
                    <p><CheckCircle size={22} color="#5B4B51" />   ¿Seguro que desea pedir aprobación de esta requisición? #{props.children.id}</p>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row as={Col}>
                        <p>
                            <CheckCircle size={22} color="#5B4B51" />
                            &nbsp;Se notificará al pagador automáticamente. 
                        </p>
                        {request ? "Cargando..." : ""}
                        <Form.Control id="comentario" as="textarea" rows={1} placeholder="¿Desea agregar algún comentario?" value={comentario} onChange={handleComentarioChange}></Form.Control>
                    </Form.Row>
                    <div className="d-flex justify-content-center">
                        <button
                            className="m-1 button-default-confirmar"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Confirmar"
                            disabled = { deactivate }
                            onClick={e => aprobarRequisicion(props.children, e)}
                        >{status ?
                            <span>Enviando...</span>
                            : <span>Confirmar</span>}
                        </button>
                        <button
                            className="m-1 button-default-cancelar"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Cancelar"
                            onClick={props.onHide}
                        ><span>Cancelar</span>
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    );
}

export default ModalPedirAprobacion;