import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_PROVEEDORES_URL } from '../../constants'
import { bigDecimalFormat } from '../../constantes/general-require'

import './css/Pagos.css'
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import { PencilSquare } from 'react-bootstrap-icons';
import { Modal, Container, Form, Col, InputGroup } from "react-bootstrap";

function ModalEditarPago(props) {    
    const [pago] = useState(props.children[0]);
    const id = props.children[1].toString();
    const [status, setStatus] = useState(false);
    const monedaClaveSat = pago.monedaClaveSat;//MXN
    const moneda = pago.monedaClaveSat + " - " + pago.monedaSat + " - Presición -" + pago.monedaDecimales;
    const monedaDecimalesSat = pago.monedaDecimales;//2
    const paridad = pago.paridad;
    const [montoPago, setMontoPago] = useState(pago.montoPago);
    const saldoAnterior = pago.saldoAnterior;
    const [saldoAnteriorFormat, setSaldoAnteriorFormat] = useState('0.0');
    const [saldoPagado, setSaldoPagado] = useState(pago.saldoPagado);
    const [saldoPagadoFormat, setSaldoPagadoFormat] = useState('0.0');
    const [saldoPendiente, setSaldoPendiente] = useState(pago.saldoPendiente);
    const [saldoPendienteFormat, setSaldoPendienteFormat] = useState('0.0');
    const [saldoPendientePesosMexicanos, setSaldoPendientePesosMexicanos] = useState('0.00');

    useEffect(() => {
        calculoSaldos();
    });

    function editarPago() {
        setStatus(true);
        axios.post(API_PROVEEDORES_URL + 'editarPago',
            {
                id: id,
                idPago: pago.id.idPago,
                idRequisicion: pago.idRequisicion,
                montoPago: parseFloat(montoPago),
                saldoAnterior: parseFloat(saldoAnterior),
                saldoPagado: parseFloat(saldoPagado),
                saldoPendiente: parseFloat(saldoPendiente)
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                setStatus(false);
                toast.success(res.data.result.message,{
                    position:"top-center",
                    autoClose: 4500, 
                });
                toast.success(res.data.result.suggestion,{
                    position:"top-center",
                    autoClose: 4500, 
                });
                props.onUpdate();//Actualiza el listado, llamando a la función padre para actualizarlo                
            } else {
                setStatus(false);
                toast.error(res.data.result.message,{
                    position:"top-center",
                    autoClose: 4500, 
                });
                toast.error(res.data.result.suggestion,{
                    position:"top-center",
                    autoClose: 4500, 
                });
            }
        }).catch((error) => {
            setStatus(false);
            toast.error("No se ha podido actualizar el pago",{
                position:"top-center",
                autoClose: 4500, 
            });
        })
    }

    function handleMontoPagoChange(e) {
        setMontoPago(e.target.value);
    }

    function calculoSaldos() {
        let montoPagoValue = montoPago;
        let montoPagoDecimal = bigDecimalFormat(montoPagoValue);
        setMontoPago(montoPagoDecimal.toFixed(parseFloat(monedaDecimalesSat)));
        let saldoAnteriorDecimal = bigDecimalFormat(saldoAnterior);
        setSaldoAnteriorFormat(saldoAnteriorDecimal.toFixed(parseFloat(monedaDecimalesSat)));
        setSaldoPagado(montoPagoDecimal.toFixed(parseFloat(monedaDecimalesSat)));
        setSaldoPagadoFormat(montoPagoDecimal.toFixed(parseFloat(monedaDecimalesSat)));
        let saldoPendienteDecimal = saldoAnteriorDecimal.minus(montoPagoDecimal);
        setSaldoPendiente(saldoPendienteDecimal.toFixed(parseFloat(monedaDecimalesSat)));
        setSaldoPendienteFormat(saldoPendienteDecimal.toFixed(parseFloat(monedaDecimalesSat)));
        let paridadValue = paridad;
        let paridadDecimal = bigDecimalFormat(paridadValue);
        let saldoPendienteConParidad = saldoPendienteDecimal.times(paridadDecimal);
        setSaldoPendientePesosMexicanos(saldoPendienteConParidad.toFixed(parseFloat(2)));
    }

    const createInputSaldoPendientePesosMexicanos = () => {
        let inputTPM = (<span><Form.Label > Saldo pendiente pesos mexicanos</Form.Label>
            <InputGroup size="sm">
                <InputGroup.Prepend >
                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                </InputGroup.Prepend>
                <NumberFormat id="saldoPendientePesosMexicanos" className="input-signs-align form-control form-control-sm" thousandSeparator={true} disabled value={saldoPendientePesosMexicanos}></NumberFormat>
            </InputGroup>
        </span>
        );
        return inputTPM;
    }

    return (
        <section className="text-center">
            <Modal
                show={props.show}
                //onHide={props.onHide}
                size="md"
                centered
                id="modalEditarPago"
                scrollable={true}
            >
                <Modal.Header className="modal-header-editar-pago" closeButton onHide={props.onHide}>
                    <p><PencilSquare size={22} color="#5B4B51" />   3.Editar pago #{pago.id.id}</p>
                </Modal.Header>
                <Modal.Body className="modal-portal-body">
                    <Container className="container-modals">
                        <fieldset>
                            <legend className="legend-editar-pago">Saldos</legend>
                            <Form.Row as={Col}>
                                <Form.Group as={Col} lg={6}>
                                    <Form.Label>Moneda</Form.Label>
                                    <Form.Control size="sm" id="moneda" as="textarea" rows={2} value={moneda} disabled />
                                    <Form.Label>Paridad</Form.Label>
                                    <InputGroup size="sm">
                                        <InputGroup.Prepend >
                                            <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control size="sm" id="paridad" type="text" className="input-signs-align" value={paridad} disabled />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} lg={6}>
                                    <Form.Label>Monto del pago</Form.Label>
                                    <InputGroup size="sm">
                                        <InputGroup.Prepend >
                                            <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <NumberFormat id="montoPago" className="input-signs-align form-control form-control-sm" required placeholder="Ingrese monto" thousandSeparator={true} value={montoPago} onChange={handleMontoPagoChange}></NumberFormat>
                                    </InputGroup>
                                    <Form.Label>Saldo anterior</Form.Label>
                                    <InputGroup size="sm">
                                        <InputGroup.Prepend >
                                            <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <NumberFormat id="saldoAnterior" className="input-signs-align form-control form-control-sm" disabled thousandSeparator={true} value={saldoAnteriorFormat}></NumberFormat>
                                    </InputGroup>
                                    <Form.Label>Saldo pagado</Form.Label>
                                    <InputGroup size="sm">
                                        <InputGroup.Prepend >
                                            <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <NumberFormat id="saldoPagado" className="input-signs-align form-control form-control-sm" disabled thousandSeparator={true} value={saldoPagadoFormat}></NumberFormat>
                                    </InputGroup>
                                    <Form.Label>Saldo pendiente</Form.Label>
                                    <InputGroup size="sm">
                                        <InputGroup.Prepend >
                                            <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <NumberFormat id="saldoPendiente" className="input-signs-align form-control form-control-sm" disabled thousandSeparator={true} value={saldoPendienteFormat}></NumberFormat>
                                    </InputGroup>
                                    {monedaClaveSat !== 'MXN' ? createInputSaldoPendientePesosMexicanos() : ''}
                                </Form.Group>
                            </Form.Row>
                        </fieldset>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="justify-content-end">
                    <button
                        className="m-1 button-default-confirmar"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Guardar pago"
                        onClick={editarPago}
                    >{status ?
                        <span>Guardando</span>
                        : <span>Guardar</span>
                        }
                    </button>
                    <button
                        className="m-1 button-default-cancelar"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Cancelar"
                        onClick={props.onHide}
                    >Cancelar
                    </button>
                </Modal.Footer>
            </Modal>
        </section>
    );
}

export default ModalEditarPago;