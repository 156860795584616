import React, { useState } from "react";
import axios from 'axios';
import { API_PROVEEDORES_URL } from '../../constants';

//Estilos
import '../SoporteTecnico/css/SoporteTecnico.css'
import { Check2Square } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';

//Componentes bootstrap
import { ButtonGroup, Button, Modal } from 'react-bootstrap';

function ModalUpdateEstatus(props){
    const [status, setStatus] = useState(false); 

    function updateEstatus(e){
        e.preventDefault();
        setStatus(true);

        axios.post(API_PROVEEDORES_URL + 'updateEstatus',
            {
                idSoporte: props.children.idSoporte,
                estatus: props.children.estatus
            }
        ).then((res) => {
            if(res.data.result.success){
                setStatus(false);
                toast.success(res.data.result.message,{
                    position:"top-center",
                    autoClose: 4500, 
                });
                props.onHide();
                props.onUpdate();
            } else {
                setStatus(false);
                toast.error(res.data.result.message,{
                    position:"top-center",
                    autoClose: 4500, 
                });
                toast.error(res.data.result.suggestion,{
                    position:"top-center",
                    autoClose: 4500, 
                });
            }
        }).catch((error) => {
            toast.error("No se pudo actualizar el estatus de la solicitud",{
                position:"top-center",
                autoClose: 4500, 
            });
            setStatus(false);
        });
    }

    return (
        <section>
            <Modal
               show={props.show}
               //onHide={props.onHide}
               size="sm"
               id="modalUpdateEstatus"
               aria-labelledby="contained-modal-title-vcent"
               centered 
            >
                <Modal.Header className="modal-header-update-estatus" closeButton onHide={props.onHide}>
                    <h5 className="legend-update-estatus"><Check2Square size={22} color="#5B4B51" />   Actualizar estatus </h5>
                </Modal.Header>
                <Modal.Body className="modal-body-update-estatus">
                    <p>
                        &nbsp;¿Está seguro que desea cambiar el estatus de la solicitud a: &nbsp;
                        <strong>{props.children.estatus === 'P' ? 'RESUELTO' : 'PENDIENTE'}</strong>?
                    </p>
                </Modal.Body>
                <Modal.Footer className="justify-content-end">
                    <ButtonGroup>
                        <Button
                            size="md"
                            className="m-1 button-eliminar-prov"
                            variant="light"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Confirmar"
                            onClick={e => updateEstatus(e)}
                        >{status ?
                            <span>Actualizar...</span>
                            : <span>Confirmar</span>}
                        </Button>
                        <Button
                            size="md"
                            className="m-1 button-cancelar"
                            variant="light"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Cancelar"
                            onClick={props.onHide}
                        ><span>Cancelar</span>
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        </section>
    );
}

export default ModalUpdateEstatus;