import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_PROVEEDORES_URL } from '../../constants'

//Componentes bootstrap
import { Form, Col, Button, ButtonGroup } from "react-bootstrap";

//Componentes
import { toast } from 'react-toastify';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { Trash, FileArrowDown } from 'react-bootstrap-icons';
import { base64ToArrayBuffer, saveByteArray } from '../../constantes/download-file-handler'
import ModalDeleteFactura from './ModalDeleteFactura';

function ListadoFacturasSubidas(props) {
    const [deactivate, setDeactivate] = useState(false);
    const idRequisicion = props.children[0];
    const idEmpresa = props.children[1];
    const [error, setError] = useState(false);
    const [errorStatus, setErrorStatus] = useState('');
    const [listaFacturasSubidas, setListaFacturasSubidas] = useState([]);
    const [archivo, setArchivo] = useState('');

    const [modalDeleteShow, setModalDeleteShow] = useState(false);
    const [updateListado, setUpdateListado] = useState(false);
    let modalDeleteClose = () => setModalDeleteShow(false);
    let updateGetListado = () => setUpdateListado(!!!updateListado);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getListadoFacturasSubidas, [updateListado]);

    function getListadoFacturasSubidas() {
        axios.post(API_PROVEEDORES_URL + 'getCFDISubidos',
            {
                "idRequisicion": idRequisicion
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                setListaFacturasSubidas(res.data.list);
            } else {
                setError(true);
                setErrorStatus(res.data.result.message);
            }
        }).catch((error) => {
            setError(true);
            setErrorStatus("Error: No se han podido recuperar los CFDI's registrados.")
        })
    }

    function getArchivo(idCfdi) {
        setArchivo(idCfdi);
        setModalDeleteShow(true);
    }

    function getArchivoDownload(cfdi) {
        axios.post(API_PROVEEDORES_URL + 'downloadCFDIfile',
            {
                idEmpresa: idEmpresa,
                idRequisicion: idRequisicion,
                nombre: cfdi.nombre
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                let arrayBuffer = base64ToArrayBuffer(res.data.bytes);
                saveByteArray(cfdi.nombre, cfdi.type, arrayBuffer);
            } else {
                setDeactivate(true);

                setTimeout(() => {
                    setDeactivate(false);
                }, 4500);
    
                toast.warning(res.data.result.message,{
                    position:"top-center",
                    autoClose: 4500, 
                });
            }
        }).catch((error) => {
            setDeactivate(true);

            setTimeout(() => {
                setDeactivate(false);
            }, 4500);
    
            toast.error("No se ha podido descargar el archivo",{
                position:"top-center",
                autoClose: 4500, 
            });
        })
    }

    let handleSizePerPage = ({ page, onSizePerPageChange }, newSizePerPage) => {
        onSizePerPageChange(newSizePerPage, page);
    }

    let listarFacturasSubidas = () => {
        if (error) {
            return (
                <div>{errorStatus}</div>
            );
        } else {
            const columns = [
                {
                    dataField: 'nombre',
                    text: 'Nombre del archivo'
                }, {
                    dataField: 'acciones',
                    isDummy: true,
                    text: 'Acciones',
                    formatter: (cellContent, row) => {
                        return (
                            <ButtonGroup>
                                <Button variant="light" size="sm" className="button-default-black-transparent" title="Eliminar archivo" onClick={() => getArchivo(row)}>
                                    <Trash size={24} className="button-default-black-transparent" color="#000" />
                                </Button>
                                <Button variant="light" size="sm" className="button-default-black-transparent" title="Descargar archivo" disabled = { deactivate } onClick={() => getArchivoDownload(row)}>
                                    <FileArrowDown size={24} className="button-default-black-transparent" color="#000" />
                                </Button>
                            </ButtonGroup>
                        );
                    }
                }];

            function indication() {
                return listaFacturasSubidas.length ? "La búsqueda no coincide con ningún criterio" : "No hay registros que mostrar";
            }

            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size}
                </span>
            );
            const options = {
                custom: true,
                sizePerPage: 5,
                totalSize: listaFacturasSubidas.length,
                paginationTotalRenderer: customTotal
            };
            return <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div className="div-facturas-subidas">
                        <Form.Row className="p-2">
                            <div className="d-flex flex-row">
                                <Form.Label>Mostrar</Form.Label>
                                <Col>
                                    <Form.Control size="sm" id="sizePerPage" as="select" onChange={(e) => handleSizePerPage(paginationProps, e.target.value)}>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="15">15</option>
                                        <option value={listaFacturasSubidas.length}>Todos</option>
                                    </Form.Control>
                                </Col>
                            </div>
                            <Col>
                                <PaginationTotalStandalone {...paginationProps} />
                            </Col>
                            <div className="d-flex  ml-auto flex-row-reverse">
                                <Col>
                                    <PaginationListStandalone {...paginationProps} />
                                </Col>
                                <Form.Label column>Página</Form.Label>
                            </div>
                        </Form.Row>
                        <div className="table-responsive table-sm text-center">
                            <BootstrapTable
                                id="listadoFacturasSubidas"
                                keyField='id.id'
                                data={listaFacturasSubidas}
                                columns={columns}
                                bordered={false}
                                noDataIndication={indication}
                                {...paginationTableProps}
                            />
                        </div>
                    </div>
                )}
            </PaginationProvider>
        }
    }

    return (
        <Form.Row as={Col}>
            <Form.Group as={Col}>
                {listaFacturasSubidas.length ? listarFacturasSubidas() : errorStatus}
                {modalDeleteShow ? <ModalDeleteFactura show={modalDeleteShow} onHide={modalDeleteClose} onUpdate={updateGetListado}>{archivo}</ModalDeleteFactura> : ''}
            </Form.Group>
        </Form.Row>
    );
}

export default ListadoFacturasSubidas;