import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import axios from "axios";
import { KRAKEN_URL } from "../../constants";
//Estilos
import './css/Configuracion.css'
//Elementos bootstrap
import { ButtonGroup, Button, Form, Col, Card, Tabs, Tab } from "react-bootstrap";
//Componentes
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next'; import { PencilSquare, Trash } from 'react-bootstrap-icons';
import ModalAgregarUsuarioContacto from "./Empresa/Usuarios/UsuarioContactos/ModalAgregarUsuarioContacto";
import ModalEditarUsuarioContacto from "./Empresa/Usuarios/UsuarioContactos/ModalEditarUsuarioContacto";
import ModalEliminarUsuarioContacto from "./Empresa/Usuarios/UsuarioContactos/ModalEliminarUsuarioContacto";
import Loader from "../Loader";

function CuentasNotificacion() {
    const empresasDisponibles = useSelector(state => state.empresas.empresasDisponibles);
    const empresaDefault = empresasDisponibles[0].empresaId;
    const [key, setKey] = useState(empresaDefault);

    function createTabs() {
        let empresasTabs;
        empresasDisponibles.length ? empresasTabs = empresasDisponibles.map(empresa => (
            <Tab key={empresa.empresaId} eventKey={empresa.empresaId} title={empresa.empresa}>
                <ListadoCuentasNotificacion>{empresa.empresa}</ListadoCuentasNotificacion>
            </Tab>
        )) : empresasTabs = empresasDisponibles.map(empresa => (
            <Tab key={empresa} eventKey={empresa} title={empresa}>
                <section id="cuentasNotificacion">
                    <Card id="cardCuentasNotificacion">
                        <Card.Body className="cardBodyCuentasNotificacion">
                            {'Error: No se ha podido cargar la información'}
                        </Card.Body>
                    </Card>
                </section>
            </Tab>
        ))
        return empresasTabs;
    }

    return (
        <div >
            <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
                {createTabs()}
            </Tabs>
        </div >
    );
}

function ListadoCuentasNotificacion(props) {
    const token = JSON.parse(localStorage.getItem('token'));
    const userId = token.userId;
    const [request] = useState(false);
    const empresaAlias = props.children;
    const [listContactos, setListContactos] = useState([]);
    const [statusError, setStatusError] = useState('');
    const [errorContactos, setErrorContactos] = useState(false);
    const [updateListado, setUpdateListado] = useState(false);
    const [usrEdit, setUsrEdit] = useState('');
    const [usrDelete, setUsrDelete] = useState('');
    const [cargando, setCargando] = useState(false);

    const usuario = {
        userId: userId,
        username: token.username,
        empresaAlias: empresaAlias
    }

    const [modalUsuarioAgregarShow, setModalUsuarioAgregarShow] = useState(false);
    const [modalUsuarioEditarShow, setModalUsuarioEditarShow] = useState(false);
    const [modalUsuarioEliminarShow, setModalUsuarioEliminarShow] = useState(false);

    let modalUsuarioAgregarClose = () => setModalUsuarioAgregarShow(false);
    let modalUsuarioEditarClose = () => setModalUsuarioEditarShow(false);
    let modalUsuarioEliminarClose = () => setModalUsuarioEliminarShow(false);
    let updateGetListado = () => { setUpdateListado(!!!updateListado); }

    useEffect(getContactos, [updateListado, userId, empresaAlias]);

    function getContactos() {
        setCargando(true);
        axios.post(KRAKEN_URL + 'empresa/usuario/contactos/list', {
            userId: userId,
            empresa: empresaAlias,
        }).then((res) => {
            if (res.data.result.code === 200) {
                setListContactos(res.data.list);
            } else {
                setListContactos('');
                //setErrorContactos(true);
                //setStatusError(res.data.result.message + " " + res.data.result.suggestion);
            }
        }).catch((error) => {
            setErrorContactos(true);
            setStatusError("Ocurrió un error, no se pudieron recuperar los contactos de este usuario");
        }).finally(() => {
            setCargando(false)
        })
    }

    function getUsuario(usuario) {
        setUsrEdit(usuario);
        setModalUsuarioEditarShow(true);
    }

    function confirmDelete(usuario) {
        setUsrDelete(usuario);
        setModalUsuarioEliminarShow(true);
    }

    const agregarContactoButton = () => {
        let boton = (<div className="d-flex ml-auto mb-2 flex-row-reverse">
            <button
                className="ml-1 button-default-req"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Agregar contacto"
                onClick={() => { setModalUsuarioAgregarShow(true) }}
            ><span>Agregar contacto </span>
            </button>
        </div>
        );
        return boton;
    }

    let mostrarListaContactos = () => {
        if (errorContactos) {
            return (
                <div>{statusError}</div>
            )
        } else {
            const columns = [
                {
                    dataField: 'contacto',
                    text: 'Nombre de contacto',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                },
                {
                    dataField: 'emailContacto',
                    text: 'Email del contacto',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                },
                {
                    dataField: 'acciones',
                    text: 'Acciones',
                    isDummyField: true,
                    formatter: (cellContent, row) => {
                        return (
                            <ButtonGroup>
                                <Button id="editar" className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Editar"
                                    onClick={() => getUsuario(row)}
                                ><PencilSquare size={22} className="button-default-black" color="#000" />
                                </Button>
                                <Button id="eliminar" className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Eliminar"
                                    onClick={() => confirmDelete(row)}
                                ><Trash size={22} className="button-default-black" color="#000" />
                                </Button>
                            </ButtonGroup>
                        )
                    }
                }
            ];


            function indication() {
                return listContactos.length ? "La búsqueda no coincide con ningún criterio" : "No se encontraron registros de contactos asociados a este usuario y empresa, agregue un contacto.";
            }

            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size}
                </span>
            );

            const options = {
                custom: true,
                sizePerPage: 10,
                totalSize: listContactos.length,
                paginationTotalRenderer: customTotal
            }

            return <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div>
                        <Form.Row>
                            {agregarContactoButton()}
                        </Form.Row>
                        <Form.Row className="p-2">
                            <div className="d-flex flex-row">
                                <Form.Label column>Mostrar</Form.Label>
                                <Col>
                                    {/* <Form.Control size="sm" as="select" onChange={(e) => handleSizeContactsPerPage(paginationProps, e.target.value)}>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value={listContactos}>Todos</option>
                                    </Form.Control> */}
                                    <SizePerPageDropdownStandalone {...paginationProps} btnContextual/>
                                </Col>
                            </div>
                            <div className="d-flex flex-row">
                                <Col>
                                    <PaginationTotalStandalone {...paginationProps} />
                                </Col>
                            </div>
                            <div className="d-flex ml-auto flex-row-reverse">
                                <Col>
                                    <PaginationListStandalone {...paginationProps} />
                                </Col>
                                <Form.Label column>Página</Form.Label>
                            </div>
                        </Form.Row>
                        <div className="table-responsive table-sm">
                            <BootstrapTable
                                id="listaContactos"
                                keyField='contactoId'
                                data={listContactos}
                                columns={columns}
                                filter={filterFactory()}
                                headerClasses="header-class-configuracion"
                                bordered={false}
                                striped
                                hover
                                condensed
                                noDataIndication={indication}
                                {...paginationTableProps}
                            />
                        </div>
                    </div>
                )}
            </PaginationProvider>
        }
    }

    if (cargando) {
        return <Loader />
    }

    return (
        <section id="cuentasNotificacion">
            <Card id="cardCuentasNotificacion">
                <Card.Body className="cardBodyCuentasNotificacion">
                    {request ? "Cargando..." : mostrarListaContactos()}
                    {modalUsuarioAgregarShow ? <ModalAgregarUsuarioContacto show={modalUsuarioAgregarShow} onHide={modalUsuarioAgregarClose} onUpdate={updateGetListado}>{usuario}</ModalAgregarUsuarioContacto> : ''}
                    {modalUsuarioEditarShow ? <ModalEditarUsuarioContacto show={modalUsuarioEditarShow} onHide={modalUsuarioEditarClose} onUpdate={updateGetListado}>{usrEdit}</ModalEditarUsuarioContacto> : ''}
                    {modalUsuarioEliminarShow ? <ModalEliminarUsuarioContacto show={modalUsuarioEliminarShow} onHide={modalUsuarioEliminarClose} onUpdate={updateGetListado}>{usrDelete}</ModalEliminarUsuarioContacto> : ''}
                </Card.Body>
            </Card>
        </section>
    )
}

export default CuentasNotificacion;