import React, { useState, useEffect } from "react";
import axios from 'axios'
import { API_PROVEEDORES_URL } from '../../constants'

//Estilos
import './Dashboard.css';

//Componentes
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import Moment from 'moment';

function NuevosProveedores(props) {
    const [empresa] = useState(props.children);
    const [request] = useState(false);
    const [error, setError] = useState(false);
    const [errorStatus, setErrorStatus] = useState('');
    const [listNuevosProveedores, setListNuevosProveedores] = useState([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getNuevosProveedores, []);

    function getNuevosProveedores() {
        axios.post(API_PROVEEDORES_URL + 'getNewsProveedores',
            {
                empresa: empresa
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                    setListNuevosProveedores(res.data.list);
            } else {
                setError(true);
                setErrorStatus(res.data.result.message);
            }
        }).catch((error) => {
            setError(true);
            setErrorStatus("Error: No se han podido recuperar nuevos proveedores");
        });
    }

    let mostrarNuevosProveedores = () => {        
        if (error) {
            return (
                <div>{errorStatus}</div>
            );
        } else {
            const columns = [
                {
                    id: 'empresa',
                    dataField: 'empresa',
                    text: '',
                    isDummyField: true,
                    formatter: (cellContent, row) => {
                        return (
                            <div className="font-title-nuevo-proveedor">
                                <div >{row.empresa}</div>
                            </div>
                        )
                    },
                },
                {
                    dataField: 'rowOne',
                    text: '',
                    isDummyField: true,
                    formatter: (cellContent, row) => {
                        return (
                            <div className=" d-flex justify-content-around prov-ventas-tbody-tr-td-div">
                                <div >
                                    <div className="nuevo-prov-tbody-tr-td-div">
                                        <div className="font-col-nuevo-prov">{'ID: '}</div>
                                    </div>
                                    <div className="nuevo-prov-tbody-tr-td-div">
                                        <div>{"#" + row.id}</div>
                                    </div>
                                </div>
                                {<div >
                                    <div className="nuevo-prov-tbody-tr-td-div">
                                        <div className="font-col-nuevo-prov">{'Fecha registro: '}</div>
                                    </div>
                                    <div className="nuevo-prov-tbody-tr-td-div">
                                        <div>{Moment.utc(row.fechaCreacion, "YYYY-MM-DD").format("DD/MM/YYYY")}</div>
                                    </div>
                                </div>}
                            </div>
                        )
                    }
                },
                {
                    dataField: 'rowTwo',
                    text: '',
                    isDummyField: true,
                    formatter: (cellContent, row) => {
                        return (
                            <div className="justify-content-around prov-ventas-tbody-tr-td-div">
                                <div className="nuevo-prov-tbody-tr-td-div">
                                    <div className="font-col-nuevo-prov">{'Razón social: '}</div>
                                </div>
                                <div className="nuevo-prov-tbody-tr-td-div">
                                    <div>{row.razonSocial}</div>
                                </div>
                            </div>
                        )
                    }
                },
                {
                    dataField: 'rowthree',
                    text: '',
                    isDummyField: true,
                    formatter: (cellContent, row) => {
                        return (
                            <div className="justify-content-around prov-ventas-tbody-tr-td-div">
                                <div className="nuevo-prov-tbody-tr-td-div">
                                    <div className="font-col-nuevo-prov">{'Nombre comercial: '}</div>
                                </div>
                                <div className="nuevo-prov-tbody-tr-td-div">
                                    <div>{row.nombreComercial}</div>
                                </div>
                            </div>
                        )
                    }
                },
                {
                    dataField: 'rowFour',
                    text: '',
                    isDummyField: true,
                    formatter: (cellContent, row) => {
                        return (
                            <div className=" justify-content-around prov-ventas-tbody-tr-td-div">
                                <div className="nuevo-prov-tbody-tr-td-div">
                                    <div className="font-col-nuevo-prov">{'RFC: '}</div>
                                </div>
                                <div className="nuevo-prov-tbody-tr-td-div">
                                    <div>{row.proveedorRfc}</div>
                                </div>
                            </div>
                        )
                    }
                },
                {
                    dataField: 'rowFive',
                    text: '',
                    isDummyField: true,
                    formatter: (cellContent, row) => {
                        return (
                            <div className="justify-content-around prov-ventas-tbody-tr-td-div">
                                <div className="nuevo-prov-tbody-tr-td-div">
                                    <div className="font-col-nuevo-prov">{'Email: '}</div>
                                </div>
                                <div className="nuevo-prov-tbody-tr-td-div">
                                    <div>{row.correoElectronico}</div>
                                </div>
                            </div>
                        )
                    }
                }
            ];

            function indication() {
                return listNuevosProveedores.length ? "La búsqueda no coincide con ningún criterio" : "No hay registros que mostrar"; 
            }

            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size}
                </span>
            );

            const options = {
                custom: true,
                sizePerPage: 4,
                totalSize: listNuevosProveedores.length,
                paginationTotalRenderer: customTotal
            }

            return <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div className="fieldsetNuevosProveedores">
                        <div className="table-responsive table-sm">
                            <BootstrapTable
                                id="nuevosProveedores"
                                keyField='id'
                                data={listNuevosProveedores}
                                columns={columns}
                                bordered={false}
                                noDataIndication={indication}
                                {...paginationTableProps}
                            />
                        </div>
                    </div>
                )}
            </PaginationProvider >
        }
    }

    return request ? "Cargando..." : mostrarNuevosProveedores();

}

export default NuevosProveedores;