import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_PROVEEDORES_URL } from '../../../constants'

//Estilos
import { Trash, FileArrowDown } from 'react-bootstrap-icons';
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';

//Componentes bootstrap
import { Button, ButtonGroup, Col, Form } from 'react-bootstrap';

//Componentes
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import ModalDeleteCotizacion from './ModalDeleteCotizacion';
import { base64ToArrayBuffer, saveByteArray } from '../../../constantes/download-file-handler'

function ListadoCotizaciones(props) {
    const [deactivate, setDeactivate] = useState(false);
    const idRequisicion = props.children[0];
    const idEmpresa = props.children[1];
    const [error, setError] = useState(false);
    const [errorStatus, setErrorStatus] = useState('');
    const [status, setStatus] = useState(false);
    const [listaCotizaciones, setListaCotizaciones] = useState([]);
    const [archivo, setArchivo] = useState('');
    const [files, setFiles] = useState('');

    const [modalDeleteShow, setModalDeleteShow] = useState(false);
    const [updateListado, setUpdateListado] = useState(false);
    let modalDeleteClose = () => setModalDeleteShow(false);
    let updateGetListado = () => { setUpdateListado(!!!updateListado); setListaCotizaciones([]); setError(false); }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getListadoCotizaciones, [updateListado]);//Se actualiza cada que se elige una requisición diferente*/

    function getListadoCotizaciones() {
        axios.post(API_PROVEEDORES_URL + 'files/getCotizaciones',
            {
                "idRequisicion": idRequisicion
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                setListaCotizaciones(res.data.list)
            } else {
                setError(true);
                setErrorStatus(res.data.result.message);
            }
        }).catch((error) => {
            setError(true);
            setErrorStatus("Error: No se han podido recuperar las cotizaciones registradas.")
        })
    };

    function handleFilesChange(e) {
        setFiles(e.target.files);
        let files = e.target.files; //FileList object
        let output = [];

        for (var i = 0; i < files.length; i++) {
            output.push('<li><strong>', escape(files[i].name), '</strong> (', files[i].type || 'n/a', ') - ', files[i].size, ' bytes</li>');
        }
        document.getElementById("list").innerHTML = '<ol>' + output.join('') + '</ol>';
    }

    function borrarArchivos(e) {
        e.preventDefault();
        setFiles('');
        document.getElementById("list").innerHTML = '';
        document.getElementById("listResponse").innerHTML = '';
    }

    function subirArchivos(e) {
        e.preventDefault();
        setStatus(true);
        if (files === '') {
            setStatus(false);
            setDeactivate(true);

            setTimeout(() => {
                setDeactivate(false);
            }, 4500);

            toast.error("No hay archivos seleccionados",{
                position:"top-center",
                autoClose: 4500, 
            });
        } else {
            const formData = new FormData();
            formData.append("idEmpresa", idEmpresa);
            formData.append("idRequisicion", idRequisicion);
            for (var i = 0; i < files.length; i++) {
                formData.append("files", files[i]);
            }

            axios.post(API_PROVEEDORES_URL + 'files/saveCotizacion', formData,
                {
                    headers: { 'Content-Type': `multipart/form-data;` }
                }
            ).then((res) => {
                let output = [];
                if (res.data.result.code === 200) {
                    setStatus(false);
                    updateGetListado();
                    toast.success(res.data.result.message,{
                        position:"top-center",
                        autoClose: 4500, 
                    });
                    output.push('<strong>', res.data.result.message, '</strong>');
                    document.getElementById("listResponse").innerHTML = '<ol>' + output.join('') + '</ol>';
                } else if (res.data.result.code === 500) {
                    setStatus(false);
                    toast.warning(res.data.result.message,{
                        position:"top-center",
                        autoClose: 4500, 
                    });
                    toast.warning(res.data.result.suggestion,{
                        position:"top-center",
                        autoClose: 4500, 
                    });
                    output.push('<strong>', "Archivos correctos: " + res.data.correctos, '</strong>');
                    res.data.registros_con_errores.forEach(incorrecto => {
                        output.push('<li><strong>', incorrecto.message, '</strong> (', incorrecto.suggestion, ')');
                    });
                    document.getElementById("listResponse").innerHTML = '<ol>' + output.join('') + '</ol>';
                } else if(res.data.result.code === 413) {
                    toast.error("El tamaño del archivo es demasiado grande",{
                        position:"top-center",
                        autoClose: 4500, 
                    });
                    setStatus(false);
                }
                else {
                    toast.error("Ha ocurrido un problema al subir los archivos. Inténtalo de nuevo",{
                        position:"top-center",
                        autoClose: 4500, 
                    });
                    setStatus(false);
                }
            }).catch((error) => {
                toast.error("Ha ocurrido un problema con el servidor. Inténtalo de nuevo",{
                    position:"top-center",
                    autoClose: 4500, 
                });
                setStatus(false);
            })
        }
    }

    function getArchivo(idCotizacion) {
      
        idCotizacion.idEmpresa = idEmpresa;
        setArchivo(idCotizacion);
        setModalDeleteShow(true);
    }

    function getArchivoDownload(archivo) {
        axios.post(API_PROVEEDORES_URL + 'files/downloadCotizacion',
            {
                idEmpresa: idEmpresa,
                idRequisicion: archivo.idRequisicion,
                nombre: archivo.nombre
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                let arrayBuffer = base64ToArrayBuffer(res.data.bytes);
                saveByteArray(archivo.nombre, archivo.type, arrayBuffer);
            } else {
                toast.warning(res.data.result.message,{
                    position:"top-center",
                    autoClose: 4500, 
                });
            }
        }).catch((error) => {
            toast.error("No se ha podido descargar el archivo",{
                position:"top-center",
                autoClose: 4500, 
            }); 
        })
    }

    let handleSizePerPage = ({ page, onSizePerPageChange }, newSizePerPage) => {
        onSizePerPageChange(newSizePerPage, page);
    }

    let listarCotizaciones = () => {
        if (error) {
            return (
                <div>{errorStatus}</div>
            );
        } else {
            const columns = [
                {
                    dataField: 'nombre',
                    text: 'Nombre del archivo'
                }, {
                    dataField: 'acciones',
                    isDummy: true,
                    text: 'Acciones',
                    formatter: (cellContent, row) => {
                        return (
                            <ButtonGroup>
                                <Button variant="light" size="sm" className="button-default-black-transparent" title="Eliminar archivo" onClick={() => getArchivo(row)}>
                                    <Trash size={24} className="button-default-black-transparent" color="#000" />
                                </Button>
                                <Button variant="light" size="sm" className="button-default-black-transparent" title="Descargar archivo" onClick={() => getArchivoDownload(row)}>
                                    <FileArrowDown size={24} className="button-default-black-transparent" color="#000" />
                                </Button>
                            </ButtonGroup>
                        );
                    }
                }];

            function indication() {
                return listaCotizaciones.length ? "La búsqueda no coincide con ningún criterio" : "No hay registros que mostrar";
            }
            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size}
                </span>
            );
            const options = {
                custom: true,
                sizePerPage: 5,
                totalSize: listaCotizaciones.length,
                paginationTotalRenderer: customTotal
            };
            return <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div className="div-cotiz-editar">
                        <Form.Row className="p-2">
                            <div className="d-flex flex-row">
                                <Form.Label>Mostrar</Form.Label>
                                <Col>
                                    <Form.Control size="sm" id="sizePerPage" as="select" onChange={(e) => handleSizePerPage(paginationProps, e.target.value)}>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="15">15</option>
                                        <option value={listaCotizaciones.length}>Todos</option>
                                    </Form.Control>
                                </Col>
                            </div>
                            <Col>
                                <PaginationTotalStandalone {...paginationProps} />
                            </Col>
                            <div className="d-flex  ml-auto flex-row-reverse">
                                <Col>
                                    <PaginationListStandalone {...paginationProps} />
                                </Col>
                                <Form.Label column>Página</Form.Label>
                            </div>
                        </Form.Row>
                        <div className="table-responsive table-sm text-center">
                            <BootstrapTable
                                id='cotizEditar'
                                keyField='id'
                                data={listaCotizaciones}
                                columns={columns}
                                bordered={false}
                                noDataIndication={indication}
                                {...paginationTableProps}
                            />
                        </div>
                    </div>
                )}
            </PaginationProvider>
        }
    }

    return (
        <div>
            <fieldset>
                <legend className="legend-editar-req">Cotización (archivos subidos anteriormente)</legend>
                <Form.Row as={Col}>
                    <Form.Group as={Col}>
                        {listaCotizaciones.length ? listarCotizaciones() : errorStatus}
                        {archivo !== '' ? <ModalDeleteCotizacion show={modalDeleteShow} onHide={modalDeleteClose} onUpdate={updateGetListado}>{archivo}</ModalDeleteCotizacion> : ''}
                    </Form.Group>
                </Form.Row>
            </fieldset>
            <fieldset>
                <legend className="legend-editar-req">Cotización (subir archivos adjuntos)</legend>
                <Form.Row as={Col} >
                    <Form.Group as={Col}>
                        <div className="div-cotiz-editar p-2">
                            <Form.File.Label>Archivos soportados: jpg, jpeg, png, pdf, txt, xml, xls, xlsx, zip</Form.File.Label>
                            <output id="list"></output>
                            <output id="listResponse"></output>
                        </div>
                        <div className="p-2">
                            <input type="file" id="files" name="files[]"
                                multiple onChange={handleFilesChange}
                                accept=".png,.pdf,.txt,.xml,.xlsx,.xls,.jpg,.jpeg,.zip"
                            />
                            <ButtonGroup>
                                <button
                                    className="m-1 button-default-cotiz"
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="Subir archivos"
                                    disabled = { deactivate }
                                    onClick={subirArchivos}
                                >{status ?
                                    <span>Guardando...</span>
                                    : <span>Subir archivos</span>}
                                </button>
                                <button
                                    className="m-1 button-default-cotiz"
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="Cancelar"
                                    onClick={borrarArchivos}
                                ><span>Limpiar</span>
                                </button>
                            </ButtonGroup>
                        </div>
                    </Form.Group>
                </Form.Row>
            </fieldset>
        </div>
    );
}

export default ListadoCotizaciones;