import React, { useState, useEffect } from "react";
import axios from 'axios'
import { useSelector } from 'react-redux';
import { API_PROVEEDORES_URL } from '../../constants';

//Estilos
import '../Requisiciones/css/Requisiciones.css'
import './css/ProveedoresCatalogo.css'
import { Eye, FileEarmarkPlus, PencilSquare, Trash, ListTask, Grid3x2GapFill, CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';

//Elementos bootstrap
import { Card, Col, Form, Button, ButtonGroup, Tabs, Tab } from 'react-bootstrap';

//Componentes
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ModalCrearProveedor from './ModalCrearProveedor';
import ModalConsultarProveedor from './ModalConsultarProveedor';
import ModalEditarProveedor from './ModalEditarProveedor';
import ModalDeleteProveedor from './ModalDeleteProveedor';
import ModalCrearRequisicion from '../Requisiciones/ModalCrearRequisicion';
import Loader from "../Loader";

function ProveedoresCatalogo() {
    const [error, setError] = useState(false);
    const [status, setStatus] = useState('');
    const empresasDisponibles = useSelector(state => state.empresas.empresasDisponibles);
    let empresas = empresasDisponibles.map(e => { return e.empresa; });
    const empresaDefault = empresas[0];
    const [cargando, setCargando] = useState(false)
    const [key, setKey] = useState(empresaDefault);
    const [listaProveedores, setListaProveedores] = useState([]);
    const [updateListado, setUpdateListado] = useState(false);

    const [modalCrearProveedorShow, setModalCrearProveedorShow] = useState(false);
    let modalCrearProveedorClose = () => setModalCrearProveedorShow(false);

    let updateGetProveedores = () => { setUpdateListado(!!!updateListado); setListaProveedores([]); }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getProveedores, [updateListado]);

    function getProveedores() {
        setCargando(true);
        //axios.post(API_PROVEEDORES_URL + 'listProveedores', { empresas: empresas }
        axios.post(API_PROVEEDORES_URL + 'listProveedoresInfo', { empresas: empresas }
        ).then((res) => {
            if (res.data.result.code === 200) {
                setListaProveedores(res.data.proveedores)
            } else {
                setError(true);
                setListaProveedores('');
                setStatus(res.data.result.message)
            }
        }).catch((error) => {
            setError(true);
            setStatus("Error: No se han podido recuperar los registros de proveedores");
        }).finally(() => {
            setCargando(false);
        });
    }

    if(cargando) {
        return <Loader />
    }

    function createTabs() {
        let empresasTabs;
        listaProveedores.length ?
            empresasTabs = listaProveedores.map(empresa => (
                <Tab key={empresa.id} eventKey={empresa.id} title={empresa.nombreEmpresa}>
                    <ListaProveedores onUpdate={updateGetProveedores}>{empresa.lista}</ListaProveedores>
                </Tab>
            )) : empresasTabs = empresas.map(empresa => (
                <Tab key={empresa} eventKey={empresa} title={empresa}>
                    <section id="listaProvedores">
                        <div className="d-flex ml-auto mb-2 flex-row-reverse">
                            <button
                                className="ml-1 button-default-req"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Agregar proveedor"
                                onClick={() => { setModalCrearProveedorShow(true) }}
                            ><span>Agregar proveedor</span>
                            </button>
                            {modalCrearProveedorShow ? <ModalCrearProveedor show={modalCrearProveedorShow} onHide={modalCrearProveedorClose} onUpdate={updateGetProveedores}></ModalCrearProveedor> : ''}
                        </div>
                        <Card id="cardProveedores">
                            <Card.Body className="cardBodyProveedores">
                                {error ? status : 'Error: No se ha podido cargar la información'}
                            </Card.Body>
                        </Card>
                    </section>
                </Tab>
            ));
        return empresasTabs;
    }

    return (
        <div >
            <Tabs id="controlled-tab-proveedores" activeKey={key} onSelect={(k) => setKey(k)}>
                {createTabs()}
            </Tabs>
        </div >
    );
}

function ListaProveedores(props) {
    const [error] = useState(false);
    const [status] = useState('Error: No se ha podido recuperar la lista de proveedores');
    const [request] = useState(false);
    const [gridCards, setGridCards] = useState(false);
    const [listaProveedores] = useState(props.children);

    const [modalCrearProveedorShow, setModalCrearProveedorShow] = useState(false);
    const [modalConsultarProveedorShow, setModalConsultarProveedorShow] = useState(false);
    const [modalEditarProveedorShow, setModalEditarProveedorShow] = useState(false);
    const [modalDeleteProveedorShow, setModalDeleteProveedorShow] = useState(false);
    const [modalCrearRequisicionShow, setModalCrearRequisicionShow] = useState(false);
    const [provConsulta, setProvConsulta] = useState('');
    const [provEditar, setProvEditar] = useState('');
    const [provDelete, setProvDelete] = useState('');
    const [proveedor, setProveedor] = useState('');

    let modalCrearProveedorClose = () => setModalCrearProveedorShow(false);
    let modalConsultarProveedorClose = () => { setModalConsultarProveedorShow(false); setProvConsulta('') };
    let modalEditarProveedorClose = () => { setModalEditarProveedorShow(false); setProvEditar('') };
    let modalDeleteProveedorClose = () => { setModalDeleteProveedorShow(false); setProvDelete('') };
    let modalCrearRequisicionClose = () => { setModalCrearRequisicionShow(false); }
    let updateListado = () => { props.onUpdate() };
    
    function getProveedor(proveedor) {
        setProvConsulta(proveedor);
        setModalConsultarProveedorShow(true);
    }

    function updateProveedor(proveedor) {
        setProvEditar(proveedor);
        setModalEditarProveedorShow(true);
    }

    function deleteProveedor(proveedor) {
        setProvDelete(proveedor);
        setModalDeleteProveedorShow(true);
    }

    function crearRequisicion(proveedor) {
        setProveedor(proveedor)
        setModalCrearRequisicionShow(true);
    }

    let mostrarListaCatalogoProveedores = () => {
        if (error) {
            return (
                <div>{status}</div>
            );
        } else {
            const columnsList = [
                {
                    dataField: 'id',
                    text: 'ID',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true,
                    sortCaret: (order, column) => {
                        if (!order) return (<span>&nbsp;&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000" /><CaretUpFill size={10} className="button-default-black" color="#000" /></span>);
                        else if (order === "desc") return (<span>&nbsp;&nbsp;<CaretUpFill size={10} className="button-default-black" color="#000" /><CaretDownFill size={10} className="button-default-black" color="#dee2e6" /></span>);
                        else if (order === "asc") return (<span>&nbsp;&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000" /><CaretUpFill size={10} className="button-default-black" color="#dee2e6" /></span>);
                        return null;
                    }
                }, {
                    dataField: 'razonSocial',
                    text: 'Razón social',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                }, {
                    dataField: 'nombreComercial',
                    text: 'Nombre comercial',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                }, {
                    dataField: 'proveedorRfc',
                    text: 'RFC',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                }, {
                    dataField: 'giro',
                    text: 'Giro',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                }, {
                    dataField: 'acciones',
                    text: 'Acciones',
                    isDummyField: true,
                    formatter: (cellContent, row) => {
                        return (
                            <div>
                                <Button id="consultarProveedor" className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Consultar"
                                    onClick={() => getProveedor(row)}>
                                    <Eye size={22} className="button-default-black" color="#000" />
                                </Button>
                                <Button id="editarProveedor" className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Editar"
                                    onClick={() => updateProveedor(row)}
                                ><PencilSquare size={22} className="button-default-black" color="#000" />
                                </Button>
                                <Button id="eliminarProveedor" className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Eliminar"
                                    onClick={() => deleteProveedor(row)}
                                ><Trash size={22} className="button-default-black" color="#000" />
                                </Button>
                                <Button id="crearRequisicion" className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Crear Requisición"
                                    onClick={() => crearRequisicion(row)}
                                ><FileEarmarkPlus size={22} className="button-default-black" color="#000" />
                                </Button>
                            </div>
                        )
                    }
                }
            ];

            const columns = [{
                id: 'titleProveedor',
                dataField: 'proveedor',
                text: '',
                isDummyField: true,
                formatter: (cellContent, row) => {
                    return (
                        <div className="font-title-proveedores">
                            <div >{'Proveedor'}</div>
                        </div>
                    )
                },
            }, {
                dataField: 'id',
                text: 'ID',
                isDummyField: true,
                filter: textFilter({
                    placeholder: 'Buscar...',
                    className: "form-control-sm"
                }),
                sort: true
            }, {
                dataField: 'razonSocial',
                text: 'Razon social',
                isDummyField: true,
                filter: textFilter({
                    placeholder: 'Buscar...',
                    className: "form-control-sm"
                }),
                sort: true
            }, {
                dataField: 'nombreComercial',
                text: 'Nombre comercial',
                isDummyField: true,
                filter: textFilter({
                    placeholder: 'Buscar...',
                    className: "form-control-sm"
                }),
                sort: true
            }, {
                dataField: 'proveedorRfc',
                text: 'RFC',
                isDummyField: true,
                filter: textFilter({
                    placeholder: 'Buscar...',
                    className: "form-control-sm"
                }),
                sort: true
            }, {
                dataField: 'giro',
                text: 'Giro',
                isDummyField: true,
                filter: textFilter({
                    placeholder: 'Buscar...',
                    className: "form-control-sm"
                }),
                sort: true
            }, {
                dataField: 'acciones',
                isDummyField: true,
                text: '',
                formatter: (cellContent, row) => {
                    return (
                        <div className="d-flex justify-content-center prov-tbody-tr-td-div">
                            <ButtonGroup >
                                <button className="button-default-black" title="Consultar" onClick={() => getProveedor(row)}>
                                    <Eye size={22} color="#000" className="button-default-black" />
                                </button>
                                <button className="button-default-black" title="Editar" onClick={() => updateProveedor(row)}>
                                    <PencilSquare size={22} color="#000" className="button-default-black"></PencilSquare>
                                </button>
                                <button className="button-default-black" title="Eliminar" onClick={() => deleteProveedor(row)}>
                                    <Trash size={22} color="#000" className="button-default-black"></Trash>
                                </button>
                                <button className="button-default-black" title="Crear requisicion" onClick={() => crearRequisicion(row)}>
                                    <FileEarmarkPlus size={22} color="#000" className="button-default-black"></FileEarmarkPlus>
                                </button>
                            </ButtonGroup>
                        </div>
                    )
                }
            }, {
                dataField: 'rowOne',
                text: '',
                isDummyField: true,
                formatter: (cellContent, row) => {
                    return (
                        <div className="d-flex justify-content-around">
                            <div>
                                <div className="prov-tbody-tr-td-div">
                                    <div className="font-col-proveedores">{'ID: '}</div>
                                    <div className="">{row.id}</div>
                                </div>
                            </div>
                            <div>
                                <div className="prov-tbody-tr-td-div">
                                    <div className="font-col-proveedores">{'Empresa: '}</div>
                                    <div className="">{row.empresa}</div>
                                </div>
                            </div>
                        </div>
                    )
                }
            }, {
                dataField: 'rowTwo',
                text: '',
                isDummyField: true,
                formatter: (cellContent, row) => {
                    return (
                        <div className="d-flex justify-content-around">
                            <div>
                                <div className="prov-tbody-tr-td-div">
                                    <div className="font-col-proveedores">{'Razón social: '}</div>
                                    <div className="font-info-proveedores">{row.razonSocial}</div>
                                </div>
                            </div>
                        </div>
                    )
                }
            }, {
                dataField: 'rowThree',
                text: '',
                isDummyField: true,
                formatter: (cellContent, row) => {
                    return (
                        <div className="d-flex justify-content-around">
                            <div>
                                <div className="prov-tbody-tr-td-div">
                                    <div className="font-col-proveedores">{'RFC: '}</div>
                                    <div className="font-info-proveedores">{row.proveedorRfc}</div>
                                </div>
                            </div>
                        </div>
                    )
                }
            }, {
                dataField: 'rowFour',
                text: '',
                isDummyField: true,
                formatter: (cellContent, row) => {
                    return (
                        <div className="d-flex justify-content-around">
                            <div>
                                <div className="prov-tbody-tr-td-div">
                                    <div className="font-col-proveedores">{'Email: '}</div>
                                    <div className="font-info-proveedores">{row.correoElectronico}</div>
                                </div>
                            </div>
                        </div>
                    )
                }
            }
            ];

            function indication() {
                return listaProveedores.length ? "Cargando..." : "No hay registros que mostrar";
            }

            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size}
                </span>
            );

            const options = {
                custom: true,
                sizePerPage: gridCards ? 4 : 10,
                totalSize: listaProveedores.length,
                paginationTotalRenderer: customTotal
            }

            return <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div>
                        <div className="d-flex ml-auto mb-2 flex-row-reverse">
                            <button
                                className="ml-1 button-default-req"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Agregar proveedor"
                                onClick={() => { setModalCrearProveedorShow(true) }}
                            ><span>Agregar proveedor</span>
                            </button>
                            {gridCards ?
                                <Button className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Listado"
                                    onClick={() => { setGridCards(false) }}
                                >
                                    <ListTask size={24} className="button-default-black" color="#000" />
                                </Button> :
                                <Button className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Cuadrícula"
                                    onClick={() => { setGridCards(true) }}
                                >
                                    <Grid3x2GapFill size={24} className="button-default-black" color="#000" />
                                </Button>
                            }
                        </div>
                        <div className="d-flex">
                            {gridCards ? '' :
                                <div className="d-flex flex-row">
                                    <Form.Label column>Mostrar</Form.Label>
                                    <Col>
                                        {/* <Form.Control size="sm" as="select" onChange={(e) => handleSizePerPage(paginationProps, e.target.value)}>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value={listaProveedores.length}>Todos</option>
                                        </Form.Control> */}
                                        <SizePerPageDropdownStandalone btnContextual {...paginationProps}/>
                                    </Col>
                                </div>
                            }
                            <div className="d-flex flex-row">
                                <Col>
                                    <PaginationTotalStandalone {...paginationProps} />
                                </Col>
                            </div>
                            <div className="d-flex ml-auto flex-row-reverse">
                                <Col>
                                    <PaginationListStandalone {...paginationProps} />
                                </Col>
                                <Form.Label column>Página</Form.Label>
                            </div>
                        </div>
                        {
                            gridCards ?
                                <div className="table-responsive table-sm">
                                    <BootstrapTable
                                        id="proveedores"
                                        keyField='id'
                                        data={listaProveedores}
                                        columns={columns}
                                        filter={filterFactory()}
                                        bordered={false}
                                        noDataIndication={indication}
                                        {...paginationTableProps}
                                    />
                                </div>
                                :
                                <div className="table-responsive table-sm text-center">
                                    <BootstrapTable
                                        keyField='id'
                                        data={listaProveedores}
                                        columns={columnsList}
                                        filter={filterFactory()}
                                        headerClasses="header-class-pendientes"
                                        bordered={false}
                                        striped
                                        hover
                                        condensed
                                        noDataIndication={indication}
                                        {...paginationTableProps}
                                    />
                                </div>
                        }
                    </div>
                )}
            </PaginationProvider >
        }
    }

    return (
        <section id="listaProvCatalogo" className="text-center">
            <Card id="cardProvCatalogo">
                {/*<div className="d-flex justify-content-center">
                    <Button
                        size="md"
                        className="m-1 btn-agregar-prov "
                        variant="light"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Cancelar"
                        onClick={() => setModalCrearProveedorShow(true)}
                    ><span>Agregar proveedor  </span>
                        <FileEarmarkPerson size={24} color="white" />
                    </Button>
                </div>*/}
                <div>
                    {modalCrearProveedorShow ? <ModalCrearProveedor show={modalCrearProveedorShow} onHide={modalCrearProveedorClose} onUpdate={updateListado}></ModalCrearProveedor> : ''}
                    {provConsulta !== '' ? <ModalConsultarProveedor show={modalConsultarProveedorShow} onHide={modalConsultarProveedorClose}>{provConsulta}</ModalConsultarProveedor> : ''}
                    {provEditar !== '' ? <ModalEditarProveedor show={modalEditarProveedorShow} onHide={modalEditarProveedorClose} onUpdate={updateListado}>{provEditar}</ModalEditarProveedor> : ''}
                    {provDelete !== '' ? <ModalDeleteProveedor show={modalDeleteProveedorShow} onHide={modalDeleteProveedorClose} onUpdate={updateListado}>{provDelete}</ModalDeleteProveedor> : ''}
                    {modalCrearRequisicionShow ? <ModalCrearRequisicion show={modalCrearRequisicionShow} onHide={modalCrearRequisicionClose} onUpdate={updateListado}>{proveedor}</ModalCrearRequisicion> : ''}
                </div>
                <Card.Body className="cardBodyProveedores">
                    {request ? "Cargando..." : mostrarListaCatalogoProveedores()}
                </Card.Body>
            </Card>
        </section>
    );
}

export default ProveedoresCatalogo;