import React, { useState, useEffect } from "react";
import axios from 'axios'
import { API_PROVEEDORES_URL } from '../../constants'
import { useSelector } from 'react-redux';

//Estilos
import './css/PagosPasados.css'

//Elementos bootstrap
import { Tabs, Tab, Card } from "react-bootstrap";

//Componentes
import PagosPasados from "./PagosPasados"
import Loader from "../Loader";

function Pagos(props) {
    const token = JSON.parse(localStorage.getItem('token'));
    const empresasDisponibles = useSelector(state => state.empresas.empresasDisponibles);
    const empresas = empresasDisponibles.map(e => { return e.empresa; });
    const empresaDefault = empresas[0];
    const [key, setKey] = useState(empresaDefault);
    const [statusError, setStatusError] = useState('');
    const [listEmpresas, setListEmpresas] = useState([]);
    const [updateListado, setUpdateListado] = useState(false);
    const [cargandoPagos, setCargandoPagos] = useState(true)

    let updateGetListado = () => { setUpdateListado(!!!updateListado); setListEmpresas([]); }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getPagosPasados, [updateListado]);

    function getPagosPasados() {
        let user = JSON.parse(localStorage.getItem('token'));
        setCargandoPagos(true)
        axios.post(API_PROVEEDORES_URL + 'getPagosPasados',
            {
                empresas: empresas
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                console.log(res.data.pagos)
                user.userType === 'SCHEMA_ADMIN'? filterDataToMaster(res.data.pagos) : 
                filterDataToUser(res.data.pagos);
                setListEmpresas((res.data.pagos));
                console.log(res.data.pagos)
            } else {
                setListEmpresas('');
                setStatusError(res.data.result.message);
            }
        }).catch((error) => {
            setStatusError("Ocurrió un error, no se pudieron recuperar los pagos pasados");
        }).finally(() => {
            setCargandoPagos(false)
        })
    }
    function filterDataToUser(empresas){ 
        empresas.map((empresa)=>{
         empresa.lista = empresa.lista.filter(function(objeto) {
            return ((objeto.solicitante ===token.username)||objeto.usuarioCompartido ===token.username||objeto.pagadorId ===token.userId );
         });
         })
         return empresas;
     }
 
     function filterDataToMaster(empresas){
         empresas.map((empresa)=>{
        
             empresa.lista = empresa.lista.filter(function(objeto) {
                 
                 return (objeto.usuarioCompartido !==token.username) ;
             });
             })
             return empresas;
 
     }


    function createTabs() {
        let empresasTabs;
        listEmpresas.length ?
            empresasTabs = listEmpresas.map(empresa => (
                <Tab key={empresa.id} eventKey={empresa.id} title={empresa.nombreEmpresa}>
                    <PagosPasados onUpdate={updateGetListado}>{empresa.lista}{empresa.id}</PagosPasados>
                </Tab>
            )) : empresasTabs = empresas.map(empresa => (
                <Tab key={empresa} eventKey={empresa} title={empresa}>
                    <section id="listaPagosPasados">
                        <Card id="cardPagosPasados">
                            <Card.Body className="cardBodyPagosPasados">
                                {statusError}
                            </Card.Body>
                        </Card>
                    </section>
                </Tab>
            ))
        return empresasTabs;
    }
    
    if(cargandoPagos){
        return <Loader/>
    }

    return (
        <div >
            <Tabs id="controlled-tab-pagos" activeKey={key} onSelect={(k) => setKey(k)}>
                {createTabs()}
            </Tabs>
                {/*<div className="d-flex justify-content-center">                
                    <Button
                            size="md"
                            className="m-1 buttonGenerarPago"
                            variant="light"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Reporte de pagos pendientes"
                        ><span>Reporte de pagos pendientes</span>
                            <FileEarmarkArrowDown size={22} color="#ffffff"></FileEarmarkArrowDown>
                        </Button>
                {modalPagoMultipleShow ? <ModalPagoMultiple show={modalPagoMultipleShow} onHide={modalPagoMultipleClose} ></ModalPagoMultiple> : ''}
            </div>*/}
        </div>
    );
}

export default Pagos;