import { userConstants, requestResponse } from '../constantes/constants'
import { alertActions } from '../acciones/alert-actions'
import { history } from '../helpers/history'
import { KRAKEN_AUTH, SERVICIO_ID } from '../constants'
import axios from 'axios';

export const userActions = {
    login,
    logout
}

function login(email, password) {
    return dispatch => {
        dispatch(request({ email }));

        axios.post(KRAKEN_AUTH,
            {
                email: email,
                password: password,
                servicioId: SERVICIO_ID
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                let authToken = res.data.authToken;
                let base64url = authToken.split('.')[1];
                let decToken = JSON.parse(window.atob(base64url));
                decToken.email = email
                let token = JSON.stringify(decToken);
                
                localStorage.setItem('token', token);//LocalStorage sólo acepta cadenas, por eso hay que convertir a json
                dispatch(success(decToken));//el dispatch guarda bien el json en un inicio, no es necesario convertirlo a json
                dispatch(getEmpresas(res.data.empresas));
            } else {
                dispatch(failure(res.data.result.message));
                dispatch(alertActions.error(res.data.result.message));
            }
        }).catch((error) => {
            dispatch(failure("Usuario y/o contraseña incorrectos"));
            dispatch(alertActions.error("Usuario y/o contraseña incorrectos"))
        })
    }

    /*Most of the actions for users are async actions that are made up of multiple sub actions, 
   this is because they have to make an http request and wait for the response before completing. 
   Async actions typically dispatch a request action before performing an async task (e.g. an http request), 
   and then dispatch a success or error action based on the result of the async task.*/
    function request(token) { return { type: userConstants.LOGIN_REQUEST, token } }
    function success(token) { return { type: userConstants.LOGIN_SUCCESS, token } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function getEmpresas(list) {
    return dispatch => {
        if (list.length) {
            let empresas = JSON.stringify(list);
            localStorage.setItem('empresasDisponibles', empresas);//LocalStorage sólo acepta cadenas, por eso hay que convertir el array a json
            dispatch(success(list));//el dispatch guarda bien el json en un inicio, no es necesario convertirlo a json
            history.push('/inicio')
        } else {
            dispatch(failure("Este usuario no tiene acceso a ninguna empresa"));
            dispatch(alertActions.error("Este usuario no tiene acceso a ninguna empresa"))
            history.push('/inicio')
        }
    }

    function success(res) { return { type: requestResponse.SUCCESS, res } }
    function failure(res) { return { type: requestResponse.FAILURE, res } }
}

function logout() {
    localStorage.removeItem('token')
    localStorage.removeItem('empresasDisponibles');
    return { type: userConstants.LOGOUT }
}