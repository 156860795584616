import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_PROVEEDORES_URL,KRAKEN_URL } from '../../constants';
import { monedasSAT } from '../../constantes/moneda-sat';
import { formaPagoSAT } from '../../constantes/forma-pago-sat';
import { bigDecimalFormat } from '../../constantes/general-require';
import { ExpandMore, ExpandLess } from "@material-ui/icons";
//Estilos
import './css/Pagos.css'
import "react-datepicker/dist/react-datepicker.css";
import { FileEarmarkArrowUp } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';

//Elementos bootstrap
import { Form, Modal, Col, Container, InputGroup,Card,Accordion,Table } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';

//Componentes
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Moment from 'moment';
import NumberFormat from 'react-number-format';
import SeleccionarCuentaEmisora from './SeleccionarCuentaEmisora'
import ModalNotificarPago from './ModalNotificarPago';
import ModalAgregarUsuario from '../Configuracion/Empresa/Usuarios/UsuarioContactos/ModalAgregarUsuarioContacto';

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            light: '#f5f5f580',
            main: '#5B4B51',
            dark: '#5B4B51',
            contrastText: '#fff',
        }
    },
});

const useStyles = makeStyles((theme) => ({
    picker: {
        margin: theme.spacing(0.6),
    }
}));

function ModalSubirPago(props) {
    console.log(props)
    const [notificarPagador, setNotificarPagador] = useState(false);
    const [pagadorName, setPagadorName] = useState("");
    const [pagadorEmail, setPagadorEmail] = useState("");
    const [pagadores, setPagadores] = useState("");


    const [deactivate, setDeactivate] = useState(false);
    const classes = useStyles();
    const [inputValue, setInputValue] = useState(Moment().format('DD/MM/yyyy'));
    const [status, setStatus] = useState(false);
    /*Inicio campos del formulario*/
    const id = props.children.id;
    const idRequisicion = props.children.idRequisicion;
    const empresa = props.children.empresa;
    const idEmpresa = props.children.idEmpresa;
    const fechaRequeridaPago = props.children.fechaRequeridaPago;
    const proveedor = props.children.proveedor;
    const idProveedor = props.children.idProveedor;
    const proveedorRfc = props.children.proveedorRfc;
    const concepto = props.children.concepto;
    const [files, setFiles] = useState('');
    const [bancoEmisor, setBancoEmisor] = useState('');
    const [cuentaEmisor, setCuentaEmisor] = useState('');
    const [cuentaClabeEmisor, setCuentaClabeEmisor] = useState('');
    const [referenciaEmisor, setReferenciaEmisor] = useState('');
    const banco = props.children.banco;
    const cuentaClabe = props.children.cuentaClabe;
    const cuentaBanco = props.children.cuentaBanco;
    const [referencia, setReferencia] = useState(props.children.referencia);
    const [fechaPago, setFechaPago] = useState(Moment());
    const [moneda, setMoneda] = useState(props.children.monedaClaveSat + "," + props.children.monedaSat + "," + props.children.monedaDecimalesSat);
    const [monedaClaveSat, setMonedaClaveSat] = useState(props.children.monedaClaveSat);//MXN
    const [monedaSat, setMonedaSat] = useState(props.children.monedaSat);//Peso mexicano    
    const [monedaDecimalesSat, setMonedaDecimalesSat] = useState(props.children.monedaDecimalesSat);//2
    const [paridad, setParidad] = useState(props.children.paridad);
    const [formaPago, setFormaPago] = useState('03,Transferencia electrónica de fondos');
    const [formaPagoClaveSat, setFormaPagoClaveSat] = useState(props.children.formaPagoClaveSat);
    const [formaPagoSat, setFormaPagoSat] = useState(props.children.formaPagoSat);
    const numeroParcialidad = props.children.numeroParcialidad;
    const [montoPago, setMontoPago] = useState('0.00');
    const [iva, setIva] = useState('16.0');
    const [ivaTasaCuota, setIvaTasaCuota] = useState('0.16');
    const [ivaImporte, setIvaImporte] = useState(props.children.ivaTrasladadoImporte);
    const total = props.children.total;
    const saldoAnterior = props.children.saldoAnterior;
    const [saldoAnteriorFormat, setSaldoAnteriorFormat] = useState('0.0');
    const [saldoPagado, setSaldoPagado] = useState('0.00');
    const [saldoPendiente, setSaldoPendiente] = useState('0.00');
    const [saldoPendientePesosMexicanos, setSaldoPendientePesosMexicanos] = useState('0.00');
    const[showParidad, setShowParidad] = useState(monedaClaveSat==="MXN"?true:false)
    const listMonedas = monedasSAT;
    const listFormaPago = formaPagoSAT;
    const token = JSON.parse(localStorage.getItem('token'));
    const userId = token.userId;
    const empresaAlias = props.children;
    const usuario = {
        userId: userId,
        username: token.username,
        empresaAlias: empresaAlias
    }
    const [isLoading,setIsLoading] = useState(false)
    const [comentario, setComentario] = useState('');
    const [modalNotificarPagoShow, setModalNotificarPagoShow] = useState(false);
    let modalConfirmarClose = () => { setModalNotificarPagoShow(false); }
    const [modalAgregarUsuarioShow, setModalAgregarUsuarioShow] = useState(false);
    const [email, setEmail] =useState('')
    const [contactos,setContactos] =useState([])
    const [notProv,setNotProv] = useState(false)
    const [solicitante, setSolicitante] = useState(props.children.solicitante)
    const [objetoImpuestos,setObjetoImpuestos] = useState(props.children.objetoImpuesto)
    const [ivaTrasImporte,setTrasIvaImporte] = useState(props.children.ivaTrasladadoImporte)
    const [isrImporte,setIsrIvaImporte] = useState(props.children.ivaTrasladadoImporte)
    const [ivaRetenidoImporte, setIvaRetenidoImporte] = useState(props.children.ivaRetenidoImporte)
    const [descuentoImporte, setDescuentoImporte] =useState(props.children.descuentoImporte)
    const [subTotalDesc, setSubTotalDesc] =useState(props.children.subtotalDescuento)
    const [compartidos, setCompartidos] = useState(props.children.compartidos?JSON.parse(props.children.compartidos): []);
    let modalAgregarUsuarioClose = () => { setModalAgregarUsuarioShow(false); }


    function handleNotificarPagador(e) {
        setNotificarPagador(!notificarPagador);
    }
    function handlePagador(e) {
        let value = e.target.value;
        let aux = value.split("-");
        setPagadorName(aux[0]);
        setPagadorEmail(aux[1]);
    }
    useEffect(() => {
        console.log(idEmpresa);
        getPagadores();
      }, [idEmpresa]);
      function getPagadores() {
        console.log("entra a la peticion "+ idEmpresa);
        axios
          .post(KRAKEN_URL + "empresa/usuarios/payers", {
            empresaId: idEmpresa,
          })
          .then((res) => {
            console.log("........");
            console.log(res);
            console.log("..........")
           
            console.log(props.children.pagadorId)
            if (res.data.list)
              res.data.list.map((pagador) => {
                if(pagador.userId===props.children.pagadorId){
                    setPagadores(pagador.username)
                   }
              });
            
          })
          .catch((error) => {
            console.log(error);
            console.log("ALGO SALIÓ MAL");
          });
      }


    useEffect(() => {
        //Es como utilizar componentDidMount() o componentDidUpadte()
        calculoImporteIva();
        calculoSaldos();
        monedaDefault();
    })
    useEffect(getSolicitante, [props.children.idSolicitante]);

    function onBancoSelected(row) {
        setBancoEmisor(row.banco);
        setCuentaEmisor(row.cuenta);
        setCuentaClabeEmisor(row.cuentaClabe);
        setReferenciaEmisor(row.referencia);
    }

    function getSolicitante() {
        setIsLoading(true)
        axios.post(KRAKEN_URL + 'empresa/usuario/solicitante/getSolicitante', {
            userId: props.children.idSolicitante
            //empresa: empresaAlias
        }).then((res) => {
       
                setEmail(res.data.list[0].email)
           
            setIsLoading(false)
        }).catch((error) => {
     
            setIsLoading(false)
        })
    }
    function onContactosSelected(contactos, comentario) {
        if (contactos.length) {
            
            let aux  = []
            aux.push(email)
            aux.push(contactos)
            setContactos(aux)
            //savePago(contactos);
        } else {
            toast.warning("Debes seleccionar al menos un contacto a notificar",{
                position:"top-center",
                autoClose: 4500, 
            });
            
            setStatus(false);
        }
    }
    function handleComentarioChange(e) {
        setComentario(e.target.value);
    }
    function handleFilesChange(e) {
        setFiles(e.target.files);
        let files = e.target.files; //FileList object
        let output = [];

        for (var i = 0; i < files.length; i++) {
            output.push('<li><strong>', escape(files[i].name), '</strong> (', files[i].type || 'n/a', ') - ', files[i].size, ' bytes</li>');
        }
        document.getElementById("list").innerHTML = '<ol>' + output.join('') + '</ol>';
    }
    /*function handleComentarioChange(e) {
        setComentario(e.target.value);
    }
    function handleBancoChange(e) {
        setBanco(e.target.value);
    }
    function handleCuentaClabeChange(e) {
        setCuentaClabe(e.target.value);
    }
    function handleCuentaBancoChange(e) {
        setCuentaBanco(e.target.value);
    }*/
    function handleReferenciaChange(e) {
        setReferencia(e.target.value);
    }
    function handleReferenciaEmisorChange(e) {
        setReferenciaEmisor(e.target.value);
    }
    const handleFechaPagoChange = (date, value) => {
        setFechaPago(date);
        setInputValue(value);
    }
    function handleMonedaChange(e) {
        var str = e.target.value;
        var array = str.split(",");
        setMoneda(e.target.value);
        setMonedaClaveSat(array[0]);
        setMonedaSat(array[1]);
        setMonedaDecimalesSat(array[2]);
    }
    function handleParidadChange(e) {
        setParidad(e.target.value);
    }
    function handleFormaPagoChange(e) {
        var str = e.target.value;
        var array = str.split(",");

        setFormaPago(e.target.value);
        setFormaPagoClaveSat(array[0]);
        setFormaPagoSat(array[1]);
    }
    function handleMontoPagoChange(e) {
        setMontoPago(e.target.value);
    }
    function handleIvaChange(e) {
        setIva(e.target.value);//Se guarda tal cual el valor que aparece en el input
        let ivaDecimal = bigDecimalFormat(e.target.value); //Se convierte a bigDecimal
        let tasaCuotaDecimal = ivaDecimal.dividedBy(100);//dividedBy: a/b
        setIvaTasaCuota(tasaCuotaDecimal);
    }
    function handleNotProv(e){
        
        setNotProv(!notProv)
    }
    function calculoImporteIva() {
        let importeDecimal = bigDecimalFormat(montoPago);//Se convierte a bigDecimal
        let importeIvaDecimal = importeDecimal.times(ivaTasaCuota).toFixed(parseFloat(monedaDecimalesSat));//times: a*b; toFixed: cuántos dígitos después del punto decimal
        setIvaImporte(importeIvaDecimal);//Se guarda el valor sin comas y se agrega el diseño hasta que se utiliza en el formulario
    }
    function monedaDefault() {
        let str = moneda;
        var array = str.split(",");
        setMoneda(str);
        setMonedaClaveSat(array[0]);
        setMonedaSat(array[1]);
        setMonedaDecimalesSat(array[2]);

        if (monedaClaveSat === 'MXN') {
            //document.getElementById("paridad").value = '1.00';
            setParidad('1.00');
            if(document.getElementById("paridad"))
            document.getElementById("paridad").disabled = true;
        } else {
            if(document.getElementById("paridad"))
            document.getElementById("paridad").disabled = false;
        }
    }
    function calculoSaldos() {
        let montoPagoValue = montoPago;
        let montoPagoDecimal = bigDecimalFormat(montoPagoValue);
        setMontoPago(montoPagoDecimal.toFixed(parseFloat(monedaDecimalesSat)));
        let saldoAnteriorDecimal = bigDecimalFormat(saldoAnterior);
        setSaldoAnteriorFormat(saldoAnteriorDecimal.toFixed(parseFloat(monedaDecimalesSat)));
        setSaldoPagado(montoPagoDecimal.toFixed(parseFloat(monedaDecimalesSat)));
        let saldoPendienteDecimal = saldoAnteriorDecimal.minus(montoPagoDecimal);
        setSaldoPendiente(saldoPendienteDecimal.toFixed(parseFloat(monedaDecimalesSat)));
        let paridadValue = paridad;
        let paridadDecimal = bigDecimalFormat(paridadValue);
        let saldoPendienteConParidad = saldoPendienteDecimal.times(paridadDecimal);
        setSaldoPendientePesosMexicanos(saldoPendienteConParidad.toFixed(parseFloat(2)));
    }

    const createInputSaldoPendientePesosMexicanos = () => {
        let inputTPM = (<span><Form.Label > Saldo pendiente pesos mexicanos</Form.Label>
            <InputGroup size="sm">
                <InputGroup.Prepend >
                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                </InputGroup.Prepend>
                <NumberFormat id="saldoPendientePesosMexicanos" className="input-signs-align form-control form-control-sm" thousandSeparator={true} disabled value={saldoPendientePesosMexicanos}></NumberFormat>
            </InputGroup>
        </span>
        );
        return inputTPM;
    }
    function cargando() {
        return (
            <div className="d-flex justify-content-center align-items-center" >
            <Spinner  animation="border" role="status">
              <span className="visually-hidden"></span>
            </Spinner>
          </div>)
      }
      function handleCheckboxChange(){
        setObjetoImpuestos(!objetoImpuestos)
      }
    function cargarSelectMonedas() {
        return listMonedas.length ? listMonedas.map(e => {
            return (<option key={e.clave} value={e.clave + "," + e.nombre + "," + e.decimales}>{[e.clave, e.nombre, "Presición", e.decimales].join(' - ')}</option>)
        }) : 'No existen monedas registradas'
    }

    function cargarSelectFormaPago() {
        return listFormaPago.length ? listFormaPago.map(e => {
            return (<option key={e.clave} value={e.clave + "," + e.nombre}>{[e.clave, e.nombre].join(' - ')}</option>)
        }) : 'No existen formas de pago registradas'
    }

    function openModalNotificar(e) {
        e.preventDefault();
        if (files === '') {
            setDeactivate(true);

            setTimeout(() => {
                setDeactivate(false);
            }, 4500);

            toast.error("No hay archivos seleccionados",{
                position:"top-center",
                autoClose: 4500, 
            });
        } else {
            //setModalNotificarPagoShow(true);
            setIsLoading(true)
            savePago()
        }
    }
    function openMasUsuarios(e){
            e.preventDefault();
            setContactos([])
            setModalNotificarPagoShow(true);
    }
    function mapeoCompartidos(compartidos){
        if(compartidos !=="[]"){
          const compartidosArray = JSON.parse(compartidos);
         return compartidosArray.map(usuario => usuario.usuarioEmail);
        
        }
        return []
      }
    function savePago() {
        setStatus(true);    
        let compartidos = mapeoCompartidos(props.children.compartidos)
        let auxArray =[]
        var monedaStr = document.getElementById("moneda").value;
        var arrayMonedaStr = monedaStr.split(",");
        if(contactos.length!==0)
        {auxArray = [...contactos,...compartidos]}
        else{auxArray = compartidos
            auxArray.push(email)} 
        const formData = new FormData();
        for (var i = 0; i < files.length; i++) {
            formData.append("files", files[i]);
        }
        formData.append("idRequisicion", idRequisicion);
        formData.append("id", parseFloat(id));
        formData.append("empresa", empresa);
        formData.append("idEmpresa", idEmpresa);
        formData.append("fechaRequeridaPago", fechaRequeridaPago);
        formData.append("fechaPago", Moment(fechaPago).format("YYYY-MM-DD"));
        formData.append("proveedor", proveedor);
        formData.append("idProveedor", idProveedor);
        formData.append("proveedorRfc", proveedorRfc);
        formData.append("banco", banco);
        formData.append("bancoEmisor", bancoEmisor);
        formData.append("cuentaClabe", cuentaClabe);
        formData.append("cuentaClabeEmisor", cuentaClabeEmisor);
        formData.append("cuentaBanco", cuentaBanco);
        formData.append("cuentaEmisor", cuentaEmisor);
        formData.append("referencia", referencia);
        formData.append("referenciaEmisor", referenciaEmisor);
        formData.append("concepto", concepto);
        formData.append("monedaClaveSat", monedaClaveSat);
        formData.append("monedaSat", monedaSat);
        formData.append("monedaDecimales", parseFloat(arrayMonedaStr[2]));
        formData.append("paridad", parseFloat(document.getElementById("paridad").value));
        formData.append("formaPagoClaveSat", formaPagoClaveSat);
        formData.append("formaPagoSat", formaPagoSat);
        formData.append("numeroParcialidad", numeroParcialidad);
        formData.append("montoPago", parseFloat(saldoPagado));
        formData.append("iva", parseFloat(ivaTasaCuota));
        formData.append("ivaImporte", parseFloat(ivaImporte));
        formData.append("total", parseFloat(total));
        formData.append("saldoAnterior", parseFloat(saldoAnterior));
        formData.append("saldoPagado", parseFloat(saldoPagado));
        formData.append("saldoPendiente", parseFloat(saldoPendiente));
        formData.append("to", auxArray);
        formData.append("comentario",comentario );
        formData.append("toProvedor",notProv)

        axios.post(API_PROVEEDORES_URL + 'savePago', formData,
            {
                headers: { 'Content-Type': `multipart/form-data;` }
            }
        ).then((res) => {
            setIsLoading(false)
            setStatus(false);
            let output = [];
            if ((res.data.result.code === 200) && (res.data.filesResult.code === 200)) {
                setStatus(false);
                toast.success(res.data.result.message,{
                    position:"top-center",
                    autoClose: 4500, 
                });
                output.push('<li><strong>', res.data.result.message, '</strong>');
                output.push('<li><strong>', res.data.filesResult.message, '</strong>');
                document.getElementById("listResponse").innerHTML = '<ol>' + output.join('') + '</ol>';
                props.onUpdate();//Actualiza el listado, llamando a la función padre para actualizarlo   
                props.onHide();//Cierra el modal, llamando a la función padre para cerrarlo                             
            } else {
                setStatus(false);
                toast.error(res.data.result.message,{
                    position:"top-center",
                    autoClose: 4500, 
                });
                output.push('<li><strong>', res.data.result.message, '</strong>');
                output.push('<li><strong>', res.data.filesResult.message, '</strong>');
                document.getElementById("listResponse").innerHTML = '<ol>' + output.join('') + '</ol>';
            }
        }).catch((error) => {
            setStatus(false);
            toast.error("No se ha podido subir el pago. No hay archivos seleccionados.",{
                position:"top-center",
                autoClose: 4500, 
            });
        })
    }

    return  (
        <section className="text-center">
            <Modal
                show={props.show}
                //onHide={props.onHide}
                size="xl"
                centered
                id="modalSubirPago"
                scrollable={true}
            >
                <Modal.Header className="modal-header-subir-pago" closeButton onHide={props.onHide}>
                    <p><FileEarmarkArrowUp size={22} color="#5B4B51" /> Subir pago de la requisición #{props.children.id} </p>
                </Modal.Header>
                <Modal.Body className="modal-portal-body">
                    {modalNotificarPagoShow ? <ModalNotificarPago show={modalNotificarPagoShow} onHide={modalConfirmarClose} onContactosSelected={onContactosSelected}>{id}{empresa}</ModalNotificarPago>  : '' }
                    {modalAgregarUsuarioShow ? <ModalAgregarUsuario show={modalAgregarUsuarioShow} onHide={modalAgregarUsuarioClose}>{usuario}</ModalAgregarUsuario> : '' }
                    <Container className="container-modals">
                        {!isLoading? <Form.Row>
                            <Form.Group as={Col} md={12} lg={6}>
                                <fieldset >
                                    <legend className="legend-subir-pago">Emisor del pago</legend>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col}>
                                            <Form.Label>Empresa</Form.Label>
                                            <Form.Control size="sm" id="empresa" type="text" disabled defaultValue={empresa}></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col} md={12} lg={6}>
                                            <Form.Label>Referencia</Form.Label>
                                            <Form.Control size="sm" type="text" required placeholder="Seleccione un banco" value={referenciaEmisor} onChange={handleReferenciaEmisorChange}></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <SeleccionarCuentaEmisora onBancoSelected={onBancoSelected}>{idEmpresa}</SeleccionarCuentaEmisora>
                                </fieldset>
                                <fieldset >
                                    <legend className="legend-subir-pago">Receptor del pago</legend>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col}>
                                            <Form.Label>Nombre proveedor</Form.Label>
                                            <Form.Control size="sm" id="proveedor" type="text" disabled defaultValue={proveedor}></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col}>
                                            <Form.Label>Banco</Form.Label>
                                            <Form.Control size="sm" id="banco" type="text" disabled defaultValue={banco}>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Cuenta CLABE</Form.Label>
                                            <NumberFormat
                                                id="cuentaClabe"
                                                format="#### #### #### #### ##"
                                                mask="_"
                                                className="form-control form-control-sm"
                                                disabled
                                                defaultValue={cuentaClabe}
                                            >
                                            </NumberFormat>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col}>
                                            <Form.Label>Cuenta banco</Form.Label>
                                            <NumberFormat
                                                id="cuentaBanco"
                                                format="#### #### #### #### #### ####"
                                                mask="_"
                                                className="form-control form-control-sm"
                                                disabled
                                                defaultValue={cuentaBanco}
                                            >
                                            </NumberFormat >
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Referencia</Form.Label>
                                            <Form.Control size="sm" id="referencia" type="text" required placeholder="Ingrese referencia" value={referencia} onChange={handleReferenciaChange}></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    
                         <Form.Row as={Col}>     
                         <legend className="legend-crear-req-not">Notificaciones</legend>    
                        <Form.Label>Usuario pagador</Form.Label>
                        <Form.Control 
                          size="sm"
                          type="text"
                          disabled
                          value={pagadores}
                        ></Form.Control>
                        </Form.Row>
                   

                   

                                    <Form.Row as={Col}>
                                    {compartidos.length>0 ?(
                    <Form.Group>
                          <legend className="legend-crear-req">Se comparte con los siguientes usuarios:</legend>

                          {/* <div style={{margin: "0px 0px 10px 0px"}}>
                            <Form.Check
                            type="checkbox"
                            label="Notificar a usuario seleccionado"
                            checked={notificarPagador}
                            onChange={handleNotificarPagador}
                            />
                          </div> */}

                          {/* <Form.Label>Usuario pagador</Form.Label>
                          <Form.Control
                            size="sm"
                            id="pagador"
                            type="text"
                            as="select"
                            required
                            placeholder="Seleccione pagador"
                            onChange={handlePagador}
                          >
                            <option default value="">
                              Seleccione pagador
                            </option>
                             {pagadores.map((pagador, index) => (
                            <option
                              key={index}
                              value={[pagador.userId, pagador.email].join("-")}
                            >
                              {pagador.username}
                            </option>
                          ))}
                          </Form.Control> */}
                        
                   <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th><b>Nombre de usuario</b></th>
                          <th><b>Email</b></th>
                        </tr>
                      </thead>
                      <tbody> {compartidos.map(elemento => (
                <tr key={elemento.usuarioId}>
                <td>{elemento.usuarioNombre}</td>
                <td>{elemento.usuarioEmail}</td>
             
            </tr>
          ))}</tbody>
                    </Table></Form.Group>):"" } 
                                    </Form.Row>
                                </fieldset>
                            </Form.Group>
                            <Form.Group as={Col} md={12} lg={6}>
                                <fieldset >
                                    <legend className="legend-subir-pago">Información del pago</legend>
                                    <Form.Row as={Col} className="formRowFiles">
                                        <Form.Group as={Col}>
                                            <Form.File.Label>Archivos soportados: jpg, jpeg, png, pdf, txt, xls, xlsx, xml, zip</Form.File.Label>
                                            <input className="input-file-req" type="file" id="files" name="files[]"
                                                multiple onChange={handleFilesChange}
                                                accept=".jpg, .jpeg, .png, .pdf, .txt, .xls, .xlsx, .xml, .zip"
                                            />
                                            <output id="list"></output>
                                            <output id="listResponse"></output>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col}>
                                            <Form.Label>Concepto general</Form.Label>
                                            <Form.Control size="sm" id="concepto" as="textarea" rows={3} disabled defaultValue={concepto}></Form.Control>
                                        </Form.Group>
                                        {/*<Form.Group as={Col}>
                                            <Form.Label>¿Comentarios?</Form.Label>
                                            <Form.Control size="sm" id="comentario" as="textarea" rows={3} value={comentario} placeholder="Ingrese sus comentarios acerca del pago" onChange={handleComentarioChange}></Form.Control>
                                        </Form.Group>*/}
                                    </Form.Row>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col}>
                                            <Form.Label>Fecha requerida de pago</Form.Label>
                                            <Form.Control size="sm" type="text" disabled defaultValue={Moment(fechaRequeridaPago).format('DD/MM/YYYY')}></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col} className={classes.picker}>
                                            <ThemeProvider theme={defaultMaterialTheme}>
                                                <MuiPickersUtilsProvider libInstance={Moment} utils={MomentUtils}>
                                                    <KeyboardDatePicker
                                                        id="fechaPago"
                                                        label="Fecha de pago (dd/mm/yyyy)"
                                                        autoOk={true}
                                                        variant="inline"
                                                        format="DD/MM/yyyy"
                                                        value={fechaPago}
                                                        inputValue={inputValue}
                                                        onChange={handleFechaPagoChange}
                                                    >
                                                    </KeyboardDatePicker>
                                                </MuiPickersUtilsProvider>
                                            </ThemeProvider>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row as={Col}>
                                        <Form.Group as={Col} lg={6}>
                                            <Form.Label>Moneda</Form.Label>
                                            <Form.Control size="sm" id="moneda" type="text" as="select" required value={moneda} onChange={handleMonedaChange}>
                                                {cargarSelectMonedas()}
                                            </Form.Control>
                                            <Form.Label hidden={showParidad}>Paridad</Form.Label>
                                            <InputGroup hidden={showParidad} size="sm">
                                                <InputGroup.Prepend >
                                                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control size="sm" id="paridad" type="number" className="input-signs-align" required value={paridad} onChange={handleParidadChange}></Form.Control>
                                            </InputGroup>
                                            <Form.Label>Forma de pago</Form.Label>
                                            <Form.Control size="sm" id="formaPago" type="text" as="select" required placeholder="Seleccione forma de pago" defaultValue={formaPago} onChange={handleFormaPagoChange}>
                                                {cargarSelectFormaPago()}
                                            </Form.Control>
                                            <Form.Label>Número de parcialidad</Form.Label>
                                            <Form.Control size="sm" id="numParcialidad" type="text" className="input-signs-align" disabled defaultValue={numeroParcialidad}></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col} lg={6}>
                                            <Form.Label>Monto del pago</Form.Label>
                                            <InputGroup size="sm">
                                                <InputGroup.Prepend >
                                                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <NumberFormat id="montoPago" className="input-signs-align form-control form-control-sm" required placeholder="Ingrese monto" thousandSeparator={true} defaultValue={montoPago} onChange={handleMontoPagoChange}></NumberFormat>
                                            </InputGroup>
                                            <Form.Label>IVA Retenido</Form.Label>
                                            <Form.Row>
                                                <Col>
                                                    <InputGroup size="sm">
                                                        <NumberFormat id="iva" className="input-signs-align form-control form-control-sm" thousandSeparator={true} value={iva} onChange={handleIvaChange}></NumberFormat>
                                                        <InputGroup.Append >
                                                            <InputGroup.Text className="input-signs-right">%</InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </Col>
                                                <Col>
                                                    <InputGroup size="sm">
                                                        <InputGroup.Prepend >
                                                            <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <NumberFormat id="ivaImporte" className="input-signs-align form-control form-control-sm" thousandSeparator={true} disabled value={ivaImporte}></NumberFormat>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Row>
                                            <Form.Label>Saldo anterior</Form.Label>
                                            <InputGroup size="sm">
                                                <InputGroup.Prepend >
                                                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <NumberFormat id="saldoAnterior" className="input-signs-align form-control form-control-sm" disabled thousandSeparator={true} value={saldoAnteriorFormat}></NumberFormat>
                                            </InputGroup>
                                            <Form.Label>Saldo pagado</Form.Label>
                                            <InputGroup size="sm">
                                                <InputGroup.Prepend >
                                                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <NumberFormat id="saldoPagado" className="input-signs-align form-control form-control-sm" disabled thousandSeparator={true} value={saldoPagado}></NumberFormat>
                                            </InputGroup>
                                            <Form.Label>Saldo pendiente</Form.Label>
                                            <InputGroup size="sm">
                                                <InputGroup.Prepend >
                                                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <NumberFormat id="saldoPendiente" className="input-signs-align form-control form-control-sm" disabled thousandSeparator={true} value={saldoPendiente}></NumberFormat>
                                            </InputGroup>
                                            {monedaClaveSat !== 'MXN' ? createInputSaldoPendientePesosMexicanos() : ''}
                                        </Form.Group>
                                    </Form.Row>
                                    <Accordion.Toggle style={{ cursor: 'pointer' }}   onClick={handleCheckboxChange}  as={Card.Header}  eventKey="1" className="legend-crear-prov">
                                    Impuestos de la requisición{!objetoImpuestos?<ExpandMore />:<ExpandLess />} 
                                    </Accordion.Toggle> 
                                    <Form.Row hidden={!objetoImpuestos}  as={Col}>
                                    <Form.Group  hidden={!objetoImpuestos} as={Col} lg={6}>
                                        
                                            <Form.Label  hidden={!objetoImpuestos} >IVA Traslado importe</Form.Label>
                                            <Form.Control   hidden={!objetoImpuestos} size="sm" id="numParcialidad" type="text" className="input-signs-align" disabled defaultValue={ivaTrasImporte}></Form.Control>
                                          
                                            <Form.Label  hidden={!objetoImpuestos}>IVA Retenido importe</Form.Label>
                                            <Form.Control  hidden={!objetoImpuestos} size="sm" id="numParcialidad" type="text" className="input-signs-align" disabled defaultValue={ivaRetenidoImporte}></Form.Control>
                                            <Form.Label  hidden={!objetoImpuestos}>ISR importe</Form.Label>
                                            <Form.Control  hidden={!objetoImpuestos} size="sm" id="numParcialidad" type="text" className="input-signs-align" disabled defaultValue={isrImporte}></Form.Control>
                                        </Form.Group>
                                        <Form.Group  hidden={!objetoImpuestos} as={Col} lg={6}>
                                            <Form.Label  hidden={!objetoImpuestos}>Descuento</Form.Label>
                                            <Form.Control  hidden={!objetoImpuestos} size="sm" id="numParcialidad" type="text" className="input-signs-align" disabled defaultValue={descuentoImporte}></Form.Control>
                                           
                                            <Form.Label  hidden={!objetoImpuestos}>SubTotal descuento</Form.Label>
                                            <Form.Control size="sm" id="numParcialidad" type="text" className="input-signs-align" disabled defaultValue={subTotalDesc}></Form.Control>
                                            <Form.Label  hidden={!objetoImpuestos}>Total</Form.Label>
                                            <Form.Control   hidden={!objetoImpuestos}size="sm" id="numParcialidad" type="text" className="input-signs-align" disabled defaultValue={total}></Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    {/*<Form.Row as={Col}>
                                    <legend className="modal-header-consultar-pagos">Notificaciones</legend>
                                     
                                        <p>Se dará aviso automaticamente al usuario solicitante <b>"{solicitante}"</b> que se realizó el pago</p>
                                    
                                    </Form.Row>*/}
                                    <Form.Row as={Col}>
                                    <Form.Group as={Col}>
                                           
                                            {/*<Form.Check
                                                type="checkbox"
                                                label="Notificar a proveedor"
                                                 checked={notProv}
                                                 onChange={handleNotProv}
                                                                                    />*/}
                                        </Form.Group>
                                        {/*<Form.Group as={Col}>
                                           
                                        <a href="#" onClick={openMasUsuarios}>Notificar a mas usuarios</a>
                                       </Form.Group>*/}
                                        {/*<Form.Control id="comentario" as="textarea" rows={1} placeholder="¿Desea agregar algún comentario?" value={comentario} onChange={handleComentarioChange} ></Form.Control>*/}
                                    </Form.Row>
                                </fieldset>
                            </Form.Group>
                        </Form.Row>:  <Form.Row as={Col}>{cargando()}</Form.Row>}
                        
                    </Container>
                </Modal.Body>
                <Modal.Footer hidden={isLoading} className="justify-content-end">
                    <button
                        className="m-1 button-default-confirmar"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Subir pago"
                        disabled = { deactivate }
                        onClick={openModalNotificar}
                    >{status ?
                        <span>Enviando...</span>
                        : <span>Subir pago</span>}
                    </button>
                    <button
                        className="m-1 button-default-cancelar"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Cancelar"
                        onClick={props.onHide}
                    ><span>Cancelar</span>
                    </button>
                </Modal.Footer>
            </Modal>
        </section>
    )
}

export default ModalSubirPago;