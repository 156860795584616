import { combineReducers } from 'redux';

import { authentication } from './authentication-reducer'
import { alert } from './alert-reducer'
import { empresas } from './system-reducer'

const rootReducer = combineReducers({
    authentication,
    alert,
    empresas
});

export default rootReducer;