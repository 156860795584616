import React, { useState } from "react";
import axios from 'axios';
import { API_PROVEEDORES_URL } from '../../constants';

//Estilos
import '../Requisiciones/css/Requisiciones.css'
import './css/ProveedoresCatalogo.css'
import { Trash } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';

//Componentes bootstrap
import { Modal } from 'react-bootstrap';

function ModalDeleteProveedor(props) {
    const [status, setStatus] = useState(false);

    function deleteProveedor(idProveedor, idProveedorEmpresa, e) {
        e.preventDefault();
        setStatus(true);
        axios.post(API_PROVEEDORES_URL + 'deleteProveedor',
            {
                idProveedor: idProveedor,
                idProveedorEmpresa: idProveedorEmpresa
            }
        ).then((res) => {
            if (res.data.result.success) {
                setStatus(false);
                toast.success(res.data.result.message,{
                    position:"top-center",
                    autoClose: 4500, 
                });
                props.onHide();//Cierra el modal, llamando a la función padre para cerrarlo
                props.onUpdate();//Actualiza el listado, llamando a la función padre para actualizarlo                         
            } else {
                setStatus(false);
                toast.error(res.data.result.message,{
                    position:"top-center",
                    autoClose: 4500, 
                });
                toast.error(res.data.result.suggestion,{
                    position:"top-center",
                    autoClose: 4500, 
                });
            }
        }).catch((error) => {
            toast.error("No se ha podido eliminar el proveedor",{
                position:"top-center",
                autoClose: 4500, 
            });
            setStatus(false);
        });
    }

    return (
        <section className="text-center">
            <Modal
                show={props.show}
                //onHide={props.onHide}
                size="sm"
                id="modalDeleteProveedor"
                aria-labelledby="contained-modal-title-vcent"
                centered
            >
                <Modal.Header className="modal-header-eliminar-prov" closeButton onHide={props.onHide}>
                    <h5 className="legend-consultar-prov"><Trash size={22} color="#5B4B51" />   Eliminar proveedor </h5>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {/*<PersonX size={22} color="#da6570" />*/}
                        &nbsp;¿Está seguro que desea eliminar este proveedor?
                    </p>
                    <p>Nombre comercial: <strong>{props.children.nombreComercial}</strong></p>
                    <div className="d-flex justify-content-center">
                        <button
                            className="m-1 button-default-confirmar"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Confirmar"
                            onClick={e => deleteProveedor(props.children.idProveedor, props.children.idProveedorEmpresa, e)}
                        >{status ?
                            <span>Eliminando...</span>
                            : <span>Confirmar</span>}
                        </button>
                        <button
                            className="m-1 button-default-cancelar"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Cancelar"
                            onClick={props.onHide}
                        ><span>Cancelar</span>
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    );
}

export default ModalDeleteProveedor;