import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_PROVEEDORES_URL, KRAKEN_URL } from "../../constants";


import "./css/Requisiciones.css";
import { CheckAll } from "react-bootstrap-icons";
import { toast } from "react-toastify";

//Elementos bootstrap
import { Modal, Form, Col, Spinner, Table } from "react-bootstrap";

//componentes


function ModalAsignacion(props) {

  //const solicitante = props.children.solicitante;
  const [esMasivo, setEsMasivo] = useState(Array.isArray(props.children));
  //const solicitante = props.children.solicitante;
  const [deactivate, setDeactivate] = useState(false);

  const token = JSON.parse(localStorage.getItem("token"));
  const userId = token.userId;
  const userEmail = token.email
  const empresaAlias = props.children.empresa;
  const [request] = useState(false);
  const [listContactos, setListContactos] = useState([]);
  const [status, setStatus] = useState(false);
  const [error, setError] = useState(false);
  const [statusError, setStatusError] = useState("");
  const [seleccionados, setSeleccionados] = useState([]); //Aquí se guardan sólo los id's de los contactos que se seleccionen
  const [ctcSeleccionados, setCtcSeleccionados] = useState([]); //Aquí se guardan sólo los emails que se seleccionen
  const [updateListado, setUpdateListado] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [solicitante, setSolicitante] = useState([]);
  const [compartidos, setCompartidos] = useState(
    props.children.compartidos ? JSON.parse(props.children.compartidos) : []
  );
  const [compartidosToSend, setCompartidosToSend] = useState([]);
  const usuario = {
    userId: userId,
    username: token.username,
    empresaAlias: empresaAlias,
  };
  const [comentario, setComentario] = useState('');
  const [pagadores,setPagadores] = useState([])
  const handleComentarioChange = (event) => {
    setComentario(event.target.value);
  };

  const [modalUsuarioAgregarShow, setModalUsuarioAgregarShow] = useState(false);
  let modalUsuarioAgregarClose = () => setModalUsuarioAgregarShow(false);
  let updateGetListado = () => {
    setUpdateListado(!!!updateListado);
  };
 
  // eslint-disable-next-line react-hooks/exhaustive-deps


  function cargando() {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden"></span>
        </Spinner>
      </div>
    );
  }


  
  function asignarRequisicion(requisicion, e) {
    e.preventDefault();
    setStatus(true);
    setIsLoading(true);
  
    console.log(requisicion)
    console.log(token)
    axios.post(API_PROVEEDORES_URL + "asignarRequisicion", {
        idRequisicion: requisicion.idRequisicion,
        pagadorId: userId,
        pagadorEmail: userEmail 
    }, {
        headers: {
            'Content-Type': 'application/json' 
        }
    })
          .then((res) => {
            setIsLoading(false);
            if (res.data.result.success) {
              setStatus(false);

              toast.success(res.data.result.message, {
                position: "top-center",
                autoClose: 4500,
              });

              toast.success(
                "Se ha asignado a usted como pagador de esta requisición",
                {
                  position: "top-center",
                  autoClose: 4500,
                }
              );

              props.onUpdate(); 
              props.onHide(); 
            } else {
              setStatus(false);

              toast.error(res.data.result.message, {
                position: "top-center",
                autoClose: 4500,
              });
              toast.error(res.data.result.suggestion, {
                position: "top-center",
                autoClose: 4500,
              });
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
            setStatus(false);
            toast.error(
              "No se ha asignar la requisición al pagador",
              {
                position: "top-center",
                autoClose: 4500,
              }
            );
          });
      
     
  }





  return (
    <section className="text-center">
      <Modal
        show={props.show}
        size="lg"
        id={!esMasivo ? "modalConfirmarRequisicion" : "modalPedirAprobacion"}
        centered
      >
        <Modal.Header className="modal-header-confirmar-req" closeButton  onClick={props.onHide} >
          <p>
            <CheckAll size={22} color="#5B4B51" />
            {!esMasivo ? (
              <p>
                &nbsp;¿Quiere asignarse esta requisición? #{props.children.id} 
              </p>
            ) : (
              <p>
               Usted quedará como usuario pagador para esta requisición
              </p>
            )}
          </p>
        </Modal.Header>
        {isLoading ? (
          cargando()
        ) : (
          <Modal.Body>
           
              {compartidos.length === 0 ? (
                 <Form.Row>
                <p>
                  <CheckAll
                    size={22}
                    color={!esMasivo ? "#5B4B51" : "#5B4B51"}
                  />
                  &nbsp;{" "}
                  Usted quedará como usuario pagador para esta requisición
                  {request
                    ? "Cargando..."
                    : ""}
                </p>
                </Form.Row>
              ) : (
                <div className="text-center">
                  {compartidos.length > 0 ? (
                    <Form.Group className="mx-4 text-center">
                    <div >
                      <legend className="legend-share-req-modal">
                      Se notificará al usuario pagador y usuarios compartidos
                      </legend>
                      <Table  striped bordered hover>
                        <thead>
                          <tr>
                            <th>
                              <b>Nombre de usuario</b>
                            </th>
                            <th>
                              <b>Email</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {compartidos.map((elemento) => (
                            <tr key={elemento.usuarioId}>
                              <td>{elemento.usuarioNombre}</td>
                              <td>{elemento.usuarioEmail}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </Form.Group>
                  ) : (
                    ""
                  )}
                </div>
              )}

            
          
            <div className="d-flex justify-content-center">
              <button
                className="m-1 button-default-confirmar"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Confirmar"
                disabled={isLoading}
                onClick={(e) => asignarRequisicion(props.children, e)}
              >
                {status ? <span>Enviando...</span> : <span>Confirmar asignación</span>}
              </button>
              <button
                className="m-1 button-default-cancelar"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Cancelar"
                onClick={props.onHide}
              >
                <span>Cancelar</span>
              </button>
            </div>
          </Modal.Body>
        )}
      </Modal>
    </section>
  );
}

export default ModalAsignacion;
