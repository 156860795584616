import React, { useEffect, useState } from "react";
import axios from 'axios';
import { KRAKEN_URL } from '../../../../constants'

//Componentes
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next';
import { Modal, Form, Col, Button } from "react-bootstrap";
import { PersonLinesFill, PencilSquare, Trash } from 'react-bootstrap-icons';
import ModalAgregarUsuarioContacto from './UsuarioContactos/ModalAgregarUsuarioContacto'
import ModalEditarUsuarioContacto from './UsuarioContactos/ModalEditarUsuarioContacto'
import ModalEliminarUsuarioContacto from './UsuarioContactos/ModalEliminarUsuarioContacto'

function ModalConsultarEmpresaUsuario(props) {
    const usuario = props.children;
    const [request] = useState(false);
    const [error, setError] = useState(false);
    const [errorCode, setErrorCode] = useState('');
    const [errorStatus, setErrorStatus] = useState('');
    const [updateListado, setUpdateListado] = useState(false);
    const [listContactos, setListContactos] = useState([]);
    const [usrEdit, setUsrEdit] = useState('');
    const [usrDelete, setUsrDelete] = useState('');

    const [modalUsuarioAgregarShow, setModalUsuarioAgregarShow] = useState(false);
    const [modalUsuarioEditarShow, setModalUsuarioEditarShow] = useState(false);
    const [modalUsuarioEliminarShow, setModalUsuarioEliminarShow] = useState(false);

    let modalUsuarioAgregarClose = () => setModalUsuarioAgregarShow(false);
    let modalUsuarioEditarClose = () => setModalUsuarioEditarShow(false);
    let modalUsuarioEliminarClose = () => setModalUsuarioEliminarShow(false);
    let updateGetListado = () => { setUpdateListado(!!!updateListado); }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getContactos, [updateListado]);

    function getContactos() {
        axios.post(KRAKEN_URL + 'empresa/usuario/contactos/list',
            {
                "userId": usuario.userId,
                "empresa": usuario.empresaAlias
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                setError(false);
                setListContactos(res.data.list);
            } else {
                setError(true);
                setErrorCode(res.data.result.code);
                setErrorStatus(res.data.result.message);
            }
        }).catch((error) => {
            setError(true);
            setErrorStatus("Error: No se han podido recuperar los contactos de este usuario.");
        })
    }

    let handleSizePerPage = ({ page, onSizePerPageChange }, newSizePerPage) => {
        onSizePerPageChange(newSizePerPage, page);
    }

    function getUsuario(usuario) {
        setUsrEdit(usuario);
        setModalUsuarioEditarShow(true);
    }

    function confirmDelete(usuario) {
        setUsrDelete(usuario);
        setModalUsuarioEliminarShow(true);
    }

    const agregarContactoButton = () => {
        let boton = (<div className="d-flex ml-auto mb-2 flex-row-reverse">
            <button
                className="ml-1 button-default-req"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Agregar contacto"
                onClick={() => { setModalUsuarioAgregarShow(true) }}
            ><span>Agregar contacto </span>
            </button>
        </div>
        );
        return boton;
    }

    let mostrarListaContactos = () => {
        if (error) {
            return (
                <div>{errorStatus}
                    {errorCode === 204 ? agregarContactoButton() : ''}
                </div>
            );
        } else {
            const columns = [{
                dataField: 'contacto',
                text: 'Nombre de contacto',
                filter: textFilter({
                    placeholder: 'Buscar...',
                    className: "form-control-sm"
                }),
                sort: true
            },
            {
                dataField: 'emailContacto',
                text: 'Email del contacto',
                filter: textFilter({
                    placeholder: 'Buscar...',
                    className: "form-control-sm"
                }),
                sort: true
            },
            {
                dataField: 'acciones',
                text: 'Acciones',
                isDummyField: true,
                formatter: (cellContent, row) => {
                    return (
                        <div>
                            <Button id="editar" className="button-default-black" variant="light" size="sm"
                                data-toggle="tooltip" data-placement="bottom" title="Editar"
                                onClick={() => getUsuario(row)}
                            ><PencilSquare size={22} className="button-default-black" color="#000" />
                            </Button>
                            <Button id="eliminar" className="button-default-black" variant="light" size="sm"
                                data-toggle="tooltip" data-placement="bottom" title="Eliminar"
                                onClick={() => confirmDelete(row)}
                            ><Trash size={22} className="button-default-black" color="#000" />
                            </Button>
                        </div>
                    )
                }
            }];

            function indication() {
                return listContactos.length ? "La búsqueda no coincide con ningún criterio" : "No hay registros que mostrar";
            }

            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size}
                </span>
            );

            const options = {
                custom: true,
                totalSize: listContactos.length,
                paginationTotalRenderer: customTotal
            }

            return <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div>
                        <Form.Row>
                            {agregarContactoButton()}
                        </Form.Row>
                        <Form.Row className="p-2">
                            <div className="d-flex flex-row">
                                <Form.Label column>Mostrar</Form.Label>
                                <Col>
                                    <Form.Control size="sm" as="select" onChange={(e) => handleSizePerPage(paginationProps, e.target.value)}>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value={listContactos.length}>Todos</option>
                                    </Form.Control>
                                </Col>
                            </div>
                            <div className="d-flex flex-row">
                                <Col>
                                    <PaginationTotalStandalone {...paginationProps} />
                                </Col>
                            </div>
                            <div className="d-flex ml-auto flex-row-reverse">
                                <Col>
                                    <PaginationListStandalone {...paginationProps} />
                                </Col>
                                <Form.Label column>Página</Form.Label>
                            </div>
                        </Form.Row>
                        <div className="table-responsive table-sm text-center">
                            <BootstrapTable
                                id="listaContactos"
                                keyField='contactoId'
                                data={listContactos}
                                columns={columns}
                                filter={filterFactory()}
                                bordered={false}
                                striped
                                hover
                                condensed
                                noDataIndication={indication}
                                {...paginationTableProps}
                            />
                        </div>
                    </div>
                )}
            </PaginationProvider>
        }
    }

    return (
        <section className="text-center">
            <Modal
                show={props.show}
                //onHide={props.onHide}
                size="lg"
                id="modalUsuarioContactos"
                centered
            >
                <Modal.Header className="modal-header-empresa-usuario" closeButton onHide={props.onHide}>
                    <p><PersonLinesFill size={22} color="#5B4B51" />   Contactos asociados a {usuario.username}</p>
                </Modal.Header>
                <Modal.Body>
                    {request ? "Cargando..." : mostrarListaContactos()}
                    {modalUsuarioAgregarShow ? <ModalAgregarUsuarioContacto show={modalUsuarioAgregarShow} onHide={modalUsuarioAgregarClose} onUpdate={updateGetListado}>{usuario}</ModalAgregarUsuarioContacto> : ''}
                    {modalUsuarioEditarShow ? <ModalEditarUsuarioContacto show={modalUsuarioEditarShow} onHide={modalUsuarioEditarClose} onUpdate={updateGetListado}>{usrEdit}</ModalEditarUsuarioContacto> : ''}
                    {modalUsuarioEliminarShow ? <ModalEliminarUsuarioContacto show={modalUsuarioEliminarShow} onHide={modalUsuarioEliminarClose} onUpdate={updateGetListado}>{usrDelete}</ModalEliminarUsuarioContacto> : ''}
                </Modal.Body>
            </Modal>
        </section>
    )
}

export default ModalConsultarEmpresaUsuario;