import React, { useEffect } from "react";
import Login from '../Login/Login'
import { useDispatch } from 'react-redux';
import { userActions } from "../../acciones/user-actions";

function Logout(props) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(userActions.logout());
        window.location.reload(true);
    }, [dispatch]);
    return (
        <div>
            <Login />
        </div>
    )
}

export default Logout;