import React, { useState } from "react";
import axios from 'axios';
import { API_PROVEEDORES_URL } from '../../constants';
//Estilos
import './css/Pagos.css'
import { Button } from 'react-bootstrap';
import { toast } from "react-toastify";
import Moment from 'moment';
import ModalMonto from './ModalMonto';

function SelectInputFile(props) {
    const [status, setStatus] = useState(false);
    const row = props.children;
    /*Inicio campos del formulario*/
    const id = props.children.id;
    const idRequisicion = props.children.idRequisicion;
    const empresa = props.children.empresa;
    const idEmpresa = props.children.idEmpresa;
    const fechaRequeridaPago = props.children.fechaRequeridaPago;
    const proveedor = props.children.proveedor;
    const idProveedor = props.children.idProveedor;
    const proveedorRfc = props.children.proveedorRfc;
    const concepto = props.children.concepto;
    const [files, setFiles] = useState('');
    const banco = props.children.banco;
    const cuentaClabe = props.children.cuentaClabe;
    const cuentaBanco = props.children.cuentaBanco;
    const referencia = props.children.referencia;
    const fechaPago = Moment();
    const monedaClaveSat = props.children.monedaClaveSat;//MXN
    const monedaSat = props.children.monedaSat;//Peso mexicano    
    const monedaDecimalesSat = props.children.monedaDecimalesSat;//2    
    const formaPagoClaveSat = props.children.formaPagoClaveSat;
    const formaPagoSat = props.children.formaPagoSat;
    const numeroParcialidad = props.children.numeroParcialidad;
    const total = props.children.total;
    const saldoAnterior = props.children.saldoAnterior;

    const [modalMontoShow, setModalMontoShow] = useState(false);
    let modalMontoClose = () => setModalMontoShow(false);

    function subirPago(pago) {
        setStatus(true);

        if (files === '') {
            setStatus(false);
            toast.error("No hay archivos seleccionados",{
                position:"top-center",
                autoClose: 4500, 
            });
        } else {
            const formData = new FormData();
            for (var i = 0; i < files.length; i++) {
                formData.append("files", files[i]);
            }
            formData.append("idRequisicion", idRequisicion);
            formData.append("id", parseFloat(id));
            formData.append("empresa", empresa);
            formData.append("idEmpresa", idEmpresa);
            formData.append("fechaRequeridaPago", fechaRequeridaPago);
            formData.append("fechaPago", Moment(fechaPago).format("YYYY-MM-DD"));
            formData.append("proveedor", proveedor);
            formData.append("idProveedor", idProveedor);
            formData.append("proveedorRfc", proveedorRfc);
            formData.append("banco", banco);
            formData.append("bancoEmisor", pago.bancoEmisor);
            formData.append("cuentaClabe", cuentaClabe);
            formData.append("cuentaClabeEmisor", pago.cuentaClabeEmisor);
            formData.append("cuentaBanco", cuentaBanco);
            formData.append("cuentaEmisor", pago.cuentaEmisor);
            formData.append("referencia", referencia);
            formData.append("referenciaEmisor", pago.referenciaEmisor);
            formData.append("concepto", concepto);
            formData.append("monedaClaveSat", monedaClaveSat);
            formData.append("monedaSat", monedaSat);
            formData.append("monedaDecimales", parseFloat(monedaDecimalesSat));
            formData.append("paridad", pago.paridad);
            formData.append("formaPagoClaveSat", formaPagoClaveSat);
            formData.append("formaPagoSat", formaPagoSat);
            formData.append("numeroParcialidad", numeroParcialidad);
            formData.append("montoPago", pago.montoPago);
            formData.append("iva", pago.iva);
            formData.append("ivaImporte", pago.ivaImporte);
            formData.append("total", parseFloat(total));
            formData.append("saldoAnterior", parseFloat(saldoAnterior));
            formData.append("saldoPagado", pago.saldoPagado);
            formData.append("saldoPendiente", pago.saldoPendiente);
            formData.append("to", pago.contactos);
            formData.append("comentario", pago.comentario);

            axios.post(API_PROVEEDORES_URL + 'savePago', formData,
                {
                    headers: { 'Content-Type': `multipart/form-data;` }
                }
            ).then((res) => {
                setStatus(false);
                let output = [];
                if ((res.data.result.code === 200) && (res.data.filesResult.code === 200)) {
                    setStatus(false);
                    toast.success(res.data.result.message,{
                        position:"top-center",
                        autoClose: 4500, 
                    });
                    toast.success(res.data.filesResult.message,{
                        position:"top-center",
                        autoClose: 4500, 
                    });
                    output.push('<li><strong>', res.data.result.message, '</strong>');
                    output.push('<li><strong>', res.data.filesResult.message, '</strong>');
                    document.getElementById("listResponse").innerHTML = '<ol>' + output.join('') + '</ol>';
                    props.onUpdate();//Actualiza el listado, llamando a la función padre para actualizarlo                       
                } else {
                    setStatus(false);
                    toast.error(res.data.result.message,{
                        position:"top-center",
                        autoClose: 4500, 
                    });
                    toast.error(res.data.filesResult.message,{
                        position:"top-center",
                        autoClose: 4500, 
                    });
                    output.push('<li><strong>', res.data.result.message, '</strong>');
                    output.push('<li><strong>', res.data.filesResult.message, '</strong>');
                    document.getElementById("listResponse").innerHTML = '<ol>' + output.join('') + '</ol>';
                }
            }).catch((error) => {
                setStatus(false);
                toast.error("No se ha podido subir el pago. Consulte al administrador del portal.",{
                    position:"top-center",
                    autoClose: 4500, 
                });
            })
        }
    }

    function handleFilesChange(e) {
        setFiles(e.target.files);
        let files = e.target.files; //FileList object
        let output = [];

        for (var i = 0; i < files.length; i++) {
            output.push('<li><strong>', escape(files[i].name), '</strong> (', files[i].type || 'n/a', ') - ', files[i].size, ' bytes</li>');
        }
        document.getElementById("list").innerHTML = '<ol>' + output.join('') + '</ol>';
    }

    return <div>
        <div>
            <input id={row.id} type="file" multiple accept=".xml,.pdf" onChange={handleFilesChange} />
            <output id="list"></output>
            <output id="listResponse"></output>
        </div>
        <div>
            {files !== '' ?
                <button className="button-default-subir-archivo" title="Subir Pago"
                    onClick={() => setModalMontoShow(true)}>
                    {status ?
                        <span>Enviando...</span>
                        : <span>Subir pago</span>}
                </button>
                :
                <Button disabled variant="light" size="sm" title="Subir Pago">
                    Subir pago
                </Button>
            }
        </div>
        {modalMontoShow ? <ModalMonto show={modalMontoShow} onHide={modalMontoClose} onMontoSelected={subirPago}>{row}</ModalMonto> : ''}
    </div >
}

export default SelectInputFile;