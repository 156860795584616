import React, { useState, useEffect } from "react";
import NumberFormat from 'react-number-format';
import { bigDecimalFormat } from '../../constantes/general-require';
import { toast } from "react-toastify";
import { ExclamationCircleFill } from 'react-bootstrap-icons';
import { Col, Modal, Form, InputGroup, Container } from 'react-bootstrap';
import SeleccionarCuentaEmisora from './SeleccionarCuentaEmisora'
import ModalNotificarPago from "./ModalNotificarPago";

function ModalMonto(props) {
    const [deactivate, setDeactivate] = useState(false);
    const [status, setStatus] = useState(false);
    const id = props.children.id;
    const empresa = props.children.empresa;
    const idEmpresa = props.children.idEmpresa;
    const concepto = props.children.concepto;
    const monedaClaveSat = props.children.monedaClaveSat;//MXN
    const moneda = props.children.monedaClaveSat + " - " + props.children.monedaSat + " - Presición -" + props.children.monedaDecimalesSat;
    const monedaDecimalesSat = props.children.monedaDecimalesSat;//2
    const [bancoEmisor, setBancoEmisor] = useState('');
    const [cuentaEmisor, setCuentaEmisor] = useState('');
    const [cuentaClabeEmisor, setCuentaClabeEmisor] = useState('');
    const [referenciaEmisor, setReferenciaEmisor] = useState('');
    const [paridad, setParidad] = useState(props.children.paridad);
    const [montoPago, setMontoPago] = useState('0.00');
    const [iva, setIva] = useState('16.0');
    const [ivaTasaCuota, setIvaTasaCuota] = useState('0.16');
    const [ivaImporte, setIvaImporte] = useState('0.00');
    const saldoAnterior = props.children.saldoAnterior;
    const [saldoAnteriorFormat, setSaldoAnteriorFormat] = useState('0.0');
    const [saldoPagado, setSaldoPagado] = useState('0.00');
    const [saldoPendiente, setSaldoPendiente] = useState('0.00');
    const [saldoPendientePesosMexicanos, setSaldoPendientePesosMexicanos] = useState('0.00');

    const [modalNotificarPagoShow, setModalNotificarPagoShow] = useState(false);
    let modalConfirmarClose = () => { setModalNotificarPagoShow(false); }

    useEffect(() => {
        calculoImporteIva();
        calculoSaldos();
    })

    function calculoSaldos() {
        let montoPagoValue = montoPago;
        let montoPagoDecimal = bigDecimalFormat(montoPagoValue);
        setMontoPago(montoPagoDecimal.toFixed(parseFloat(monedaDecimalesSat)));
        let saldoAnteriorDecimal = bigDecimalFormat(saldoAnterior);
        setSaldoAnteriorFormat(saldoAnteriorDecimal.toFixed(parseFloat(monedaDecimalesSat)));
        setSaldoPagado(montoPagoDecimal.toFixed(parseFloat(monedaDecimalesSat)));
        let saldoPendienteDecimal = saldoAnteriorDecimal.minus(montoPagoDecimal);
        setSaldoPendiente(saldoPendienteDecimal.toFixed(parseFloat(monedaDecimalesSat)));
        let paridadValue = paridad;
        let paridadDecimal = bigDecimalFormat(paridadValue);
        let saldoPendienteConParidad = saldoPendienteDecimal.times(paridadDecimal);
        setSaldoPendientePesosMexicanos(saldoPendienteConParidad.toFixed(parseFloat(2)));
    }

    function calculoImporteIva() {
        let importeDecimal = bigDecimalFormat(montoPago);//Se convierte a bigDecimal
        let importeIvaDecimal = importeDecimal.times(ivaTasaCuota).toFixed(parseFloat(monedaDecimalesSat));//times: a*b; toFixed: cuántos dígitos después del punto decimal
        setIvaImporte(importeIvaDecimal);//Se guarda el valor sin comas y se agrega el diseño hasta que se utiliza en el formulario
    }

    function handleReferenciaEmisorChange(e) {
        setReferenciaEmisor(e.target.value);
    }
    function handleMontoPagoChange(e) {
        setMontoPago(e.target.value);
    }
    function handleParidadChange(e) {
        setParidad(e.target.value);
    }
    function handleIvaChange(e) {
        setIva(e.target.value);//Se guarda tal cual el valor que aparece en el input
        let ivaDecimal = bigDecimalFormat(e.target.value); //Se convierte a bigDecimal
        let tasaCuotaDecimal = ivaDecimal.dividedBy(100);//dividedBy: a/b
        setIvaTasaCuota(tasaCuotaDecimal);
    }
    function onBancoSelected(row) {
        setBancoEmisor(row.banco);
        setCuentaEmisor(row.cuenta);
        setCuentaClabeEmisor(row.cuentaClabe);
        setReferenciaEmisor(row.referencia);
    }
    function openModalNotificar(e) {
        e.preventDefault();
        setModalNotificarPagoShow(true);
    }
    function onContactosSelected(contactos, comentario) {
        if (contactos.length) {
            subirMonto(contactos, comentario);
        } else {
            setDeactivate(true);

            setTimeout(() => {
                setDeactivate(false);
            }, 4500);

            toast.warning("Debes seleccionar al menos un contacto a notificar",{
                position:"top-center",
                autoClose: 4500, 
            });
            setStatus(false);
        }
    }
    function subirMonto(contactos, comentario) {
        setStatus(true);
        let pago = {
            bancoEmisor: bancoEmisor,
            cuentaEmisor: cuentaEmisor,
            cuentaClabeEmisor: cuentaClabeEmisor,
            referenciaEmisor: referenciaEmisor,
            paridad: paridad,
            montoPago: parseFloat(montoPago),
            iva: parseFloat(ivaTasaCuota),
            ivaImporte: parseFloat(ivaImporte),
            saldoPagado: parseFloat(saldoPagado),
            saldoPendiente: parseFloat(saldoPendiente),
            contactos: contactos,
            comentario: comentario
        }
        props.onMontoSelected(pago);
        props.onHide();
    }

    const createInputSaldoPendientePesosMexicanos = () => {
        let inputTPM = (<span><Form.Label > Saldo pendiente pesos mexicanos</Form.Label>
            <InputGroup size="sm">
                <InputGroup.Prepend >
                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                </InputGroup.Prepend>
                <NumberFormat id="saldoPendientePesosMexicanos" className="input-signs-align form-control form-control-sm" thousandSeparator={true} disabled value={saldoPendientePesosMexicanos}></NumberFormat>
            </InputGroup>
        </span>
        );
        return inputTPM;
    }

    return (
        <section className="text-center">
            <Modal
                size="md"
                show={props.show}
                //onHide={props.onHide}
                id="modalSubirPago"
                centered
                scrollable={true}
            >
                <Modal.Body className="modal-portal-body">
                    {modalNotificarPagoShow ? <ModalNotificarPago show={modalNotificarPagoShow} onHide={modalConfirmarClose} disabled = { deactivate } onContactosSelected={onContactosSelected}>{id}{empresa}</ModalNotificarPago> : ''}
                    <Container className="container-modals">
                        <fieldset>
                            <legend className="legend-editar-pago">Subir pago de la requisición #{props.children.id}</legend>
                            <Form.Row as={Col}>
                                <Form.Group as={Col}>
                                    <Form.Label>Concepto</Form.Label>
                                    <Form.Control size="sm" type="text" disabled defaultValue={concepto}></Form.Control>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row as={Col}>
                                <Form.Group as={Col}>
                                    <Form.Label>Empresa</Form.Label>
                                    <Form.Control size="sm" id="empresa" type="text" disabled defaultValue={empresa}></Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} md={12} lg={6}>
                                    <Form.Label>Referencia</Form.Label>
                                    <Form.Control size="sm" type="text" required placeholder="Seleccione un banco" value={referenciaEmisor} onChange={handleReferenciaEmisorChange}></Form.Control>
                                </Form.Group>
                            </Form.Row>
                            <SeleccionarCuentaEmisora onBancoSelected={onBancoSelected}>{idEmpresa}</SeleccionarCuentaEmisora>
                            <Form.Row as={Col}>
                                <Form.Group as={Col} lg={6}>
                                    <Form.Label>Moneda</Form.Label>
                                    <Form.Control size="sm" id="moneda" as="textarea" rows={2} value={moneda} disabled />
                                    <Form.Label>Paridad</Form.Label>
                                    <InputGroup size="sm">
                                        <InputGroup.Prepend >
                                            <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        {monedaClaveSat !== 'MXN' ?
                                            <Form.Control size="sm" id="paridad" type="text" className="input-signs-align" value={paridad} required onChange={handleParidadChange} />
                                            : <Form.Control size="sm" id="paridad" type="text" className="input-signs-align" value={paridad} disabled />
                                        }
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} lg={6}>
                                    <ExclamationCircleFill size={22} color="#FF5F58" />&nbsp;¿Monto correcto?
                                    <Form.Label>Monto del pago</Form.Label>
                                    <InputGroup size="sm">
                                        <InputGroup.Prepend >
                                            <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <NumberFormat id="montoPago" className="input-signs-align form-control form-control-sm" required placeholder="Ingrese monto" thousandSeparator={true} value={montoPago} onChange={handleMontoPagoChange}></NumberFormat>
                                    </InputGroup>
                                    <Form.Label>IVA Retenido</Form.Label>
                                    <Form.Row>
                                        <Col>
                                            <InputGroup size="sm">
                                                <NumberFormat id="iva" className="input-signs-align form-control form-control-sm" thousandSeparator={true} value={iva} onChange={handleIvaChange}></NumberFormat>
                                                <InputGroup.Append >
                                                    <InputGroup.Text className="input-signs-right">%</InputGroup.Text>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Col>
                                        <Col>
                                            <InputGroup size="sm">
                                                <InputGroup.Prepend >
                                                    <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <NumberFormat id="ivaImporte" className="input-signs-align form-control form-control-sm" thousandSeparator={true} disabled value={ivaImporte}></NumberFormat>
                                            </InputGroup>
                                        </Col>
                                    </Form.Row>
                                    <Form.Label>Saldo anterior</Form.Label>
                                    <InputGroup size="sm">
                                        <InputGroup.Prepend >
                                            <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <NumberFormat id="saldoAnterior" className="input-signs-align form-control form-control-sm" disabled thousandSeparator={true} value={saldoAnteriorFormat}></NumberFormat>
                                    </InputGroup>
                                    <Form.Label>Saldo pagado</Form.Label>
                                    <InputGroup size="sm">
                                        <InputGroup.Prepend >
                                            <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <NumberFormat id="saldoPagado" className="input-signs-align form-control form-control-sm" disabled thousandSeparator={true} value={saldoPagado}></NumberFormat>
                                    </InputGroup>
                                    <Form.Label>Saldo pendiente</Form.Label>
                                    <InputGroup size="sm">
                                        <InputGroup.Prepend >
                                            <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <NumberFormat id="saldoPendiente" className="input-signs-align form-control form-control-sm" disabled thousandSeparator={true} value={saldoPendiente}></NumberFormat>
                                    </InputGroup>
                                    {monedaClaveSat !== 'MXN' ? createInputSaldoPendientePesosMexicanos() : ''}
                                </Form.Group>
                            </Form.Row>
                        </fieldset>
                    </Container>
                    <div className="d-flex justify-content-center">
                        <button
                            className="m-1 button-default-subir-archivo"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Subir pago"
                            onClick={openModalNotificar}
                        >{status ?
                            <span>Guardando...</span>
                            : <span>Subir pago</span>}
                        </button>
                        <button
                            className="m-1 button-default-cancelar"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Cerrar"
                            onClick={props.onHide}
                        ><span>Cancelar</span>
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    );
}

export default ModalMonto;