import React, { useEffect, Fragment } from 'react';
import './App.css';
import { ToastContainer } from 'react-toastify';

import { Router, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

//Componentes
import Login from './componentes/Login/Login.js'
import SideNavMenu from './componentes/SideNavMenu/SideNavMenu.js'

import { history } from './helpers/history'
import { alertActions } from './acciones/alert-actions'
import { PrivateRoute } from './componentes/PrivateRoute/PrivateRoute'

function App() {
  const alert = useSelector(state => state.alert);
  const dispatch = useDispatch();

  useEffect(() => {
    history.listen((location, action) => {
      dispatch(alertActions.clear());//clear alert on location change
    });
  }, [dispatch]);

  return (
    <div>
      {alert.message &&
        <div className={`alert ${alert.type}`}>{alert.message}</div>
      }
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <meta name="viewport" content="width=device-width, height=device-height, initial-scale=1.0, user-scalable=no" />
      <Router history={history}>
        <Fragment>
          <Switch>
            <Route exact path="/" component={Login}></Route>
            <Fragment>
              <PrivateRoute component={SideNavMenu} />
            </Fragment>
          </Switch>
        </Fragment>
      </Router>
    </div>
  );
}

export default App;
