import React, { useEffect, useState } from "react";
import axios from 'axios'
import { useSelector } from 'react-redux';
import { API_PROVEEDORES_URL } from '../../constants'

//Componentes
import { Card, Tabs, Tab } from "react-bootstrap";
import './css/RequisicionesPasadas.css'
import RequisicionesPasadas from "./RequisicionesPasadas";

function Requisiciones(props) {
    const empresasDisponibles = useSelector(state => state.empresas.empresasDisponibles);
    let empresas = empresasDisponibles.map(e => { return e.empresa; });
    const empresaDefault = empresas[0];
    const [key, setKey] = useState(empresaDefault);
    const [statusError, setStatusError] = useState('');
    const [listEmpresas, setListEmpresas] = useState([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getRequisicionesPasadas, []);

    function getRequisicionesPasadas() {
        axios.post(
            API_PROVEEDORES_URL + 'getRequisicionesPasadas',
            {
                empresas: empresas
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                setListEmpresas(res.data.requisiciones)
            } else {
                setListEmpresas('');
                setStatusError(res.data.result.message);
            }
        }).catch((error) => {
            setStatusError("Ocurrió un error, no se pudieron recuperar las requisiciones pasadas");
        })
    }

    function createTabs() {
        let empresasTabs;
        listEmpresas.length ?
            empresasTabs = listEmpresas.map(empresa => (
                <Tab key={empresa.id} eventKey={empresa.id} title={empresa.nombreEmpresa}>
                    <RequisicionesPasadas>{empresa.lista}</RequisicionesPasadas>
                </Tab>
            )) : empresasTabs = empresas.map(empresa => (
                <Tab key={empresa} eventKey={empresa} title={empresa}>
                    <section id="listaReqPasadas">
                        <Card id="cardReqPasadas">
                            <Card.Body className="cardBodyReqPasadas">
                                {statusError}
                            </Card.Body>
                        </Card>
                    </section>
                </Tab>
            ))
        return empresasTabs;
    }

    return (
        <div >
            <Tabs id="controlled-tab-requisiciones" activeKey={key} onSelect={(k) => setKey(k)}>
                {createTabs()}
            </Tabs>
        </div >
    );
}

export default Requisiciones;