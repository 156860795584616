import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_PROVEEDORES_URL } from '../../constants'
import { useSelector } from 'react-redux';

//Estilos
import './css/Requisiciones.css'
import './css/RequisicionesAprobadas.css'
import { Eye, ListTask, Grid3x2GapFill, CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';

//Componentes bootstrap
import { Card, Col, Form, Button, ButtonGroup, Tabs, Tab, Badge } from 'react-bootstrap';

//Componentes
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import Moment from 'moment';
import NumberFormat from 'react-number-format';
import ModalConsultarRequisicion from './ModalConsultarRequisicion';
import Loader from "../Loader";

function RequisicionesAprobadas() {
    const token = JSON.parse(localStorage.getItem('token'));
    const empresasDisponibles = useSelector(state => state.empresas.empresasDisponibles);
    let empresas = empresasDisponibles.map(e => { return e.empresa; });
    const empresaDefault = empresas[0];
    const [key, setKey] = useState(empresaDefault);
    const [cargando, setCargando] = useState(false);
    const [listaAprobadas, setListaAprobadas] = useState([]);
    const [error, setError] = useState(false);
    const [errorStatus, setErrorStatus] = useState('');

    // La siguiente línea evita un warning que no compromete la ejecución
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    useEffect(getListadoReqAprobadas, []);

    function getListadoReqAprobadas() {
        let user = JSON.parse(localStorage.getItem('token'));
        setCargando(true);
        axios.post(API_PROVEEDORES_URL + 'getRequisicionesAprobadas',
            {
                empresas: empresas
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                user.userType === 'SCHEMA_ADMIN'? filterDataToMaster(res.data.requisiciones) : 
                filterDataToUser(res.data.requisiciones);

                setListaAprobadas(res.data.requisiciones);
            } else {
                setError(true);
                setErrorStatus(res.data.result.message);
            }
        }).catch((error) => {
            setError(true);
            setErrorStatus("Error: No se han podido recuperar las requisiciones aprobadas.")
        }).finally(() => {
            setCargando(false);
        });
    }

    if (cargando) {
        return <Loader />
    }

    function createTabs() {
        let empresasTabs;
        listaAprobadas.length ?
            empresasTabs = listaAprobadas.map(empresa => (
                <Tab key={empresa.id} eventKey={empresa.id} title={empresa.nombreEmpresa}>
                    <ListadoRequisicionesAprobadas>{empresa.lista}</ListadoRequisicionesAprobadas>
                </Tab>
            )) : empresasTabs = empresas.map(empresa => (
                <Tab key={empresa} eventKey={empresa} title={empresa}>
                    <section id="listaReqPasadas">
                        <Card id="cardReqPasadas">
                            <Card.Body className="cardBodyReqPasadas">
                                {error ? errorStatus : 'Error: No se ha podido cargar la información'}
                            </Card.Body>
                        </Card>
                    </section>
                </Tab>
            ))
        return empresasTabs;
    }

    return (
        <div >
            <Tabs id="controlled-tab-requisiciones" activeKey={key} onSelect={(k) => setKey(k)}>
                {createTabs()}
            </Tabs>
        </div >
    );

    function filterDataToUser(empresas){ 
        empresas.map((empresa)=>{
         empresa.lista = empresa.lista.filter(function(objeto) {
            return ((objeto.solicitante ===token.username)||objeto.usuarioCompartido ===token.username||objeto.pagadorId ===token.userId );
         });
         })
         return empresas;
     }
 
     function filterDataToMaster(empresas){
         empresas.map((empresa)=>{
        
             empresa.lista = empresa.lista.filter(function(objeto) {
                 
                 return (objeto.usuarioCompartido !==token.username );
             });
             })
             return empresas;
 
     }

}

function ListadoRequisicionesAprobadas(props) {
    const token = JSON.parse(localStorage.getItem('token'));
    const userType = token.userType;
    const [gridCards, setGridCards] = useState(false);
    const [listaAprobadas] =useState(eliminarObjetosCompartidos(props.children));
    const [error] = useState(false);
    const [request] = useState(false);
    const [requisicion, setRequisicion] = useState('');
    const [modalConsultarRequisicionShow, setModalConsultarRequisicionShow] = useState(false);

    let modalConsultarRequisicionClose = () => setModalConsultarRequisicionShow(false);

    function viewRequisicion(requisicion) {
        setRequisicion(requisicion);
        setModalConsultarRequisicionShow(true);
    }
    function eliminarObjetosCompartidos(arreglo) {
        arreglo = arreglo.filter(function(objeto) {
        
            return !(objeto.esCompartida === 1 && (objeto.usuarioCompartido !== token.username && objeto.usuarioCompartido !== ""));
        });
        return arreglo;
    }

    let mostrarListaAprobadas = () => {
        if (error) {
            return (
                <div>Error: No se han podido recuperar las requisiciones aprobadas</div>
            );
        } else {
            const columnsList = [
                {
                    dataField: 'id',
                    text: 'ID',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                },
                {
                    dataField: 'solicitante',
                    text: 'Solicitante',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                },
                {
                    dataField: 'proveedorRfc',
                    text: 'RFC proveedor',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                },
                {
                    dataField: 'proveedor',
                    text: 'Proveedor',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                },
                {
                    dataField: 'fechaRequeridaPago',
                    text: 'Fecha requerida',
                    formatter: (cellContent, row) => {
                        return (
                            Moment(row.fechaRequeridaPago).format('DD/MM/YYYY')
                        )
                    },
                    sort: true,
                    sortCaret: (order, column) => {
                        if (!order) return (<span>&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000"/><CaretUpFill size={10} className="button-default-black" color="#000"/></span>);
                        else if (order === "desc") return (<span>&nbsp;<CaretUpFill size={10} className="button-default-black" color="#000"/><CaretDownFill size={10} className="button-default-black" color="#dee2e6"/></span>);
                        else if (order === "asc") return (<span>&nbsp;<CaretUpFill size={10} className="button-default-black" color="#dee2e6"/><CaretDownFill size={10} className="button-default-black" color="#000"/></span>);
                        return null;
                    }
                },
                {
                    dataField: 'porcentajeTotal',
                    text: '% del Total',
                    formatter: (cellContent, row) => {
                        return (
                            <NumberFormat
                                displayType={'text'} prefix={'%'}
                                thousandSeparator={true}
                                value={row.porcentajeTotal.toFixed(2/*row.monedaDecimales*/)}
                            ></NumberFormat>
                        )
                    },
                    sort: true,
                    sortCaret: (order, column) => {
                        if (!order) return (<span>&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000"/><CaretUpFill size={10} className="button-default-black" color="#000"/></span>);
                        else if (order === "desc") return (<span>&nbsp;<CaretUpFill size={10} className="button-default-black" color="#000"/><CaretDownFill size={10} className="button-default-black" color="#dee2e6"/></span>);
                        else if (order === "asc") return (<span>&nbsp;<CaretUpFill size={10} className="button-default-black" color="#dee2e6"/><CaretDownFill size={10} className="button-default-black" color="#000"/></span>);
                        return null;
                    }
                },
                {
                    dataField: 'total',
                    text: 'Total',
                    formatter: (cellContent, row) => {
                        return (
                            <NumberFormat
                                displayType={'text'}
                                thousandSeparator={true}
                                value={row.total.toFixed(2/*row.monedaDecimales*/)}
                            ></NumberFormat>
                        )
                    },
                    sort: true,
                    sortCaret: (order, column) => {
                        if (!order) return (<span>&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000"/><CaretUpFill size={10} className="button-default-black" color="#000"/></span>);
                        else if (order === "desc") return (<span>&nbsp;<CaretUpFill size={10} className="button-default-black" color="#000"/><CaretDownFill size={10} className="button-default-black" color="#dee2e6"/></span>);
                        else if (order === "asc") return (<span>&nbsp;<CaretUpFill size={10} className="button-default-black" color="#dee2e6"/><CaretDownFill size={10} className="button-default-black" color="#000"/></span>);
                        return null;
                    }
                },
                {
                    dataField: 'totalRequerido',
                    text: 'Total requerido',
                    formatter: (cellContent, row) => {
                        return (
                            <NumberFormat
                                displayType={'text'}
                                thousandSeparator={true}
                                value={row.totalRequerido.toFixed(2/*row.monedaDecimales*/)}
                            ></NumberFormat>
                        )
                    },
                    sort: true,
                    sortCaret: (order, column) => {
                        if (!order) return (<span>&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000"/><CaretUpFill size={10} className="button-default-black" color="#000"/></span>);
                        else if (order === "desc") return (<span>&nbsp;<CaretUpFill size={10} className="button-default-black" color="#000"/><CaretDownFill size={10} className="button-default-black" color="#dee2e6"/></span>);
                        else if (order === "asc") return (<span>&nbsp;<CaretUpFill size={10} className="button-default-black" color="#dee2e6"/><CaretDownFill size={10} className="button-default-black" color="#000"/></span>);
                        return null;
                    }
                },
                {
                    dataField: 'diasVencimiento',
                    text: 'Días de vencimiento',
                    sort: true,
                    sortCaret: (order, column) => {
                        if (!order) return (<span>&nbsp;&nbsp;&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000"/><CaretUpFill size={10} className="button-default-black" color="#000"/></span>);
                        else if (order === "desc") return (<span>&nbsp;&nbsp;&nbsp;<CaretUpFill size={10} className="button-default-black" color="#000"/><CaretDownFill size={10} className="button-default-black" color="#dee2e6"/></span>);
                        else if (order === "asc") return (<span>&nbsp;&nbsp;&nbsp;<CaretUpFill size={10} className="button-default-black" color="#dee2e6"/><CaretDownFill size={10} className="button-default-black" color="#000"/></span>);
                        return null;
                    },
                    formatter: (cellContent, row) => {
                        return(
                            row.diasVencimiento >= 10 ? //Verde '#6ee3af'
                                <h6><Badge variant="success">{row.diasVencimiento}</Badge></h6>
                            :
                                row.diasVencimiento <= 9 && row.diasVencimiento >= 4 ? //Amarillo e9b052
                                    <h6><Badge variant="warning">{row.diasVencimiento}</Badge></h6>
                            :
                                row.diasVencimiento <= 3 && row.diasVencimiento >= 1 ? //Naranja FFC757
                                    <h6><Badge className="vencimientoNaranja">{row.diasVencimiento}</Badge></h6>
                            :
                                row.diasVencimiento <= 0 && row.diasVencimiento >= -5 ? //Rojo FF5F58, ff5f5899
                                    <h6><Badge variant="danger">{row.diasVencimiento}</Badge></h6>
                            :
                                row.diasVencimiento <= -6 ? //Morado 8f41d8
                                <h6><Badge className="vencimientoMorado">{row.diasVencimiento}</Badge></h6>
                            :
                                row.diasVencimiento 
                        )
                    }  
                },
                {
                    dataField: 'acciones',
                    isDummyField: true,
                    text: 'Acciones',
                    formatter: (cellContent, row) => {
                        return (
                            <ButtonGroup>
                                <Button id="consultarRequisicion" className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Consultar"
                                    onClick={() => viewRequisicion(row)}>
                                    <Eye size={24} className="button-default-black" color="#000" />
                                </Button>
                            </ButtonGroup>
                        )
                    }

                }
            ];

            const columns = [
                {
                    id: 'titleAprobada',
                    dataField: 'aprobada',
                    text: '',
                    isDummyField: true,
                    formatter: (cellContent, row) => {
                        return (
                            <div className="font-title-req-aprobadas">
                                <div >{'Aprobada'}</div>
                            </div>
                        )
                    },
                },
                {
                    dataField: 'empresa',
                    text: 'Empresa',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    formatter: (cellContent, row) => {
                        return (
                            <div className="d-flex justify-content-center req-tbody-tr-td-div">
                                <div className="font-col-req ">{'Empresa: '}</div>
                                <div>{row.empresa}</div>
                            </div>
                        )
                    },
                    sort: true
                },
                {
                    dataField: 'id',
                    text: 'ID',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    formatter: (cellContent, row) => {
                        return (
                            <div className="d-flex justify-content-center req-tbody-tr-td-div">
                                <div className="id-req">{'ID: '}</div>
                                <div>{row.id}</div>
                            </div>
                        )
                    },
                    sort: true
                },
                {
                    dataField: 'acciones',
                    isDummyField: true,
                    text: '',
                    formatter: (cellContent, row) => {
                        return (
                            <div className="d-flex justify-content-center req-tbody-tr-td-div">
                                <ButtonGroup>
                                    <Button id="consultarRequisicion" className="button-default-black" variant="light" size="sm"
                                        data-toggle="tooltip" data-placement="bottom" title="Consultar"
                                        onClick={() => viewRequisicion(row)}>
                                        <Eye size={24} className="button-default-black" color="#000" />
                                    </Button>
                                </ButtonGroup>
                            </div>
                        )
                    }

                },
                {
                    dataField: 'solicitante',
                    text: 'Solicitante',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    formatter: (cellContent, row) => {
                        return (
                            <div className="req-tbody-tr-td-div">
                                <div className="font-col-req">{'Solicitante: '}</div>
                                <div>{row.solicitante}</div>
                            </div>
                        )
                    },
                    sort: true
                },
                {
                    dataField: 'proveedorRfc',
                    text: 'RFC proveedor',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    formatter: (cellContent, row) => {
                        return (
                            <div className="req-tbody-tr-td-div">
                                <div className="font-col-req">{'RFC Proveedor: '}</div>
                                <div>{row.proveedorRfc}</div>
                            </div>
                        )
                    },
                    sort: true
                },
                {
                    dataField: 'proveedor',
                    text: 'Proveedor',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    formatter: (cellContent, row) => {
                        return (
                            <div className="req-tbody-tr-td-div">
                                <div className="font-col-req">{'Proveedor: '}</div>
                                <div>{row.proveedor}</div>
                            </div>
                        )
                    },
                    sort: true
                },
                {
                    dataField: 'fechaRequeridaPago',
                    text: '',
                    formatter: (cellContent, row) => {
                        return (
                            <div className="req-tbody-tr-td-div">
                                <div className="font-col-req">{'Fecha requerida: '}</div>
                                <div>{Moment(row.fechaRequeridaPago).format('DD/MM/YYYY')}</div>
                            </div>
                        )
                    },
                    sort: true
                },
                {
                    dataField: 'porcentajeTotal',
                    text: '',
                    formatter: (cellContent, row) => {
                        return (
                            <div className="req-tbody-tr-td-div">
                                <div className="font-col-req">{'% del Total: '}</div>
                                <NumberFormat
                                    displayType={'text'} prefix={'%'}
                                    thousandSeparator={true}
                                    value={row.porcentajeTotal.toFixed(2/*row.monedaDecimales*/)}
                                ></NumberFormat>
                            </div>
                        )
                    },
                    sort: true
                },
                {
                    dataField: 'importePesosMexicanos',
                    text: '',
                    formatter: (cellContent, row) => {
                        return (
                            <div className="req-tbody-tr-td-div">
                                <div className="font-col-req">{'Total: '}</div>
                                <NumberFormat
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    value={row.importePesosMexicanos.toFixed(/*row.monedaDecimales*/)}
                                ></NumberFormat>
                            </div>
                        )
                    },
                    sort: true
                },
                {
                    dataField: 'totalRequerido',
                    text: '',
                    formatter: (cellContent, row) => {
                        return (
                            <div className="req-tbody-tr-td-div">
                                <div className="font-col-req">{'Total requerido: '}</div>
                                <NumberFormat
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    value={row.totalRequerido.toFixed(2/*row.monedaDecimales*/)}
                                ></NumberFormat>
                            </div>
                        )
                    },
                    sort: true
                },
                {
                    dataField: 'diasVencimiento',
                    text: '',
                    formatter: (cellContent, row) => {
                        return (
                            <div className="req-tbody-tr-td-div">
                                <div className="font-col-req">{'Días de vencimiento: '}</div>
                                <div>
                                    { row.diasVencimiento >= 10 ? //Verde '#6ee3af'
                                        <h6><Badge variant="success">{row.diasVencimiento}</Badge></h6>
                                    :
                                        row.diasVencimiento <= 9 && row.diasVencimiento >= 4 ? //Amarillo e9b052
                                            <h6><Badge variant="warning">{row.diasVencimiento}</Badge></h6>
                                    :
                                        row.diasVencimiento <= 3 && row.diasVencimiento >= 1 ? //Naranja FFC757
                                            <h6><Badge className="vencimientoNaranja">{row.diasVencimiento}</Badge></h6>
                                    :
                                        row.diasVencimiento <= 0 && row.diasVencimiento >= -5 ? //Rojo FF5F58, ff5f5899
                                            <h6><Badge variant="danger">{row.diasVencimiento}</Badge></h6>
                                    :
                                        row.diasVencimiento <= -6 ? //Morado 8f41d8
                                        <h6><Badge className="vencimientoMorado">{row.diasVencimiento}</Badge></h6>
                                    :
                                        row.diasVencimiento }
                                </div>
                            </div>
                        )
                    },
                    sort: true
                }
            ];
            function indication() {
                return listaAprobadas.length ? "La búsqueda no coincide con ningún criterio" : "No hay registros que mostrar";
            }

            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size}
                </span>
            );

            const options = {
                custom: true,
                sizePerPage: 10,
                totalSize: listaAprobadas.length,
                paginationTotalRenderer: customTotal
            }

            return <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div className="fieldsetReqAprobadas">
                        <div className="d-flex ml-auto mb-2 flex-row-reverse">
                            {gridCards ?
                                <Button className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Listado"
                                    onClick={() => { setGridCards(false) }}
                                >
                                    <ListTask size={24} className="button-default-black" color="#000" />
                                </Button> :
                                <Button className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Cuadrícula"
                                    onClick={() => { setGridCards(true) }}
                                >
                                    <Grid3x2GapFill size={24} className="button-default-black" color="#000" />
                                </Button>
                            }
                        </div>
                        <div className="d-flex">
                            {gridCards ? '' :
                                <div className="d-flex flex-row">
                                    <Form.Label column>Mostrar</Form.Label>
                                    <Col>
                                        {/* <Form.Control size="sm" as="select" onChange={(e) => handleSizePerPage(paginationProps, e.target.value)}>
                                            <option value="4">4</option>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value={listaAprobadas.length}>Todos</option>
                                        </Form.Control> */}
                                        <SizePerPageDropdownStandalone {...paginationProps} btnContextual />
                                    </Col>
                                </div>}
                            <div className="d-flex flex-row">
                                <Col>
                                    <PaginationTotalStandalone {...paginationProps} />
                                </Col>
                            </div>
                            <div className="d-flex ml-auto flex-row-reverse">
                                <Col>
                                    <PaginationListStandalone {...paginationProps} />
                                </Col>
                                <Form.Label column>Página</Form.Label>
                            </div>
                        </div>
                        {
                            gridCards ?
                                <div className="table-responsive table-sm">
                                    <BootstrapTable
                                        id="reqAprobadas"
                                        keyField='id'
                                        data={listaAprobadas}
                                        columns={columns}
                                        filter={filterFactory()}
                                        bordered={false}
                                        noDataIndication={indication}
                                        {...paginationTableProps}
                                    />
                                </div>
                                :
                                <div className="table-responsive table-sm text-center">
                                    <BootstrapTable
                                        keyField='id'
                                        data={listaAprobadas}
                                        columns={columnsList}
                                        filter={filterFactory()}
                                        headerClasses="header-class-aprobadas"
                                        bordered={false}
                                        striped
                                        hover
                                        condensed
                                        noDataIndication={indication}
                                        {...paginationTableProps}
                                    />
                                </div>
                        }
                    </div>
                )}
            </PaginationProvider >
        }
    }

    return (
        <section id="listaReqAprobadas">
            <Card id="cardReqAprobadas">
                <div>
                    {modalConsultarRequisicionShow ? <ModalConsultarRequisicion show={modalConsultarRequisicionShow} onHide={modalConsultarRequisicionClose}>{requisicion}</ModalConsultarRequisicion> : ''}
                </div>
                <Card.Body className="cardBodyReqAprobadas">
                    {request ? "Cargando..." : mostrarListaAprobadas()}
                </Card.Body>
            </Card>
        </section>
    )

}

export default RequisicionesAprobadas;