import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_PROVEEDORES_URL } from '../../constants'

//Componentes bootstrap
import { Form, Col } from "react-bootstrap";

//Componentes
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { toast } from 'react-toastify';

function SeleccionarCuentaEmisora(props) {
    const idEmpresa = props.children;
    const [status, setStatus] = useState('');
    const [error, setError] = useState(false);
    const [listaCuentasEmisoras, setListaCuentasEmisoras] = useState([]);
    const [listaPorBanco, setListaPorBanco] = useState([]);
    const [banco, setBanco] = useState('');
    const [rowSelected, setRowSelected] = useState([]);

    useEffect(getCuentasEmisoras, [idEmpresa]);//Se actualiza cada que se elige un proveedor diferente
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getListaPorBanco, [banco, listaCuentasEmisoras]);//Se actualiza cada que se elige un banco diferente

    function handleBancoChange(e) {
        setBanco(e.target.value);
    }

    function getCuentasEmisoras() {
        axios.post(API_PROVEEDORES_URL + 'listEmisorCuentas',
            {
                "idEmpresa": idEmpresa
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                setListaCuentasEmisoras(res.data.cuentasEmisoras);
                if (res.data.cuentasEmisoras.length) {
                    setBanco(res.data.cuentasEmisoras[0].nombreBanco)
                }
            } else {
                setError(true);
                setStatus(res.data.result.message);
            }
        }).catch((error) => {
            setError(true);
            setStatus("Error: No se han podido recuperar las cuentas bancarias de esta empresa")
        })
    }

    function cargarSelectBanco() {
        return listaCuentasEmisoras.length ? listaCuentasEmisoras.map(e => {
            return (<option key={e.id} value={e.nombreBanco}>{e.nombreBanco}</option>)
        }) : 'No existen bancos registrados'
    }

    function getListaPorBanco() {
        let lista;
        lista = listaCuentasEmisoras.filter(function (l) {
            return l.nombreBanco === banco;
        })
        if (lista.length) {
            setListaPorBanco(lista[0].lista);
            setRowSelected(lista[0].lista[0]);
            props.onBancoSelected(lista[0].lista[0]);
        }
    }

    let handleSizePerPage = ({ page, onSizePerPageChange }, newSizePerPage) => {
        onSizePerPageChange(newSizePerPage, page);
    }

    let handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            setRowSelected(row);
            props.onBancoSelected(row);
        } else {
            toast.warning("Debes seleccionar una cuenta bancaria",{
                position:"top-center",
                autoClose: 4500, 
            });
        }
    }

    let listarCuentasEmisoras = () => {
        if (error) {
            return (<div>{status}</div>);
        } else {
            const columns = [{
                dataField: 'id',
                text: 'ID',
                sort: true,
                filter: textFilter({
                    placeholder: 'Buscar...',
                    className: "form-control-sm"
                })
            },
            {
                dataField: 'cuenta',
                text: 'Cuenta',
                sort: true,
                filter: textFilter({
                    placeholder: 'Buscar...',
                    className: "form-control-sm"
                })
            },
            {
                dataField: 'cuentaClabe',
                text: 'Cuenta CLABE',
                sort: true,
                filter: textFilter({
                    placeholder: 'Buscar...',
                    className: "form-control-sm"
                })
            },
            {
                dataField: 'comentario',
                text: 'Comentario',
                sort: true,
                filter: textFilter({
                    placeholder: 'Buscar...',
                    className: "form-control-sm"
                })
            }];

            const selectRow = {
                mode: "radio",
                clickToSelect: true,
                onSelect: handleOnSelect,
                selected: [listaPorBanco.length ? listaPorBanco[0].id : ''],
                style: { backgroundColor: '#f5f5f580' },
                selectionHeaderRenderer: () => 'X'
            };

            function indication() {
                return listaPorBanco.length ? "La búsqueda no coincide con ningún criterio" : "No hay registros que mostrar";
            }

            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size}
                </span>
            );

            const options = {
                custom: true,
                sizePerPage: 5,
                totalSize: listaPorBanco.length,
                paginationTotalRenderer: customTotal
            }

            const contentTable = ({ paginationProps, paginationTableProps }) => (
                <div>
                    <ToolkitProvider
                        keyField='id'
                        data={listaPorBanco}
                        columns={columns}
                    >
                        {toolkitprops => (
                            <div className="div-select-cuenta">
                                <Form.Row className="p-2">
                                    <div className="d-flex flex-row">
                                        <Form.Label column>Mostrar</Form.Label>
                                        <Col>
                                            <Form.Control className="form-control-font-size" size="sm" as="select" onChange={(e) => handleSizePerPage(paginationProps, e.target.value)}>
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="15">15</option>
                                                <option value={listaPorBanco.length}>Todos</option>
                                            </Form.Control>
                                        </Col>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <Col>
                                            <PaginationTotalStandalone {...paginationProps} />
                                        </Col>
                                    </div>
                                    <div className="d-flex ml-auto flex-row-reverse">
                                        <Col>
                                            <PaginationListStandalone {...paginationProps} />
                                        </Col>
                                        <Form.Label column>Página</Form.Label>
                                    </div>
                                </Form.Row>
                                <div className="table-responsive table-sm text-center">
                                    <BootstrapTable
                                        id='selectCuenta'
                                        selectRow={selectRow}
                                        filter={filterFactory()}
                                        bordered={false}
                                        noDataIndication={indication}
                                        {...paginationTableProps}
                                        {...toolkitprops.baseProps}
                                    />
                                </div>
                            </div>
                        )}
                    </ToolkitProvider>
                </div>
            );

            return <PaginationProvider pagination={paginationFactory(options)}>
                {contentTable}
            </PaginationProvider >
        }
    }

    return (
        <fieldset>
            <Form.Row as={Col}>
                <Form.Group as={Col}>
                    <Form.Label>Banco</Form.Label>
                    <Form.Control size="sm" id="banco" type="text" as="select" required value={banco} onChange={handleBancoChange}>
                        <option default value="">Seleccione banco</option>
                        {cargarSelectBanco()}
                    </Form.Control>
                </Form.Group>
            </Form.Row>
            <Form.Row as={Col}>
                {listaPorBanco.length !== 0 ? listarCuentasEmisoras() : " Seleccione un banco para obtener sus cuentas bancarias"}
            </Form.Row>
        </fieldset>
    )
}

export default SeleccionarCuentaEmisora;