import { userConstants } from '../constantes/constants'

/**
 * Each reducer updates a different part of the application state in response to dispatched redux actions
 */
/*let user = localStorage.getItem('username');
let userType = localStorage.getItem('userType');
let toEmail = localStorage.getItem('toEmail');*/
let token = localStorage.getItem('token');
const initialState = token ? { loggedIn: true, token } : {};

function authentication(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                loggedIn: true,
                user: action.token.username,
                userType: action.token.userType,
                userId: action.token.userId
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.token.username,
                userType: action.token.userType,
                userId: action.token.userId
            };
        case userConstants.LOGIN_FAILURE:
            return {
                loggedIn: false
            };
        case userConstants.LOGOUT:
            return {};
        default:
            return state
    }
}

export { authentication };