import React, { useState } from "react";
import axios from 'axios';
import { KRAKEN_URL } from '../../../../constants'

//Componentes bootstrap
import { Modal } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import { toast } from "react-toastify";

function ModalEliminarEmpresaUsuario(props) {
    const usuario = props.children;
    const [status, setStatus] = useState(false);

    function eliminarUsuario() {
        setStatus(true);
        axios.post(KRAKEN_URL + 'empresa/usuarios/delete',
            {
                "userId": usuario.userId
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                setStatus(false);
                toast.success(res.data.result.message,{
                    position:"top-center",
                    autoClose: 4500, 
                });
                props.onUpdate();//Actualiza el listado, llamando a la función padre para actualizarlo  
                props.onHide();//Cierra el modal, llamando a la función padre para cerrarlo 
            } else {
                setStatus(false);
                toast.warning(res.data.result.message,{
                    position:"top-center",
                    autoClose: 4500, 
                });
                toast.warning(res.data.result.suggestion,{
                    position:"top-center",
                    autoClose: 4500, 
                });
            }
        }).catch((error) => {
            setStatus(false);
            toast.error("Hubo un error, no se pudo eliminar este usuario.",{
                position:"top-center",
                autoClose: 4500, 
            });
        })
    }

    return (
        <section className="text-center">
            <Modal
                show={props.show}
                //onHide={props.onHide}
                size="sm"
                id="modalEliminarUsuario"
                centered
            >
                <Modal.Header className="modal-header-empresa-usuario" closeButton onHide={props.onHide}>
                    <p><Trash size={22} color="#5B4B51" />   Eliminar usuario de {usuario.empresaAlias}</p>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        &nbsp;¿Está seguro que desea eliminar a {usuario.username} de {usuario.empresaAlias}?
                    </p>
                    <div className="d-flex justify-content-center">
                        <button
                            className="m-1 button-default-confirmar"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Confirmar"
                            onClick={e => eliminarUsuario()}
                        >{status ?
                            <span>Eliminando...</span>
                            : <span>Confirmar</span>}
                        </button>
                        <button
                            className="m-1 button-default-cancelar"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Cancelar"
                            onClick={props.onHide}
                        ><span>Cancelar</span>
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    );
}

export default ModalEliminarEmpresaUsuario;