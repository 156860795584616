import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useSelector } from 'react-redux';
import { KRAKEN_URL } from '../../constants'
import { ListItem } from '@material-ui/core';
import { Link as RouterLink } from "react-router-dom";

import './css/Configuracion.css'
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next';
import { CaretDownFill, CaretUpFill, PeopleFill } from 'react-bootstrap-icons';
import { Col, Form } from 'react-bootstrap';
import Loader from '../Loader';

function Configuracion() {
    const empresasDisponibles = useSelector(state => state.empresas.empresasDisponibles);
    const empresas = empresasDisponibles.map(e => { return e.empresa; });
    const [error, setError] = useState(false);
    const [cargando, setCargando] = useState(false);
    const [status, setStatus] = useState('');
    const [listaEmpresas, setListaEmpresas] = useState([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getListadoEmpresas, []);

    function getListadoEmpresas() {
        setCargando(true);
        axios.post(KRAKEN_URL + 'empresas/empresasByAlias',
            {
                empresas: empresas
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                setListaEmpresas(res.data.empresas);
            } else {
                setError(true);
                setStatus(res.data.result.message);
            }
        }).catch((error) => {
            setError(true);
            setStatus("Error: No se han podido recuperar los registros de las empresas.")
        }).finally(() => {
            setCargando(false);
        })
    }
    
    let listarEmpresas = () => {
        if (error) {
            return (
                <div>{status}</div>
            );
        } else {
            const columns = [
                {
                    dataField: 'razonSocial',
                    text: 'Razón Social',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                },
                {
                    dataField: 'alias',
                    text: 'Alias',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                },
                {
                    dataField: 'rfc',
                    text: 'RFC',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true,
                    editable: false
                },
                {
                    dataField: 'tipoPersona',
                    text: 'Tipo persona',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true,
                    editable: false
                },
                {
                    dataField: 'fechaRegistro',
                    text: 'Fecha registro',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true,
                    sortCaret: (order, column) => {
                        if (!order) return (<span>&nbsp;&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000"/><CaretUpFill size={10} className="button-default-black" color="#000"/></span>);
                        else if (order === "desc") return (<span>&nbsp;&nbsp;<CaretUpFill size={10} className="button-default-black" color="#000"/><CaretDownFill size={10} className="button-default-black" color="#dee2e6"/></span>);
                        else if (order === "asc") return (<span>&nbsp;&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000"/><CaretUpFill size={10} className="button-default-black" color="#dee2e6"/></span>);
                        return null;
                    },
                    editable: false
                },
                {
                    dataField: 'acciones',
                    isDummyField: true,
                    editable: false,
                    text: 'Acciones',
                    formatter: (cellContent, row) => {
                        return (
                            <ListItem button to={{ pathname: `/configuracion/${row.alias}`, empresa: row }} component={RouterLink}
                                data-toggle="tooltip" data-placement="bottom" title="Usuarios">
                                <PeopleFill size={24} className="button-default-black" color="#000" />
                            </ListItem>
                        )
                    }
                }];

            function indication() {
                return listaEmpresas.length ? "Cargando..." : "No hay registros que mostrar";
            }

            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size}
                </span>
            );

            const options = {
                custom: true,
                sizePerPage: 10,
                totalSize: listaEmpresas.length,
                paginationTotalRenderer: customTotal
            };

            return <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div>
                        <Form.Row className="p-2">
                            <div className="d-flex flex-row">
                                <Form.Label>Mostrar</Form.Label>
                                <Col>
                                    {/* <Form.Control size="sm" id="sizePerPage" as="select" onChange={(e) => handleSizePerPage(paginationProps, e.target.value)}>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="15">15</option>
                                        <option value={listaEmpresas.length}>Todos</option>
                                    </Form.Control> */}
                                    <SizePerPageDropdownStandalone {...paginationProps} btnContextual />
                                </Col>
                            </div>
                            <Col>
                                <PaginationTotalStandalone {...paginationProps} />
                            </Col>
                            <div className="d-flex  ml-auto flex-row-reverse">
                                <Col>
                                    <PaginationListStandalone {...paginationProps} />
                                </Col>
                                <Form.Label column>Página</Form.Label>
                            </div>
                        </Form.Row>
                        <div className="table-responsive table-sm">
                            <BootstrapTable
                                id="empresasDisponibles"
                                keyField='empresaId'
                                data={listaEmpresas}
                                columns={columns}
                                filter={filterFactory()}
                                bordered={false}
                                striped
                                hover
                                condensed
                                noDataIndication={indication}
                                {...paginationTableProps}
                            />
                        </div>
                    </div>
                )}
            </PaginationProvider>
        }
    }

    if (cargando) {
        return <Loader />
    }

    return (
        <div>
            <Form.Row as={Col}>
                <Form.Group as={Col}>
                    {listaEmpresas.length ? listarEmpresas() : status}
                </Form.Group>
            </Form.Row>
        </div>
    );
}

export default Configuracion;