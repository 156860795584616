import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_PROVEEDORES_URL, KRAKEN_URL } from "../../constants";
import { monedasSAT } from "../../constantes/moneda-sat";
import { formaPagoSAT } from "../../constantes/forma-pago-sat";
import { bigDecimalFormat } from "../../constantes/general-require";

//Estilos
import "./css/Requisiciones.css";
import { FileEarmarkPlus } from "react-bootstrap-icons";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { ExpandMore, ExpandLess } from "@material-ui/icons";

//Componentes bootstrap
import {
  Modal,
  Form,
  Col,
  Container,
  InputGroup,
  Accordion,
  Card,
  Row,
  ListGroup,
  Table,
} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
//Componentes
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { createMuiTheme } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import Moment from "moment";
import NumberFormat from "react-number-format";
import SeleccionarBanco from "./SeleccionarBanco";
import SeleccionarEmpresa from "./SeleccionarEmpresa";
import ModalNotificarPago from "../Pagos/ModalNotificarPago";

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      light: "#f5f5f580",
      main: "#5B4B51",
      dark: "#5B4B51",
      contrastText: "#fff",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  picker: {
    margin: theme.spacing(0.6),
  },
}));

function ModalCrearRequisicion(props) {

  const token = JSON.parse(localStorage.getItem("token"));
  const username = token.username;
  const classes = useStyles();
  const [status, setStatus] = useState(false);
  const [inputValue, setInputValue] = useState(Moment().format("DD/MM/yyyy"));
  /*Inicio campos del formulario*/
  const [solicitante] = useState(username);
  const [idSolicitante] = useState(token.userId);
  let formFechaActual = Moment().format("DD/MM/YYYY");
  const [empresa, setEmpresa] = useState(props.children.empresa);
  const [idEmpresa, setIdEmpresa] = useState(props.children.idEmpresa);
  const [fechaRequerida, setFechaRequerida] = useState(Moment());
  const [concepto, setConcepto] = useState("");
  const [moneda, setMoneda] = useState("MXN,Peso Mexicano,2");
  const [monedaClaveSat, setMonedaClaveSat] = useState("MXN"); //MXN
  const [monedaSat, setMonedaSat] = useState(""); //Peso mexicano
  const [monedaDecimalesSat, setMonedaDecimalesSat] = useState("2"); //2
  const [paridad, setParidad] = useState("1.00");
  const [metodoPago, setMetodoPago] = useState("");
  const [metodoPagoClaveSat, setMetodoPagoClaveSat] = useState("");
  const [metodoPagoSat, setMetodoPagoSat] = useState("");
  const [formaPago, setFormaPago] = useState("");
  const [formaPagoClaveSat, setFormaPagoClaveSat] = useState("");
  const [formaPagoSat, setFormaPagoSat] = useState("");
  /*const [precisionCalculos, setPrecisionCalculos] = useState('');*/
  const [decimalesRequisicion, setDecimalesRequisicion] = useState("6");
  const [importe, setImporte] = useState("0");
  const [ivaTrasladadoTasaCuota, setIvaTrasladadoTasaCuota] = useState("0.00");
  const [ivaTrasladadoImporte, setIvaTrasladadoImporte] = useState("0.00");
  const [ivaRetenido, setIvaRetenido] = useState("0.00");
  const [ivaRetenidoTasaCuota, setIvaRetenidoTasaCuota] = useState("0.00");
  const [ivaRetenidoImporte, setIvaRetenidoImporte] = useState("0.00");
  const [isr, setIsr] = useState("0.00");
  const [isrTasaCuota, setIsrTasaCuota] = useState("0.00");
  const [isrImporte, setIsrImporte] = useState("0.00");
  const [otrosImpuestos, setOtrosImpuestos] = useState("0.00");
  const [otrosImpuestosImporte, setOtrosImpuestosImporte] = useState("0.00");
  const [opcionDescuento, setOpcionDescuento] = useState("");
  const [descuento, setDescuento] = useState("0.00");
  const [descuentoPorcentaje, setDescuentoPorcentaje] = useState("0.00");
  const [descuentoImporte, setDescuentoImporte] = useState("0.00");
  const [porcentaje, setPorcentaje] = useState("0.00");
  const [descuentoImpCantidad, setDescuentoImpCantidad] = useState("0.00");
  const [descuentoCantidad, setDescuentoCantidad] = useState("0.00");
  const [subtotal, setSubtotal] = useState("0.00"); //Total sin impuestos
  const [subtotalConDescuento, setSubtotalConDescuento] = useState("0.00"); //Total con descuento y sin impuestos
  const [total, setTotal] = useState("0.00"); //Total con impuestos
  const [importePesosMexicanos, setImportePesosMexicanos] = useState("0.00");
  const proveedor = props.children.nombreComercial; //
  const idProveedor = props.children.idProveedor; //
  const proveedorRfc = props.children.proveedorRfc; //
  const [porcentajeTotal, setPorcentajeTotal] = useState("0.00"); //Porcentaje de anticipo requerido del total
  const [totalRequerido, setTotalRequerido] = useState("0.00"); //Anticipo del total requerido
  const [anticipoPesosMexicanos, setAnticipoPesosMexicanos] = useState("0.00");
  const [banco, setBanco] = useState(""); //lista de cuentas
  const [cuentaClabe, setCuentaClabe] = useState(""); //lista de cuentas
  const [cuentaBanco, setCuentaBanco] = useState(""); //lista de cuentas
  const [referencia, setReferencia] = useState("");
  const [taxesChecked, setTaxesChecked] = useState(false);
  const [showParidad, setShowParidad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  /*Fin campos del formulario*/
  const listMonedas = monedasSAT;
  const listFormaPago = formaPagoSAT;
  const [pagadores, setPagadores] = useState([]);
  const [pagadorName, setPagadorName] = useState("");
  const [pagadorEmail, setPagadorEmail] = useState("");
  const [notificarPagador, setNotificarPagador] = useState(false);
  const [contactos, setContactos] = useState([]);
  const [modalNotificarPagoShow, setModalNotificarPagoShow] = useState(false);
  let modalConfirmarClose = () => {
    setModalNotificarPagoShow(false);
  };
  useEffect(() => {
    calculoImporteTrasladado();
    calculoImporteRetenido();
    calculoImporteIsr();
    calculoImporteOtrosImpuestos();
    calculoImporteDescuento();
    calculoPorcentajeDescuento();
    calculoImportePorcentajeTotal();
    calculoTotales();
  });

  useEffect(() => {
    console.log(idEmpresa);
    getPagadores();
  }, [idEmpresa]);
  function getPagadores() {
    console.log("entra a la peticion "+ idEmpresa);
    axios
      .post(KRAKEN_URL + "empresa/usuarios/payers", {
        empresaId: idEmpresa,
      })
      .then((res) => {
        console.log("........");
        console.log(res);

        let aux = [];
        if (res.data.list)
          res.data.list.map((pagador) => {
            aux.push(pagador);
          });
        setPagadores(aux);
      })
      .catch((error) => {
        console.log(error);
        console.log("ALGO SALIÓ MAL");
      });
  }
  function bancoSelected(row) {
    setBanco(row.banco);
    setCuentaClabe(row.cuentaClabe);
    setCuentaBanco(row.cuenta);
  }
  const handleCheckboxChange = () => {
    setTaxesChecked(!taxesChecked);
    setIvaTrasladadoTasaCuota(0);
    setIvaRetenido(0);
    setIvaTrasladadoImporte("0.00");
    setIvaRetenidoImporte("0.00");
    setIvaRetenidoTasaCuota("0.00");
    setIsrTasaCuota("0.00");
    setIsr(0);
    setIsrImporte("0.00");
    setDescuentoImporte("0.00");
    setDescuentoPorcentaje("0.00");
    setDescuentoImpCantidad("0.00");
    setOtrosImpuestos("0.00");
    setOtrosImpuestosImporte("0.00");
    setMetodoPago("");
    setFormaPago("");
    setMetodoPago("");
    setMetodoPagoClaveSat("");
    setMetodoPagoSat("");
  };
  function empresaSelected(empresa) {
    var str = empresa;
    var array = str.split(",");
    setEmpresa(array[0]);
    setIdEmpresa(array[1]);
  }

  const handleFechaRequeridaChange = (date, value) => {
    setFechaRequerida(date);
    setInputValue(value);
  };
  function handleConceptoChange(e) {
    setConcepto(e.target.value);
  }
  function handleMonedaChange(e) {
    var str = e.target.value;
    var array = str.split(",");
    setMoneda(e.target.value);
    setMonedaClaveSat(array[0]);
    setMonedaSat(array[1]);
    setMonedaDecimalesSat(array[2]);
    setDecimalesRequisicion(array[2]);

    if (array[0] === "MXN") {
      setShowParidad(false);
      setParidad("1.00");
      document.getElementById("paridad").disabled = true;
    } else {
      setShowParidad(true);
      setParidad("1.00");
      document.getElementById("paridad").disabled = false;
    }
  }
  function handleParidadChange(e) {
    setParidad(e.target.value);
  }
  function handleMetodoPagoChange(e) {
    var str = e.target.value;
    var array = str.split(",");
    setMetodoPago(e.target.value);
    setMetodoPagoClaveSat(array[0]);
    setMetodoPagoSat(array[1]);

    if (array[0] === "PPD") {
      setFormaPago("99,Por definir");
      setFormaPagoClaveSat("99");
      setFormaPagoSat("Por definir");
      document.getElementById("formaPago").disabled = true;
    } else {
      document.getElementById("formaPago").disabled = false;
    }
  }
  function handleFormaPagoChange(e) {
    var str = e.target.value;
    var array = str.split(",");

    setFormaPago(e.target.value);
    setFormaPagoClaveSat(array[0]);
    setFormaPagoSat(array[1]);
  }
  /*function handlePrecisionCalculos(e) {
        setPrecisionCalculos(e.target.value);
        if (e.target.value === '2') {
            setDecimalesRequisicion(monedaDecimalesSat);
        } else {
            setDecimalesRequisicion(e.target.value);
        }
    }*/
  function handleImporteChange(e) {
    setImporte(e.target.value); //Se guarda tal cual el valor que aparece en el input
  }
  function handleIvaTrasladadoChange(e) {
    setIvaTrasladadoTasaCuota(e.target.value); //Se guarda tal cual el valor que aparece en el input
  }
  function calculoImporteTrasladado() {
    let importeDecimal = bigDecimalFormat(importe); //Se convierte a bigDecimal
    let iDecimal = importeDecimal.times(ivaTrasladadoTasaCuota); //Método times que sirve para multiplicar a*b
    let iFixed = iDecimal.toFixed(parseFloat(monedaDecimalesSat)); //toFixed: cuántos dígitos se van a visualizar después del punto decimal; parseFloat: convierte un string a número
    setIvaTrasladadoImporte(iFixed); //Se guarda el valor sin comas y se agrega el diseño hasta que se utiliza en el formulario
  }
  function handleIvaRetenidoChange(e) {
    setIvaRetenido(e.target.value); //Se guarda tal cual el valor que aparece en el input
    let ivaRetenidoDecimal = bigDecimalFormat(e.target.value); //Se convierte a bigDecimal
    let tasaCuotaDecimal = ivaRetenidoDecimal.dividedBy(100); //dividedBy: a/b
    setIvaRetenidoTasaCuota(tasaCuotaDecimal); //Se guarda el valor sin comas
  }
  function calculoImporteRetenido() {
    let importeDecimal = bigDecimalFormat(importe); //Se convierte a bigDecimal
    let importeIvaRetenidoDecimal = importeDecimal
      .times(ivaRetenidoTasaCuota)
      .toFixed(parseFloat(monedaDecimalesSat)); //times: a*b; toFixed: cuántos dígitos después del punto decimal
    setIvaRetenidoImporte(importeIvaRetenidoDecimal); //Se guarda el valor sin comas y se agrega el diseño hasta que se utiliza en el formulario
  }
  function handleIsrChange(e) {
    setIsr(e.target.value); //Se guarda tal cual el valor que aparece en el input
    let isrDecimal = bigDecimalFormat(e.target.value); //Se convierte a bigDecimal
    let tasaCuotaDecimal = isrDecimal.dividedBy(100); //dividedBy: a/b
    setIsrTasaCuota(tasaCuotaDecimal); //Se guarda el valor sin comas
  }
  function calculoImporteIsr() {
    let importeDecimal = bigDecimalFormat(importe); //Se convierte a bigDecimal
    let importeIsrDecimal = importeDecimal
      .times(isrTasaCuota)
      .toFixed(parseFloat(monedaDecimalesSat)); //times: a*b; toFixed: cuántos dígitos después del punto decimal
    setIsrImporte(importeIsrDecimal); //Se guarda el valor sin comas
  }
  function handleOtrosImpuestosChange(e) {
    setOtrosImpuestos(e.target.value); //Se guarda tal cual el valor que aparece en el input
  }
  function calculoImporteOtrosImpuestos() {
    let otrosImpuestosDecimal = bigDecimalFormat(otrosImpuestos);
    let importeOtrosImpuestosDecimal = otrosImpuestosDecimal.toFixed(
      parseFloat(monedaDecimalesSat)
    ); //toFixed: cuántos dígitos después del punto decimal
    setOtrosImpuestosImporte(importeOtrosImpuestosDecimal); //Se guarda el valor sin comas
  }
  function handleDescuentoOpcionPorcentajeChange(e) {
    let porcentaje = document.getElementById("porcentaje").checked;

    porcentaje
      ? (document.getElementById("cantidad").checked = false)
      : (document.getElementById("porcentaje").checked = true);
    setOpcionDescuento("porcentaje");
    opcionesDescuento();
  }
  function handleDescuentoOpcionCantidadChange(e) {
    let cantidad = document.getElementById("cantidad").checked;
    cantidad
      ? (document.getElementById("porcentaje").checked = false)
      : (document.getElementById("cantidad").checked = true);

    setOpcionDescuento("cantidad");
    opcionesDescuento();
  }
  function opcionesDescuento() {
    return opcionDescuento === "porcentaje" ? (
      <Form.Row hidden={!taxesChecked}>
        <Col>
          <InputGroup size="sm">
            <NumberFormat
              id="descuento"
              className="input-signs-align form-control form-control-sm"
              thousandSeparator={true}
              value={descuento}
              onChange={handleDescuentoChange}
            ></NumberFormat>
            <InputGroup.Append>
              <InputGroup.Text className="input-signs-right">%</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Col>
        <Col>
          <InputGroup size="sm">
            <InputGroup.Prepend>
              <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
            </InputGroup.Prepend>
            <NumberFormat
              id="descuentoImporte"
              className="input-signs-align form-control form-control-sm"
              thousandSeparator={true}
              disabled
              value={descuentoImporte}
            ></NumberFormat>
          </InputGroup>
        </Col>
      </Form.Row>
    ) : opcionDescuento === "cantidad" ? (
      <Form.Row>
        <Col>
          <InputGroup size="sm">
            <NumberFormat
              id="porcentaje"
              className="input-signs-align form-control form-control-sm"
              thousandSeparator={true}
              disabled
              value={descuentoImpCantidad}
            ></NumberFormat>
            <InputGroup.Prepend>
              <InputGroup.Text className="input-signs-right">%</InputGroup.Text>
            </InputGroup.Prepend>
          </InputGroup>
        </Col>
        <Col>
          <InputGroup size="sm">
            <InputGroup.Append>
              <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
            </InputGroup.Append>
            <NumberFormat
              id="descuentoPorcentaje"
              className="input-signs-align form-control form-control-sm"
              thousandSeparator={true}
              value={porcentaje}
              onChange={handlePorcentajeDescuentoChange}
            ></NumberFormat>
          </InputGroup>
        </Col>
      </Form.Row>
    ) : (
      ""
    );
  }
  function handleDescuentoChange(e) {
    setDescuento(e.target.value); //Se guarda tal cual el valor que aparece en el input
    let descuentoDecimal = bigDecimalFormat(e.target.value);
    let porcentajeDecimal = descuentoDecimal.dividedBy(100); //dividedBy: a/b
    setDescuentoPorcentaje(porcentajeDecimal); //Se guarda el valor sin comas
  }
  function calculoImporteDescuento() {
    let importeDecimal = bigDecimalFormat(importe); //Se convierte a bigDecimal
    let importeDescuentoDecimal = importeDecimal
      .times(descuentoPorcentaje)
      .toFixed(parseFloat(monedaDecimalesSat)); //times: a*b; toFixed: cuántos dígitos después del punto decimal
    setDescuentoImporte(importeDescuentoDecimal); //Se guarda el valor sin comas y se agrega el diseño hasta que se utiliza en el formulario
  }
  function handlePorcentajeDescuentoChange(e) {
    setPorcentaje(e.target.value); //Se guarda tal cual el valor que aparece en el input
    let descuentoDecimal = bigDecimalFormat(e.target.value);
    let importeDecimal = bigDecimalFormat(importe); //Se convierte a bigDecimal
    let porcentajeDecimal = descuentoDecimal.dividedBy(importeDecimal); //dividedBy: a/b
    setDescuentoCantidad(porcentajeDecimal); //Se guarda el valor sin comas
  }
  function calculoPorcentajeDescuento() {
    let importeDecimal = bigDecimalFormat(100); //Se convierte a bigDecimal
    let importeDescuentoDecimal = importeDecimal
      .times(descuentoCantidad)
      .toFixed(parseFloat(monedaDecimalesSat)); //times: a*b; toFixed: cuántos dígitos después del punto decimal
    setDescuentoImpCantidad(importeDescuentoDecimal); //Se guarda el valor sin comas y se agrega el diseño hasta que se utiliza en el formulario
  }
  function handlePorcentajeTotalChange(e) {
    setPorcentajeTotal(e.target.value); //Se guarda tal cual el valor que aparece en el input
  }
  function calculoImportePorcentajeTotal() {
    let porcentajeDecimal = bigDecimalFormat(porcentajeTotal).dividedBy(100); //dividedBy: a/b
    let totalDecimal = bigDecimalFormat(total);
    let totalRequeridoDecimal = totalDecimal
      .times(porcentajeDecimal)
      .toFixed(parseFloat(monedaDecimalesSat)); //times: a*b; toFixed: cuántos dígitos después del punto decimal
    setTotalRequerido(totalRequeridoDecimal); //El diseño se pondrá cuando se use en el formulario
  }
  function handleReferenciaChange(e) {
    setReferencia(e.target.value);
  }

  function handlePagador(e) {
    let value = e.target.value;
    let aux = value.split("-");
    console.log(value)
  
    setPagadorName(aux[0]);
    setPagadorEmail(aux[1]);
  }
  function handleNotificarPagador(e) {
    setNotificarPagador(!notificarPagador);
  }
  function calculoTotales() {
    /** NOTA: NO SE PUEDE CONCATENAR BIGDECIMALFORMAT CON MÉTODOS DE DECIMAL EN UNA SÓLA OPERACIÓN
     *  PORQUE DECIMAL SÓLO SOPORTA SUS PROPIOS MÉTODOS, DE LO CONTRARIO LANZARÁ ERROR
     *  DECIMAL API: http://mikemcl.github.io/decimal.js/
     */
    let importeDecimal = bigDecimalFormat(importe);
    setSubtotal(importeDecimal.toFixed(parseFloat(monedaDecimalesSat)));
    let descuentoImporteDecimal = bigDecimalFormat(descuentoImporte);
    let descuentoPorcentajeDecimal = bigDecimalFormat(porcentaje); //descuentoImpCantidad
    let subtotalConDescuentoDecimal = importeDecimal.minus(
      descuentoImporteDecimal
    );
    let subtotalConDescuentoPorcentajeDecimal = importeDecimal.minus(
      descuentoPorcentajeDecimal
    );
    setSubtotalConDescuento(
      opcionDescuento === "porcentaje"
        ? subtotalConDescuentoDecimal.toFixed(parseFloat(monedaDecimalesSat))
        : subtotalConDescuentoPorcentajeDecimal.toFixed(
            parseFloat(monedaDecimalesSat)
          )
    ); //minus: a-b; plus: a+b
    let ivaTrasladadoImporteDecimal = bigDecimalFormat(ivaTrasladadoImporte);
    let ivaRetenidoImporteDecimal = bigDecimalFormat(ivaRetenidoImporte);
    let isrImporteDecimal = bigDecimalFormat(isrImporte);
    let otrosImpuestosDecimal = bigDecimalFormat(otrosImpuestosImporte);
    let totalFinal = subtotalConDescuentoDecimal
      .plus(ivaTrasladadoImporteDecimal)
      .minus(ivaRetenidoImporteDecimal)
      .minus(isrImporteDecimal)
      .plus(otrosImpuestosDecimal);
    let totalPorcentajeFinal = subtotalConDescuentoPorcentajeDecimal
      .plus(ivaTrasladadoImporteDecimal)
      .minus(ivaRetenidoImporteDecimal)
      .minus(isrImporteDecimal)
      .plus(otrosImpuestosDecimal);
    setTotal(
      opcionDescuento === "porcentaje"
        ? totalFinal.toFixed(parseFloat(monedaDecimalesSat))
        : totalPorcentajeFinal.toFixed(parseFloat(monedaDecimalesSat))
    );
    let paridadDecimal = bigDecimalFormat(paridad);
    let importeConParidad = totalFinal.times(paridadDecimal);
    let importeConparidadPorcentaje =
      totalPorcentajeFinal.times(paridadDecimal);
    setImportePesosMexicanos(
      opcionDescuento === "porcentaje"
        ? importeConParidad.toFixed(parseFloat(monedaDecimalesSat))
        : importeConparidadPorcentaje.toFixed(parseFloat(monedaDecimalesSat))
    );
    let totalRequeridoDecimal = bigDecimalFormat(totalRequerido);
    let totalRequeridoConParidad = totalRequeridoDecimal.times(paridadDecimal);
    setAnticipoPesosMexicanos(
      totalRequeridoConParidad.toFixed(parseFloat(monedaDecimalesSat))
    );
  }

  const createInputTotalPesosMexicanos = () => {
    let inputTPM = (
      <span>
        <Form.Label> Total pesos mexicanos</Form.Label>
        <InputGroup size="sm">
          <InputGroup.Prepend>
            <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
          </InputGroup.Prepend>
          <NumberFormat
            id="totalPesosMexicanos"
            className="input-signs-align form-control form-control-sm"
            thousandSeparator={true}
            disabled
            value={importePesosMexicanos}
          ></NumberFormat>
        </InputGroup>
      </span>
    );
    return inputTPM;
  };

  const createInputAnticipoPesosMexicanos = () => {
    let inputTPM = (
      <span>
        <Form.Label> Anticipo/Total pesos mexicanos</Form.Label>
        <InputGroup size="sm">
          <InputGroup.Prepend>
            <InputGroup.Text className="input-signs-left">$</InputGroup.Text>
          </InputGroup.Prepend>
          <NumberFormat
            id="anticipoPesosMexicanos"
            className="input-signs-align form-control form-control-sm"
            thousandSeparator={true}
            disabled
            value={anticipoPesosMexicanos}
          ></NumberFormat>
        </InputGroup>
      </span>
    );
    return inputTPM;
  };

  function cargarSelectMonedas() {
    return listMonedas.length
      ? listMonedas.map((e) => {
          return (
            <option
              key={e.clave}
              value={e.clave + "," + e.nombre + "," + e.decimales}
            >
              {[e.clave, e.nombre, "Precisión", e.decimales].join(" - ")}
            </option>
          );
        })
      : "No existen monedas registradas";
  }

  function cargarSelectFormaPago() {
    return listFormaPago.length
      ? listFormaPago.map((e) => {
          return (
            <option key={e.clave} value={e.clave + "," + e.nombre}>
              {[e.clave, e.nombre].join(" - ")}
            </option>
          );
        })
      : "No existen formas de pago registradas";
  }
  function cargando() {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden"></span>
        </Spinner>
      </div>
    );
  }
  function openCompartirReq(e) {
    e.preventDefault();
    //setContactos([]);
    setModalNotificarPagoShow(true);
  }
  function onContactosSelected(contactosEntrantes, comentario) {
 
      let aux = [];
        aux.push(contactosEntrantes);
        setContactos(contactosEntrantes);
        
  }
  function crearRequisicion(e) {
    e.preventDefault();
    setStatus(true);
    setIsLoading(true);
    let importeDecimal = bigDecimalFormat(importe); //Se convierte a bigDecimal
    let importeDecimalFloat = importeDecimal.toFixed(
      parseFloat(monedaDecimalesSat)
    );
    let errors = 0;

    if (!concepto) {
      toast.error("Se requiere el Concepto General", {
        position: "top-center",
        autoClose: 4500,
      });
      setStatus(false);
      errors++;
    }
    if (!pagadorName) {
      toast.error("Se requiere seleccionar pagador", {
        position: "top-center",
        autoClose: 4500,
      });
      setStatus(false);
      errors++;
    }
    
    if (concepto.length > 199) {
      toast.error("El concepto no puede tener más de 200 caracteres ", {
        position: "top-center",
        autoClose: 4500,
      });
      errors++;
    }

    if (importe === "0" || importe.length === 0) {
      toast.error("Se requiere ingresar un importe mayor a 0", {
        position: "top-center",
        autoClose: 4500,
      });
      errors++;
    }
    if(contieneEmail(contactos,pagadorEmail)){
      toast.error("No se puede compartir la requisición con el pagador", {
        position: "top-center",
        autoClose: 4500,
      });
      errors++;
    }

    if (errors > 0) {
      setIsLoading(false);
      return false;
    }
    console.log(pagadorName)
    let pagadoresAux= ""
    if(pagadorName==="0"){
      console.log("Pagador")
      pagadores.map((p,index)=>{
      if(index!=0){
        pagadoresAux+=","
      }
        pagadoresAux+=p.email
       
      })
  
    }
    const requisicion = {
      solicitante: solicitante,
      idSolicitante: idSolicitante,
      empresa: empresa,
      idEmpresa: idEmpresa,
      fechaRequeridaPago: fechaRequerida,
      concepto: concepto,
      monedaClaveSat: monedaClaveSat,
      monedaSat: monedaSat,
      monedaDecimalesSat: parseFloat(monedaDecimalesSat),
      paridad: parseFloat(paridad),
      metodoPagoClaveSat: metodoPagoClaveSat,
      metodoPagoSat: metodoPagoSat,
      formaPagoClaveSat: formaPagoClaveSat,
      formaPagoSat: formaPagoSat,
      decimalesRequisicion: parseFloat(decimalesRequisicion),
      importe: parseFloat(importeDecimalFloat),
      ivaTrasladadoTasaCuota: parseFloat(ivaTrasladadoTasaCuota),
      ivaTrasladadoImporte: parseFloat(ivaTrasladadoImporte),
      ivaRetenidoTasaCuota: parseFloat(ivaRetenidoTasaCuota),
      ivaRetenidoImporte: parseFloat(ivaRetenidoImporte),
      isrTasaCuota: parseFloat(isrTasaCuota),
      isrImporte: parseFloat(isrImporte),
      otrosImpuestosImporte: parseFloat(otrosImpuestosImporte),
      descuentoPorcentaje:
        opcionDescuento === "porcentaje"
          ? parseFloat(descuentoPorcentaje)
          : parseFloat(descuentoCantidad),
      descuentoImporte:
        opcionDescuento === "porcentaje"
          ? parseFloat(descuentoImporte)
          : parseFloat(porcentaje),
      subtotal: parseFloat(subtotal),
      subtotalConDescuento: parseFloat(subtotalConDescuento),
      total: parseFloat(total),
      importePesosMexicanos: parseFloat(importePesosMexicanos),
      proveedor: proveedor,
      idProveedor: idProveedor,
      proveedorRfc: proveedorRfc,
      porcentajeTotal: parseFloat(porcentajeTotal),
      totalRequerido: parseFloat(totalRequerido),
      anticipoPesosMexicanos: parseFloat(anticipoPesosMexicanos),
      banco: banco,
      cuentaClabe: cuentaClabe,
      cuentaBanco: cuentaBanco,
      referencia: referencia,
      objetoImpuesto: taxesChecked,
      pagadorId: pagadorName,
      pagadorEmail: pagadorName!=="0"? pagadorEmail:pagadoresAux,
      notificarPagador: notificarPagador,
      compartidos: contactos ?JSON.stringify(contactos): []
    };
   console.log( requisicion)
    axios
      .post(API_PROVEEDORES_URL + "saveRequisicion", {
        solicitante: requisicion.solicitante,
        idSolicitante: requisicion.idSolicitante,
        empresa: requisicion.empresa,
        idEmpresa: requisicion.idEmpresa,
        fechaRequeridaPago: requisicion.fechaRequeridaPago,
        concepto: requisicion.concepto,
        monedaClaveSat: requisicion.monedaClaveSat,
        monedaSat: requisicion.monedaSat,
        monedaDecimalesSat: requisicion.monedaDecimalesSat,
        paridad: requisicion.paridad,
        metodoPagoClaveSat: requisicion.metodoPagoClaveSat,
        metodoPagoSat: requisicion.metodoPagoSat,
        formaPagoClaveSat: requisicion.formaPagoClaveSat,
        formaPagoSat: requisicion.formaPagoSat,
        decimalesRequisicion: requisicion.decimalesRequisicion,
        importe: requisicion.importe,
        ivaTrasladadoTasaCuota: requisicion.ivaTrasladadoTasaCuota,
        ivaTrasladadoImporte: requisicion.ivaTrasladadoImporte,
        ivaRetenidoTasaCuota: requisicion.ivaRetenidoTasaCuota,
        ivaRetenidoImporte: requisicion.ivaRetenidoImporte,
        isrTasaCuota: requisicion.isrTasaCuota,
        isrImporte: requisicion.isrImporte,
        otrosImpuestosImporte: requisicion.otrosImpuestosImporte,
        descuentoPorcentaje: requisicion.descuentoPorcentaje,
        descuentoImporte: requisicion.descuentoImporte,
        subtotal: requisicion.subtotal,
        subtotalConDescuento: requisicion.subtotalConDescuento,
        total: requisicion.total,
        importePesosMexicanos: requisicion.importePesosMexicanos,
        proveedor: requisicion.proveedor,
        idProveedor: requisicion.idProveedor,
        proveedorRfc: requisicion.proveedorRfc,
        porcentajeTotal: requisicion.porcentajeTotal,
        totalRequerido: requisicion.totalRequerido,
        anticipoPesosMexicanos: requisicion.anticipoPesosMexicanos,
        banco: requisicion.banco,
        cuentaClabe: requisicion.cuentaClabe,
        referencia: requisicion.referencia,
        objetoImpuesto: requisicion.objetoImpuesto,
        cuentaBanco: requisicion.cuentaBanco,
        pagadorId: pagadorName,
        pagadorEmail: pagadorName!=="0"? pagadorEmail:pagadoresAux,
        notificarPagador: notificarPagador,
        compartidos: contactos ?JSON.stringify(contactos): []
      })
      .then((res) => {
        if (res.data.result.code === 200) {
          setStatus(false);
          toast.success(res.data.result.message, {
            position: "top-center",
            autoClose: 4500,
          });
          toast.success(
            "Podrá encontrarla en el listado de requisiciones por autorizar",
            {
              position: "top-center",
              autoClose: 4500,
            }
          );

          setTimeout(() => {
            setIsLoading(false);
            //window.location.href = "/requisiciones/pendientes";
            props.onHide();
          }, "2000");

       
        } else {
          setStatus(false);
          toast.error(res.data.result.message, {
            position: "top-center",
            autoClose: 4500,
          });
          toast.error(res.data.result.suggestion, {
            position: "top-center",
            autoClose: 4500,
          });
        }
      })
      .catch((error) => {
        setStatus(false);
        toast.error("No se ha podido crear la requisición", {
          position: "top-center",
          autoClose: 4500,
        });
      });
  }
  function contieneEmail(arreglo, email) {
    return arreglo.find(objeto => objeto.usuarioEmail === email) !== undefined;
}

  return (
    <section className="text-center">
      <Modal
        show={props.show}
        //onHide={props.onHide}
        size="xl"
        centered
        id="modalCrearRequisicion"
        scrollable={true}
      >
        <Modal.Header
          className="modal-header-crear-req"
          closeButton
          onHide={props.onHide}
        >
          <p>
            <FileEarmarkPlus size={22} color="#5B4B51" /> 2.Crear requisición
          </p>
        </Modal.Header>
        {!isLoading ? (
          <Modal.Body className="modal-portal-body">
            {modalNotificarPagoShow ? (
              <ModalNotificarPago
                show={modalNotificarPagoShow}
                onHide={modalConfirmarClose}
                onContactosSelected={onContactosSelected}
              >
                {idEmpresa}
                {empresa}
                {true}
                {contactos}
                {pagadorEmail}
                {solicitante}
              </ModalNotificarPago>
            ) : (
              ""
            )}
            <Container className="container-modals">
              <Form.Row>
                <Form.Group as={Col} md={12} lg={6}>
                  <fieldset>
                    <legend className="legend-crear-req">Proveedor</legend>
                    <Form.Row as={Col}>
                      <Form.Group as={Col}>
                        <Form.Label>Nombre proveedor</Form.Label>
                        <Form.Control
                          size="sm"
                          type="text"
                          disabled
                          defaultValue={props.children.nombreComercial}
                        ></Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row as={Col}>
                      <Form.Group as={Col}>
                        <Form.Label>% Anticipo/Total</Form.Label>
                        <InputGroup size="sm">
                          <NumberFormat
                            id="anticipoPorcentaje"
                            className="input-signs-align form-control form-control-sm"
                            thousandSeparator={true}
                            required
                            placeholder="Ingrese porcentaje de anticipo"
                            value={porcentajeTotal}
                            onChange={handlePorcentajeTotalChange}
                          ></NumberFormat>
                          <InputGroup.Append>
                            <InputGroup.Text className="input-signs-right">
                              %
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Anticipo/Total</Form.Label>
                        <InputGroup size="sm">
                          <InputGroup.Prepend>
                            <InputGroup.Text className="input-signs-left">
                              $
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <NumberFormat
                            id="totalRequerido"
                            className="input-signs-align form-control form-control-sm"
                            thousandSeparator={true}
                            disabled
                            value={totalRequerido}
                          ></NumberFormat>
                        </InputGroup>
                        {monedaClaveSat !== "MXN"
                          ? createInputAnticipoPesosMexicanos()
                          : ""}
                      </Form.Group>
                    </Form.Row>
                    <Form.Row as={Col}>
                      <Form.Group as={Col} md={12} lg={6}>
                        <Form.Label>Referencia</Form.Label>
                        <Form.Control
                          size="sm"
                          type="text"
                          required
                          placeholder="Ingrese referencia"
                          value={referencia}
                          onChange={handleReferenciaChange}
                        ></Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <SeleccionarBanco onBancoSelected={bancoSelected}>
                      {idProveedor}
                      {banco}
                    </SeleccionarBanco>
                  </fieldset>
                  <fieldset>
                    <legend className="legend-crear-req">
                      Cotización (archivos adjuntos)
                    </legend>
                    <Form.Row as={Col}>
                      <Form.Group as={Col}>
                        <div className="div-crear-req">
                          <p>
                            Después de haber guardado una requisición, podrás
                            subir archivos en el listado de requisiciones por
                            autorizar: Acción "Editar"
                          </p>
                          <p>
                            Archivos soportados: jpg, jpeg, png, pdf, txt, xls,
                            xlsx, zip
                          </p>
                        </div>
                      </Form.Group>
                    </Form.Row>
                  </fieldset>

                  <Form.Row as={Col}>
                    <Form.Group as={Col}>
                      <legend className="legend-crear-req-not">Notificaciones</legend>
                      
                     {/*< <div style={{margin: "0px 0px 10px 0px"}}>
                        <Form.Check
                          type="checkbox"
                          label="Notificar a usuario seleccionado"
                          checked={notificarPagador}
                          onChange={handleNotificarPagador}
                        />
                      </div>*/}
                      
                      <Form.Label>Usuario pagador</Form.Label>
                      <Form.Control
                        size="sm"
                        id="pagador"
                        type="text"
                        as="select"
                        required
                        placeholder="Seleccione pagador"
                        onChange={handlePagador}
                      >
                        <option default value="">
                          Seleccione pagador
                        </option>
                        <option  value={["0","pendiente"].join("-")}>
                         Todos (provisional)
                        </option>
                        {pagadores.map((pagador, index) => (
                          <option
                            key={index}
                            value={[pagador.userId, pagador.email].join("-")}
                          >
                            {pagador.username}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row as={Col}>
                    <Form.Group>
                      <button
                        className="m-1 button-default-subir-archivo"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Guardar requisición"
                        onClick={openCompartirReq}
                        disabled={!pagadorEmail}
                      >
                        {contactos.length>0?(<span>Editar el compartir</span>):<span> Compartir Requisición</span>}
                      </button>
                    </Form.Group>
                   {contactos.length>0 ?(
                    <Form.Group>
                          <legend className="legend-crear-req">Se compartirá con los siguientes usuarios:</legend>
                   <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th><b>Nombre de usuario</b></th>
                          <th><b></b>Email</th>
                        </tr>
                      </thead>
                      <tbody> {contactos.map(elemento => (
              <tr key={elemento.usuarioId}>
              <td>{elemento.usuarioNombre}</td>
              <td>{elemento.usuarioEmail}</td>
             
            </tr>
          ))}</tbody>
                    </Table></Form.Group>):"" } 
                  </Form.Row>
                </Form.Group>

                <Form.Group as={Col} md={12} lg={6}>
                  <fieldset>
                    <legend className="legend-crear-req">Requisición</legend>
                    <Form.Row as={Col}>
                      <Form.Group as={Col}>
                        <Form.Label>Solicitante</Form.Label>
                        <Form.Control
                          size="sm"
                          type="text"
                          disabled
                          value={solicitante}
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Fecha de creación</Form.Label>
                        <Form.Control
                          size="sm"
                          type="text"
                          disabled
                          defaultValue={formFechaActual}
                        ></Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row as={Col}>
                      <SeleccionarEmpresa onEmpresaSelected={empresaSelected}>
                        {empresa + "," + idEmpresa}
                      </SeleccionarEmpresa>
                      <Form.Group as={Col} className={classes.picker}>
                        <ThemeProvider theme={defaultMaterialTheme}>
                          <MuiPickersUtilsProvider
                            theme={defaultMaterialTheme}
                            libInstance={Moment}
                            utils={MomentUtils}
                          >
                            <KeyboardDatePicker
                              id="fechaRequerida"
                              label="Fecha requerida de pago (dd/mm/yyyy)"
                              autoOk={true}
                              variant="inline"
                              format="DD/MM/yyyy"
                              value={fechaRequerida}
                              inputValue={inputValue}
                              onChange={handleFechaRequeridaChange}
                            ></KeyboardDatePicker>
                          </MuiPickersUtilsProvider>
                        </ThemeProvider>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row as={Col}>
                      <Form.Group as={Col}>
                        <Form.Label>Concepto general</Form.Label>
                        <Form.Control
                          size="sm"
                          as="textarea"
                          rows={3}
                          required
                          placeholder="Ingrese concepto general"
                          value={concepto}
                          onChange={handleConceptoChange}
                        ></Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <Accordion.Toggle
                      style={{ cursor: "pointer" }}
                      onClick={handleCheckboxChange}
                      as={Card.Header}
                      eventKey="1"
                      className="legend-crear-prov"
                    >
                     Impuestos
                      {!taxesChecked ? <ExpandMore /> : <ExpandLess />}
                    </Accordion.Toggle>
                    <Form.Row as={Col}>
                      <Form.Group as={Col}>
                        <Form.Check
                          hidden={true}
                          type="checkbox"
                          checked={taxesChecked}
                          onChange={handleCheckboxChange}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row as={Col}>
                      <Form.Group as={Col} lg={4}>
                        <Form.Label>Moneda</Form.Label>
                        <Form.Control
                          size="sm"
                          id="moneda"
                          type="text"
                          as="select"
                          required
                          placeholder="Seleccione moneda"
                          value={moneda}
                          onChange={handleMonedaChange}
                        >
                          <option value={"NA,NA,2"}>Seleccione moneda</option>
                          {cargarSelectMonedas()}
                        </Form.Control>
                        <Form.Label hidden={!showParidad}>Paridad</Form.Label>
                        <InputGroup hidden={!showParidad} size="sm">
                          <InputGroup.Prepend>
                            <InputGroup.Text className="input-signs-left">
                              $
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            size="sm"
                            id="paridad"
                            type="number"
                            className="input-signs-align"
                            required
                            value={paridad}
                            onChange={handleParidadChange}
                          ></Form.Control>
                        </InputGroup>

                        <Form.Label hidden={!taxesChecked}>
                          Método de pago
                        </Form.Label>
                        <Form.Control
                          size="sm"
                          hidden={!taxesChecked}
                          id="metodoPago"
                          type="text"
                          as="select"
                          required
                          defaultValue={metodoPago}
                          onChange={handleMetodoPagoChange}
                        >
                          <option default>Seleccione método de pago</option>
                          <option value="PUE,Pago en una sola exhibición">
                            PUE - Pago en una sola exhibición
                          </option>
                          <option value="PPD,Pago en parcialidades o diferido">
                            PPD - Pago en parcialidades o diferido
                          </option>
                        </Form.Control>
                        <Form.Label hidden={!taxesChecked}>
                          Forma de pago
                        </Form.Label>
                        <Form.Control
                          hidden={!taxesChecked}
                          size="sm"
                          id="formaPago"
                          type="text"
                          as="select"
                          required
                          placeholder="Seleccione forma de pago"
                          value={formaPago}
                          onChange={handleFormaPagoChange}
                        >
                          <option default>Seleccione forma de pago</option>
                          {cargarSelectFormaPago()}
                        </Form.Control>
                        {/*<Form.Label>Precisión de los cálculos</Form.Label>
                                                <Form.Control size="sm" id="decimalesRequisicion" as="select" required placeholder="Seleccione precisión" value={precisionCalculos} onChange={handlePrecisionCalculos}>
                                                    <option value="0">Seleccione precisión</option>
                                                    <option value="2">Indicada por la moneda</option>
                                                    <option value="4">4 decimales</option>
                                                    <option value="6">6 decimales</option>
                                                </Form.Control>*/}
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Importe</Form.Label>
                        <InputGroup size="sm">
                          <InputGroup.Prepend>
                            <InputGroup.Text className="input-signs-left">
                              $
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <NumberFormat
                            id="importe"
                            className="input-signs-align form-control form-control-sm"
                            required
                            placeholder="Ingrese importe"
                            thousandSeparator={true}
                            value={importe}
                            onChange={handleImporteChange}
                          ></NumberFormat>
                        </InputGroup>
                        <Form.Label hidden={!taxesChecked}>
                          IVA Trasladado
                        </Form.Label>
                        <Form.Row hidden={!taxesChecked}>
                          <Col>
                            <Form.Control
                              size="sm"
                              id="ivaTrasladado"
                              type="text"
                              as="select"
                              required
                              placeholder="Seleccione porcentaje"
                              value={ivaTrasladadoTasaCuota}
                              onChange={handleIvaTrasladadoChange}
                            >
                              <option value="0">Seleccione porcentaje</option>
                              <option value="0.16">16 %</option>
                              <option value="0.0">0 %</option>
                              <option value="-1.0">Excento</option>
                              <option value="-2.0">No aplica</option>
                            </Form.Control>
                          </Col>
                          <Col hidden={!taxesChecked}>
                            <InputGroup size="sm">
                              <InputGroup.Prepend>
                                <InputGroup.Text className="input-signs-left">
                                  $
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <NumberFormat
                                id="ivaTrasladadoImporte"
                                className="input-signs-align form-control form-control-sm"
                                disabled
                                thousandSeparator={true}
                                value={ivaTrasladadoImporte}
                              ></NumberFormat>
                            </InputGroup>
                          </Col>
                        </Form.Row>
                        <Form.Label hidden={!taxesChecked}>
                          IVA Retenido
                        </Form.Label>
                        <Form.Row hidden={!taxesChecked}>
                          <Col>
                            <InputGroup size="sm">
                              <NumberFormat
                                id="ivaRetenido"
                                className="input-signs-align form-control form-control-sm"
                                thousandSeparator={true}
                                value={ivaRetenido}
                                onChange={handleIvaRetenidoChange}
                              ></NumberFormat>
                              <InputGroup.Append>
                                <InputGroup.Text className="input-signs-right">
                                  %
                                </InputGroup.Text>
                              </InputGroup.Append>
                            </InputGroup>
                          </Col>
                          <Col>
                            <InputGroup size="sm">
                              <InputGroup.Prepend>
                                <InputGroup.Text className="input-signs-left">
                                  $
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <NumberFormat
                                id="ivaRetenidoImporte"
                                className="input-signs-align form-control form-control-sm"
                                thousandSeparator={true}
                                disabled
                                value={ivaRetenidoImporte}
                              ></NumberFormat>
                            </InputGroup>
                          </Col>
                        </Form.Row>
                        <Form.Text hidden={!taxesChecked} muted>
                          Debe ser entre 0.0 y 16.0
                        </Form.Text>
                        <Form.Label hidden={!taxesChecked}>
                          Porcentaje ISR
                        </Form.Label>
                        <Form.Row hidden={!taxesChecked}>
                          <Col>
                            <InputGroup size="sm">
                              <NumberFormat
                                id="isr"
                                className="input-signs-align form-control form-control-sm"
                                thousandSeparator={true}
                                value={isr}
                                onChange={handleIsrChange}
                              ></NumberFormat>
                              <InputGroup.Append>
                                <InputGroup.Text className="input-signs-right">
                                  %
                                </InputGroup.Text>
                              </InputGroup.Append>
                            </InputGroup>
                          </Col>
                          <Col>
                            <InputGroup size="sm">
                              <InputGroup.Prepend>
                                <InputGroup.Text className="input-signs-left">
                                  $
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <NumberFormat
                                id="isrImporte"
                                className="input-signs-align form-control form-control-sm"
                                thousandSeparator={true}
                                disabled
                                value={isrImporte}
                              ></NumberFormat>
                            </InputGroup>
                          </Col>
                        </Form.Row>
                        <Form.Text hidden={!taxesChecked} muted>
                          Debe ser entre 0.0 y 35.0
                        </Form.Text>
                        <Form.Label hidden={!taxesChecked}>
                          Más otros impuestos
                        </Form.Label>
                        <Form.Row hidden={!taxesChecked}>
                          <Col>
                            <InputGroup size="sm">
                              <InputGroup.Append>
                                <InputGroup.Text className="input-signs-left">
                                  $
                                </InputGroup.Text>
                              </InputGroup.Append>
                              <NumberFormat
                                id="otrosImpuestos"
                                className="input-signs-align form-control form-control-sm"
                                thousandSeparator={true}
                                value={otrosImpuestos}
                                onChange={handleOtrosImpuestosChange}
                              ></NumberFormat>
                            </InputGroup>
                          </Col>
                          <Col>
                            <InputGroup size="sm">
                              <InputGroup.Prepend>
                                <InputGroup.Text className="input-signs-left">
                                  $
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <NumberFormat
                                id="otrosImpuestosImporte"
                                className="input-signs-align form-control form-control-sm"
                                thousandSeparator={true}
                                disabled
                                value={otrosImpuestosImporte}
                              ></NumberFormat>
                            </InputGroup>
                          </Col>
                        </Form.Row>
                        <Form.Text muted hidden={!taxesChecked}>
                          Estos impuestos se sumarán al total
                        </Form.Text>
                        <Form.Label hidden={!taxesChecked}>
                          Descuento
                        </Form.Label>
                        <div hidden={!taxesChecked} className="mb-3">
                          <Form.Check
                            inline
                            type="radio"
                            label="Porcentaje %"
                            name="porcentaje"
                            id="porcentaje"
                            onChange={handleDescuentoOpcionPorcentajeChange}
                          />
                          <Form.Check
                            inline
                            type="radio"
                            label="Cantidad $"
                            name="cantidad"
                            id="cantidad"
                            onChange={handleDescuentoOpcionCantidadChange}
                          />
                        </div>
                        {opcionesDescuento()}
                        <Form.Label hidden={!taxesChecked}>Subtotal</Form.Label>
                        <InputGroup hidden={!taxesChecked} size="sm">
                          <InputGroup.Prepend>
                            <InputGroup.Text className="input-signs-left">
                              $
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <NumberFormat
                            id="subtotal"
                            className="input-signs-align form-control form-control-sm"
                            disabled
                            thousandSeparator={true}
                            value={subtotal}
                          />
                        </InputGroup>
                        <Form.Label hidden={!taxesChecked}>
                          Subtotal con descuento
                        </Form.Label>
                        <InputGroup hidden={!taxesChecked} size="sm">
                          <InputGroup.Prepend>
                            <InputGroup.Text className="input-signs-left">
                              $
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <NumberFormat
                            id="subtotalConDescuento"
                            className="input-signs-align form-control form-control-sm"
                            disabled
                            thousandSeparator={true}
                            value={subtotalConDescuento}
                          ></NumberFormat>
                        </InputGroup>
                        <Form.Label hidden={!taxesChecked}>
                          Total con impuestos
                        </Form.Label>
                        <InputGroup hidden={!taxesChecked} size="sm">
                          <InputGroup.Prepend>
                            <InputGroup.Text className="input-signs-left">
                              $
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <NumberFormat
                            id="totalConImpuestos"
                            className="input-signs-align form-control form-control-sm"
                            thousandSeparator={true}
                            disabled
                            value={total}
                          ></NumberFormat>
                        </InputGroup>
                        {monedaClaveSat !== "MXN"
                          ? createInputTotalPesosMexicanos()
                          : ""}
                      </Form.Group>
                    </Form.Row>
                  </fieldset>
                </Form.Group>
              </Form.Row>
            </Container>
          </Modal.Body>
        ) : (
          <Form.Row as={Col}>{cargando()}</Form.Row>
        )}
        <Modal.Footer className="justify-content-end">
          <button
            hidden={isLoading}
            className="m-1 button-default-confirmar"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Guardar requisición"
            onClick={crearRequisicion}
          >
            {status ? <span>Enviando...</span> : <span>Guardar</span>}
          </button>
          <button
            hidden={isLoading}
            className="m-1 button-default-cancelar"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Cancelar"
            onClick={props.onHide}
          >
            <span>Cancelar</span>
          </button>
        </Modal.Footer>
      </Modal>
    </section>
  );
}

export default ModalCrearRequisicion;
