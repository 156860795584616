const monedasSAT = [
    { clave: "MXN", nombre: "Peso Mexicano", decimales: 2 },
    { clave: "USD", nombre: "Dolar americano", decimales: 2 },
    { clave: "EUR", nombre: "Euro", decimales: 2 },
    { clave: "JPY", nombre: "Yen", decimales: 0 },
    { clave: "GBP", nombre: "Libra Esterlina", decimales: 2 },
    { clave: "CAD", nombre: "Dolar Canadiense", decimales: 2 },
    { clave: "AED", nombre: "Dirham de EAU", decimales: 2 },
    { clave: "AFN", nombre: "Afghani", decimales: 2 },
    { clave: "ALL", nombre: "Lek", decimales: 2 },
    { clave: "AMD", nombre: "Dram armenio", decimales: 2 },
    { clave: "ANG", nombre: "Florín antillano neerlandés", decimales: 2 },
    { clave: "AOA", nombre: "Kwanza", decimales: 2 },
    { clave: "ARS", nombre: "Peso Argentino", decimales: 2 },
    { clave: "AUD", nombre: "Dólar Australiano", decimales: 2 },
    { clave: "AWG", nombre: "Aruba Florin", decimales: 2 },
    { clave: "AZN", nombre: "Azerbaijanian Manat", decimales: 2 },
    { clave: "BAM", nombre: "Convertibles marca", decimales: 2 },
    { clave: "BBD", nombre: "Dólar de Barbados", decimales: 2 },
    { clave: "BDT", nombre: "Taka", decimales: 2 },
    { clave: "BGN", nombre: "Lev búlgaro", decimales: 2 },
    { clave: "BHD", nombre: "Dinar de Bahrein", decimales: 3 },
    { clave: "BIF", nombre: "Burundi Franc", decimales: 0 },
    { clave: "BMD", nombre: "Dólar de Bermudas", decimales: 2 },
    { clave: "BND", nombre: "Dólar de Brunei", decimales: 2 },
    { clave: "BOB", nombre: "Boliviano", decimales: 2 },
    { clave: "BOV", nombre: "Mvdol", decimales: 2 },
    { clave: "BRL", nombre: "Real brasileño", decimales: 2 },
    { clave: "BSD", nombre: "Dólar de las Bahamas", decimales: 2 },
    { clave: "BTN", nombre: "Ngultrum", decimales: 2 },
    { clave: "BWP", nombre: "Pula", decimales: 2 },
    { clave: "BYR", nombre: "Rublo bielorruso", decimales: 0 },
    { clave: "BZD", nombre: "Dólar de Belice", decimales: 2 },
    { clave: "CDF", nombre: "Franco congoleño", decimales: 2 },
    { clave: "CHE", nombre: "WIR Euro", decimales: 2 },
    { clave: "CHF", nombre: "Franco Suizo", decimales: 2 },
    { clave: "CHW", nombre: "Franc WIR", decimales: 2 },
    { clave: "CLF", nombre: "Unidad de Fomento", decimales: 4 },
    { clave: "CLP", nombre: "Peso chileno", decimales: 0 },
    { clave: "CNY", nombre: "Yuan Renminbi", decimales: 2 },
    { clave: "COP", nombre: "Peso Colombiano", decimales: 2 },
    { clave: "COU", nombre: "Unidad de Valor real", decimales: 2 },
    { clave: "CRC", nombre: "Colón costarricense", decimales: 2 },
    { clave: "CUC", nombre: "Peso Convertible", decimales: 2 },
    { clave: "CUP", nombre: "Peso Cubano", decimales: 2 },
    { clave: "CVE", nombre: "Cabo Verde Escudo", decimales: 2 },
    { clave: "CZK", nombre: "Corona checa", decimales: 2 },
    { clave: "DJF", nombre: "Franco de Djibouti", decimales: 0 },
    { clave: "DKK", nombre: "Corona danesa", decimales: 2 },
    { clave: "DOP", nombre: "Peso Dominicano", decimales: 2 },
    { clave: "DZD", nombre: "Dinar argelino", decimales: 2 },
    { clave: "EGP", nombre: "Libra egipcia", decimales: 2 },
    { clave: "ERN", nombre: "Nakfa", decimales: 2 },
    { clave: "ETB", nombre: "Birr etíope", decimales: 2 },
    { clave: "FJD", nombre: "Dólar de Fiji", decimales: 2 },
    { clave: "FKP", nombre: "Libra malvinense", decimales: 2 },
    { clave: "GEL", nombre: "Lari", decimales: 2 },
    { clave: "GHS", nombre: "Cedi de Ghana", decimales: 2 },
    { clave: "GIP", nombre: "Libra de Gibraltar", decimales: 2 },
    { clave: "GMD", nombre: "Dalasi", decimales: 2 },
    { clave: "GNF", nombre: "Franco guineano", decimales: 0 },
    { clave: "GTQ", nombre: "Quetzal", decimales: 2 },
    { clave: "GYD", nombre: "Dólar guyanés", decimales: 2 },
    { clave: "HKD", nombre: "Dolar De Hong Kong", decimales: 2 },
    { clave: "HNL", nombre: "Lempira", decimales: 2 },
    { clave: "HRK", nombre: "Kuna", decimales: 2 },
    { clave: "HTG", nombre: "Gourde", decimales: 2 },
    { clave: "HUF", nombre: "Florín", decimales: 2 },
    { clave: "IDR", nombre: "Rupia", decimales: 2 },
    { clave: "ILS", nombre: "Nuevo Shekel Israelí", decimales: 2 },
    { clave: "INR", nombre: "Rupia india", decimales: 2 },
    { clave: "IQD", nombre: "Dinar iraquí", decimales: 3 },
    { clave: "IRR", nombre: "Rial iraní", decimales: 2 },
    { clave: "ISK", nombre: "Corona islandesa", decimales: 0 },
    { clave: "JMD", nombre: "Dólar Jamaiquino", decimales: 2 },
    { clave: "JOD", nombre: "Dinar jordano", decimales: 3 },
    { clave: "KES", nombre: "Chelín keniano", decimales: 2 },
    { clave: "KGS", nombre: "Som", decimales: 2 },
    { clave: "KHR", nombre: "Riel", decimales: 2 },
    { clave: "KMF", nombre: "Franco Comoro", decimales: 0 },
    { clave: "KPW", nombre: "Corea del Norte ganó", decimales: 2 },
    { clave: "KRW", nombre: "Won", decimales: 0 },
    { clave: "KWD", nombre: "Dinar kuwaití", decimales: 3 },
    { clave: "KYD", nombre: "Dólar de las Islas Caimán", decimales: 2 },
    { clave: "KZT", nombre: "Tenge", decimales: 2 },
    { clave: "LAK", nombre: "Kip", decimales: 2 },
    { clave: "LBP", nombre: "Libra libanesa", decimales: 2 },
    { clave: "LKR", nombre: "Rupia de Sri Lanka", decimales: 2 },
    { clave: "LRD", nombre: "Dólar liberiano", decimales: 2 },
    { clave: "LSL", nombre: "Loti", decimales: 2 },
    { clave: "LYD", nombre: "Dinar libio", decimales: 3 },
    { clave: "MAD", nombre: "Dirham marroquí", decimales: 2 },
    { clave: "MDL", nombre: "Leu moldavo", decimales: 2 },
    { clave: "MGA", nombre: "Ariary malgache", decimales: 2 },
    { clave: "MKD", nombre: "Denar", decimales: 2 },
    { clave: "MMK", nombre: "Kyat", decimales: 2 },
    { clave: "MNT", nombre: "Tugrik", decimales: 2 },
    { clave: "MOP", nombre: "Pataca", decimales: 2 },
    { clave: "MRO", nombre: "Ouguiya", decimales: 2 },
    { clave: "MUR", nombre: "Rupia de Mauricio", decimales: 2 },
    { clave: "MVR", nombre: "Rupia", decimales: 2 },
    { clave: "MWK", nombre: "Kwacha", decimales: 2 },
    { clave: "MXV", nombre: "México Unidad de Inversión (UDI)", decimales: 2 },
    { clave: "MYR", nombre: "Ringgit malayo", decimales: 2 },
    { clave: "MZN", nombre: "Mozambique Metical", decimales: 2 },
    { clave: "NAD", nombre: "Dólar de Namibia", decimales: 2 },
    { clave: "NGN", nombre: "Naira", decimales: 2 },
    { clave: "NIO", nombre: "Córdoba Oro", decimales: 2 },
    { clave: "NOK", nombre: "Corona noruega", decimales: 2 },
    { clave: "NPR", nombre: "Rupia nepalí", decimales: 2 },
    { clave: "NZD", nombre: "Dólar de Nueva Zelanda", decimales: 2 },
    { clave: "OMR", nombre: "Rial omaní", decimales: 3 },
    { clave: "PAB", nombre: "Balboa", decimales: 2 },
    { clave: "PEN", nombre: "Nuevo Sol", decimales: 2 },
    { clave: "PGK", nombre: "Kina", decimales: 2 },
    { clave: "PHP", nombre: "Peso filipino", decimales: 2 },
    { clave: "PKR", nombre: "Rupia de Pakistán", decimales: 2 },
    { clave: "PLN", nombre: "Zloty", decimales: 2 },
    { clave: "PYG", nombre: "Guaraní", decimales: 0 },
    { clave: "QAR", nombre: "Qatar Rial", decimales: 2 },
    { clave: "RON", nombre: "Leu rumano", decimales: 2 },
    { clave: "RSD", nombre: "Dinar serbio", decimales: 2 },
    { clave: "RUB", nombre: "Rublo ruso", decimales: 2 },
    { clave: "RWF", nombre: "Franco ruandés", decimales: 0 },
    { clave: "SAR", nombre: "Riyal saudí", decimales: 2 },
    { clave: "SBD", nombre: "Dólar de las Islas Salomón", decimales: 2 },
    { clave: "SCR", nombre: "Rupia de Seychelles", decimales: 2 },
    { clave: "SDG", nombre: "Libra sudanesa", decimales: 2 },
    { clave: "SEK", nombre: "Corona sueca", decimales: 2 },
    { clave: "SGD", nombre: "Dolar De Singapur", decimales: 2 },
    { clave: "SHP", nombre: "Libra de Santa Helena", decimales: 2 },
    { clave: "SLL", nombre: "Leona", decimales: 2 },
    { clave: "SOS", nombre: "Chelín somalí", decimales: 2 },
    { clave: "SRD", nombre: "Dólar de Suriname", decimales: 2 },
    { clave: "SSP", nombre: "Libra sudanesa Sur", decimales: 2 },
    { clave: "STD", nombre: "Dobra", decimales: 2 },
    { clave: "SVC", nombre: "Colon El Salvador", decimales: 2 },
    { clave: "SYP", nombre: "Libra Siria", decimales: 2 },
    { clave: "SZL", nombre: "Lilangeni", decimales: 2 },
    { clave: "THB", nombre: "Baht", decimales: 2 },
    { clave: "TJS", nombre: "Somoni", decimales: 2 },
    { clave: "TMT", nombre: "Turkmenistán nuevo manat", decimales: 2 },
    { clave: "TND", nombre: "Dinar tunecino", decimales: 3 },
    { clave: "TOP", nombre: "Pa'anga", decimales: 2 },
    { clave: "TRY", nombre: "Lira turca", decimales: 2 },
    { clave: "TTD", nombre: "Dólar de Trinidad y Tobago", decimales: 2 },
    { clave: "TWD", nombre: "Nuevo dólar de Taiwán", decimales: 2 },
    { clave: "TZS", nombre: "Shilling tanzano", decimales: 2 },
    { clave: "UAH", nombre: "Hryvnia", decimales: 2 },
    { clave: "UGX", nombre: "Shilling de Uganda", decimales: 0 },
    { clave: "USN", nombre: "Dólar estadounidense (día siguiente)", decimales: 2 },
    { clave: "UYI", nombre: "Peso Uruguay en Unidades Indexadas (URUIURUI)", decimales: 0 },
    { clave: "UYU", nombre: "Peso Uruguayo", decimales: 2 },
    { clave: "UZS", nombre: "Uzbekistán Sum", decimales: 2 },
    { clave: "VEF", nombre: "Bolívar", decimales: 2 },
    { clave: "VND", nombre: "Dong", decimales: 0 },
    { clave: "VUV", nombre: "Vatu", decimales: 0 },
    { clave: "WST", nombre: "Tala", decimales: 2 },
    { clave: "XAF", nombre: "Franco CFA BEAC", decimales: 0 },
    { clave: "XAG", nombre: "Plata", decimales: 0 },
    { clave: "XAU", nombre: "Oro", decimales: 0 },
    { clave: "XBA", nombre: "Unidad de Mercados de Bonos Unidad Europea Composite (EURCO)", decimales: 0 },
    { clave: "XBB", nombre: "Unidad Monetaria de Bonos de Mercados Unidad Europea (UEM-6)", decimales: 0 },
    { clave: "XBC", nombre: "Mercados de Bonos Unidad Europea unidad de cuenta a 9 (UCE-9)", decimales: 0 },
    { clave: "XBD", nombre: "Mercados de Bonos Unidad Europea unidad de cuenta a 17 (UCE-17)", decimales: 0 },
    { clave: "XCD", nombre: "Dólar del Caribe Oriental", decimales: 2 },
    { clave: "XDR", nombre: "DEG (Derechos Especiales de Giro)", decimales: 0 },
    { clave: "XOF", nombre: "Franco CFA BCEAO", decimales: 0 },
    { clave: "XPD", nombre: "Paladio", decimales: 0 },
    { clave: "XPF", nombre: "Franco CFP", decimales: 0 },
    { clave: "XPT", nombre: "Platino", decimales: 0 },
    { clave: "XSU", nombre: "Sucre", decimales: 0 },
    { clave: "XTS", nombre: "Códigos reservados específicamente para propósitos de prueba", decimales: 0 },
    { clave: "XUA", nombre: "Unidad ADB de Cuenta", decimales: 0 },
    { clave: "XXX", nombre: "Los códigos asignados para las transacciones en que intervenga ninguna moneda", decimales: 0 },
    { clave: "YER", nombre: "Rial yemení", decimales: 2 },
    { clave: "ZAR", nombre: "Rand", decimales: 2 },
    { clave: "ZMW", nombre: "Kwacha zambiano", decimales: 2 },
    { clave: "ZWL", nombre: "Zimbabwe Dólar", decimales: 2 }
];

export { monedasSAT };