import React, { useState, useEffect } from "react";
import axios from 'axios'
import { useSelector } from 'react-redux';
import { API_PROVEEDORES_URL } from '../../constants';

//Estilos
import './css/Pagos.css'

//Elementos bootstrap
import { Col, Form, Card, Tabs, Tab, Badge } from 'react-bootstrap';

//Componentes
import NumberFormat from 'react-number-format';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import cellEditFactory from 'react-bootstrap-table2-editor';
import SelectInputFile from './SelectInputFile'
import Loader from "../Loader";

function PagoMultiple() {
    const token = JSON.parse(localStorage.getItem('token'));
    const empresasDisponibles = useSelector(state => state.empresas.empresasDisponibles);
    let empresas = empresasDisponibles.map(e => { return e.empresa; });
    const empresaDefault = empresas[0];
    const [key, setKey] = useState(empresaDefault);
    const [error, setError] = useState(false);
    const [status, setStatus] = useState('');
    const [cargando, setCargando] = useState(false);
    const [listaPagosPendientes, setListaPagosPendientes] = useState([]);
    const [updateListado, setUpdateListado] = useState(false);

    let updateGetListado = () => { setUpdateListado(!!!updateListado); setListaPagosPendientes([]); }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getPagosPendientes, [updateListado]);

    function getPagosPendientes() {
        setCargando(true);
        let user = JSON.parse(localStorage.getItem('token'));
        axios.post(API_PROVEEDORES_URL + 'getPagosPendientes', { empresas: empresas }
        ).then((res) => {
            if (res.data.result.code === 200) {
                user.userType === 'SCHEMA_ADMIN'? filterDataToMaster(res.data.pagos) : 
                filterDataToUser(res.data.pagos);

                setListaPagosPendientes(res.data.pagos);
            } else {
                setError(true);
                setStatus(res.data.result.message);
            }
        }).catch((error) => {
            setError(true);
            setStatus("Error: No se han podido recuperar los pagos pendientes");
        }).finally(() => {
            setCargando(false);
        });
    }
    function filterDataToUser(empresas){ 
        empresas.map((empresa)=>{
         empresa.lista = empresa.lista.filter(function(objeto) {
            return ((objeto.solicitante ===token.username)||objeto.usuarioCompartido ===token.username||objeto.pagadorId ===token.userId );
         });
         })
         return empresas;
     }
 
     function filterDataToMaster(empresas){
         empresas.map((empresa)=>{
        
             empresa.lista = empresa.lista.filter(function(objeto) {
                 
                 return (objeto.usuarioCompartido !==token.username );
             });
             })
             return empresas;
 
     }

    function createTabs() {
        let empresasTabs;
        listaPagosPendientes.length ?
            empresasTabs = listaPagosPendientes.map(empresa => (
                <Tab key={empresa.id} eventKey={empresa.id} title={empresa.nombreEmpresa}>
                    <ListadoPagoMultiple onUpdate={updateGetListado}>{empresa.lista}</ListadoPagoMultiple>
                </Tab>
            )) : empresasTabs = empresas.map(empresa => (
                <Tab key={empresa} eventKey={empresa} title={empresa}>
                    <section id="listaPagosPendientes">
                        <Card id="cardPagoMultiple">
                            <Card.Body>
                                {error ? status : 'Error: No se ha podido cargar la información'}
                            </Card.Body>
                        </Card>
                    </section>
                </Tab>
            ))
        return empresasTabs;
    }

    if(cargando) {
        return <Loader />
    }

    return (
        <div >
            <Tabs id="controlled-tab-pagos" activeKey={key} onSelect={(k) => setKey(k)}>
                {createTabs()}
            </Tabs>
        </div >
    );
}

function ListadoPagoMultiple(props) {
    const token = JSON.parse(localStorage.getItem('token'));
    const [request] = useState(false);
    const [error] = useState(false);
    const [listaPagosPendientes] = useState(eliminarObjetosCompartidos(props.children));
function eliminarObjetosCompartidos(arreglo) {
        arreglo = arreglo.filter(function(objeto) {
        
            return !(objeto.esCompartida === 1 && (objeto.usuarioCompartido !== token.username && objeto.usuarioCompartido !== ""));
        });
        return arreglo;
    }
    let updateGetListado = () => { props.onUpdate() };

    
    let mostrarListaPagosPendientes = () => {
        if (error) {
            return (
                <div>Error: No se han podido recuperar los pagos pendientes</div>
            );
        } else {
            const columns = [
                {
                    dataField: 'id',
                    text: 'ID',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true,
                    footer: '',
                }, {
                    dataField: 'proveedor',
                    text: 'Proveedor',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true,
                    editable: false,
                    footer: '',
                }, {
                    dataField: 'concepto',
                    text: 'Concepto',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true,
                    editable: false,
                    footer: '',
                }, {
                    dataField: 'monedaClaveSat',
                    text: 'Moneda',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true,
                    editable: false,
                    footer: 'Totales:',
                }, {
                    dataField: 'saldoPendiente',
                    text: 'Saldo pendiente',
                    sort: true,
                    editable: false,
                    footer: columnData => {
                        let sumatoria = columnData.reduce((acc, item) => acc + item, 0).toFixed(2)
                        return (<h5><Badge variant="success">
                            <NumberFormat displayType={'text'} thousandSeparator={true} value={sumatoria} />
                        </Badge></h5>)
                    },
                    formatter: (cellContent, row) => {
                        return (
                            <NumberFormat
                                displayType={'text'}
                                thousandSeparator={true}
                                value={row.saldoPendiente.toFixed(2/*row.monedaDecimalesSat*/)}
                            ></NumberFormat>
                        )
                    }
                }, {
                    dataField: 'diasVencimiento',
                    text: 'Días de vencimiento',
                    sort: true,
                    editable: false,
                    formatter: (cellContent, row) => {
                        return(
                            row.diasVencimiento >= 10 ?
                                <h6><Badge variant="success">{row.diasVencimiento}</Badge></h6>
                            :
                                row.diasVencimiento <= 9 && row.diasVencimiento >= 4 ?
                                    <h6><Badge variant="warning">{row.diasVencimiento}</Badge></h6>
                            :
                                row.diasVencimiento <= 3 && row.diasVencimiento >= 1 ?
                                    <h6><Badge className="vencimientoNaranja">{row.diasVencimiento}</Badge></h6>
                            :
                                row.diasVencimiento <= 0 && row.diasVencimiento >= -5 ?
                                    <h6><Badge variant="danger">{row.diasVencimiento}</Badge></h6>
                            :
                                row.diasVencimiento <= -6 ?
                                <h6><Badge className="vencimientoMorado">{row.diasVencimiento}</Badge></h6>
                            :
                                row.diasVencimiento 
                        )
                    }
                }, {
                    dataField: 'archivos',
                    text: 'Clic para subir pago',
                    isDummyField: true,
                    editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
                        <SelectInputFile onUpdate={updateGetListado}>{row}</SelectInputFile>
                    )
                },
            ];


            function indication() {
                return listaPagosPendientes.length ? "La búsqueda no coincide con ningún criterio" : "No hay registros que mostrar";
            }

            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size}
                </span>
            );

            const options = {
                custom: true,
                sizePerPage: 10,
                totalSize: listaPagosPendientes.length,
                paginationTotalRenderer: customTotal
            }

            return <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div>
                        <Form.Row className="p-2">
                            <div className="d-flex flex-row">
                                <Form.Label column>Mostrar</Form.Label>
                                <Col>
                                    {/* <Form.Control className="form-control-font-size" size="sm" id="sizePerPage" as="select" onChange={(e) => handleSizePerPage(paginationProps, e.target.value)}>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value={listaPagosPendientes.length}>Todos</option>
                                    </Form.Control> */}
                                    <SizePerPageDropdownStandalone {...paginationProps} btnContextual />
                                </Col>
                            </div>
                            <div className="d-flex flex-row">
                                <Col>
                                    <PaginationTotalStandalone {...paginationProps} />
                                </Col>
                            </div>
                            <div className="d-flex ml-auto flex-row-reverse">
                                <Col>
                                    <PaginationListStandalone {...paginationProps} />
                                </Col>
                                <Form.Label column>Página</Form.Label>
                            </div>
                        </Form.Row>
                        <div className="table-responsive table-sm">
                            <BootstrapTable
                                keyField='id'
                                data={listaPagosPendientes}
                                columns={columns}
                                cellEdit={cellEditFactory({
                                    mode: 'click',
                                    blurToSave: true
                                })}
                                filter={filterFactory()}
                                headerClasses="header-class-pago"
                                bordered={false}
                                striped
                                hover
                                condensed
                                noDataIndication={indication}
                                {...paginationTableProps}
                            />
                        </div>
                    </div>
                )}
            </PaginationProvider>
        }

    }
    return (
        <section id="pagoMultiple" className="text-center">
            <Card id="cardPagoMultiple">
                <Card.Body>
                    {request ? "Cargando..." : mostrarListaPagosPendientes()}
                </Card.Body>
            </Card>
        </section>
    );
}
export default PagoMultiple;