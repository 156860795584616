import React, { useState } from "react";
import axios from 'axios';
import { API_PROVEEDORES_URL } from '../../../constants';

//Estilos
import "../../Requisiciones/css/Requisiciones.css"
import "../css/CuentasBancarias.css"
import { CreditCard2Front } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';

//Componentes bootstrap
import { Container, Modal, Form, Col } from "react-bootstrap";

//Componentes
import NumberFormat from 'react-number-format';

function ModalAgregarCuenta(props) {
    const [status, setStatus] = useState(false);
    const [banco, setBanco] = useState('');
    const [comentario, setComentario] = useState('');
    const [cuenta, setCuenta] = useState('');
    const [cuentaClabe, setCuentaClabe] = useState('');
    const [referencia, setReferencia] = useState('');

    function handleBancoChange(e) {
        setBanco(e.target.value);
    }
    function handleComentarioChange(e) {
        setComentario(e.target.value);
    }
    function handleCuentaChange(e) {
        setCuenta(e.target.value);
    }
    function handleCuentaClabeChange(e) {
        setCuentaClabe(e.target.value);
    }
    function handleReferenciaChange(e) {
        setReferencia(e.target.value);
    }

    function insertCuenta() {
        setStatus(true);
        const cuentaBancaria = {
            idProveedor: props.children,
            banco: banco,
            comentario: comentario,
            cuenta: cuenta,
            cuentaClabe: cuentaClabe,
            referencia: referencia
        }
        axios.post(API_PROVEEDORES_URL + 'insertCuenta', {
            idProveedor: cuentaBancaria.idProveedor,
            banco: cuentaBancaria.banco,
            cuenta: cuentaBancaria.cuenta,
            cuentaClabe: cuentaBancaria.cuentaClabe,
            comentario: cuentaBancaria.comentario,
            referencia: cuentaBancaria.referencia
        }).then((res) => {
            if (res.data.result.success) {
                setStatus(false);
                toast.success(res.data.result.message,{
                    position:"top-center",
                    autoClose: 4500, 
                });
                props.onHide();//Cierra el modal, llamando a la función padre para cerrarlo
                props.onUpdate();//Actualiza el listado, llamando a la función padre para actualizarlo                
            } else {
                setStatus(false);
                toast.error(res.data.result.message,{
                    position:"top-center",
                    autoClose: 4500, 
                });
                toast.error(res.data.result.suggestion,{
                    position:"top-center",
                    autoClose: 4500, 
                });
            }
        }).catch((error) => {
            toast.error("No se ha podido registrar el proveedor y cuenta bancaria",{
                position:"top-center",
                autoClose: 4500, 
            });
            setStatus(false);
        });
    }

    return (
        <section className="text-center">
            <Modal
                show={props.show}
                //onHide={props.onHide}
                size="md"
                aria-labelledby="example-modal-sizes-title-vcenter"
                id="modalNuevaCuenta"
                centered
            >
                <Modal.Header className="modal-header-editar-cuenta" closeButton onHide={props.onHide}>
                    <h5 className="legend-agregar-cuenta"><CreditCard2Front size={22} color="#5B4B51" />   Agregar cuenta bancaria</h5>
                </Modal.Header>
                <Container>
                    <Modal.Body className="modal-body-cuenta">
                        <Form>
                            <fieldset >
                                <legend className="legend-agregar-cuenta">Cuenta bancaria</legend>
                                <Form.Row as={Col}>
                                    <Form.Group as={Col}>
                                        <Form.Label>Banco</Form.Label>
                                        <Form.Control type="text" required
                                            placeholder="Ingrese banco"
                                            className="form-control form-control-sm"
                                            onChange={handleBancoChange}>
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row as={Col}>
                                    <Form.Group as={Col}>
                                        <Form.Label>Comentario</Form.Label>
                                        <Form.Control type="text" required
                                            placeholder="Ingrese notas adicionales"
                                            className="form-control form-control-sm"
                                            onChange={handleComentarioChange}>
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row as={Col}>
                                    <Form.Group as={Col}>
                                        <Form.Label>Cuenta</Form.Label>
                                        <NumberFormat
                                            format="#### #### #### #### #### ####"
                                            mask="_"
                                            className="form-control form-control-sm"
                                            placeholder="Ingrese número de cuenta"
                                            onChange={handleCuentaChange}
                                        />
                                        <Form.Text muted>Hasta 24 dígitos </Form.Text>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row as={Col}>
                                    <Form.Group as={Col}>
                                        <Form.Label>Cuenta CLABE</Form.Label>
                                        <NumberFormat
                                            format="#### #### #### #### ##"
                                            mask="_"
                                            className="form-control form-control-sm"
                                            placeholder="Ingrese cuenta clabe"
                                            onChange={handleCuentaClabeChange}
                                        />
                                        <Form.Text muted>18 dígitos </Form.Text>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row as={Col}>
                                    <Form.Group as={Col}>
                                        <Form.Label>Referencia</Form.Label>
                                        <Form.Control
                                            type="text" required
                                            placeholder="Ingrese referencia"
                                            className="form-control form-control-sm"
                                            onChange={handleReferenciaChange}
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>
                            </fieldset>
                        </Form>
                    </Modal.Body>
                </Container>
                <Modal.Footer className="justify-content-end">
                    <button
                        className="m-1 button-default-subir-archivo"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Guardar"
                        onClick={insertCuenta}
                    >{status ?
                        <span>Guardando...</span>
                        : <span>Guardar</span>}
                    </button>
                    <button
                        className="m-1 button-default-cancelar"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Cancelar"
                        onClick={props.onHide}
                    ><span>Cancelar</span>
                    </button>
                </Modal.Footer>
            </Modal>
        </section>
    );
}

export default ModalAgregarCuenta;