import React from "react";
//Estilos
import '../SoporteTecnico/css/SoporteTecnico.css'
import { Eye } from 'react-bootstrap-icons';

//Componentes bootstrap
import { Container, Col, Form, Modal } from "react-bootstrap";
import Moment from 'moment';

function ModalConsultarSolicitud(props) {
    return (
        <section className="text-center">
            <Modal
                {...props}
                size="md"
                id="modalConsultarSolicitud"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className="modal-header-consultar-solicitud" closeButton>
                    <h5 className="legend-consultar-solicitud"><Eye size={22} color="#5B4B51" />   Consultar solicitud</h5>
                </Modal.Header>
                <Modal.Body className="modal-body-solicitud">
                    <Container>
                        <Form.Row >
                            <Form.Group as={Col}>
                                <Form.Label>Empresa</Form.Label>
                                <Form.Control type="text" className="form-control form-control-sm"
                                    disabled value={props.children.empresa}
                                    placeholder="No hay empresa registrada"
                                ></Form.Control>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Solicitante</Form.Label>
                                <Form.Control type="text" className="form-control form-control-sm"
                                    disabled value={props.children.solicitante}
                                ></Form.Control>
                            </Form.Group>
                        </Form.Row >
                        <Form.Row >
                            <Form.Group as={Col}>
                                <Form.Label>Fecha</Form.Label>
                                <Form.Control type="text" className="form-control form-control-sm"
                                    disabled value={Moment(props.children.fecha).format("DD/MM/YYYY")}
                                    placeholder="No hay empresa registrada"
                                ></Form.Control>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Correo Electrónico</Form.Label>
                                <Form.Control type="text" className="form-control form-control-sm"
                                    disabled value={props.children.correoElectronico}
                                ></Form.Control>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row >
                            <Form.Group as={Col}>
                                <Form.Label>Teléfono</Form.Label>
                                <Form.Control type="text" className="form-control form-control-sm"
                                    disabled value={props.children.telefono}
                                    placeholder="No hay empresa registrada"
                                ></Form.Control>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Módulo</Form.Label>
                                <Form.Control type="text" className="form-control form-control-sm"
                                    disabled value={props.children.modulo}
                                ></Form.Control>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row >
                            <Form.Group as={Col}>
                                <Form.Label>Motivo</Form.Label>
                                <Form.Control type="text" className="form-control form-control-sm"
                                    disabled value={props.children.motivo}
                                    placeholder="No hay empresa registrada"
                                ></Form.Control>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Estatus</Form.Label>
                                <Form.Control type="text" className=" form-control-sm"
                                    disabled value={props.children.estatus === 'R' ? "Resuelto" : 'Pendiente'}
                                ></Form.Control>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row >
                            <Form.Group as={Col} md={12} lg={12}>
                                <Form.Label className="labelSoporte">Descripción del problema</Form.Label>
                                <textarea id="descripcion" className="md-textarea form-control"
                                    rows="6" placeholder="Describa el problema que presenta el sistema"
                                    size="sm" disabled value={props.children.descripcion}
                                    required
                                ></textarea>
                            </Form.Group>
                        </Form.Row>
                    </Container>
                </Modal.Body>
            </Modal>

        </section>
    )
}

export default ModalConsultarSolicitud;