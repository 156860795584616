import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import axios from 'axios'
import { API_PROVEEDORES_URL } from '../../constants'

//Elementos bootstrap
import { Col, Card, Form, Tabs, Tab } from "react-bootstrap";
//Estilos
import './Dashboard.css';
import 'react-calendar/dist/Calendar.css';

//Componentes
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import NumberFormat from 'react-number-format';
import Moment from 'moment';
import NuevosProveedores from './NuevosProveedores';
//import SaldosLiquidar from './SaldosLiquidar';
import DeudasAntiguas from './DeudasAntiguas';
import { set } from "date-fns";

function Dashboard() {
  const empresasDisponibles = useSelector(state => state.empresas.empresasDisponibles);
  const empresas = empresasDisponibles.map(e => { return e.empresa; });
  const empresaDefault = empresas[0];

  const [error, setError] = useState(false);
  const [status, setStatus] = useState('');
  const [errorStatus, setErrorStatus] = useState('');
  const [cargando, setCargando] = useState(false);
  const [key, setKey] = useState(empresaDefault);
  const [listProveedoresVentas, setListProveedoresVentas] = useState([]);
  const [updateDashboard, setUpdateDashboard] = useState(false);

  let updateGetDashboard = () => { setUpdateDashboard(!!!updateDashboard); listProveedoresVentas([]); }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getProveedoresMasVentas, [updateDashboard]);

  function getProveedoresMasVentas() {
    setCargando(true);

    axios.post(API_PROVEEDORES_URL + 'getVentasProveedores',
      {
        empresas: empresas,
      }
    ).then((res) => {
      if (res.data.result.code === 200) {
        setListProveedoresVentas(res.data.vwPagos);
      } else {
        setError(true);
        setErrorStatus(res.data.result.message);
      }
    }).catch((error) => {
      setError(true);
      setErrorStatus("Error: No se han podido recuperar las ventas de los proveedores");
    }).finally(() => {
      setCargando(false);
    })
  }

  function createTabs() {
    let empresasTabs;

    listProveedoresVentas.length ?
      empresasTabs = listProveedoresVentas.map(empresa => (
        <Tab key={empresa.id} eventKey={empresa.id} title={empresa.nombreEmpresa}>
          {<section id="dasboard">
            <div>
              <Form.Row as={Col}>
                <Form.Group as={Col} sm={12} md={12} lg={12}>
                  <br></br>
                  <div className="proveedores-con-mas-ventas-tit">Principales proveedores</div>
                  <ProveedoresVentas onUpdate={updateGetDashboard}>{empresa.lista}{empresas}{errorStatus}</ProveedoresVentas>
                </Form.Group>
              </Form.Row>
              <Form.Row as={Col}>
                <Form.Group className="cardNuevosProveedores" as={Col} sm={12} md={12} lg={9}>
                  <div className="nuevos-proveedores-tit">Nuevos proveedores</div>
                  <NuevosProveedores onUpdate={updateGetDashboard}>{empresa.nombreEmpresa}</NuevosProveedores>
                </Form.Group>
                <Form.Group className="cardDeudasViejas" as={Col} sm={12} md={6} lg={3}>
                  <div className="deudas-mas-viejas-titulo">Deudas más antiguas</div>
                  <DeudasAntiguas>{empresa.nombreEmpresa}</DeudasAntiguas>
                </Form.Group>
              </Form.Row>
            </div>
          </section>}
        </Tab>
      )) : empresasTabs = empresas.map(empresa => (
        <Tab key={empresa} eventKey={empresa} title={empresa}>
          <section id="dashboard">
            <Card id="cardProveedores">
              <Card.Body className="cardBodyProveedores">
                {error ? status : 'Error: No se ha podido cargar la informassssción'}
              </Card.Body>
            </Card>
          </section>
        </Tab>
      ));

    return empresasTabs;
  }

  if (cargando) {
    return (
      <section>
        <div>
          <Form.Row as={Col}>
            <Form.Group as={Col} sm={12} md={12} lg={12}>
              <br></br>
              <div className="bg-preload proveedores-con-mas-ventas-tit">
                <div className="load-skeleton"></div>
              </div>
              <div className="bg-preload content-box">
                <div className="load-skeleton"></div>
              </div>
            </Form.Group>
          </Form.Row>
          <Form.Row as={Col}>
            <Form.Group className="" as={Col} sm={12} md={12} lg={9}>
              <div className="bg-preload nuevos-proveedores-tit">
                <div className="load-skeleton"></div>
              </div>
              <div className="bg-preload content-box"></div>
            </Form.Group>
            <Form.Group className="" as={Col} sm={12} md={6} lg={3}>
              <div className="bg-preload deudas-mas-viejas-titulo">
                <div className="load-skeleton"></div>
              </div>
              <div className="bg-preload content-box">
                <div className="load-skeleton"></div>
              </div>
            </Form.Group>
          </Form.Row>
        </div>
      </section>
    )
  }

  return (
    <Tabs className="tabDashboard" id="controlled-tab-dashboard" activeKey={key} onSelect={(k) => setKey(k)}>
      {createTabs()}
    </Tabs>

  );
}

function ProveedoresVentas(props) {
  const [error] = useState(false);
  const [request] = useState(false);
  const [errorStatus] = useState(props.children[2]);
  const [listProveedoresVentas] = useState(props.children[0]);


  let mostrarProveedoresVentas = () => {
    if (error) {
      return (
        <div>{errorStatus}</div>
      );
    } else {
      const columns = [
        {
          id: 'proveedor',
          dataField: 'Proveedor',
          text: '',
          isDummyField: true,
          formatter: (cellContent, row) => {
            return (
              <div className="font-title-proveedor-ventas">
                <div >{'Proveedor'}</div>
              </div>
            )
          },
        },
        {
          dataField: 'rowOne',
          text: '',
          isDummyField: true,
          formatter: (cellContent, row) => {
            return (
              <div className="d-flex justify-content-around prov-ventas-tbody-tr-td-div">
                <div className="prov-ventas">
                  <div className="title-prov-ventas">{row.proveedor}</div>
                </div>

              </div>
            )
          }
        },
        {
          dataField: 'rowTwo',
          text: '',
          isDummyField: true,
          formatter: (cellContent, row) => {
            return (
              <div className="d-flex justify-content-around prov-ventas-tbody-tr-td-div">
                <div className="prov-ventas-tbody-tr-td-div">
                  <div className="font-col-prov-ventas">{'ID: '}</div>
                </div>
                <div className="prov-ventas-tbody-tr-td-div">
                  <div >{"#" + row.id}</div>
                </div>
              </div>
            )
          }
        },
        {
          dataField: 'rowthree',
          text: '',
          isDummyField: true,
          formatter: (cellContent, row) => {
            return (
              <div className="d-flex justify-content-around prov-ventas-tbody-tr-td-div">
                <div className="prov-ventas-tbody-tr-td-div">
                  <div className="font-col-prov-ventas">{'Empresa: '}</div>
                </div>
                <div className="prov-ventas-tbody-tr-td-div">
                  <div>{row.empresa}</div>
                </div>
              </div>
            )
          }
        },
        {
          dataField: 'rowFour',
          text: '',
          isDummyField: true,
          formatter: (cellContent, row) => {
            return (
              <div className="d-flex justify-content-around prov-ventas-tbody-tr-td-div">
                <div className="prov-ventas-tbody-tr-td-div">
                  <div className="font-col-prov-ventas">{'Venta: '}</div>
                </div>
                <div className="prov-ventas-tbody-tr-td-div">
                  <div>
                    <NumberFormat
                      displayType={'text'} prefix={'$'}
                      thousandSeparator={true}
                      value={row.total.toFixed(2)}
                    ></NumberFormat>
                  </div>
                </div>
              </div>
            )
          }
        }, {
          dataField: 'rowFive',
          text: '',
          isDummyField: true,
          formatter: (cellContent, row) => {
            return (
              <div className="d-flex justify-content-around prov-ventas-tbody-tr-td-div">
                <div className="prov-ventas-tbody-tr-td-div">
                  <div className="font-col-prov-ventas">{'Fecha: '}</div>
                </div>
                <div className="prov-ventas-tbody-tr-td-div">
                  <div>{Moment(row.fechaPago).format('DD/MM/YYYY') /**fechaRequeridaPago*/}</div>
                </div>
              </div>
            )
          }
        }
      ];

      function indication() {
        return listProveedoresVentas.length ? "La búsqueda no coincide con ningún criterio" : "No hay registros que mostrar";
      }

      const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
          Mostrando {from} - {to} de {size}
        </span>
      );

      const options = {
        custom: true,
        sizePerPage: 4,
        totalSize: listProveedoresVentas.length,
        paginationTotalRenderer: customTotal
      }

      return <PaginationProvider pagination={paginationFactory(options)}>
        {({ paginationProps, paginationTableProps }) => (
          <div className="fieldsetProveedorVentas">
            <div className="table-responsive table-sm">
              <BootstrapTable
                id="ventasProveedores"
                keyField='id'
                data={listProveedoresVentas}
                columns={columns}
                filter={filterFactory()}
                bordered={false}
                noDataIndication={indication}
                {...paginationTableProps}
              />
            </div>
          </div>
        )}
      </PaginationProvider >
    }
  }

  return (
    request ? "Cargando..." : mostrarProveedoresVentas()
    /*<section id="dasboard">
        <div>
          <Form.Row as={Col}>
            <Form.Group as={Col} sm={12} md={12} lg={12}>
              <div className="proveedores-con-mas-ventas-tit">Principales proveedores</div>
              {request ? "Cargando..." : mostrarProveedoresVentas()}
            </Form.Group>
          </Form.Row>
          <Form.Row as={Col}>
            <Form.Group className="cardNuevosProveedores" as={Col} sm={12} md={12} lg={9}>
            <div className="nuevos-proveedores-tit">Nuevos proveedores</div>
              <NuevosProveedores>{empresa}</NuevosProveedores>
            </Form.Group>
            <Form.Group className="cardDeudasViejas" as={Col} sm={12} md={6} lg={3}>
              <div className="deudas-mas-viejas-titulo">Deudas más antiguas</div>
                <DeudasAntiguas>{empresas}</DeudasAntiguas>
            </Form.Group>
          </Form.Row>
        </div>
        </section>*/
  );
}

export default Dashboard;