import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import axios from "axios";
import { API_PROVEEDORES_URL, KRAKEN_URL } from "../../constants";
//Estilos
import "./css/Requisiciones.css"
import { toast } from 'react-toastify';
//Elementos bootstrap
import { Form, Col, Card, Tabs, Tab, Badge } from "react-bootstrap";
//Componentes
import NumberFormat from 'react-number-format';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next';
import ModalAgregarUsuarioContacto from '../Configuracion/Empresa/Usuarios/UsuarioContactos/ModalAgregarUsuarioContacto';
import Loader from "../Loader";


function ModalAprobarRequisiciones(props) {
    const token = JSON.parse(localStorage.getItem('token'));
    const empresasDisponibles = useSelector(state => state.empresas.empresasDisponibles);
    let empresas = empresasDisponibles.map(e => { return e.empresa; });
    const empresaDefault = empresas[0];
    const [key, setKey] = useState(empresaDefault);
    const [error, setError] = useState(false);
    const [cargando, setCargando] = useState(false);
    const [errorStatus, setErrorStatus] = useState('');
    const [listaPendientes, setListaPendientes] = useState([]);
    const [updateListado, setUpdateListado] = useState(false);
    
    let updateGetListado = () => { setUpdateListado(!!!updateListado); setListaPendientes([]); }

    // La siguiente línea evita un warning que no compromete la ejecución
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    useEffect(getListadoReqPendientes, [updateListado]);//Se actualiza cada que se agrega nueva requisición*/    

    function getListadoReqPendientes() {
        let user = JSON.parse(localStorage.getItem('token'));
        setCargando(true);
        axios.post(API_PROVEEDORES_URL + 'getRequisicionesPendientes',
            {
                empresas: empresas
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                user.userType === 'SCHEMA_ADMIN'? filterDataToMaster(res.data.requisiciones) : 
                filterDataToUser(res.data.requisiciones);

                setListaPendientes(res.data.requisiciones);
            } else {
                setError(true);
                setListaPendientes('');
                setErrorStatus(res.data.result.message);
            }
        }).catch((error) => {
            setError(true);
            setErrorStatus("Error: No se han podido recuperar las requisiciones pendientes.")
        }).finally(() => {
            setCargando(false);
        })
    }
    function filterDataToUser(empresas){ 
        empresas.map((empresa)=>{
         empresa.lista = empresa.lista.filter(function(objeto) {
            return ((objeto.solicitante ===token.username)||objeto.usuarioCompartido ===token.username||objeto.pagadorId ===token.userId );
         });
         })
         return empresas;
     }
 
     function filterDataToMaster(empresas){
         empresas.map((empresa)=>{
        
             empresa.lista = empresa.lista.filter(function(objeto) {
                 
                 return (objeto.usuarioCompartido !==token.username );
             });
             })
             return empresas;
 
     }

    function createTabs() {
        let empresasTabs;
        listaPendientes.length ?
            empresasTabs = listaPendientes.map(empresa => (
                <Tab key={empresa.id} eventKey={empresa.id} title={empresa.nombreEmpresa}>
                    <ListadoAprobarRequisiciones onUpdate={updateGetListado}>{empresa.lista}{empresa.nombreEmpresa}</ListadoAprobarRequisiciones>
                </Tab>
            )) : empresasTabs = empresas.map(empresa => (
                <Tab key={empresa} eventKey={empresa} title={empresa}>
                    <section id="listaPagosPendientes">
                        <Card id="cardPagoMultiple">
                            <Card.Body>
                                {error ? errorStatus : 'Error: No se ha podido cargar la información'}
                            </Card.Body>
                        </Card>
                    </section>
                </Tab>
            ))
        return empresasTabs;
    }

    if (cargando) {
        return <Loader />
    }

    return (
        <div >
            <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
                {createTabs()}
            </Tabs>
        </div >
    );
}

function ListadoAprobarRequisiciones(props) {
    const [deactivate, setDeactivate] = useState(false);
    const token = JSON.parse(localStorage.getItem('token'));
    const userId = token.userId;
    const [request] = useState(false);
    const [listaPendientes] = useState(eliminarObjetosCompartidos(props.children[0]));
    const empresaAlias = props.children[1];
    const [listContactos, setListContactos] = useState([]);
    const [status, setStatus] = useState(false);
    const [statusError, setStatusError] = useState('');
    const [errorContactos, setErrorContactos] = useState(false);
    const errorRequisiciones = false;
    const [idReqSeleccionadas, setIdReqSeleccionadas] = useState([]);//Aquí se guardan sólo los id's de las requisiciones que se seleccionen
    const [reqSeleccionadas, setReqSeleccionadas] = useState([]);//Aquí se guardan sólo las requisiciones que se seleccionen
    const [seleccionados, setSeleccionados] = useState([]);//Aquí se guardan sólo los id's de los contactos que se seleccionen
    const [ctcSeleccionados, setCtcSeleccionados] = useState([]);//Aquí se guardan sólo los emails que se seleccionen
    const [updateListado, setUpdateListado] = useState(false);
    const [solicitante, setSolicitante] = useState("");
    const[notificarMas,setNotificarMas]=useState(false)
    const usuario = {
        userId: userId,
        username: token.username,
        empresaAlias: empresaAlias
    }
    function eliminarObjetosCompartidos(arreglo) {
        arreglo = arreglo.filter(function(objeto) {
        
            return !(objeto.esCompartida === 1 && (objeto.usuarioCompartido !== token.username && objeto.usuarioCompartido !== ""));
        });
        return arreglo;
    }
    const [modalUsuarioAgregarShow, setModalUsuarioAgregarShow] = useState(false);
    let modalUsuarioAgregarClose = () => setModalUsuarioAgregarShow(false);
    let updateGetListado = () => { setUpdateListado(!!!updateListado); }


  useEffect(getContactosRequisiciones, [updateListado, userId, empresaAlias]);

    function getContactosRequisiciones() {
        const ids = listaPendientes.map(row => row.id);
        const reqs = listaPendientes.map(row => row);

        
        setIdReqSeleccionadas(ids);
        setReqSeleccionadas(reqs);
        axios.post(KRAKEN_URL + 'empresa/usuario/contactos/list', {
            userId: userId,
            empresa: empresaAlias,
        }).then((res) => {
            if (res.data.result.code === 200 && res.data.list.length) {
                setListContactos(res.data.list);
                setSeleccionados([...seleccionados, res.data.list[0].contactoId]);
                setCtcSeleccionados([...ctcSeleccionados, res.data.list[0].emailContacto]);
            } else {
                setListContactos('');
                //setErrorContactos(true);
                setStatusError(res.data.result.message + " " + res.data.result.suggestion);
            }
        }).catch((error) => {
            setErrorContactos(true);
            setStatusError("Ocurrió un error, no se pudieron recuperar los contactos de este usuario");
        })
    }

    function handleNotificarMas(){
       setNotificarMas(!notificarMas)
     }
    async function getSolicitanteOnebyOne(idSolicitante) {
        return new Promise((resolve, reject) => {
          axios.post(KRAKEN_URL + 'empresa/usuario/solicitante/getSolicitante', {
            userId: idSolicitante
          }).then((res) => {
            console.log("aqui en res ");
            console.log(res);
            console.log(res.data.result.code)
            if (res.data.result.code === 200) {
              setSolicitante(res.data.list[0]);
              resolve(res.data.list[0]);
            } else {
              setSolicitante('');
              setStatusError(res.data.result.message);
              reject(res.data.result.message);
            }
          }).catch((error) => {
            console.log(error);
          
            setStatusError("Ocurrió un error, no se pudieron recuperar los contactos de este usuario");
            reject(error);
          });
        });
      }

    async function aprobarRequisiciones() {
        console.log(reqSeleccionadas)
        setStatus(true);
        let contador =0;
        console.log(reqSeleccionadas)
        let errores =""
        for (const req of reqSeleccionadas){
          
           
            try {
                let solicitanteRes = await getSolicitanteOnebyOne(req.idSolicitante);
                req.solicitante = solicitanteRes.email;
              } catch (error) {
                console.error(error);

                toast.error("Ha ocurrido un error en la recuperacion de información del solicitante",{
                    position:"top-center",
                    autoClose: 4500, 
                });
                toast.error(error,{
                    position:"top-center",
                    autoClose: 4500, 
                });
              }
              if(req.pagadorId==="0"){
                errores+=req.id+", "
            }
            }
           
           
        if (ctcSeleccionados.length&&errores.length===0) {
            console.log(reqSeleccionadas)
            await axios.post(API_PROVEEDORES_URL + 'aprobarRequisiciones',
                {
                    "to": notificarMas?ctcSeleccionados:[],
                    "requisiciones": reqSeleccionadas
                }
            ).then((res) => {
                if (res.data.mail.success) {
                    setStatus(false);
                    toast.success(res.data.mail.message,{
                        position:"top-center",
                        autoClose: 4500, 
                    });

                    toast.success("Podrá encontrarlas en el listado de requisiciones por pagar",{
                        position:"top-center",
                        autoClose: 4500, 
                    });
                
                    props.onUpdate();                
                } else {
                   
                    setStatus(false);
                    toast.warning(res.data.mail.message,{
                        position:"top-center",
                        autoClose: 4500, 
                    });
                    toast.warning(res.data.mail.suggestion,{
                        position:"top-center",
                        autoClose: 4500, 
                    });
                    props.onUpdate();             
      
                }
            }).catch((error) => {
                setStatus(false);
                toast.error("No se han podido aprobar las requisiciones",{
                    position:"top-center",
                    autoClose: 4500, 
                });
            })
        }else if(errores.length!==0){
            setDeactivate(true);

            setTimeout(() => {
                setDeactivate(false);
            }, 4500);

            toast.warning("Las requisiciones " +errores+ " no tienen pagador", {
                position:"top-center",
                autoClose: 4500, 
            });


            setStatus(false);
        }
        
        
        else {
            setDeactivate(true);

            setTimeout(() => {
                setDeactivate(false);
            }, 4500);

            toast.warning("Debes seleccionar al menos un contacto y una requisición", {
                position:"top-center",
                autoClose: 4500, 
            });


            setStatus(false);
        }
    }

    let mostrarListaPendientes = () => {
        if (errorRequisiciones) {
            return (
                <div>Error: No se han podido cargar las requisiciones pendientes</div>
            )
        } else {
            const columns = [
                {
                    dataField: 'empresa',
                    text: 'Empresa',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    footer: '',
                    sort: true
                }, {
                    dataField: 'id',
                    text: 'ID',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    footer: '',
                    sort: true
                }, {
                    dataField: 'concepto',
                    text: 'Concepto',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    footer: 'Suma total:',
                    sort: true
                }, {
                    dataField: 'total',
                    text: 'Total',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    formatter: (cellContent, row) => {
                        return (<NumberFormat displayType={'text'} thousandSeparator={true} value={row.total.toFixed(2)} />)
                    },
                    footer: sumaFooter,
                    sort: true
                }
            ];

            function sumaFooter(){
                let sumatoria = reqSeleccionadas.reduce((acc, item) => acc + item.total, 0).toFixed(2)
                return (<h5><Badge variant="success">
                            <NumberFormat displayType={'text'} thousandSeparator={true} value={sumatoria} />
                        </Badge></h5>)
            }

            const selectRow = {
                mode: 'checkbox',
                clickToSelect: true,
                style: { backgroundColor: '#6ee3af' },
                selected: idReqSeleccionadas,
                onSelect: (row, isSelect, rowIndex, e) => {
                    if (isSelect) {
                        console.log(idReqSeleccionadas)
                        console.log(reqSeleccionadas)
                        setIdReqSeleccionadas([...idReqSeleccionadas, row.id]);
                        setReqSeleccionadas([...reqSeleccionadas, row]);
                    } else {
                        let eliminarIdReqSeleccionada = idReqSeleccionadas.filter(x => x !== row.id);
                        setIdReqSeleccionadas(eliminarIdReqSeleccionada);
                        let eliminarReqSeleccionada = reqSeleccionadas.filter(x => x !== row);
                        setReqSeleccionadas(eliminarReqSeleccionada);
                    }
                },
                onSelectAll: (isSelect, rows, e) => {
                    const ids = listaPendientes.map(row => row.id);
                    const reqs = listaPendientes.map(row => row);
                    if (isSelect) {
                        setIdReqSeleccionadas(ids);
                        setReqSeleccionadas(reqs);
                    } else {
                        setIdReqSeleccionadas([]);
                        setReqSeleccionadas([]);
                    }
                },
            };
            function indication() {
                return listaPendientes.length ? "La búsqueda no coincide con ningún criterio" : "No hay registros que mostrar";
            }

            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size}
                </span>
            );

            const options = {
                custom: true,
                totalSize: listaPendientes.length,
                paginationTotalRenderer: customTotal
            }

            return <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div>
                        <Form.Row className="p-2">
                            <div className="d-flex flex-row">
                                <Form.Label column>Mostrar</Form.Label>
                                <Col>
                                    {/* <Form.Control size="sm" as="select" onChange={(e) => handleSizePerPage(paginationProps, e.target.value)}>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value={listaPendientes.length}>Todos</option>
                                    </Form.Control> */}
                                    <SizePerPageDropdownStandalone btnContextual {...paginationProps}/>
                                </Col>
                            </div>
                            <div className="d-flex flex-row">
                                <Col>
                                    <PaginationTotalStandalone {...paginationProps} />
                                </Col>
                            </div>
                            <div className="d-flex ml-auto flex-row-reverse">
                                <Col>
                                    <PaginationListStandalone {...paginationProps} />
                                </Col>
                                <Form.Label column>Página</Form.Label>
                            </div>
                        </Form.Row>
                        <div className="table-responsive table-sm">
                            <BootstrapTable
                                id="aprobarRequisiciones"
                                keyField='id'
                                data={listaPendientes}
                                columns={columns}
                                selectRow={selectRow}
                                filter={filterFactory()}
                                bordered={false}
                                striped
                                hover
                                condensed
                                noDataIndication={indication}
                                {...paginationTableProps}
                            />
                        </div>
                    </div>
                )}
            </PaginationProvider>
        }
    }

    let mostrarListaContactos = () => {
        if (errorContactos) {
            return (
                <div>{statusError}</div>
            )
        } else {
            const columns = [
                {
                    dataField: 'contacto',
                    text: 'Nombre de contacto',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                },
                {
                    dataField: 'emailContacto',
                    text: 'Email del contacto',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true
                }];

            const selectRow = {
                mode: 'checkbox',
                clickToSelect: true,
                style: { backgroundColor: 'rgb(155, 227, 230)' },
                selected: seleccionados,//renglones seleccionados de acuerdo al contactoId (keyField del BootstrapTable)
                onSelect: (row, isSelect, rowIndex, e) => {
                    if (isSelect) {
                        setSeleccionados([...seleccionados, row.contactoId]);
                        setCtcSeleccionados([...ctcSeleccionados, row.emailContacto]);
                    } else {
                        let eliminarSeleccionado = seleccionados.filter(x => x !== row.contactoId);
                        setSeleccionados(eliminarSeleccionado);
                        let eliminarContacto = ctcSeleccionados.filter(x => x !== row.emailContacto);
                        setCtcSeleccionados(eliminarContacto);
                    }
                },
                onSelectAll: (isSelect, rows, e) => {
                    const ids = rows.map(r => r.contactoId);
                    const emails = rows.map(r => r.emailContacto);
                    if (isSelect) {
                        setSeleccionados(ids);
                        setCtcSeleccionados(emails);
                    } else {
                        setSeleccionados([]);
                        setCtcSeleccionados([]);
                    }
                },
            }
         

            function indication() {
                return listContactos.length ? "La búsqueda no coincide con ningún criterio" : "No hay registros que mostrar";
            }

            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size}
                </span>
            );

            const options = {
                custom: true,
                sizePerPage: 10,
                totalSize: listContactos.length,
                paginationTotalRenderer: customTotal
            }
       

            return <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div>
                        <Form.Row className="d-flex justify-content-center">
                            <div className="d-flex ">
                                <button
                                    className="m-1 button-default-req"
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="Agregar contacto"
                                    onClick={() => { setModalUsuarioAgregarShow(true) }}
                                ><span>Agregar contacto</span>
                                </button>
                                {modalUsuarioAgregarShow ? <ModalAgregarUsuarioContacto show={modalUsuarioAgregarShow} onHide={modalUsuarioAgregarClose} onUpdate={updateGetListado}>{usuario}</ModalAgregarUsuarioContacto> : ''}
                            </div>
                        </Form.Row>
                        <Form.Row className="p-2">
                            <div className="d-flex flex-row">
                                <Form.Label column>Mostrar</Form.Label>
                                <Col>
                                    {/* <Form.Control size="sm" as="select" onChange={(e) => handleSizeContactsPerPage(paginationProps, e.target.value)}>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value={listContactos}>Todos</option>
                                    </Form.Control> */}
                                    <SizePerPageDropdownStandalone btnContextual {...paginationProps} />
                                </Col>
                            </div>
                            <div className="d-flex flex-row">
                                <Col>
                                    <PaginationTotalStandalone {...paginationProps} />
                                </Col>
                            </div>
                            <div className="d-flex ml-auto flex-row-reverse">
                                <Col>
                                    <PaginationListStandalone {...paginationProps} />
                                </Col>
                                <Form.Label column>Página</Form.Label>
                            </div>
                        </Form.Row>
                        <div className="table-responsive table-sm">
                            <BootstrapTable
                                id="listaContactos"
                                keyField='contactoId'
                                data={listContactos}
                                columns={columns}
                                selectRow={selectRow}
                                filter={filterFactory()}
                                bordered={false}
                                striped
                                hover
                                condensed
                                noDataIndication={indication}
                                {...paginationTableProps}
                            />
                        </div>
                    </div>
                )}
            </PaginationProvider>
        }
    }
    return (
        <section className="text-center">
            <Card id="cardAprobMultiple">
                <Card.Body>
                    <div className="p-2">
                        <output id="listResponse"></output>
                    </div>
                    <Form.Row>
                        <Form.Group as={Col} md={12} lg={notificarMas?6:12}>
                            <fieldset>
                                <legend className="legend-consultar-req">Selecciona al menos una requisición</legend>
        
                                {request ? "Cargando..." : mostrarListaPendientes()}
                            </fieldset>

                            <p className="legend-consultar-req-sm"><b>Nota:</b>Se notificará al respectivo solicitante automaticamente.</p>
                        </Form.Group>
                        {notificarMas?
                         <Form.Group as={Col} md={12} lg={6}>
                            <fieldset>
                                <legend className="legend-consultar-req">Seleccione al menos un contacto para enviar notificación</legend>
                                {request ? "Cargando..." : mostrarListaContactos()}
                            </fieldset>
                        </Form.Group>:"" }
                    </Form.Row>
                </Card.Body>
                <Card.Footer className="justify-content-center">
                {/*<Form.Check 
                    type="checkbox"
                    label="Notificar a mas contactos"
                    checked={notificarMas}
                    onChange={handleNotificarMas}/>*/}
                    <button
                        className="m-1 button-default-subir-archivo"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Confirmar"
                        disabled = { deactivate }
                        onClick={aprobarRequisiciones}
                    >{status ?
                        <span>Enviando...</span>
                        : <span>Confirmar</span>}
                    </button>
                </Card.Footer>
            </Card>
        </section>
    );
}

export default ModalAprobarRequisiciones;