import React, { useState, useEffect } from "react";
import axios from 'axios'
import { useSelector } from 'react-redux';
import { API_PROVEEDORES_URL } from '../../constants'

//Estilos
import './css/Requisiciones.css';
import { toast } from 'react-toastify';

//Componentes bootstrap
import { Row, Col, Form, Card, Tabs, Tab } from 'react-bootstrap';

//Componentes
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ModalCrearRequisicion from './ModalCrearRequisicion';
import ModalCrearProveedor from '../ProveedoresCatalogo/ModalCrearProveedor'
import Loader from '../Loader'

function ProveedoresByEmpresa() {
    const empresasDisponibles = useSelector(state => state.empresas.empresasDisponibles);
    let empresas = empresasDisponibles.map(e => { return e.empresa; });
    const empresaDefault = empresas[0];
    const [key, setKey] = useState(empresaDefault);
    const [cargando, setCargando] = useState(true);
    const [statusError, setStatusError] = useState('');
    const [listEmpresas, setListEmpresas] = useState([]);
    const [updateListado, setUpdateListado] = useState(false);
    let updateGetListado = () => { setUpdateListado(!!!updateListado); setListEmpresas([]); }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getProveedores, [updateListado]);

    function getProveedores() {
        setCargando(true)
        axios.post(
            API_PROVEEDORES_URL + 'getProveedoresByEmpresas',
            {
                empresas: empresas
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                setListEmpresas(res.data.proveedores)
            } else {
                setListEmpresas('');
                setStatusError(res.data.result.message);
            }
        }).catch((error) => {
            setStatusError("Ocurrió un error, no se pudieron recuperar los proveedores disponibles");
        }).finally(() => {
            setCargando(false)
        });
    }

    if(cargando){
        return <Loader />
    }

    function createTabs() {
        let empresasTabs;
        listEmpresas.length ?
            empresasTabs = listEmpresas.map(empresa => (
                <Tab key={empresa.id} eventKey={empresa.id} title={empresa.nombreEmpresa}>
                    <SeleccionarProveedor onUpdate={updateGetListado}>{empresa.lista}</SeleccionarProveedor>
                </Tab>
            )) : empresasTabs = empresas.map(empresa => (
                <Tab key={empresa} eventKey={empresa} title={empresa}>
                    <section id="seleccionarProveedor">
                        <Card id="cardSeleccionarProveedor">
                            <Card.Body className="cardBodySeleccionarProveedor">
                                {statusError}
                            </Card.Body>
                        </Card>
                    </section>
                </Tab>
            ))
        return empresasTabs;
    }

    return (
        <div>
            <Tabs id="controlled-tab-requisiciones" activeKey={key} onSelect={(k) => setKey(k)}>
                {createTabs()}
            </Tabs>
        </div>
    );
}

function SeleccionarProveedor(props) {
    const [deactivate, setDeactivate] = useState(false);
    const [request] = useState(false);
    const [rowSelected, setRowSelected] = useState([]);
    const [listaProveedores] = useState(props.children);
    const [error] = useState(false);
    const [modalCrearRequisicionShow, setModalCrearRequisicionShow] = useState(false);
    const [modalCrearProveedorShow, setModalCrearProveedorShow] = useState(false);
    const [toastShown, setToastShown] = useState(false);
    let modalCrearRequisicionClose = () => { setModalCrearRequisicionShow(false); }
    let modalCrearProveedorClose = () => setModalCrearProveedorShow(false);
    let updateGetListado = () => { props.onUpdate() }
    let handleOnSelect = (row, isSelect) => {
        if (isSelect) {
          setRowSelected(row);
          setModalCrearRequisicionShow(true);
        } else {
          if (!toastShown) {
            setDeactivate(true);
    
            setTimeout(() => {
              setDeactivate(false);
              setToastShown(false); // Restablecer el estado después de cerrar el toast
            }, 4500);
    
            toast.error('Debe seleccionar un proveedor', {
              position: "top-center",
              autoClose: 4500,
            });
    
            setToastShown(true);
          }
        }
      };

    let mostrarListaProveedores = () => {
        if (error) {
            return (
                <div>Error: No se han podido cargar los proveedores disponibles</div>
            );
        } else {
            const columns = [{
                dataField: 'id',
                text: 'ID'
            },
            {
                dataField: 'nombreComercial',
                text: 'Nombre'
            },
            {
                dataField: 'razonSocial',
                text: 'Razón social'
            },
            {
                dataField: 'proveedorRfc',
                text: 'RFC'
            }];

            const selectRow = {
                mode: "radio",
                clickToSelect: true,
                style: { backgroundColor: 'rgb(155, 227, 230)' },
                selectionHeaderRenderer: () => 'X',
                onSelect: handleOnSelect
            };

            function indication() {
                return listaProveedores.length ? "La búsqueda no coincide con ningún criterio" : "No hay registros que mostrar";
            }

            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size}
                </span>
            );

            const options = {
                custom: true,
                sizePerPage: 10,
                totalSize: listaProveedores.length,
                paginationTotalRenderer: customTotal
            }

            const { SearchBar } = Search;

            const contentTable = ({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                    keyField='id'
                    data={listaProveedores}
                    columns={columns}
                    search
                >
                    {toolkitprops => (
                        <div>
                            <Row>
                                <div className="d-flex ml-auto mb-2 flex-row-reverse">
                                    <button
                                        className="ml-1 button-default-req"
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title="Nuevo proveedor"
                                        onClick={() => { setModalCrearProveedorShow(true) }}
                                    ><span>Nuevo proveedor  </span>
                                    </button>
                                    <SearchBar className="form-control-font-size" {...toolkitprops.searchProps} placeholder="Buscar proveedor" />
                                </div>
                            </Row>
                            <Row>
                                <div className="d-flex flex-row">
                                    <Form.Label column>Mostrar</Form.Label>
                                    <Col>
                                        {/* <Form.Control className="form-control-font-size" size="sm" as="select" onChange={(e) => handleSizePerPage(paginationProps, e.target.value)}>
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="15">15</option>
                                            <option value={listaProveedores.length}>Todos</option>
                                        </Form.Control> */}
                                        <SizePerPageDropdownStandalone btnContextual {...paginationProps} />
                                    </Col>
                                </div>
                                <div className="d-flex flex-row">
                                    <Col>
                                        <PaginationTotalStandalone {...paginationProps} />
                                    </Col>
                                </div>
                                <div className="d-flex ml-auto flex-row-reverse">
                                    <Col>
                                        <PaginationListStandalone {...paginationProps} />
                                    </Col>
                                    <Form.Label column>Página</Form.Label>
                                </div>
                            </Row>
                            <div className="table-responsive table-sm text-center">
                                <BootstrapTable
                                    selectRow={selectRow}
                                    bordered={false}
                                    noDataIndication={indication}
                                    striped
                                    hover
                                    condensed
                                    {...paginationTableProps}
                                    {...toolkitprops.baseProps}
                                />
                            </div>
                        </div>
                    )}
                </ToolkitProvider>
            );

            return <PaginationProvider pagination={paginationFactory(options)}>
                {contentTable}
            </PaginationProvider >
        }
    }
    return (
        <section id="seleccionarProveedor" className="text-center">
            <Card id="cardSeleccionarProveedor">
                <div>
                    {modalCrearRequisicionShow ? <ModalCrearRequisicion show={modalCrearRequisicionShow} onHide={modalCrearRequisicionClose}>{rowSelected}</ModalCrearRequisicion> : ''}
                    {modalCrearProveedorShow ? <ModalCrearProveedor show={modalCrearProveedorShow} onHide={modalCrearProveedorClose} onUpdate={updateGetListado}></ModalCrearProveedor> : ''}
                </div>
                <Card.Body className="cardBodySeleccionarProveedor">
                    {request ? "Cargando..." : mostrarListaProveedores()}
                </Card.Body>
                <Card.Footer className="justify-content-end">
                    <button
                        className="m-1 button-default-req"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        onClick={handleOnSelect}
                        disabled = { deactivate }
                    ><span>Siguiente</span>
                    </button>
                </Card.Footer>
            </Card>
        </section>
    );
}

export default ProveedoresByEmpresa;