import { requestResponse } from '../constantes/constants'

/**
 * Each reducer updates a different part of the application state in response to dispatched redux actions
 */

let empresasDisponibles = JSON.parse(localStorage.getItem('empresasDisponibles'));//LocalStorage sólo acepta cadenas, por eso hay que usar parse para leer los arrays
const initialState = empresasDisponibles ? { getEmpresas: true, empresasDisponibles } : {};

function empresas(state = initialState, action) {
    switch (action.type) {
        case requestResponse.REQUEST:
            return {
                getEmpresas: true,
                message: action.res
            };
        case requestResponse.SUCCESS:
            return {
                getEmpresas: true,
                empresasDisponibles: action.res
            };
        case requestResponse.FAILURE:
            return {
                getEmpresas: false,
                message: action.res
            };
        default:
            return state;
    }
}

export { empresas };