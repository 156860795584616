import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_PROVEEDORES_URL, KRAKEN_URL } from "../../constants";

//Estilos
import "./css/Requisiciones.css";
import { CheckAll } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";

//Elementos bootstrap
import { Modal, Form, Col, Spinner, Table } from "react-bootstrap";

//componentes
import ModalAgregarUsuarioContacto from "../Configuracion/Empresa/Usuarios/UsuarioContactos/ModalAgregarUsuarioContacto";

function ModalConfirmarRequisicion(props) {
  console.log(Array.isArray(props.children));
  //const solicitante = props.children.solicitante;
  const [esMasivo, setEsMasivo] = useState(Array.isArray(props.children));
  //const solicitante = props.children.solicitante;
  const [deactivate, setDeactivate] = useState(false);

  const token = JSON.parse(localStorage.getItem("token"));
  const userId = token.userId;
  const empresaAlias = props.children.empresa;
  const [request] = useState(false);
  const [listContactos, setListContactos] = useState([]);
  const [status, setStatus] = useState(false);
  const [error, setError] = useState(false);
  const [statusError, setStatusError] = useState("");
  const [seleccionados, setSeleccionados] = useState([]); //Aquí se guardan sólo los id's de los contactos que se seleccionen
  const [ctcSeleccionados, setCtcSeleccionados] = useState([]); //Aquí se guardan sólo los emails que se seleccionen
  const [updateListado, setUpdateListado] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [solicitante, setSolicitante] = useState([]);
  const [compartidos, setCompartidos] = useState(
    props.children.compartidos ? JSON.parse(props.children.compartidos) : []
  );
  const [compartidosToSend, setCompartidosToSend] = useState([]);
  const usuario = {
    userId: userId,
    username: token.username,
    empresaAlias: empresaAlias,
  };
  const [comentario, setComentario] = useState('');
  const [pagadores,setPagadores] = useState([])
  const handleComentarioChange = (event) => {
    setComentario(event.target.value);
  };

  const [modalUsuarioAgregarShow, setModalUsuarioAgregarShow] = useState(false);
  let modalUsuarioAgregarClose = () => setModalUsuarioAgregarShow(false);
  let updateGetListado = () => {
    setUpdateListado(!!!updateListado);
  };
 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getContactos, [updateListado, userId, empresaAlias]);
  const handleClose = () => {
    // Lógica que deseas ejecutar cuando se cierra el modal
    console.log('Modal cerrado');
  };

  function mapeoCompartidos() {
    let aux = [];
    if (compartidos.length > 0) {
      compartidos.map((e) => {
        aux.push(e.usuarioEmail);
      });
    }
    setCompartidosToSend(aux);
  }
  function getContactos() {
    mapeoCompartidos();
    if (!esMasivo && compartidos.length === 0)
      axios
        .post(KRAKEN_URL + "empresa/usuario/contactos/list", {
          userId: userId,
          empresa: empresaAlias,
        })
        .then((res) => {
          if (res.data.result.code === 200 && res.data.list.length) {
            setListContactos(res.data.list);
            setSeleccionados([...seleccionados, res.data.list[0].contactoId]);
            setCtcSeleccionados([
              ...ctcSeleccionados,
              res.data.list[0].emailContacto,
            ]);
          } else {
            setListContactos("");
            setStatusError(res.data.result.message);
          }
        })
        .catch((error) => {
          setError(true);
          setStatusError(
            "Ocurrió un error, no se pudieron recuperar los contactos de este usuario"
          );
        });
  }
  function cargando() {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden"></span>
        </Spinner>
      </div>
    );
  }
  async function getSolicitanteOnebyOne(idSolicitante) {
    return new Promise((resolve, reject) => {
      axios.post(KRAKEN_URL + 'empresa/usuario/solicitante/getSolicitante', {
        userId: idSolicitante
      }).then((res) => {
      
        if (res.data.result.code === 200) {
          setSolicitante(res.data.list[0]);
          resolve(res.data.list[0]);
        } else {
          setSolicitante('');
          setStatusError(res.data.result.message);
          reject(res.data.result.message);
        }
      }).catch((error) => {
        console.log(error);
      
        setStatusError("Ocurrió un error, no se pudieron recuperar los contactos de este usuario");
        reject(error);
      });
    });
  }

  function getPagadores(requisicion) {
    console.log("entra a la peticion " + requisicion.empresa);
    return axios
        .post(KRAKEN_URL + "empresa/usuarios/payers", {
            empresaId: requisicion.idEmpresa,
        })
        .then((res) => {
            console.log("........");
            console.log(res);

            let aux = [];
            if (res.data.list)
                res.data.list.map((pagador) => {
                    aux.push(pagador);
                });
            setPagadores(aux); // Suponiendo que setPagadores es una función para actualizar el estado
          
        })
        .catch((error) => {
            console.log(error);
            console.log("ALGO SALIÓ MAL");
            throw error; // Propagar el error para que se maneje en el bloque catch de aprobarRequisicion
        });
}
  
 async function confirmarRequisicion(requisicion, e) {
    e.preventDefault();
    setStatus(true);
    setIsLoading(true);
  
    if (!esMasivo) {
  let solicitanteRes = await getSolicitanteOnebyOne(requisicion.idSolicitante);
  
  let compartidos =[]
  if(requisicion.compartidos !=="[]"){
    const compartidosArray = JSON.parse(requisicion.compartidos);
   compartidos = compartidosArray.map(usuario => usuario.usuarioEmail);
  
  }

  compartidos.push(solicitanteRes.email)
   
        axios
          .post(API_PROVEEDORES_URL + "confirmarRequisicion", {
            to:compartidos,
            idRequisicion: requisicion.idRequisicion,
            id: requisicion.id,
            empresa: requisicion.empresa,
            proveedor: requisicion.proveedor,
            fechaRequeridaPago: requisicion.fechaRequeridaPago,
            total: requisicion.total,
            porcentajeRequerido: requisicion.porcentajeTotal,
            comentario: comentario,
            idEmpresa: requisicion.idEmpresa,
            idProveedor: requisicion.idProveedor,
            proveedorRfc: requisicion.proveedorRfc,
            banco: requisicion.banco,
            cuentaClabe: requisicion.cuentaClabe,
            cuentaBanco: requisicion.cuentaBanco,
            referencia: requisicion.referencia,
            concepto: requisicion.concepto,
            monedaClaveSat: requisicion.monedaClaveSat,
            monedaSat: requisicion.monedaSat,
            monedaDecimales: requisicion.monedaDecimales,
            paridad: requisicion.paridad,
            formaPagoClaveSat: requisicion.formaPagoClaveSat,
            formaPagoSat: requisicion.formaPagoSat,
            compartidos:requisicion.compartidos
          })
          .then((res) => {
            setIsLoading(false);
            if (res.data.result.success) {
              setStatus(false);

              toast.success(res.data.result.message, {
                position: "top-center",
                autoClose: 4500,
              });

              toast.success(
                "Podrá encontrarla en el listado de requisiciones por pagar",
                {
                  position: "top-center",
                  autoClose: 4500,
                }
              );

              props.onUpdate(); //Actualiza el listado, llamando a la función padre para actualizarlo
              props.onHide(); //Cierra el modal, llamando a la función padre para cerrarlo
            } else {
              setStatus(false);

              toast.error(res.data.result.message, {
                position: "top-center",
                autoClose: 4500,
              });
              toast.error(res.data.result.suggestion, {
                position: "top-center",
                autoClose: 4500,
              });
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
            setStatus(false);
            toast.error(
              "No se ha podido enviar la requisición al solicitante",
              {
                position: "top-center",
                autoClose: 4500,
              }
            );
          });
      
    } else {


      let requisiciones = [];
      let requisicionesErrores = [];
      props.children.map((req) => {
        let auxCompartidos=[] ;
        let auxToSend = []
        
      if(req.compartidos!=="[]"){
        auxCompartidos = JSON.parse(req.compartidos)
        auxCompartidos.map((e)=>{
            auxToSend.push(e.usuarioEmail)
        })
        auxToSend.push(req.pagadorEmail)
      }
      
           
        if (req.pagadorEmail === "no aplica" || req.pagadorEmail === ""|| req.pagadorEmail === "pendiente") {
          requisicionesErrores.push(req.id);
        }
        let reqMapped = {
          to: req.pagadorEmail,
          idRequisicion: req.idRequisicion,
          id: req.id,
          empresa: req.empresa,
          proveedor: req.proveedor,
          fechaRequeridaPago: req.fechaRequeridaPago,
          total: req.total,
          porcentajeRequerido: req.porcentajeTotal,
          solicitante: req.solicitante,
          comentario: comentario,
          compartidos: auxToSend.length>0?auxToSend:[]
        };

        requisiciones.push(reqMapped);
      });
      console.log(requisiciones);
      if (requisicionesErrores.length > 0) {
        setIsLoading(false);
        let reqSinPagador = requisicionesErrores.join(", ");
        setStatus(false)
        toast.warning(
          "Las siguientes requisiciones no tienen asignado un pagador: " +
            reqSinPagador,
          {
            onClick: () => toast.dismiss(),
            position: "top-center",
            autoClose: 4500,
          }
        );
      } else {


        axios
          .post(API_PROVEEDORES_URL + "pedirAprobacionMasiva", requisiciones)
          .then((res) => {
            setIsLoading(false);
            console.log(res.status);
            if (res.status === 200) {
              setStatus(false);
              toast.success(
                "Se ha pedido la aprobación masiva correctamente.",
                {
                  position: "top-center",
                  autoClose: 4500,
                }
              );
              toast.success(
                "Podrá encontrarla en el listado de requisiciones por pagar",
                {
                  position: "top-center",
                  autoClose: 4500,
                }
              );
              props.onUpdate();
              props.onHide(); 
            } else {
              setStatus(false);
              toast.error(res.data.result.message, {
                position: "top-center",
                autoClose: 4500,
              });
              toast.error(res.data.result.suggestion, {
                position: "top-center",
                autoClose: 4500,
              });
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
            setStatus(false);
            toast.error(
              "No se ha podido enviar la requisición al solicitante",
              {
                position: "top-center",
                autoClose: 4500,
              }
            );
          });
      }
    }
  }

  let handleSizePerPage = ({ page, onSizePerPageChange }, newSizePerPage) => {
    onSizePerPageChange(newSizePerPage, page);
  };


  let mostrarListaContactos = () => {
    if (error) {
      return <div>{statusError}</div>;
    } else {
      const columns = [
        {
          dataField: "contacto",
          text: "Nombre de contacto",
          filter: textFilter({
            placeholder: "Buscar...",
            className: "form-control-sm",
          }),
          sort: true,
        },
        {
          dataField: "emailContacto",
          text: "Email del contacto",
          filter: textFilter({
            placeholder: "Buscar...",
            className: "form-control-sm",
          }),
          sort: true,
        },
      ];

      const selectRow = {
        mode: "checkbox",
        clickToSelect: true,
        style: { backgroundColor: "rgb(155, 227, 230)" },
        selected: seleccionados, //renglones seleccionados de acuerdo al contactoId (keyField del BootstrapTable)
        onSelect: (row, isSelect, rowIndex, e) => {
          if (isSelect) {
            setSeleccionados([...seleccionados, row.contactoId]);
            setCtcSeleccionados([...ctcSeleccionados, row.emailContacto]);
          } else {
            let eliminarSeleccionado = seleccionados.filter(
              (x) => x !== row.contactoId
            );
            setSeleccionados(eliminarSeleccionado);
            let eliminarContacto = ctcSeleccionados.filter(
              (x) => x !== row.emailContacto
            );
            setCtcSeleccionados(eliminarContacto);
          }
        },
        onSelectAll: (isSelect, rows, e) => {
          const ids = rows.map((r) => r.contactoId);
          const emails = rows.map((r) => r.emailContacto);
          if (isSelect) {
            setSeleccionados(ids);
            setCtcSeleccionados(emails);
          } else {
            setSeleccionados([]);
            setCtcSeleccionados([]);
          }
        },
      };

     
      function indication() {
        return listContactos.length
          ? "La búsqueda no coincide con ningún criterio"
          : "No hay registros que mostrar, da clic en el botón 'Agregar contacto' para crear uno";
      }

      const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
          Mostrando {from} - {to} de {size}
        </span>
      );

      const options = {
        custom: true,
        sizePerPage: 5,
        totalSize: listContactos.length,
        paginationTotalRenderer: customTotal,
      };

      return (
        <PaginationProvider pagination={paginationFactory(options)}>
          {({ paginationProps, paginationTableProps }) => (
            <div>
              <Form.Row className="d-flex justify-content-center">
                <div className="d-flex ">
                  <button
                    className="m-1 button-default-req"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Agregar contacto"
                    onClick={() => {
                      setModalUsuarioAgregarShow(true);
                    }}
                  >
                    <span>Agregar contacto</span>
                  </button>
                  {modalUsuarioAgregarShow ? (
                    <ModalAgregarUsuarioContacto
                      show={modalUsuarioAgregarShow}
                      onHide={modalUsuarioAgregarClose}
                      onUpdate={updateGetListado}
                    >
                      {usuario}
                    </ModalAgregarUsuarioContacto>
                  ) : (
                    ""
                  )}
                </div>
              </Form.Row>
              <Form.Row className="p-2">
                <div className="d-flex flex-row">
                  <Form.Label column>Mostrar</Form.Label>
                  <Col>
                    <Form.Control
                      size="sm"
                      as="select"
                      onChange={(e) =>
                        handleSizePerPage(paginationProps, e.target.value)
                      }
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value={listContactos}>Todos</option>
                    </Form.Control>
                  </Col>
                </div>
                <div className="d-flex flex-row">
                  <Col>
                    <PaginationTotalStandalone {...paginationProps} />
                  </Col>
                </div>
                <div className="d-flex ml-auto flex-row-reverse">
                  <Col>
                    <PaginationListStandalone {...paginationProps} />
                  </Col>
                  <Form.Label column>Página</Form.Label>
                </div>
              </Form.Row>
              <div className="table-responsive table-sm">
                <BootstrapTable
                  id="listaContactos"
                  keyField="contactoId"
                  data={listContactos}
                  columns={columns}
                  selectRow={selectRow}
                  filter={filterFactory()}
                  bordered={false}
                  striped
                  hover
                  condensed
                  noDataIndication={indication}
                  {...paginationTableProps}
                />
              </div>
            </div>
          )}
        </PaginationProvider>
      );
    }
  };

  return (
    <section className="text-center">
      <Modal
        show={props.show}
        size="lg"
        id={!esMasivo ? "modalConfirmarRequisicion" : "modalPedirAprobacion"}
        centered
      >
        <Modal.Header className="modal-header-confirmar-req" closeButton  onClick={props.onHide} >
          <p>
            <CheckAll size={22} color="#5B4B51" />
            {!esMasivo ? (
              <p>
                &nbsp;¿Quiere aprobar esta requisición? #{props.children.id}
              </p>
            ) : (
              <p>
                Se solicitará aprobación de todas las requisiciones
                seleccionadas
              </p>
            )}
          </p>
        </Modal.Header>
        {isLoading ? (
          cargando()
        ) : (
          <Modal.Body>
           {/*
              {compartidos.length === 0 ? (
                 <Form.Row>
                <p>
                  <CheckAll
                    size={22}
                    color={!esMasivo ? "#5B4B51" : "#5B4B51"}
                  />
                  &nbsp;{" "}
                  {!esMasivo
                    ? "Se notificará al usuario solicitante de forma automatica"
                    : "Se notificará a los usuarios pagadores correspondientes."}
                  {request
                    ? "Cargando..."
                    : ""}
                </p>
                </Form.Row>
              ) : (
                <div className="text-center">
                  {compartidos.length > 0 ? (
                    <Form.Group className="mx-4 text-center">
                    <div >
                      <legend className="legend-share-req-modal">
                      Se notificará al usuario solicitante y usuarios compartidos
                      </legend>
                      <Table  striped bordered hover>
                        <thead>
                          <tr>
                            <th>
                              <b>Nombre de usuario</b>
                            </th>
                            <th>
                              <b>Email</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {compartidos.map((elemento) => (
                            <tr key={elemento.usuarioId}>
                              <td>{elemento.usuarioNombre}</td>
                              <td>{elemento.usuarioEmail}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </Form.Group>
                  ) : (
                    ""
                  )}
                </div>
              )}*/}

              <Form.Control
                id="comentario"
                as="textarea"
                rows={1}
                value={comentario}
                onChange={handleComentarioChange}
                placeholder="¿Desea agregar algún comentario?"
              ></Form.Control>
          
            <div className="d-flex justify-content-center">
              <button
                className="m-1 button-default-confirmar"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Confirmar"
                disabled={isLoading}
                onClick={(e) => confirmarRequisicion(props.children, e)}
              >
                {status ? <span>Enviando...</span> : <span>Confirmar</span>}
              </button>
              <button
                className="m-1 button-default-cancelar"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Cancelar"
                onClick={props.onHide}
              >
                <span>Cancelar</span>
              </button>
            </div>
          </Modal.Body>
        )}
      </Modal>
    </section>
  );
}

export default ModalConfirmarRequisicion;
