import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_PROVEEDORES_URL } from '../../constants'
import { useSelector } from 'react-redux';

//Estilos
import './css/Requisiciones.css'
import './css/RequisicionesPendientes.css'
import { PencilSquare, Trash, Eye, CheckCircle, CheckAll, ListTask, Grid3x2GapFill, CaretDownFill, CaretUpFill,PeopleFill,ClipboardPlus } from 'react-bootstrap-icons';

//Elementos bootstrap
import { Card, Col, Form, Button, ButtonGroup, Tabs, Tab, Badge } from 'react-bootstrap'

//Componentes
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import Moment from 'moment';
import NumberFormat from 'react-number-format';
import ModalEditarRequisicion from './ModalEditarRequisicion';
import ModalConsultarRequisicion from './ModalConsultarRequisicion';
import ModalDeleteRequisicion from './ModalDeleteRequisicion';
import ModalPedirAprobacion from './ModalPedirAprobacion';
import ModalConfirmarRequisicion from './ModalConfirmarRequisicion';
import ModalAsignacion from './ModalAsignacion';
import ModalAprobarRequisiciones from './ModalAprobarRequisiciones';
import Loader from "../Loader";

function RequisicionesPendientes() {
     const token = JSON.parse(localStorage.getItem('token'));
    const empresasDisponibles = useSelector(state => state.empresas.empresasDisponibles);
    let empresas = empresasDisponibles.map(e => { return e.empresa; });
    const empresaDefault = empresas[0];
    const [key, setKey] = useState(empresaDefault);
    const [listaPendientes, setListaPendientes] = useState([]);//Así se pasan los datos de un componente a otro, se puede llamar props o cómo sea
    const [error, setError] = useState(false);
    const [cargando, setCargando] = useState(false);
    const [errorStatus, setErrorStatus] = useState('');
    const [updateListado, setUpdateListado] = useState(false);

    let updateGetRequisiciones = () => { setUpdateListado(!!!updateListado); setListaPendientes([]); }

    // La siguiente línea evita un warning que no compromete la ejecución
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    useEffect(getListadoReqPendientes, [updateListado]);//Se actualiza cada que se agrega nueva requisición*/
    
    function getListadoReqPendientes() {
        let user = JSON.parse(localStorage.getItem('token'));
        setCargando(true);
        axios.post(API_PROVEEDORES_URL + 'getRequisicionesPendientes',
            {
                empresas: empresas
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                user.userType === 'SCHEMA_ADMIN'? filterDataToMaster(res.data.requisiciones) : 
                filterDataToUser(res.data.requisiciones);

                setListaPendientes(res.data.requisiciones);
             
            } else {
                setError(true);
                setListaPendientes('');
                setErrorStatus(res.data.result.message);
            }
        }).catch((error) => {
          
            setError(true);
            setErrorStatus("Error: No se han podido recuperar las requisiciones pendientes.")
        }).finally(() => {
            setCargando(false);
        })
    }

    function filterDataToUser(empresas){ 
       empresas.map((empresa)=>{
        empresa.lista = empresa.lista.filter(function(objeto) {

            
            return ((objeto.solicitante ===token.username)||objeto.usuarioCompartido ===token.username||(objeto.pagadorId ===token.userId||(objeto.pagadorId==="0"&&token.userType==="ADMINISTRADOR")));
        });
        console.log()
        })
        return empresas;
    }

    function filterDataToMaster(empresas){
        empresas.map((empresa)=>{
       
            empresa.lista = empresa.lista.filter(function(objeto) {
                
                return (objeto.usuarioCompartido !==token.username );
            });
            })
            return empresas;

    }

    function createTabs() {
        let empresasTabs;
    
         listaPendientes.length ? 
         listaPendientes[0].lista.map((pendiente,index) =>{
         pendiente.index = index
        }):console.log("no hay nada que mapear")
       

        listaPendientes.length ?
            empresasTabs = listaPendientes.map(empresa => (
                <Tab key={empresa.id} eventKey={empresa.id} title={empresa.nombreEmpresa}>
                    <ListadoRequisicionesPendientes onUpdate={updateGetRequisiciones}>{empresa.lista}{empresa.nombreEmpresa}</ListadoRequisicionesPendientes>
                </Tab>
            )) : empresasTabs = empresas.map(empresa => (
                <Tab key={empresa} eventKey={empresa} title={empresa}>
                    <section id="listaReqPasadas">
                        <Card id="cardReqPasadas">
                            <Card.Body className="cardBodyReqPasadas">
                                {error ? errorStatus : 'Error: No se ha podido cargar la información'}
                            </Card.Body>
                        </Card>
                    </section>
                </Tab>
            ))
        return empresasTabs;
    }

    if (cargando) {
        return <Loader />
    }

    return (
        <div >
            <Tabs id="controlled-tab-requisiciones" activeKey={key} onSelect={(k) => setKey(k)}>
                {createTabs()}
            </Tabs>
        </div >
    );

}

function ListadoRequisicionesPendientes(props) {
   
    const token = JSON.parse(localStorage.getItem('token'));
    const userType = token.userType;
  
    const [gridCards, setGridCards] = useState(false);
    const [request] = useState(false);
    const [error] = useState(false);
    const [listaPendientes,setListaPendientes] = useState(eliminarObjetosCompartidos(props.children[0]));
    const empresaAlias = props.children[1];
    const [modalEditarRequisicionShow, setModalEditarRequisicionShow] = useState(false);
    const [modalConsultarRequisicionShow, setModalConsultarRequisicionShow] = useState(false);
    const [modalDeleteRequisicionShow, setModalDeleteRequisicionShow] = useState(false);
    const [modalPedirAprobacionShow, setModalPedirAprobacionShow] = useState(false);
    const [modalConfirmarShow, setModalConfirmarShow] = useState(false);
    const [modalAsignationShow, setModalAsignationShow] = useState(false)
    const [modalAprobarRequisicionesShow, setModalAprobarRequisicionesShow] = useState(false);
    const [reqEditar, setReqEditar] = useState('');
    const [reqConsulta, setReqConsulta] = useState('');
    const [reqDelete, setReqDelete] = useState('');
    const [reqPedirA, setReqPedirA] = useState('');
    const [reqConfirmar, setReqConfirmar] = useState('');
    const [checkboxStates, setCheckboxStates] = useState([]);
    const [reqSeleccionadas, setReqSeleccionadas] = useState([]);
    const[aprobacionMasivaButton, setAprobacionMasivaButton] = useState(true)
    let modalEditarRequisicionClose = () => { setModalEditarRequisicionShow(false); setReqEditar(''); }
    let modalConsultarRequisicionClose = () => { setModalConsultarRequisicionShow(false); setReqConsulta(''); }
    let modalDeleteRequisicionClose = () => { setModalDeleteRequisicionShow(false); setReqDelete(''); }
    let modalPedirAprobacionClose = () => { setModalPedirAprobacionShow(false); setReqPedirA(''); }
    let modalConfirmarClose = () => { setModalConfirmarShow(false); setReqConfirmar(''); }
    let modalAssignationClose = () => { setModalAsignationShow(false); setReqConfirmar(''); }
    let modalAprobarRequisicionesClose = () => setModalAprobarRequisicionesShow(false);
    let updateListado = () => { props.onUpdate() };

    function eliminarObjetosCompartidos(arreglo) {
        arreglo = arreglo.filter(function(objeto) {
        
            return !(objeto.esCompartida === 1 && (objeto.usuarioCompartido !== token.username && objeto.usuarioCompartido !== ""));
        });
        return arreglo;
    }
      useEffect(() => {
        if(checkboxStates.length>0){
            setAprobacionMasivaButton(false)
        }
        else{
            setAprobacionMasivaButton(true)
        }
      }, [checkboxStates]);

    let handleSizePerPage = ({ page, onSizePerPageChange }, newSizePerPage) => {
        onSizePerPageChange(newSizePerPage, page);
    }
    function aprobarMasivamente(){
       
        setReqConfirmar(reqSeleccionadas);
        setModalConfirmarShow(true)
    }
    function getRequisicion(requisicion) {
        setReqEditar(requisicion);
        setModalEditarRequisicionShow(true);
    }

    function viewRequisicion(requisicion) {
        setReqConsulta(requisicion);
        setModalConsultarRequisicionShow(true);
    }

    function confirmDelete(requisicion) {
        setReqDelete(requisicion);
        setModalDeleteRequisicionShow(true)
    }

    function aprobarRequisicion(requisicion) {
        setReqPedirA(requisicion);
        setModalPedirAprobacionShow(true)
    }

    function confirmarRequisicion(requisicion) {
        setReqConfirmar(requisicion);
        setModalConfirmarShow(true)
    }
    function asignacionRequisicion(requisicion) {
        setReqConfirmar(requisicion);
        setModalAsignationShow(true)
    }
    const handleCheckboxChange = (index) => {
   
  
   setCheckboxStates((prevStates) => {
   
    let aux = [...prevStates]; // Crear una copia del estado actual
    aux[index] = !aux[index];
    return aux;
  })
   }

    let mostrarListaPendientes = () => {
        if (error) {
            return (
                <div>Error: No se han podido recuperar las requisiciones pendientes</div>
            );
        } else {
            const columnsList = [
         
                {
                    dataField: 'id',
                    text: 'ID',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true,
                    footer: ''
                },
                {
                    dataField: 'concepto',
                    text: 'Concepto',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true,
                    footer: ''
                },
                {
                    dataField: 'solicitante',
                    text: 'Solicitante',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true,
                    footer: ''
                },
                {
                    dataField: 'proveedorRfc',
                    text: 'RFC proveedor',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true,
                    footer: ''
                },
                {
                    dataField: 'proveedor',
                    text: 'Proveedor',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true,
                    footer: ''
                },
                {
                    dataField: 'fechaRequeridaPago',
                    text: 'Fecha requerida',
                    formatter: (cellContent, row) => {
                        return (
                            Moment(row.fechaRequeridaPago).format('DD/MM/YYYY')
                        )
                    },
                    sort: true,
                    footer: '',
                    sortCaret: (order, column) => {
                        if (!order) return (<span><CaretDownFill size={10} className="button-default-black" color="#000" /><CaretUpFill size={10} className="button-default-black" color="#000" /></span>);
                        else if (order === "desc") return (<span><CaretUpFill size={10} className="button-default-black" color="#000" /><CaretDownFill size={10} className="button-default-black" color="#dee2e6" /></span>);
                        else if (order === "asc") return (<span><CaretUpFill size={10} className="button-default-black" color="#dee2e6" /><CaretDownFill size={10} className="button-default-black" color="#000" /></span>);
                        return null;
                    }
                },
                {
                    dataField: 'porcentajeTotal',
                    text: '% Requerido',
                    formatter: (cellContent, row) => {
                        return (
                            <NumberFormat
                                displayType={'text'} prefix={'%'}
                                thousandSeparator={true}
                                value={row.porcentajeTotal.toFixed(row.monedaDecimales)}
                            ></NumberFormat>
                        )
                    },
                    sort: true,
                    footer: 'Totales:',
                    sortCaret: (order, column) => {
                        if (!order) return (<span>&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000" /><CaretUpFill size={10} className="button-default-black" color="#000" /></span>);
                        else if (order === "desc") return (<span>&nbsp;<CaretUpFill size={10} className="button-default-black" color="#000" /><CaretDownFill size={10} className="button-default-black" color="#dee2e6" /></span>);
                        else if (order === "asc") return (<span>&nbsp;<CaretUpFill size={10} className="button-default-black" color="#dee2e6" /><CaretDownFill size={10} className="button-default-black" color="#000" /></span>);
                        return null;
                    }
                },
                {
                    dataField: 'importePesosMexicanos',
                    text: 'Total',
                    formatter: (cellContent, row) => {
                        return (<div>
                            <NumberFormat
                                displayType={'text'}
                                thousandSeparator={true}
                                value={row.importePesosMexicanos.toFixed(2/*row.monedaDecimales*/)}
                            ></NumberFormat>
                            
                            </div>
                        )
                    },
                    sort: true,
                    footer: columnData => {
                        let sumatoria = columnData.reduce((acc, item) => acc + item, 0).toFixed(2)
                        return (<h5><Badge variant="success">
                            <NumberFormat displayType={'text'} thousandSeparator={true} value={sumatoria} />
                        </Badge></h5>)
                    },
                    sortCaret: (order, column) => {
                        if (!order) return (<span>&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000" /><CaretUpFill size={10} className="button-default-black" color="#000" /></span>);
                        else if (order === "desc") return (<span>&nbsp;<CaretUpFill size={10} className="button-default-black" color="#000" /><CaretDownFill size={10} className="button-default-black" color="#dee2e6" /></span>);
                        else if (order === "asc") return (<span>&nbsp;<CaretUpFill size={10} className="button-default-black" color="#dee2e6" /><CaretDownFill size={10} className="button-default-black" color="#000" /></span>);
                        return null;
                    }
                },
                {
                    dataField: 'totalRequerido',
                    text: 'Total requerido',
                    formatter: (cellContent, row) => {
                        return (
                            <NumberFormat
                                displayType={'text'}
                                thousandSeparator={true}
                                value={row.totalRequerido.toFixed(2/*row.monedaDecimales*/)}
                            ></NumberFormat>
                        )
                    },
                    sort: true,
                    footer: columnData => {
                        let sumatoria = columnData.reduce((acc, item) => acc + item, 0).toFixed(2)
                        return (<h5><Badge variant="success">
                            <NumberFormat displayType={'text'} thousandSeparator={true} value={sumatoria} />
                        </Badge></h5>)
                    },
                    sortCaret: (order, column) => {
                        if (!order) return (<span><CaretDownFill size={10} className="button-default-black" color="#000" /><CaretUpFill size={10} className="button-default-black" color="#000" /></span>);
                        else if (order === "desc") return (<span><CaretUpFill size={10} className="button-default-black" color="#000" /><CaretDownFill size={10} className="button-default-black" color="#dee2e6" /></span>);
                        else if (order === "asc") return (<span><CaretUpFill size={10} className="button-default-black" color="#dee2e6" /><CaretDownFill size={10} className="button-default-black" color="#000" /></span>);
                        return null;
                    }
                },
                {
                    dataField: 'diasVencimiento',
                    text: 'Días de vencimiento',
                    isDummyField: true,
                    sort: true,
                    sortCaret: (order, column) => {
                        if (!order) return (<span><CaretDownFill size={10} className="button-default-black" color="#000" /><CaretUpFill size={10} className="button-default-black" color="#000" /></span>);
                        else if (order === "desc") return (<span><CaretUpFill size={10} className="button-default-black" color="#000" /><CaretDownFill size={10} className="button-default-black" color="#dee2e6" /></span>);
                        else if (order === "asc") return (<span><CaretUpFill size={10} className="button-default-black" color="#dee2e6" /><CaretDownFill size={10} className="button-default-black" color="#000" /></span>);
                        return null;
                    },
                    formatter: (cellContent, row) => {
                        return (
                            row.diasVencimiento >= 10 ?
                                <h6><Badge variant="success">{row.diasVencimiento}</Badge></h6>
                                :
                                row.diasVencimiento <= 9 && row.diasVencimiento >= 4 ?
                                    <h6><Badge variant="warning">{row.diasVencimiento}</Badge></h6>
                                    :
                                    row.diasVencimiento <= 3 && row.diasVencimiento >= 1 ?
                                        <h6><Badge className="vencimientoNaranja">{row.diasVencimiento}</Badge></h6>
                                        :
                                        row.diasVencimiento <= 0 && row.diasVencimiento >= -5 ?
                                            <h6><Badge variant="danger">{row.diasVencimiento}</Badge></h6>
                                            :
                                            row.diasVencimiento <= -6 ?
                                                <h6><Badge className="vencimientoMorado">{row.diasVencimiento}</Badge></h6>
                                                :
                                                row.diasVencimiento
                        )
                    }
                },
                {
                    dataField: 'acciones',
                    text: 'Acciones',
                    isDummyField: true,
                    formatter: (cellContent, row) => {
                      
                        return (
                            <div>
                                <Button id="consultarRequisicion" className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Consultar"
                                    onClick={() => viewRequisicion(row)}>
                                    <Eye size={22} className="button-default-black" color="#000" />
                                </Button>
                                {row.esCompartida!==1?(       
                                     <Button id="editarRequisicion" className="button-default-black" variant="light" size="sm"
                                        data-toggle="tooltip" data-placement="bottom" title="Editar"
                                        onClick={() => getRequisicion(row)}
                                    ><PencilSquare size={24} className="button-default-black" color="#000" />
                                    </Button>):""}
                            
                                    {row.esCompartida!==1 ? (
                                          <Button id="eliminarRequisicion" className="button-default-black" variant="light" size="sm"
                                          data-toggle="tooltip" data-placement="bottom" title="Eliminar"
                                          onClick={() => confirmDelete(row)}
                                      ><Trash size={24} className="button-default-black" color="#000" />
                                      </Button>
                                    ):""}
                             
                                {
                                    (userType === 'SCHEMA_SOLICITANTE' || userType === 'SCHEMA_ADMIN')&&row.esCompartida!==1 ?
                                        row.estatus === "Solicitar aprobación" ?
                                            <Button id="pedirAprobacion" className="button-default-black" variant="light" size="sm"
                                                data-toggle="tooltip" data-placement="bottom" title="Aprobación solicitada"
                                                onClick={() => aprobarRequisicion(row)}
                                            ><CheckCircle size={22} className="button-default-black" color="#3bca82" /></Button>
                                        : 
                                            <Button id="pedirAprobacion" className="button-default-black" variant="light" size="sm"
                                                data-toggle="tooltip" data-placement="bottom" title="Pedir aprobación"
                                                onClick={() => aprobarRequisicion(row)}
                                            ><CheckCircle size={22} className="button-default-black" color="#000" />
                                            </Button>
                                        : ''
                                }
                                {
                                    (userType === 'ADMINISTRADOR' || userType === 'SCHEMA_ADMIN')&&row.esCompartida!==1 ?
                                        <Button  disabled={row.pagadorId==="0"} variant="light" className="button-default-black" title={row.pagadorId==="0"?"Para aprobarse debe primero asignarse a un pagador":"Aprobar"} onClick={() => confirmarRequisicion(row)}>
                                            <CheckAll size={22} className="button-default-black" color="#000" />
                                        </Button>
                                        : ''
                                }
                                 {
                                      (userType === 'ADMINISTRADOR' || userType === 'SCHEMA_ADMIN')&&!row.usuarioCompartido &&row.pagadorId==="0"  ?
                                            <Button variant="light" className="button-default-black" title="Asignarme como pagador" onClick={() => asignacionRequisicion(row)}>
                                                <ClipboardPlus size={22} className="button-default-black" color="#000" />
                                            </Button>
                                            : ''
                                    }
                                  {row.compartidos!=="[]" ? (<Button  className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Compartida"                         
                                ><PeopleFill size={22} className="button-default-black" color="#000" />
                                </Button>):""} 
                            </div>
                        )
                    }
                },
            ];

            const selectRow = {
                mode: 'checkbox',
                clickToSelect: true,
                style: { backgroundColor: '#6ee3af' },
                selected: checkboxStates,
                onSelect: (row, isSelect, rowIndex, e) => {
                    if (isSelect) {
                
                      
                        setCheckboxStates([...checkboxStates, row.id]);
                        setReqSeleccionadas([...reqSeleccionadas, row]);
                      
                    } else {
                        
                       
                        let eliminarIdReqSeleccionada = checkboxStates.filter(x => x !== row.id);
                        setCheckboxStates(eliminarIdReqSeleccionada);
                        let eliminarReqSeleccionada = reqSeleccionadas.filter(x => x !== row);
                        setReqSeleccionadas(eliminarReqSeleccionada);
                      
                   
                    }
                },
                onSelectAll: (isSelect, rows, e) => {
                    const ids = listaPendientes.map(row => row.id);
                    const reqs = listaPendientes.map(row => row);
                    if (isSelect) {
                       
                            setAprobacionMasivaButton(false)
                        
                       
                        setCheckboxStates(ids);
                        setReqSeleccionadas(reqs);
                    } else {
                       
                        
                            setAprobacionMasivaButton(true)
                        
                        setCheckboxStates([]);
                        setReqSeleccionadas([]);
                    }
                },
            };
            const columns = [
                {
                    id: 'titlePendiente',
                    dataField: 'pendiente',
                    text: '',
                    isDummyField: true,
                    formatter: (cellContent, row) => {
                        return (
                            <div className="font-title-req-pendientes">
                                <div >{'Pendiente'}</div>
                            </div>
                        )
                    },
                },
                {
                    dataField: 'id',
                    text: 'ID',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    formatter: (cellContent, row) => {
                        return (
                            <div className="d-flex justify-content-center req-tbody-tr-td-div ">
                                <div className="id-req">{'ID: '}</div>
                                <div>{row.id}</div>
                            </div>
                        )
                    },
                    sort: true
                },
                {
                    dataField: 'concepto',
                    text: 'Concepto',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    formatter: (cellContent, row) => {
                        return (
                            <div className="d-flex justify-content-center req-tbody-tr-td-div ">
                                <div className="id-req">{'Concepto: '}</div>
                                <div>{row.concepto}</div>
                            </div>
                        )
                    },
                    sort: true
                },
                {
                    dataField: 'acciones',
                    text: '',
                    isDummyField: true,
                    formatter: (cellContent, row) => {
                        return (
                            <div className="d-flex justify-content-center req-tbody-tr-td-div">
                                <ButtonGroup>
                                    <Button id="consultarRequisicion" className="button-default-black" variant="light" size="sm"
                                        data-toggle="tooltip" data-placement="bottom" title="Consultar"
                                        onClick={() => viewRequisicion(row)}>
                                        <Eye size={24} className="button-default-black" color="#000" />
                                    </Button>
                                    {!row.usuarioCompartido?(        <Button id="editarRequisicion" className="button-default-black" variant="light" size="sm"
                                        data-toggle="tooltip" data-placement="bottom" title="Editar"
                                        onClick={() => getRequisicion(row)}
                                    ><PencilSquare size={24} className="button-default-black" color="#000" />
                                    </Button>):""}
                            
                                    {!row.usuarioCompartido ? (
                                          <Button id="eliminarRequisicion" className="button-default-black" variant="light" size="sm"
                                          data-toggle="tooltip" data-placement="bottom" title="Eliminar"
                                          onClick={() => confirmDelete(row)}
                                      ><Trash size={24} className="button-default-black" color="#000" />
                                      </Button>
                                    ):""}
                                  
                                    {
                                         (userType === 'SCHEMA_SOLICITANTE' || userType === 'SCHEMA_ADMIN')&&!row.usuarioCompartido ?
                                            <Button id="pedirAprobacion" className="button-default-black" variant="light" size="sm"
                                                data-toggle="tooltip" data-placement="bottom" title="Pedir aprobación"
                                                onClick={() => aprobarRequisicion(row)}
                                            ><CheckCircle size={24} className="button-default-black" color="#000" />
                                            </Button>
                                            : ''
                                    }
                                    {
                                      (userType === 'ADMINISTRADOR' || userType === 'SCHEMA_ADMIN')&&!row.usuarioCompartido ?
                                            <Button disabled={row.pagadorId==="0"} variant="light" className="button-default-black" title="Aprobar" onClick={() => confirmarRequisicion(row)}>
                                                <CheckAll size={22} className="button-default-black" color="#000" />
                                            </Button>
                                            : ''
                                    }
                                     {
                                      (userType === 'ADMINISTRADOR'|| userType === 'SCHEMA_ADMIN')&&!row.usuarioCompartido &&row.pagadorId==="0" ?
                                            <Button variant="light" className="button-default-black" title="Asignarme como pagador" onClick={() => asignacionRequisicion(row)}>
                                                <ClipboardPlus size={22} className="button-default-black" color="#000" />
                                            </Button>
                                            : ''
                                    }
                                     {!row.esCompartida ? (<Button  className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Eliminar" 
                                ><PeopleFill size={22} className="button-default-black" color="#000" />
                                </Button>):""} 
                                </ButtonGroup>
                                
                            </div>
                        )
                    }
                },
                {
                    dataField: 'solicitante',
                    text: 'Solicitante',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    formatter: (cellContent, row) => {
                        return (
                            <div className="req-tbody-tr-td-div">
                                <div className="font-col-req">{'Solicitante: '}</div>
                                <div>{row.solicitante}</div>
                            </div>
                        )
                    },
                    sort: true
                },
                {
                    dataField: 'proveedorRfc',
                    text: 'RFC proveedor ',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    formatter: (cellContent, row) => {
                        return (
                            <div className="req-tbody-tr-td-div">
                                <div className="font-col-req">{'RFC Proveedorxd: '}</div>
                                <div>{row.proveedorRfc}</div>
                            </div>
                        )
                    },
                    sort: true
                },
                {
                    dataField: 'proveedor',
                    text: 'Proveedor',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    formatter: (cellContent, row) => {
                        return (
                            <div className="req-tbody-tr-td-div">
                                <div className="font-col-req">{'Proveedor: '}</div>
                                <div>{row.proveedor}</div>
                            </div>
                        )
                    },
                    sort: true
                },
                {
                    dataField: 'fechaRequeridaPago',
                    text: '',
                    formatter: (cellContent, row) => {
                        return (
                            <div className="req-tbody-tr-td-div">
                                <div className="font-col-req">{'Fecha requerida: '}</div>
                                <div>{Moment(row.fechaRequeridaPago).format('DD/MM/YYYY')}</div>
                            </div>
                        )
                    },
                    sort: true
                },
                {
                    dataField: 'porcentajeTotal',
                    text: '',
                    formatter: (cellContent, row) => {
                        return (
                            <div className="req-tbody-tr-td-div">
                                <div className="font-col-req">{'% del Total: '}</div>
                                <NumberFormat
                                    displayType={'text'} prefix={'%'}
                                    thousandSeparator={true}
                                    value={row.porcentajeTotal.toFixed(2/*row.monedaDecimales*/)}
                                ></NumberFormat>
                            </div>
                        )
                    },
                    sort: true
                },
                {
                    dataField: 'importePesosMexicanos',
                    text: '',
                    formatter: (cellContent, row) => {
                        return (
                            <div className="req-tbody-tr-td-div">
                                <div className="font-col-req">{'Total: '}</div>
                                <NumberFormat
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    value={row.importePesosMexicanos.toFixed(2/*row.monedaDecimales*/)}

                                ></NumberFormat>
                            </div>
                        )
                    },
                    sort: true
                },
                {
                    dataField: 'totalRequerido',
                    text: '',
                    formatter: (cellContent, row) => {
                        return (
                            <div className="req-tbody-tr-td-div">
                                <div className="font-col-req">{'Total requerido: '}</div>
                                <NumberFormat
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    value={row.totalRequerido.toFixed(/*row.monedaDecimales*/)}
                                ></NumberFormat>
                            </div>
                        )
                    },
                    sort: true
                },
                {
                    dataField: 'diasVencimiento',
                    text: '',
                    formatter: (cellContent, row) => {
                        return (
                            <div className="req-tbody-tr-td-div">
                                <div className="font-col-req">{'Días de vencimiento: '}</div>
                                <div>
                                    {row.diasVencimiento >= 10 ?
                                        <h6><Badge variant="success">{row.diasVencimiento}</Badge></h6>
                                        :
                                        row.diasVencimiento <= 9 && row.diasVencimiento >= 4 ?
                                            <h6><Badge variant="warning">{row.diasVencimiento}</Badge></h6>
                                            :
                                            row.diasVencimiento <= 3 && row.diasVencimiento >= 1 ?
                                                <h6><Badge className="vencimientoNaranja">{row.diasVencimiento}</Badge></h6>
                                                :
                                                row.diasVencimiento <= 0 && row.diasVencimiento >= -5 ?
                                                    <h6><Badge variant="danger">{row.diasVencimiento}</Badge></h6>
                                                    :
                                                    row.diasVencimiento <= -6 ?
                                                        <h6><Badge className="vencimientoMorado">{row.diasVencimiento}</Badge></h6>
                                                        :
                                                        row.diasVencimiento}
                                </div>
                            </div>
                        )
                    },
                    sort: true
                }
            ];

            function indication() {
                return listaPendientes.length ? "La búsqueda no coincide con ningún criterio" : "No hay registros que mostrar";
            }

            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size}
                </span>
            );

            const options = {
                custom: true,
                sizePerPage: gridCards ? 4 : 10,
                totalSize: listaPendientes.length,
                paginationTotalRenderer: customTotal
            }

            return <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div>
                        <div className="d-flex ml-auto mb-2 flex-row-reverse">
                            {/*
                                userType === 'ADMINISTRADOR' || userType === 'SCHEMA_ADMIN' ?
                                    <button
                                        className="ml-1 button-default-req"
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title="Aprobar requisiciones"
                                        onClick={() => { setModalAprobarRequisicionesShow(true) }}
                                    ><span>Autorización múltiple</span>
                            </button> : ''*/}
                            {gridCards ?
                                <Button className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Listado"
                                    onClick={() => { setGridCards(false) }}
                                >
                                    <ListTask size={24} className="button-default-black" color="#000" />
                                </Button> :
                                <Button className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Cuadrícula"
                                    onClick={() => { setGridCards(true) }}
                                >
                                    <Grid3x2GapFill size={24} className="button-default-black" color="#000" />
                                </Button>
                            }
                        </div>
                        <div className="d-flex">
                            {/*token.userType==="SCHEMA_SOLICITANTE" || token.userType==="SCHEMA_ADMIN"?(
                            <button
                            className={`m-1 ${aprobacionMasivaButton ? 'button-default-subir-archivo-disabled' : 'button-default-subir-archivo'}`}
                            mata-toggle="tooltip"
                            data-placement="bottom"
                            title="Confirmar"
                            disabled={aprobacionMasivaButton} 
                            onClick={aprobarMasivamente}
                            >Pedir aprobaciones
                            </button>
                            ):""
                        */}
                        
                            {gridCards ? '' :
                                <div className="d-flex flex-row">
                                    <Form.Label column>Mostrar</Form.Label>
                                    <Col>
                                        {/* <Form.Control size="sm" as="select" onChange={(e) => handleSizePerPage(paginationProps, e.target.value)}>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value={listaPendientes.length}>Todos</option>
                                        </Form.Control> */}
                                        <SizePerPageDropdownStandalone {...paginationProps} btnContextual />
                                    </Col>
                                </div>}
                            <div className="d-flex flex-row">
                                <Col>
                                    <PaginationTotalStandalone {...paginationProps} />
                                </Col>
                            </div>
                            <div className="d-flex ml-auto flex-row-reverse">
                                <Col>
                                    <PaginationListStandalone {...paginationProps} />
                                </Col>
                                <Form.Label column>Página</Form.Label>
                               
                            </div>
                        </div>
                        {
                            gridCards ?
                                <div className="table-responsive table-sm">
                                    {
                                      (userType === 'SCHEMA_SOLICITANTE' || userType === 'SCHEMA_ADMIN')?
                                        <BootstrapTable
                                          id="reqPendientes"
                                          keyField='id'
                                          data={listaPendientes}
                                          columns={columns}
                                          selectRow={selectRow}
                                          filter={filterFactory()}
                                          bordered={false}
                                          noDataIndication={indication}
                                          {...paginationTableProps}
                                        />: 
                                        <BootstrapTable
                                          id="reqPendientes"
                                          keyField='id'
                                          data={listaPendientes}
                                          columns={columns}
                                          filter={filterFactory()}
                                          bordered={false}
                                          noDataIndication={indication}
                                          {...paginationTableProps}
                                        />
                                    }
                                </div>
                                :
                                <div className="table-responsive table-sm text-center">

{
                                      (userType === 'SCHEMA_SOLICITANTE' || userType === 'SCHEMA_ADMIN')?
                                        <BootstrapTable
                                            keyField='id'
                                            data={listaPendientes}
                                            columns={columnsList}
                                            filter={filterFactory()}
                                            headerClasses="header-class-pendientes"
                                            bordered={false}
                                            selectRow={selectRow}
                                            striped
                                            hover
                                            condensed
                                            noDataIndication={indication}
                                            {...paginationTableProps}
                                        />
                                        : 
                                        <BootstrapTable
                                            keyField='id'
                                            data={listaPendientes}
                                            columns={columnsList}
                                            filter={filterFactory()}
                                            headerClasses="header-class-pendientes"
                                            bordered={false}
                                            striped
                                            hover
                                            condensed
                                            noDataIndication={indication}
                                            {...paginationTableProps}
                                        />
                                    }
                                </div>
                        }

                    </div>
                )}
            </PaginationProvider >
        }
    }

    return (
        <section id="listaReqPendientes">
            <Card id="cardReqPendientes">
                <div>
                    {reqConsulta !== '' ? <ModalConsultarRequisicion show={modalConsultarRequisicionShow} onHide={modalConsultarRequisicionClose}>{reqConsulta}</ModalConsultarRequisicion> : ''}
                    {reqEditar !== '' ? <ModalEditarRequisicion show={modalEditarRequisicionShow} onHide={modalEditarRequisicionClose} onUpdate={updateListado}>{reqEditar}</ModalEditarRequisicion> : ''}
                    {reqDelete !== '' ? <ModalDeleteRequisicion show={modalDeleteRequisicionShow} onHide={modalDeleteRequisicionClose} onUpdate={updateListado}>{reqDelete}</ModalDeleteRequisicion> : ''}
                    {reqPedirA !== '' ? <ModalPedirAprobacion show={modalPedirAprobacionShow} onHide={modalPedirAprobacionClose} onUpdate={updateListado}>{reqPedirA}</ModalPedirAprobacion> : ''}
                    {reqConfirmar !== '' ? <ModalConfirmarRequisicion show={modalConfirmarShow} onHide={modalConfirmarClose} onUpdate={updateListado}>{reqConfirmar}</ModalConfirmarRequisicion> : ''}
                    {modalAsignationShow !== '' ? <ModalAsignacion show={modalAsignationShow} onHide={modalAssignationClose}  onUpdate={updateListado}>{reqConfirmar}</ModalAsignacion> : ''
                    }
                    {modalAprobarRequisicionesShow ? <ModalAprobarRequisiciones show={modalAprobarRequisicionesShow} onHide={modalAprobarRequisicionesClose} onUpdate={updateListado}>{listaPendientes}{empresaAlias}</ModalAprobarRequisiciones> : ''}
                </div>
                <Card.Body className="cardBodyReqPendientes">
                    {request ? "Cargando..." : mostrarListaPendientes()}
                </Card.Body>
            </Card>
        </section>
    );
}

export default RequisicionesPendientes;