import React, { useState } from 'react';
import axios from "axios";
import { API_PROVEEDORES_URL } from '../../constants'

//Estilos
import "./css/Cfdis.css";
import { FileEarmarkArrowUp } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';

//Elementos bootstrap
import { Form, Modal, Col } from "react-bootstrap";

function ModalSubirXML(props) {
    const [deactivate, setDeactivate] = useState(false);
    const idRequisicion = props.children.idRequisicion;
    const idEmpresa = props.children.idEmpresa;
    const [status, setStatus] = useState(false);
    const [file, setFile] = useState('');

    function handleFileChange(e) {
        setFile(e.target.files[0]);
        let file = e.target.files[0];
        let output = [];
        output.push('<li><strong>', escape(file.name), '</strong> (', file.type || 'n/a', ') - ', file.size, ' bytes</li>');
        document.getElementById("list").innerHTML = '<ol>' + output.join('') + '</ol>';
    }

    function subirArchivo(e) {
        e.preventDefault();
        setStatus(true);
        if (file === '') {
            setStatus(false);
            setDeactivate(true);

            setTimeout(() => {
                setDeactivate(false);
            }, 4500);

            toast.warning("No se ha seleccionado ningún archivo",{
                position:"top-center",
                autoClose: 4500, 
            });
        } else {
            const formData = new FormData();
            formData.append("idEmpresa", idEmpresa);
            formData.append("idRequisicion", idRequisicion);
            formData.append("xml", file);

            axios.post(API_PROVEEDORES_URL + 'saveXML', formData,
                {
                    headers: { 'Content-Type': `multipart/form-data;` }
                }
            ).then((res) => {
                let output = [];
                if (res.data.result.code === 200) {
                    setStatus(false);
                    toast.success(res.data.result.message,{
                        position:"top-center",
                        autoClose: 4500, 
                    });
                    output.push('<strong>', res.data.result.message, '</strong>');
                    document.getElementById("listResponse").innerHTML = '<ol>' + output.join('') + '</ol>';
                } else if (res.data.result.code === 500) {
                    setStatus(false);
                    toast.warning(res.data.result.message,{
                        position:"top-center",
                        autoClose: 4500, 
                    });
                    toast.warning(res.data.result.suggestion,{
                        position:"top-center",
                        autoClose: 4500, 
                    });
                } else {
                    toast.error("Ha ocurrido un problema al subir el archivo XML. Inténtalo de nuevo",{
                        position:"top-center",
                        autoClose: 4500, 
                    });
                    setStatus(false);
                }
            }).catch((error) => {
                toast.error("Ha ocurrido un problema con el servidor. Inténtalo de nuevo",{
                    position:"top-center",
                    autoClose: 4500, 
                });
                setStatus(false);
            })
        }
    }

    return (
        <section className="text-center">
            <Modal
                {...props}
                size="md"
                id="modalSubirXML"
                centered
            >
                <Modal.Header className="modal-header-subir-archivo " closeButton>
                    <p><FileEarmarkArrowUp size={22} color="#5B4B51" /> &nbsp;Subir archivo XML - Requisición {props.children.id}</p>
                </Modal.Header>
                <Modal.Body>
                    <fieldset>
                        <Form.Row as={Col}>
                            <Form.Group as={Col}>
                                <div className="div-facturas-subidas p-2">
                                    <Form.File.Label>Sólo permite archivo con extensión .xml</Form.File.Label>
                                    <output id="list"></output>
                                    <output id="listResponse"></output>
                                </div>
                                <div className="p-2">
                                    <input type="file" id="xmlFile" name="xmlFile" accept=".xml" onChange={handleFileChange}></input>
                                    <div className="d-flex justify-content-center">
                                        <button
                                            className="m-1 button-default-subir-archivo"
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                            title="Subir archivos"
                                            disabled = { deactivate }
                                            onClick={subirArchivo}
                                        >{status ?
                                            <span>Guardando...</span>
                                            : <span>Subir archivo</span>}
                                        </button>
                                        <button
                                            className="m-1 button-default-subir-archivo"
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                            title="Cancelar"
                                            onClick={props.onHide}
                                        ><span>Cancelar</span>
                                        </button>
                                    </div>
                                </div>
                            </Form.Group>
                        </Form.Row>
                    </fieldset>
                </Modal.Body>
            </Modal>
        </section>
    );
}

export default ModalSubirXML;
