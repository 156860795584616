import React, { useState, useEffect } from "react";
import axios from 'axios'
import { useSelector } from 'react-redux';
import { API_PROVEEDORES_URL } from '../../constants';

//Estilos
import './CuentasEmisoras.css'
import { Eye, PencilSquare, Trash, ListTask, Grid3x2GapFill, CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';

//Elementos bootstrap
import { Card, Col, Form, Button, ButtonGroup, Tab, Tabs } from 'react-bootstrap';

//Componentes
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ModalNuevaCuentaEmisora from './ModalNuevaCuentaEmisora';
import ModalConsultarCuentaEmisora from './ModalConsultarCuentaEmisora';
import ModalEditarCuentaEmisora from './ModalEditarCuentaEmisora';
import ModalBorrarCuentaEmisora from './ModalBorrarCuentaEmisora';
import Loader from "../Loader";

function ListaCuentasEmisoras() {
    const [error, setError] = useState(false);
    const [status, setStatus] = useState('');
    const empresasDisponibles = useSelector(state => state.empresas.empresasDisponibles);
    let empresas = empresasDisponibles.map(e => { return e.empresa; });
    const empresaDefault = empresas[0];
    const [cargando, setCargando] = useState(false);
    const [key, setKey] = useState(empresaDefault);
    const [listaCuentasEmisoras, setListaCuentasEmisoras] = useState([]);
    const [updateListado, setUpdateListado] = useState(false);

    const [modalNuevaCuentaEmisoraShow, setModalNuevaCuentaEmisoraShow] = useState(false);
    let modalNuevaCuentaEmisoraClose = () => setModalNuevaCuentaEmisoraShow(false);

    let updateGetCuentasEmisoras = () => { setUpdateListado(!!!updateListado); setListaCuentasEmisoras([]); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getCuentasEmisoras, [updateListado]);

    function getCuentasEmisoras() {
        setCargando(true);
        axios.post(API_PROVEEDORES_URL + 'listCuentasEmisorasByEmpresas',
            {
                empresas: empresas
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                setListaCuentasEmisoras(res.data.cuentasEmisoras)
            } else {
                setError(true);
                setListaCuentasEmisoras('')
                setStatus(res.data.result.message);
            }
        }).catch((error) => {
            setError(true);
            setStatus("Error: No se han podido recuperar las cuentas emisoras de esta empresa");
        }).finally(() => {
            setCargando(false);
        })
    }

    function createTabs() {
        let empresasTabs;
        listaCuentasEmisoras.length ?
            empresasTabs = listaCuentasEmisoras.map(empresa => (
                <Tab key={empresa.id} eventKey={empresa.id} title={empresa.nombreEmpresa}>
                    <CuentasEmisoras onUpdate={updateGetCuentasEmisoras}>{empresa.lista}</CuentasEmisoras>
                </Tab>
            )) : empresasTabs = empresas.map(empresa => (
                <Tab key={empresa} eventKey={empresa} title={empresa}>
                    <section id="listaCuentasEmisoras">
                        <div className="d-flex ml-auto mb-2 flex-row-reverse">
                            <button
                                className="ml-1 button-default-req"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Agregar Cuenta emisora"
                                onClick={() => { setModalNuevaCuentaEmisoraShow(true) }}
                            ><span>Agregar cuenta emisora</span>
                            </button>
                            {modalNuevaCuentaEmisoraShow ? <ModalNuevaCuentaEmisora show={modalNuevaCuentaEmisoraShow} onHide={modalNuevaCuentaEmisoraClose} onUpdate={updateGetCuentasEmisoras}></ModalNuevaCuentaEmisora> : ''}
                        </div>
                        <Card id="cardCuentasEmisors">
                            <Card.Body className="cardBodyCuentasEmisoras">
                                {error ? status : 'Error: No se ha podido cargar la información'}
                            </Card.Body>
                        </Card>
                    </section>
                </Tab>
            ));
        return empresasTabs
    }

    if (cargando) {
        return <Loader />
    }

    return (
        <div >
            <Tabs id="controlled-tab-cuentas-emisoras" activeKey={key} onSelect={(k) => setKey(k)}>
                {createTabs()}
            </Tabs>
        </div >
    );
}


function CuentasEmisoras(props) {
    const [error] = useState(false);
    const [status] = useState('Error: No se ha podido recuperar la lista de cuentas emisoras de pago');
    const [request] = useState(false);
    const [gridCards, setGridCards] = useState(false);
    const [listaCuentasEmisoras] = useState(props.children);

    const [ctaEmisoraConsultar, setCtaEmisoraConsultar] = useState('');
    const [ctaEmisoraEditar, setCtaEmisoraEditar] = useState('');
    const [ctaEmisoraBorrar, setCtaEmisoraBorrar] = useState('');
    const [modalNuevaCuentaEmisoraShow, setModalNuevaCuentaEmisoraShow] = useState(false);
    const [modalConsultarCuentaEmisoraShow, setModalConsultarCuentaEmisoraShow] = useState(false);
    const [modalEditarCuentaEmisoraShow, setModalEditarCuentaEmisoraShow] = useState(false);
    const [modalBorrarCuentaEmisoraShow, setModalBorrarCuentaEmisoraShow] = useState(false);

    let modalNuevaCuentaEmisoraClose = () => setModalNuevaCuentaEmisoraShow(false);
    let modalConsultarCuentaEmisoraClose = () => { setModalConsultarCuentaEmisoraShow(false); setCtaEmisoraConsultar('') };
    let modalEditarCuentaEmisoraClose = () => { setModalEditarCuentaEmisoraShow(false); setCtaEmisoraEditar('') };
    let modalBorrarCuentaEmisoraClose = () => { setModalBorrarCuentaEmisoraShow(false); setCtaEmisoraBorrar('') };
    let updateListado = () => { props.onUpdate() };

    function getCuentaEmisora(cuentaEmisora) {
        setCtaEmisoraConsultar(cuentaEmisora);
        setModalConsultarCuentaEmisoraShow(true);
    }

    function updateCuentaEmisora(cuentaEmisora) {
        setCtaEmisoraEditar(cuentaEmisora);
        setModalEditarCuentaEmisoraShow(true);
    }

    function deleteCuentaEmisora(cuentaEmisora) {
        setCtaEmisoraBorrar(cuentaEmisora);
        setModalBorrarCuentaEmisoraShow(true);
    }

    let mostratListaCuentasEmisoras = () => {
        if (error) {
            return (
                <div>{status}</div>
            );
        } else {
            const columnsList = [
                {
                    dataField: 'id',
                    text: 'ID',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    }),
                    sort: true,
                    sortCaret: (order, column) => {
                        if (!order) return (<span>&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000" /><CaretUpFill size={10} className="button-default-black" color="#000" /></span>);
                        else if (order === "desc") return (<span>&nbsp;<CaretUpFill size={10} className="button-default-black" color="#000" /><CaretDownFill size={10} className="button-default-black" color="#dee2e6" /></span>);
                        else if (order === "asc") return (<span>&nbsp;<CaretDownFill size={10} className="button-default-black" color="#000" /><CaretUpFill size={10} className="button-default-black" color="#dee2e6" /></span>);
                        return null;
                    }
                }, {
                    dataField: 'banco',
                    text: 'Banco',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    })
                }, {
                    dataField: 'cuenta',
                    text: 'Cuenta bancaria',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    })
                }, {
                    dataField: 'referencia',
                    text: 'Referencia',
                    filter: textFilter({
                        placeholder: 'Buscar...',
                        className: "form-control-sm"
                    })
                }, {
                    dataField: 'acciones',
                    text: 'Acciones',
                    isDummyField: true,
                    formatter: (cellContent, row) => {
                        return (
                            <div>
                                <Button id="consultarCuentaE" className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Consultar"
                                    onClick={() => getCuentaEmisora(row)}>
                                    <Eye size={22} className="button-default-black" color="#000" />
                                </Button>
                                <Button id="editarCuentaE" className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Editar"
                                    onClick={() => updateCuentaEmisora(row)}
                                ><PencilSquare size={22} className="button-default-black" color="#000" />
                                </Button>
                                <Button id="eliminarCuentaE" className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Eliminar"
                                    onClick={() => deleteCuentaEmisora(row)}
                                ><Trash size={22} className="button-default-black" color="#000" />
                                </Button>
                            </div>
                        )
                    }
                }
            ];

            const columns = [{
                id: 'titleCuenta',
                dataField: 'cuenta',
                text: '',
                isDummyField: true,
                formatter: (cellContent, row) => {
                    return (
                        <div className="font-title-cuenta-emisora">
                            <div>{'Cuenta'}</div>
                        </div>
                    )
                }
            }, {
                dataField: 'id',
                text: 'ID',
                sort: true,
                isDummyField: true,
                filter: textFilter({
                    placeholder: 'Buscar...',
                    className: "form-control-sm"
                })
            }, {
                dataField: 'banco',
                text: 'Banco',
                sort: true,
                isDummyField: true,
                filter: textFilter({
                    placeholder: 'Buscar...',
                    className: "form-control-sm"
                })
            }, {
                dataField: 'cuenta',
                text: 'Cuenta bancaria',
                sort: true,
                isDummyField: true,
                filter: textFilter({
                    placeholder: 'Buscar...',
                    className: "form-control-sm"
                })
            }, {
                dataField: 'referencia',
                text: 'Referencia',
                sort: true,
                isDummyField: true,
                filter: textFilter({
                    placeholder: 'Buscar...',
                    className: "form-control-sm"
                })
            }, {
                dataField: 'acciones',
                isDummyField: true,
                text: '',
                formatter: (cellContent, row) => {
                    return (
                        <div className="d-flex justify-content-center cem-tbody-tr-td-div">
                            <ButtonGroup>
                                <button className="button-default-black" title="Consultar" onClick={() => getCuentaEmisora(row)}
                                ><Eye size={24} color="#000" className="button-default-black" />
                                </button>
                                <button className="button-default-black" title="Editar" onClick={() => updateCuentaEmisora(row)}
                                ><PencilSquare size={24} color="#000" className="button-default-black" />
                                </button>
                                <button className="button-default-black" title="Eliminar" onClick={() => deleteCuentaEmisora(row)}
                                ><Trash size={24} color="#000" />
                                </button>
                            </ButtonGroup>
                        </div>
                    )
                }
            }, {
                dataField: 'rowOne',
                text: '',
                isDummyField: true,
                formatter: (cellContent, row) => {
                    return (
                        <div className="d-flex justify-content-around cem-tbody-tr-td-div">
                            <div>
                                <div className="cem-tbody-tr-td-div">
                                    <div className="font-col-cuenta-emisora">{'ID: '}</div>
                                    <div className="">{row.id}</div>
                                </div>
                            </div>
                            <div>
                                <div className="cem-tbody-tr-td-div">
                                    <div className="font-col-cuenta-emisora">{'Banco: '}</div>
                                    <div className="">{row.banco}</div>
                                </div>
                            </div>
                        </div>
                    )
                }
            }, {
                dataField: 'rowThree',
                text: '',
                isDummyField: true,
                formatter: (cellContent, row) => {
                    return (
                        <div className="d-flex justify-content-around cem-tbody-tr-td-div">
                            <div>
                                <div className="cem-tbody-tr-td-div">
                                    <div className="font-col-cuenta-emisora">{'Cuenta: '}</div>
                                    <div className="">{row.cuenta}</div>
                                </div>
                                <div className="cem-tbody-tr-td-div">
                                    <div className="font-col-cuenta-emisora">{'Cuenta clabe: '}</div>
                                    <div className="">{row.cuentaClabe}</div>
                                </div>
                            </div>
                        </div>
                    )
                }
            }, {
                dataField: 'rowTwo',
                text: '',
                isDummyField: true,
                formatter: (cellContent, row) => {
                    return (
                        <div className="d-flex justify-content-around cem-tbody-tr-td-div">
                            <div>
                                <div className="cem-tbody-tr-td-div">
                                    <div className="font-col-cuenta-emisora">{'Comentario: '}</div>
                                    <div className="">{row.comentario}</div>
                                </div>
                                <div className="cem-tbody-tr-td-div">
                                    <div className="font-col-cuenta-emisora">{'Referencia: '}</div>
                                    <div className="">{row.referencia}</div>
                                </div>
                            </div>
                        </div>
                    )
                }
            }];

            function indication() {
                return listaCuentasEmisoras.length ? "Cargando..." : "No hay registros que mostrar";
            }

            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size}
                </span>
            );

            const options = {
                custom: true,
                sizePerPage: gridCards ? 4 : 10,
                totalSize: listaCuentasEmisoras.length,
                paginationTotalRenderer: customTotal
            }

            return <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div>
                        <div className="d-flex ml-auto mb-2 flex-row-reverse">
                            <button
                                className="ml-1 button-default-req"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Agregar Cuenta emisora"
                                onClick={() => { setModalNuevaCuentaEmisoraShow(true) }}
                            ><span>Agregar cuenta emisora</span>
                            </button>
                            {gridCards ?
                                <Button className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Listado"
                                    onClick={() => { setGridCards(false) }}
                                >
                                    <ListTask size={24} className="button-default-black" color="#000" />
                                </Button> :
                                <Button className="button-default-black" variant="light" size="sm"
                                    data-toggle="tooltip" data-placement="bottom" title="Cuadrícula"
                                    onClick={() => { setGridCards(true) }}
                                >
                                    <Grid3x2GapFill size={24} className="button-default-black" color="#000" />
                                </Button>
                            }
                        </div>
                        <div className="d-flex">
                            {gridCards ? '' :
                                <div className="d-flex flex-row">
                                    <Form.Label column>Mostrar</Form.Label>
                                    <Col>
                                        {/* <Form.Control size="sm" as="select" onChange={(e) => handleSizePerPage(paginationProps, e.target.value)}>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value={listaCuentasEmisoras.length}>Todos</option>
                                        </Form.Control> */}
                                        <SizePerPageDropdownStandalone {...paginationProps} btnContextual />
                                    </Col>
                                </div>
                            }
                            <div className="d-flex flex-row">
                                <Col>
                                    <PaginationTotalStandalone {...paginationProps} />
                                </Col>
                            </div>
                            <div className="d-flex ml-auto flex-row-reverse">
                                <Col>
                                    <PaginationListStandalone {...paginationProps} />
                                </Col>
                                <Form.Label column>Página</Form.Label>
                            </div>
                        </div>
                        {
                            gridCards ?
                                <div className="table-responsive table-sm">
                                    <BootstrapTable
                                        id="cuentaEmisora"
                                        keyField='id'
                                        data={listaCuentasEmisoras}
                                        columns={columns}
                                        filter={filterFactory()}
                                        bordered={false}
                                        noDataIndication={indication}
                                        {...paginationTableProps}
                                    />
                                </div>
                                :
                                <div className="table-responsive table-sm text-center">
                                    <BootstrapTable
                                        keyField='id'
                                        data={listaCuentasEmisoras}
                                        columns={columnsList}
                                        filter={filterFactory()}
                                        headerClasses="header-class-pendientes"
                                        bordered={false}
                                        striped
                                        hover
                                        condensed
                                        noDataIndication={indication}
                                        {...paginationTableProps}
                                    />
                                </div>
                        }
                    </div>
                )}
            </PaginationProvider>
        }
    }

    return (
        <section id="listaCuentasEmisoras" className="text-center">
            <Card id="cardCuentasEmisoras">
                {/*<div className="d-flex justify-content-center">
                    <Button
                        size="md"
                        className="m-1 btn-add-cuenta-emisora "
                        variant="light"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Cancelar"
                        onClick={() => setModalNuevaCuentaEmisoraShow(true)}
                    ><span>Agregar cuenta emisora </span>
                        <CreditCard2Front size={24} color="white" />
                    </Button>
                </div>*/}
                <div>
                    {modalNuevaCuentaEmisoraShow ? <ModalNuevaCuentaEmisora show={modalNuevaCuentaEmisoraShow} onHide={modalNuevaCuentaEmisoraClose} onUpdate={updateListado}></ModalNuevaCuentaEmisora> : ''}
                    {modalConsultarCuentaEmisoraShow ? <ModalConsultarCuentaEmisora show={modalConsultarCuentaEmisoraShow} onHide={modalConsultarCuentaEmisoraClose} onUpdate={updateListado}>{ctaEmisoraConsultar}</ModalConsultarCuentaEmisora> : ''}
                    {modalEditarCuentaEmisoraShow ? <ModalEditarCuentaEmisora show={modalEditarCuentaEmisoraShow} onHide={modalEditarCuentaEmisoraClose} onUpdate={updateListado}>{ctaEmisoraEditar}</ModalEditarCuentaEmisora> : ''}
                    {modalBorrarCuentaEmisoraShow ? <ModalBorrarCuentaEmisora show={modalBorrarCuentaEmisoraShow} onHide={modalBorrarCuentaEmisoraClose} onUpdate={updateListado}>{ctaEmisoraBorrar}</ModalBorrarCuentaEmisora> : ''}
                </div>
                <Card.Body className="cardBodyCuentasEmisoras">
                    {request ? "Cargando..." : mostratListaCuentasEmisoras()}
                </Card.Body>
            </Card>
        </section>
    )
}

export default ListaCuentasEmisoras;