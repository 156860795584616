import React, { useState } from "react";
import { useSelector } from 'react-redux';

//Componentes bootstrap
import { Form, Col } from "react-bootstrap";

function SeleccionarEmpresa(props) {
    const [empresa, setEmpresa] = useState(props.children);
    const empresasDisponibles = useSelector(state => state.empresas.empresasDisponibles);

    function handleEmpresaChange(e) {
        var str = e.target.value;
        setEmpresa(str);
        props.onEmpresaSelected(str);
    }

    function cargarSelectEmpresas() {
        return empresasDisponibles.length ? empresasDisponibles.map(e => {
            return (<option key={e.empresaId} value={e.empresa + "," + e.empresaId}>{e.empresa}</option>)
        }) : 'No existen empresas registradas'
    }

    return (
        <Form.Group as={Col} lg={6}>
            <Form.Label>Empresa</Form.Label>
            <Form.Control size="sm" id="empresa" type="text" as="select" required placeholder="Seleccione empresa" defaultValue={empresa} onChange={handleEmpresaChange}>
                <option default value="">Seleccione empresa</option>
                {cargarSelectEmpresas()}
            </Form.Control>
        </Form.Group>
    );
}

export default SeleccionarEmpresa;
