import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_PROVEEDORES_URL } from '../../../constants'

//Estilos
import { FileArrowDown } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';

//Componentes bootstrap
import { Button, ButtonGroup, Col, Form } from 'react-bootstrap';

//Componentes
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { base64ToArrayBuffer, saveByteArray } from '../../../constantes/download-file-handler'

function ListadoCotizacionesConsulta(props) {
    const idRequisicion = props.children[0];
    const idEmpresa = props.children[1];
    const [status, setStatus] = useState('');
    const [error, setError] = useState(false);
    const [listaCotizaciones, setListaCotizaciones] = useState([]);

    useEffect(getListadoCotizaciones, [idRequisicion]);//Se actualiza cada que se elige una requisición diferente*/

    function getListadoCotizaciones() {
        axios.post(API_PROVEEDORES_URL + 'files/getCotizaciones',
            {
                "idRequisicion": idRequisicion
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                setListaCotizaciones(res.data.list)
            } else {
                setError(true);
                setStatus(res.data.result.message);
            }
        }).catch((error) => {
            setError(true);
            setStatus("Error: No se han podido recuperar las cotizaciones registradas.")
        })
    };

    function getArchivoDownload(archivo) {
        axios.post(API_PROVEEDORES_URL + 'files/downloadCotizacion',
            {
                idEmpresa: idEmpresa,
                idRequisicion: archivo.idRequisicion,
                nombre: archivo.nombre
            }
        ).then((res) => {
            if (res.data.result.code === 200) {
                let arrayBuffer = base64ToArrayBuffer(res.data.bytes);
                saveByteArray(archivo.nombre, archivo.type, arrayBuffer);
            } else {
                toast.warning(res.data.result.message,{
                    position:"top-center",
                    autoClose: 4500, 
                });
            }
        }).catch((error) => {
            toast.error("No se ha podido descargar el archivo",{
                position:"top-center",
                autoClose: 4500, 
            });
        })
    }

    let handleSizePerPage = ({ page, onSizePerPageChange }, newSizePerPage) => {
        onSizePerPageChange(newSizePerPage, page);
    }

    let listarCotizaciones = () => {
        if (error) {
            return (
                <div>{status}</div>
            );
        } else {
            const columns = [
                {
                    dataField: 'nombre',
                    text: 'Nombre del archivo'
                }, {
                    dataField: 'acciones',
                    isDummy: true,
                    text: 'Acciones',
                    formatter: (cellContent, row) => {
                        return (
                            <ButtonGroup>
                                <Button variant="light" size="sm" className="button-default-black-transparent" title="Descargar archivo" onClick={() => getArchivoDownload(row)}>
                                    <FileArrowDown size={24} className="button-default-black-transparent" color="#000" />
                                </Button>
                            </ButtonGroup>
                        );
                    }
                }];

            function indication() {
                return listaCotizaciones.length ? "Cargando..." : "No hay registros que mostrar";
            }
            const customTotal = (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} - {to} de {size}
                </span>
            );
            const options = {
                custom: true,
                sizePerPage: 5,
                totalSize: listaCotizaciones.length,
                paginationTotalRenderer: customTotal
            };
            return <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => (
                    <div className="div-cotiz-consulta">
                        <Form.Row className="p-2">
                            <div className="d-flex flex-row">
                                <Form.Label>Mostrar</Form.Label>
                                <Col>
                                    <Form.Control size="sm" id="sizePerPage" as="select" onChange={(e) => handleSizePerPage(paginationProps, e.target.value)}>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="15">15</option>
                                        <option value={listaCotizaciones.length}>Todos</option>
                                    </Form.Control>
                                </Col>
                            </div>
                            <Col>
                                <PaginationTotalStandalone {...paginationProps} />
                            </Col>
                            <div className="d-flex  ml-auto flex-row-reverse">
                                <Col>
                                    <PaginationListStandalone {...paginationProps} />
                                </Col>
                                <Form.Label column>Página</Form.Label>
                            </div>
                        </Form.Row>
                        <div className="table-responsive table-sm text-center">
                            <BootstrapTable
                                id='cotizConsulta'
                                keyField='id'
                                data={listaCotizaciones}
                                columns={columns}
                                bordered={false}
                                noDataIndication={indication}
                                {...paginationTableProps}
                            />
                        </div>
                    </div>
                )}
            </PaginationProvider>
        }
    }

    return (
        <fieldset>
            <legend className="legend-consultar-req">Cotizaciones subidas</legend>
            <Form.Row as={Col}>
                <Form.Group as={Col}>
                    {listaCotizaciones.length ? listarCotizaciones() : status}
                </Form.Group>
            </Form.Row>
        </fieldset>
    );
}

export default ListadoCotizacionesConsulta;